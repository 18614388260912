import styled from '@emotion/styled';

const CategoryModalStyle = styled.form`
  width: 45rem;
  
  p[class^="MuiFormHelperText-error"] {
    background: #ffff00;
  }

  .product-dialog-content{
    padding-top: 1rem;
  }

  .action-group{
    margin: 16px;
  }

  & .form-container{
    & div[class^="MuiGrid-container"] {
        align-items: unset;
    }
  }
  
  .cancel-btn {
        min-width: 104px;
        border-radius: 24px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #0028c2;
        border: 1px solid #0028c2;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        & svg {
            margin-right: 8px;
            margin-bottom: 2px;
            
            & path {
                fill: #0028c2;
            }
        }
    }
    .submit-btn {
        min-width: 104px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #fff;
        font-size: 14px;
        margin-left: 24px;
        background: #0028c2;
        & path:nth-of-type(1){
            fill: #fff;
        }
        & svg {
            margin-right: 8px;
            margin-top: 3px;
        }
    }
`;
const InputCustomStyle = styled.div`
    width: 100%;
    p[class^="MuiFormHelperText-root"] {
        margin: 8px 0;
    }
`;
export { CategoryModalStyle, InputCustomStyle };