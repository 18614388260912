import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Zendesk from 'react-zendesk';
const ZENDESK_KEY = '6b36a20e-5218-4933-addb-b34dd1e27870';

const ProtectedRoute = ({
  component: Component,
  authenticated,
  redirectTo,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      authenticated ? (
        <>
          <Component {...props} />
          <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={() => console.log('is loaded')} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
