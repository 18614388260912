import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 24
  }
});

function EditIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill="#8A96C1"
        fillRule="nonzero"
        d="M13.02 6.95l4.243 4.242-9.899 9.9H3.121v-4.243l9.9-9.9zm0 1.98l-8.499 8.5v2.262h2.263l8.5-8.5L13.02 8.93zm7.072-4.809a3 3 0 0 1 0 4.243l-2.121 2.121-4.243-4.242 2.121-2.122a3 3 0 0 1 4.243 0zM17.97 8.505l1.13-1.131a1.6 1.6 0 1 0-2.262-2.263l-1.131 1.132 2.263 2.262z"
      />
    </SvgIcon>
  );
}
export default withStyles(styles)(EditIcon);
