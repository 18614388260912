import {
  SET_SHIPMENTLIST_DATA,
  ACTIVE_SEARCH_SHIPMENT,
  ACTIVE_SORT_SHIPMENT,
  DELETE_SHIPMENT,
  CREATE_SHIPMENT
} from "../actions/types";

const initialState = {
  rows: [],
  count: null,
  nextPage: null,
  sorting: null,
  sortingName: null,
  searching: null,  
  getDefaultLeast: null,
  leadDataShipmentNumber: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SHIPMENTLIST_DATA: {
      if (state.getDefaultLeast && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: null,
          sortingName: null,
          searching: null,
          getDefaultLeast: true,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: null,
          sortingName: null,
          searching: null,
          getDefaultLeast: true,
        };
      }
    }

    case ACTIVE_SEARCH_SHIPMENT: {
      if (state.searching && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          searching: true,
          sorting: null,
          sortingName: null,
          getDefaultLeast: null,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          searching: true,
          sorting: null,
          sortingName: null,
          getDefaultLeast: null,
        };
      }
    }

    case ACTIVE_SORT_SHIPMENT: {
      if (state.sorting && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: true,
          sortingName: action.payload.actionData,
          searching: null,
          getDefaultLeast: null,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: true,
          sortingName: action.payload.actionData,
          searching: null,
          getDefaultLeast: null,
        };
      }
    }

    case DELETE_SHIPMENT: {
      const newState = state.rows.reduce((acc, item) => {
        if (item.id !== action.payload.id) {
          acc.push(item);
        }
        return acc;
      }, []);
      const newLeadDataShipmentNumber = state.leadDataShipmentNumber.reduce((acc, item) => {
        if (item.ukrposhta_tracking_id !== action.payload.ukrposhta_tracking_id) {
          acc.push(item);
        }
        return acc;
      }, []);
      return {
        ...state,
        rows: [...newState],
        leadDataShipmentNumber: [...newLeadDataShipmentNumber]
      };
    }

    case CREATE_SHIPMENT: {
      const leadDataShipmentNumberTemp = [...state.leadDataShipmentNumber];
      action.payload.rows.map((item) => {
        if (item.id === action.payload.id) {
          item.ukrposhta_tracking_id = action.payload.ukrposhta_tracking_id;
          const ind = leadDataShipmentNumberTemp.findIndex((ls) => ls.id === action.payload.id)
          if (ind === -1) {
            leadDataShipmentNumberTemp.push(item);
          } else {
            leadDataShipmentNumberTemp[ind] = item;
          }
        }
        return item;
      });
      return {
        ...state,
        leadDataShipmentNumber: [...leadDataShipmentNumberTemp]
      };
    }

    default:
      return state;
    }
}
