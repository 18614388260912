import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
    "&:hover circle": {
      fill: "#F3F5F8"
    },
    circle: {}
  }
});

function ShipmentIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#FFF" />
      <path transform="translate(11,9)"  d="M17.49 6.51947C17.4843 6.49311 17.4843 6.46583 17.49 6.43947C17.4852 6.41639 17.4852 6.39255 17.49 6.36947V6.27947L17.43 6.12947C17.4056 6.08854 17.3753 6.05147 17.34 6.01947L17.25 5.93947H17.2L13.26 3.44947L9.54001 1.14947C9.45394 1.0812 9.35546 1.03026 9.25001 0.99947H9.17001C9.08062 0.984552 8.98939 0.984552 8.90001 0.99947H8.80001C8.68385 1.02516 8.57248 1.06904 8.47001 1.12947L1.00001 5.77947L0.910007 5.84947L0.820007 5.92947L0.720007 5.99947L0.670007 6.05947L0.610007 6.20947V6.29947V6.35947C0.600294 6.42578 0.600294 6.49316 0.610007 6.55947V15.2895C0.609667 15.4594 0.652643 15.6266 0.734878 15.7754C0.817113 15.9241 0.935894 16.0494 1.08001 16.1395L8.58001 20.7795L8.73001 20.8395H8.81001C8.97919 20.8931 9.16083 20.8931 9.33001 20.8395H9.41001L9.56001 20.7795L17 16.2095C17.1441 16.1194 17.2629 15.9941 17.3451 15.8454C17.4274 15.6966 17.4703 15.5294 17.47 15.3595V6.62947C17.47 6.62947 17.49 6.55947 17.49 6.51947ZM9.00001 3.16947L10.78 4.26947L5.19001 7.72947L3.40001 6.62947L9.00001 3.16947ZM8.00001 18.1695L2.50001 14.8095V8.41947L8.00001 11.8195V18.1695ZM9.00001 10.0595L7.09001 8.90947L12.68 5.43947L14.6 6.62947L9.00001 10.0595ZM15.5 14.7795L10 18.1995V11.8195L15.5 8.41947V14.7795Z" fill="#9FB0CB"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(ShipmentIcon);
