const styles = (theme) => ({
  rootBtn: {
    width: 156,
    height: 36,
    borderRadius: 24,
    fontSize: 14,
    fontWeight: 500,
    color: "#ffffff",
    padding: 0,
    background: "#00a0c9",
  },
  loadMoreContainer: {
    textAlign: "center",
    padding: "20px 0",
  },
  loadMoreDataBtn: {
    width: "auto",
    padding: "5px 15px",
    marginBottom: "20px",
  },
  buttonDownload: {
    color: "#0028c2",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      background: "#fff",
    },
    marginRight: 35,
  },
  buttonDownloadIcon: {
    marginRight: 8,
  },
});

export default styles;
