import styled from '@emotion/styled';

const ProductPageContainer = styled.div`
    .title{
        margin-bottom: 16px;
    }
    .paper{
        width: 100%;
        height: 100%;
    }
    .action-group{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;

        .btn-control{
            height: 34px;
            border-radius: 24px;
            box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
            & svg{
            font-size: 1rem;
             margin-right: 8px;
            }
            & path{
                fill: #ffffff;
            }
            .add-icon{
                margin-right: 8px;
                margin-bottom: 2px;
            }
        }
    }
`;

export {
    ProductPageContainer
}