import {
  GET_PRODUCT_CATEGORY_SUCCESS,
  SET_CURRENT_CATEGORY_PAGE,
} from '../actions/types';
import queryString from 'query-string';

const initialState = {
  categoryList: [],
  pagination: {
    total: null,
    count: null,
    next: null,
    currentPage: 1
  }
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORY_SUCCESS: {
      const pagination = {
        count: action.payload.results.length,
        total: action.payload.count,
        next: action.payload.next
      }
      if(action.payload.next){
        const queryStringTemp = queryString.parseUrl(action.payload.next);
        pagination.next = queryStringTemp.query.page
      }
      return {
        ...state,
        categoryList: action.payload.results,
        pagination: {
          ...state.pagination,
          ...pagination
        }
      };
    }

    case SET_CURRENT_CATEGORY_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload
        }
      }
    }

    default:
      return state;
  }
}

export default categoryReducer;