import {
  SET_LEADSLIST_DATA,
  UPDATE_LEAD_ROW,
  UPDATE_LEADS_ROWS_CONFIRM_ALL,
  ACTIVE_SEARCH_LEAD,
  ACTIVE_SORT_LEAD,
  UPDATE_LEAD_ROW_READ,
  UPDATE_LEAD_ROW_FEEDBACK_READ,
  UPDATE_CURRENT_PAGE
} from "./types";

import { appLoading, openPopupSystem, openToastSystem, stopAppLoading } from "./appActions";
import apiService from "../../api/apiService";
import {NotificationManager} from 'react-notifications';
import { I18n } from 'react-redux-i18n'
import store from '../store';

export const saveLeadsTableData = (
  type,
  arr,
  next,
  prev,
  count,
  actionData
) => {
  return {
    type,
    payload: {
      arr,
      next,
      prev,
      count,
      actionData,
    },
  };
};
export const updateLeadRow = obj => ({
  type: UPDATE_LEAD_ROW,
  payload: obj,
});
export const updateLeadRowConfirmAll = (arr) => ({
  type: UPDATE_LEADS_ROWS_CONFIRM_ALL,
  payload: arr,
});
export const updateLeadRowRead = obj => ({
  type: UPDATE_LEAD_ROW_READ,
  payload: obj,
});
export const updateLeadRowFeedBackRead = obj => ({
  type: UPDATE_LEAD_ROW_FEEDBACK_READ,
  payload: obj,
});
export const updateCurrentPage = payload => ({
  type: UPDATE_CURRENT_PAGE,
  payload
})
export const getLeadsTableData = (loadMoreData) => (dispatch) => {
  dispatch(appLoading());
  let getLeadList;

  if (loadMoreData) {
    getLeadList = apiService.getLeadsListData(loadMoreData);
    dispatch(updateCurrentPage(loadMoreData))
  } else {
    getLeadList = apiService.getLeadsListData();
  }

  getLeadList
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        item.suppliers_caught_count = item.fulfilled.suppliers_caught_count;
        acc.push({ ...item, index });
        return acc;
      }, []);

      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveLeadsTableData(
          SET_LEADSLIST_DATA,
          tableDataNormolize,
          nextPage,
          prevPage,
          count
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const patchLeadsConfirm = (formData, id) => async (dispatch) => {
  await apiService
    .patchLeadsConfirm(formData, id)
    .then((res) => {
      dispatch(updateLeadRow(res.data));
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.code === 1932) {
        NotificationManager.error(I18n.t("LeadsList.LeadsListConfirmAllError"), '', 4000);
      } else {
        NotificationManager.error(err.response.data.message, '', 4000);
      }
    });
};

export const patchLeadsConfirmAll = (formData) => (dispatch) => {
  apiService
    .patchLeadsConfirmAll(formData)
    .then((res) => {
      dispatch(updateLeadRowConfirmAll(res.data.results));
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.code === 1932) {
        NotificationManager.error(I18n.t("LeadsList.LeadsListConfirmAllError"), '', 4000);
      } else {
        NotificationManager.error(err.response.data.message, '', 4000);
      }
    });
};

export const searchLeadList = (data, loadMoreData) => (dispatch) => {
  dispatch(appLoading());

  let getSearchLeadList;

  if (loadMoreData) {
    getSearchLeadList = apiService.searchLeadsList(data, loadMoreData);
  } else {
    getSearchLeadList = apiService.searchLeadsList(data);
  }

  getSearchLeadList
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveLeadsTableData(
          ACTIVE_SEARCH_LEAD,
          tableDataNormolize,
          nextPage,
          prevPage,
          count
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
    });
};

export const sortColumnLeadsList = (data, loadMoreData) => (dispatch) => {
  dispatch(appLoading());

  let getSortColumnLeadsList;

  if (loadMoreData) {
    getSortColumnLeadsList = apiService.sortColumnLeadsList(data, loadMoreData);
  } else {
    getSortColumnLeadsList = apiService.sortColumnLeadsList(data);
  }

  getSortColumnLeadsList
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveLeadsTableData(
          ACTIVE_SORT_LEAD,
          tableDataNormolize,
          nextPage,
          prevPage,
          count,
          data
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};


export const patchLeadsRead = (id, data) => dispatch => {
  apiService.patchLeadsRead(id, data)
    .then(res => {
      dispatch(updateLeadRowRead({pk: id, lead: {pk: id, is_read: true}}));
    })
    .catch(error => {
      console.log('patchLeadsRead error', error);
    });
};

export const patchFeedbackLeadsRead = (id, data) => dispatch => {
  apiService.patchFeedbackLeadsRead(id, data)
    .then(res => {
      dispatch(updateLeadRowFeedBackRead({id: id, feedback: {id: id, is_read: true}}));
    })
    .catch(error => {
      console.log('patchFeedbackLeadsRead error', error);
    });
};

export const createLeadDeliveryInfo = (data) => async (dispatch) => {
  dispatch(appLoading());
  try {
    const response = await apiService.createLeadDeliveryInfo(data);
    dispatch(getLeadsTableData(store.getState().leadsList.currentPage))
    dispatch(openToastSystem({
      message: I18n.t('ProductCategories.ProductCategoriesCreateSuccess')
    }));
    dispatch(stopAppLoading());
    return response
  } catch (error) {
    // dispatch(reset("LeadInfoForm"))
    dispatch(stopAppLoading());
    if (error && error.response && error.response.data) {
      if (error.response.data.code === 1915) {
        dispatch(
          openPopupSystem(
            { 
              message: I18n.t('LeadsList.LeadsListModalErrorCode1915'),
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      } else if (error.response.data.code === 2312) {
        dispatch(
          openPopupSystem(
            { 
              message: I18n.t('Profile.ProfileErrorsCreateUpdateDeliveryInfo'),
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      } else {
        dispatch(
          openPopupSystem(
            { 
              message: error.response.data.message,
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      }
    }
    return error.response.data
  }
}

export const updateLeadDeliveryInfo = (id, data) => async (dispatch) => {
  dispatch(appLoading());
  try {
    const response = await apiService.updateLeadDeliveryInfo(id, data)
    dispatch(getLeadsTableData(store.getState().leadsList.currentPage))
    dispatch(openToastSystem({
      message: I18n.t('ProductCategories.ProductCategoriesUpdateSuccess'),
    }));
    dispatch(stopAppLoading());
    return response
  } catch (error) {
    // dispatch(reset("LeadInfoForm"))
    dispatch(stopAppLoading());
    if (error && error.response && error.response.data) {
      if (error.response.data.code === 1915) {
        dispatch(
          openPopupSystem(
            { 
              message: I18n.t('LeadsList.LeadsListModalErrorCode1915'),
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      } else if (error.response.data.code === 2312) {
        dispatch(
          openPopupSystem(
            { 
              message: I18n.t('Profile.ProfileErrorsCreateUpdateDeliveryInfo'),
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      } else {
        dispatch(
          openPopupSystem(
            { 
              message: error.response.data.message,
              status: "fail",
              detail: error.response.data.error_detail
            }
          )
        )
      }
    }
    return error.response.data
  }
}
