import {
  SET_LEADSLIST_DATA,
  UPDATE_LEAD_ROW,
  UPDATE_LEADS_ROWS_CONFIRM_ALL,
  ACTIVE_SEARCH_LEAD,
  ACTIVE_SORT_LEAD,
  UPDATE_LEAD_ROW_READ,
  UPDATE_LEAD_ROW_FEEDBACK_READ,
  UPDATE_CURRENT_PAGE
} from "../actions/types";
const initialState = {
  rows: [],
  count: null,
  selectCampaign: [],
  nextPage: null,
  getDefaultLeast: null,
  sorting: null,
  sortingName: null,
  searching: null,
  currentPage: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LEADSLIST_DATA: {
      if (state.getDefaultLeast && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          getDefaultLeast: true,
          sorting: null,
          sortingName: null,
          searching: null,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          getDefaultLeast: true,
          sorting: null,
          sortingName: null,
          searching: null,
        };
      }
    }
    case UPDATE_LEAD_ROW: {
      const newState = [...state.rows];
      const { id } = action.payload;
      const index = newState.findIndex((el) => el.id === id);
      newState[index] = { ...action.payload, index };
      return {
        ...state,
        rows: [...newState],
      };
    }
    case UPDATE_LEADS_ROWS_CONFIRM_ALL: {
      function getNewDataRows(state, payload) {
        const newState = [...state.rows];
        payload.forEach(el => {
          const index = newState.findIndex(element => element.id === el);
          newState[index] = { ...state.rows[index], is_confirmed: true };
        });
        return newState;
      }

      return {
        ...state,
        rows: getNewDataRows(state, action.payload),
      };
    }

    case ACTIVE_SEARCH_LEAD: {
      if (state.searching && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          searching: true,
          sorting: null,
          sortingName: null,
          getDefaultLeast: null,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          searching: true,
          sorting: null,
          sortingName: null,
          getDefaultLeast: null,
        };
      }
    }

    case ACTIVE_SORT_LEAD: {
      if (state.sorting && action.payload.prev) {
        let newRowsArr = [...state.rows, ...action.payload.arr];
        newRowsArr.reduce((acc, cur, i, arr) => (cur.index = i));

        return {
          ...state,
          rows: newRowsArr,
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: true,
          sortingName: action.payload.actionData,
          getDefaultLeast: null,
          searching: null,
        };
      } else {
        return {
          ...state,
          rows: [...action.payload.arr],
          count: action.payload.count,
          nextPage: action.payload.next,
          sorting: true,
          sortingName: action.payload.actionData,
          searching: null,
          getDefaultLeast: null,
        };
      }
    }

    case UPDATE_LEAD_ROW_READ: {
      const newState = [...state.rows];
      const { pk } = action.payload;
      const index = newState.findIndex((el) => el.lead && el.lead.pk === pk);
      if (index >= 0) {
        newState[index].lead = { ...action.payload.lead };
        return {
          ...state,
          rows: [...newState],
        };
      } else {
        return {...state};
      }
    }

    case UPDATE_LEAD_ROW_FEEDBACK_READ: {
      const newState = [...state.rows];
      const { id} = action.payload;
      const index = newState.findIndex((el) => el.feedback && el.feedback.id === id);
      if (index >= 0) {
        newState[index].feedback.is_read = action.payload.feedback.is_read;
        return {
          ...state,
          rows: [...newState],
        };
      } else {
        return {...state};
      }
    }

    case UPDATE_CURRENT_PAGE: {
      return { ...state, currentPage: action.payload}
    }
    default:
      return state;
  }
}

