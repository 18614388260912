const styles = theme => ({
  container: {
    padding: "32px 24px 0 24px",
    borderRight: "1px solid rgba(199, 199, 199, 0.24)",
    flexBasis: "62%"
  },
  title: {
    marginBottom: 10
  },
  name: {
    margin: "18px 0 8px 0"
  },
  marginBottom: {
    marginBottom: 8
  },
  customDropdown: {
    width: "calc(100% - 140px)"
  },
  label: {
    marginRight: "0px !important"
  },
  customWrapSelect: {
    marginBottom: 0,
    width: '90px'
  },
  groupDropdown: {
    width: '90px'
  },
  customPaper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    height: 'auto',
    overflowY: 'hidden',
    '&>div': {
      maxHeight: '100%',
      overflowY: 'auto'
    }
  },
  input: {
    width: '100%',
    minWidth: '100%',
    fontWeight: 400
  },
  singleValue: {
    fontFamily: "Roboto",
    fontWeight: 400,
    color: '#000'
  },
  singleValueDisable: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  placeholder: {
    position: "absolute",
    fontFamily: "Roboto",
    left: 2,
    fontWeight: 400
  },
  positionRelative: {
    position: 'relative'
  },
  costError: {
    position: 'absolute',
    left: 117,
    top: 7
  },
  errorInfoIcon: {
    color: "#FF3D33",
    fontSize: 18
  },
  tooltip: {
    background: '#9fb0cb'
  },
  bonusInputCover: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  totalBonus: {
    width: '100px',
    padding: '0px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bonusError: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#7C7C7C',
    marginLeft: '155px'
  },
  formControlLabelRoot: {
    margin: '0'
  },
  formControlLabelLabel: {
    width: '152px'
  },
  checkboxRoot: {
    padding: '4px 0'
  }
});

export default styles;
