import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Grid, TableCell, TableSortLabel, withStyles } from '@material-ui/core'
import { AutoSizer, Column, Table } from 'react-virtualized';
import categoryHeaderData from '../../../constants/categoryTable';
import SortIcon from "../../../components/Icons/SortIcon";
import ArrowUpIcon from "../../../components/Icons/ArrowUpIcon";
import ArrowDownIcon from "../../../components/Icons/ArrowDownIcon";
import styles from "./styles";
import ActionGroupCell from './ActionGroupCell';
import styled from '@emotion/styled';

const TextWrapper = styled.span`
    white-space: break-spaces;
`;

class MuiVirtualizedTable extends React.PureComponent {
  state = {
    column: "",
  };

  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;
    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  onButtonClick = (e, rowData) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("is_confirmed", true);
    this.props.patchLeadsConfirm(formData, rowData.id);
  };

  onColumnClick = (columnName) => {
    // const { column } = this.state;
    // const { sortColumn } = this.props;
    // if (column === columnName) {
    //   switch (columnName) {
    //     case "customer_name":
    //       sortColumn(`-user__display_name`);
    //       break;
    //     case "customer_email":
    //       sortColumn(`-user__email`);
    //       break;
    //     case "customer_phone":
    //       sortColumn(`-user__phone`);
    //       break;
    //     case "customer_city":
    //       sortColumn(`-user__customers__city`);
    //       break;
    //     case "campaign_name":
    //       sortColumn(`-campaign__name`);
    //       break;
    //     case "tags_count":
    //       sortColumn(`-tag_count`);
    //       break;
    //     default:
    //       sortColumn(`-${columnName}`);
    //       break;
    //   }
    //   this.setState({
    //     column: `-${columnName}`,
    //   });
    // } else {
    //   this.setState({
    //     column: columnName,
    //   });
    //   switch (columnName) {
    //     case "customer_name":
    //       sortColumn(`user__display_name`);
    //       break;
    //     case "customer_email":
    //       sortColumn(`user__email`);
    //       break;
    //     case "customer_phone":
    //       sortColumn(`user__phone`);
    //       break;
    //     case "customer_city":
    //       sortColumn(`user__customers__city`);
    //       break;
    //     case "campaign_name":
    //       sortColumn(`campaign__name`);
    //       break;
    //     case "tags_count":
    //       sortColumn(`tag_count`);
    //       break;
    //     default:
    //       sortColumn(`${columnName}`);
    //       break;
    //   }
    // }
  };
  tableCellInner = (columnIndex, rowData, cellData) => {
    switch (columnIndex) {

      case 1:
        const { onEditProductCategory, onDeleteProductCategory } = this.props;
        return (
          <ActionGroupCell
            actions={cellData}
            rowData={rowData}
            onEditProductCategory={val => onEditProductCategory(val)}
            onDeleteProductCategory={val => onDeleteProductCategory(val)}
          />
        );

      default:
        return <TextWrapper>{cellData}</TextWrapper>;
    }
  };

  cellRenderer = ({ cellData, columnIndex = null, rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
      >
        {this.tableCellInner(columnIndex, rowData, cellData)}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, classes } = this.props;
    let inner;
    if (columnIndex === 0 || columnIndex === 9) {
      inner = <TableSortLabel>{label}</TableSortLabel>;
    } else if (this.state.column === dataKey) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowUpIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else if (this.state.column === `-${dataKey}`) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowDownIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else {
      inner = (
        <TableSortLabel
          IconComponent={SortIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    }
    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCellHeader,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight, paddingRight: 0 }}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            style={{ outline: "none" }}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                { cellContentRenderer = null, className, dataKey, ...other },
                index
              ) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = (cellRendererProps) =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}
MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 120,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const ProductTable = props => {
  const { rows, patchLeadsConfirm, sortColumn, onEditProductCategory, onDeleteProductCategory } = props;
  return (
    <Grid style={{ height: 550, width: "100%", padding: "0 24px" }}>
      <WrappedVirtualizedTable
        rowCount={rows.length}
        rowData={rows}
        patchLeadsConfirm={patchLeadsConfirm}
        rowGetter={({ index }) => rows[index]}
        columns={categoryHeaderData}
        sortColumn={sortColumn}
        onEditProductCategory={onEditProductCategory}
        onDeleteProductCategory={onDeleteProductCategory}
      />
    </Grid>
  )
}

ProductTable.propTypes = {
  onEditProductCategory: PropTypes.func.isRequired,
  onDeleteProductCategory: PropTypes.func.isRequired
}

export default ProductTable;