const styles = (theme) => ({
  container: {},
  paperRoot: {
    maxWidth: 1320,
    minWidth: 660,
    borderRadius: 8,
    boxShadow: "0 2px 8px -2px rgba(0, 0, 0, 0.16)",
    backgroundColor: "#ffffff",
    position: "relative",
    marginRight: 32,
  },
  paperTitle: {
    fontFamily: "Helvetica",
    fontSize: 20,
    fontWeight: "bold",
    color: "#9fb0cb",
    display: "block",
    margin: 24,
  },
  paperItemMain: {
    display: "flex",
    borderBottom: "1px solid rgba(199, 199, 199, 0.24)",
  },
  paperItemEdit: {
    position: "absolute",
    right: 0,
  },
  buttonEdit: {
    margin: "18px 16px",
    color: "#0028c2",
    "&:hover": {
      background: "#fff",
    },
  },
  buttonEditIcon: {
    display: "block",
    marginRight: 8,
    fontSize: 18,
    "& path": {
      fill: "#0028c2",
    },
  },
  infoTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#9fb0cb",
  },

  saveBtn: {
    minWidth: 104,
    height: 36,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#ffffff",
    fontSize: 14,
    marginLeft: 24,
    backgroundColor: "#0028c2",
    "& path:nth-child(1)": {
      fill: "#fff",
    },
    "& svg": {
      marginRight: 8,
      marginTop: 3,
    },
  },
  cancelBtn: {
    minWidth: 104,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#0028c2",
    border: "1px solid #0028c2",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    "& path": {
      fill: "#0028c2",
    },
    "& svg": {
      marginRight: 8,
      marginBottom: "1.5px",
    },
  },
  error: {
    width: "100%",
    height: 24,
    paddingLeft: 68,
    fontFamily: "Roboto",
    color: "#de2323",
    fontSize: 14,
  },
  nameDisabled: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#505967",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: 0,
    "& fieldset": {
      borderColor: "transparent !important",
    },
  },
  infoDisabled: {
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "rgba(80, 89, 103, 0.87)",
    "& fieldset": {
      borderColor: "transparent !important",
    },
  },
  checkboxRoot: {
    color: "#1AA3D3",
    padding: "7.5px",
  },
  checkboxChecked: {
    color: "#1AA3D3",
  },
  formControlLabelRoot: {
    "&:last-child": {
      marginLeft: "20px",
    },
  },
  paperBody: {
    padding: 24,
    display: "flex",
  },
  bodyLeft: {
    width: 660,
    boxSizing: "border-box",
    paddingRight: "40px",
    "&:last-child": {
      paddingRight: 0,
    },
  },
  bodyRight: {
    width: 659,
    paddingLeft: "40px",
    borderLeft: "1px solid rgba(199, 199, 199, 0.24)",
  },

  horizontalDivider: {
    width: "100%",
    height: 1,
    margin: "24px 0",
    backgroundColor: "rgba(199, 199, 199, 0.24)",
  },
  selectAddRoot: {
    width: "140px",
    background: "rgba(159, 176, 203, 0.12)",
    borderRadius: "4px",
    position: "absolute",
    right: 0,
    "&:before": {
      content: "unset",
    },
  },
  selectAddSelect: {
    padding: "10px 16px",
    fontSize: "14px",
  },
  switchRoot: {},
  switchBar: {
    width: "56px",
    height: "32px",
    borderRadius: "32px",
    marginTop: "-16px",
    marginLeft: "-24px",
  },
  switchIcon: {
    height: "24px",
    width: "24px",
  },
  switchBase: {
    height: "32px",
    "&$switchChecked": {
      color: "#fafafa",
      "& + $switchBar": {
        backgroundColor: "#0E7731",
        opacity: 1,
      },
    },
  },
  switchIconChecked: {},
  switchChecked: {
    transform: "translateX(22px)",
  },
  radioRoot: {
    color: "#4A90E2",
    "& svg": {
      width: "16px",
    },
  },
  radioChecked: {
    color: "#4A90E2",
  },
  radioColorSecondary: {
    "&$radioChecked": {
      color: "#4A90E2",
    },
  },
  deliveryInformation: {
    width: "100%",
    marginTop: 32,
    padding: "12px 12px 12px 8px",
    backgroundColor: "#FFC9004D",
  },
});

export default styles;
