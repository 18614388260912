import React from 'react';
import { Translate } from 'react-redux-i18n';

const data = (currencyText) => [
  {
    width: 235,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableName" />,
    dataKey: 'name'
  },
  {
    width: 100,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableWorldwide" />,
    dataKey: 'is_world_wide'
  },
  {
    width: 101,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableTags" />,
    dataKey: 'tags',
    numeric: true
  },
  {
    width: 131,
    flexGrow: 1.0,
    label: <span><Translate value="Campaigns.CampaignsTablePrice" />, <span>{currencyText}</span></span>,
    dataKey: 'price_per_lead'
  },
  {
    width: 131,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableLeads" />,
    dataKey: 'leads_count'
  },
  {
    width: 131,
    flexGrow: 1.0,
    label: <span><Translate value="Campaigns.CampaignsTableTotalCost" />, <span>{currencyText}</span></span>,
    dataKey: 'total_cost'
  },
  {
    width: 131,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableRemaining" />,
    dataKey: 'end_date'
  },
  {
    width: 105,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableStatus" />,
    dataKey: 'status'
  },
  {
    width: 245,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableActions" />,
    dataKey: 'actions'
  }
];

export const finishedData = (currencyText) => [
  {
    width: 235,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableName" />,
    dataKey: 'name'
  },
  {
    width: 100,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableWorldwide" />,
    dataKey: 'is_world_wide'
  },
  {
    width: 101,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableTags" />,
    dataKey: 'tags',
    numeric: true
  },
  {
    width: 96,
    flexGrow: 1.0,
    label: <span><Translate value="Campaigns.CampaignsTablePrice" />, <span>{currencyText}</span></span>,
    dataKey: 'price_per_lead'
  },
  {
    width: 100,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableLeads" />,
    dataKey: 'leads_count'
  },
  {
    width: 96,
    flexGrow: 1.0,
    label: <span><Translate value="Campaigns.CampaignsTableBonusPrice" />, <span>{currencyText}</span></span>,
    dataKey: 'bonus'
  },
  {
    width: 131,
    flexGrow: 1.0,
    label: <span><Translate value="Campaigns.CampaignsTableTotalCost" />, <span>{currencyText}</span></span>,
    dataKey: 'total_cost'
  },
  {
    width: 101,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableRemaining" />,
    dataKey: 'end_date'
  },
  {
    width: 153,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableStatus" />,
    dataKey: 'status'
  },
  {
    width: 245,
    flexGrow: 1.0,
    label: <Translate value="Campaigns.CampaignsTableActions" />,
    dataKey: 'actions'
  }
];

export default data;
