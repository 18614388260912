import React, { Component } from "react";
import { withStyles, Typography, Button, Grid } from "@material-ui/core";
import Layout from "../../components/Layout";
import CustomInputRounded from "../../components/CustomInputRounded";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { clearErrors } from "../../redux/actions/errorsActions";
import { connect } from "react-redux";
import { changePassword } from "../../redux/actions/authActions";
import { Translate, I18n } from "react-redux-i18n";
import styles from './styles';

const renderTextField = ({
  input,
  serverErrors,
  type,
  placeholder,
  meta: { error }
}) => (
  <CustomInputRounded
    error={!!error || !!serverErrors.length}
    type={type}
    placeholder={placeholder}
    {...input}
  />
);

class Settings extends Component {
  onSubmit = values => {
    const letters = /^[A-Za-z]+$/;
    if (!values.old_password) {
      throw new SubmissionError({
        old_password: I18n.t("Settings.SettingsOldPasswordRequired"),
        _error: I18n.t("Settings.SettingsOldPasswordRequired")
      });
    }
    
    if (!values.new_password1) {
      throw new SubmissionError({
        new_password1: I18n.t("Settings.SettingsPasswordRequired"),
        _error: I18n.t("Settings.SettingsPasswordRequired")
      });
    }
    if(values.new_password1 && values.new_password1.length < 8 && !values.new_password1.match(letters)){
      throw new SubmissionError({
        new_password1: I18n.t("Settings.SettingsNewPasswordInvalid"),
        _error: I18n.t("Settings.SettingsNewPasswordInvalid")
      });
    }


    if (!values.new_password2) {
      throw new SubmissionError({
        new_password2: I18n.t("Settings.SettingsPasswordRequired"),
        _error: I18n.t("Settings.SettingsPasswordRequired")
      });
    }


    if (
      values.new_password1 &&
      values.new_password2 &&
      values.new_password1 !== values.new_password2
    ) {
      throw new SubmissionError({
        new_password1: I18n.t("Settings.SettingsPasswordMustIdentical"),
        new_password2: I18n.t("Settings.SettingsPasswordMustIdentical"),
        _error: I18n.t("Settings.SettingsPasswordMustIdentical")
      });
    }
    this.props.changePassword(values);
  };

  clearActionsErrors = () => {
    const { error, serverErrors } = this.props;
    if (!!error || !!serverErrors) {
      this.props.clearSubmitErrors();
      this.props.clearErrors();
    }
  };

  render() {
    const { classes, handleSubmit, pristine, error, serverErrors } = this.props;
    return (
      <Layout>
        <div className={classes.main}>
          <div className={classes.item}>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Grid container direction="column">
                <Typography variant="h4" style={{ margin: 18 }}>
                  <Translate value="Settings.SettingsTitle" />
                </Typography>
                <div className={classes.errorwrap}>
                  <span>{error && error}</span>
                  {serverErrors && serverErrors.toString().includes('1015') && <span>{I18n.t("Settings.SettingsPasswordChangeErrorCode1015")}</span>}
                  {serverErrors && serverErrors.toString().includes('1016') && <span>{I18n.t("Settings.SettingsPasswordChangeErrorCode1016")}</span>}
                  {serverErrors && serverErrors.toString().includes('1929') && <span>{I18n.t("Settings.SettingsPasswordChangeErrorCode1929")}</span>}
                  {serverErrors && serverErrors.toString().includes('1930') && <span>{I18n.t("Settings.SettingsPasswordChangeErrorCode1930")}</span>}
                  {/* <span>{!!serverErrors.message.length && serverErrors.message}</span> */}
                </div>
                <Field
                  type="password"
                  name="old_password"
                  placeholder={I18n.t("Settings.SettingsOldPassword")}
                  component={renderTextField}
                  serverErrors={serverErrors}
                  onChange={this.clearActionsErrors}
                  // normalize={toLowerCase}
                />
                <Field
                  type="password"
                  name="new_password1"
                  placeholder={I18n.t("Settings.SettingsPassword")}
                  component={renderTextField}
                  serverErrors={serverErrors}
                  onChange={this.clearActionsErrors}
                  // normalize={toLowerCase}
                />
                <Field
                  type="password"
                  name="new_password2"
                  placeholder={I18n.t("Settings.SettingsRepeatPassword")}
                  component={renderTextField}
                  serverErrors={serverErrors}
                  onChange={this.clearActionsErrors}
                  // normalize={toLowerCase}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  classes={{
                    root: classes.buttonRoot
                  }}
                  disabled={pristine || !!error || !!serverErrors.length}
                >
                  <Translate value="Settings.SettingsButtonChange" />
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  serverErrors: state.errors,
  locale: state.i18n.locale,
});

Settings = connect(
  mapStateToProps,
  { clearErrors, changePassword }
)(withStyles(styles)(Settings));

export default reduxForm({
  form: "Settings"
})(Settings);
