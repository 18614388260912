import { isEmpty } from './isEmpty';
import { I18n } from 'react-redux-i18n';

const validateProfileInfo = (values) => {
  const errors = {
    deliveryInfo: {}
  };

  if (isEmpty(values.displayName) || values.displayName.length > 50) {
    errors.displayName = I18n.t('Profile.ProfileErrorsName');
  }

  if (isEmpty(values.website)) {
    errors.website = I18n.t('Profile.ProfileErrorsWebsite');
  }

  if (isEmpty(values.address)) {
    errors.address = I18n.t('Profile.ProfileErrorsAddress');
  }

  if (isEmpty(values.available)) {
    errors.available = I18n.t('Profile.ProfileErrorsAvailable');
  }

  if (!!values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = I18n.t('Profile.ProfileErrorsEmail');
  }

  if (values.phone && !isEmpty(values.phone) && values.phone.length < 10) {
    errors.phone = I18n.t('Profile.ProfileErrorsPhone');
  }

  if (isEmpty(values.description) || values.description > 470) {
    errors.description = I18n.t('Profile.ProfileErrorsDescription');
  }

  // for deliveryInfo

  const { deliveryInfo } = values;

  if (deliveryInfo !== null && deliveryInfo !== undefined && deliveryInfo.type !== 'BRANCH') {
    if (isEmpty(deliveryInfo.postcode)) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcode');
    }

    if (deliveryInfo.postcode && deliveryInfo.postcode.length < 5) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcodeInvalid');
    }

    if (!/^\d+$/.test(deliveryInfo.postcode)) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcodeInvalid');
    }

    if (isEmpty(deliveryInfo.city)) {
      errors.deliveryInfo.city = I18n.t('Profile.ProfileErrorsDeliveryCity');
    }

    if (isEmpty(deliveryInfo.street)) {
      errors.deliveryInfo.street = I18n.t('Profile.ProfileErrorsDeliveryStreet');
    }

    if (isEmpty(deliveryInfo.building)) {
      errors.deliveryInfo.building = I18n.t('Profile.ProfileErrorsDeliveryBuilding');
    }
  }

  return errors;
};

export default validateProfileInfo;
