import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
  }
});

function ProfileActiveIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
        <circle cx="20" cy="20" r="20" fill="#0028c2"/>
        <path fill="#FFF" d="M12 12.4a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2zm0-1.4a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm6 1.4a.6.6 0 1 0 0 1.2h10a.6.6 0 0 0 0-1.2H18zm0-1.4h10a2 2 0 1 1 0 4H18a2 2 0 1 1 0-4zm-6 8.4a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2zm0-1.4a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm6 1.4a.6.6 0 0 0 0 1.2h10a.6.6 0 0 0 0-1.2H18zm0-1.4h10a2 2 0 1 1 0 4H18a2 2 0 1 1 0-4zm-6 8.4a.6.6 0 1 0 0 1.2.6.6 0 0 0 0-1.2zm0-1.4a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm6 1.4a.6.6 0 0 0 0 1.2h10a.6.6 0 0 0 0-1.2H18zm0-1.4h10a2 2 0 1 1 0 4H18a2 2 0 1 1 0-4z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(ProfileActiveIcon);
