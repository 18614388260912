import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 14
  }
});

function DownloadIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path fill="#FFF" d="M-546-432H492v737H-546z" />
      <path
        fill="#0028c2"
        d="M1.25 3.625h1.588a.788.788 0 0 1 0 1.575H1.825v6.975h10.35V5.2h-1.013a.788.788 0 0 1 0-1.575h1.588a1 1 0 0 1 1 1v8.125a1 1 0 0 1-1 1H1.25a1 1 0 0 1-1-1V4.625a1 1 0 0 1 1-1zM6.213 8.2V.95a.7.7 0 0 1 .7-.7h.175a.7.7 0 0 1 .7.7v7.246l1.265-1.404a.748.748 0 0 1 1.084-.028c.311.311.32.812.021 1.134L7.73 10.51a1 1 0 0 1-1.465 0L3.838 7.898a.817.817 0 0 1 .02-1.134.748.748 0 0 1 1.085.028L6.213 8.2z"
      />
    </SvgIcon>
  );
}
export default withStyles(styles)(DownloadIcon);
