/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import DoneIcon from '../../../../components/Icons/DoneIcon';
import ProductPickList from './ProductPickList';
import { ProductCampaignModalStyle } from './styles';
import { getProductList } from '../../../../redux/actions/productActions';
import { addNewProductsToCampaign, addSelectedProductTemp } from '../../../../redux/actions/campaignsActions';
import ProductModal from '../../../Products/ProductModal';
import { createProduct } from '../../../../redux/actions/productActions';
import _ from 'lodash';
const ProductCampaignModal = props => {
    const { productsSelected, open, onShowModal } = props;
    const [isShowAddProductModal, setShowAddProductModal] = useState(false);
    const [opacityModal, setOpacityModal] = useState(1);

    const dispatch = useDispatch();
    const { productList } = useSelector(state => state.product);
    const { selectedProductsTemp } = useSelector(state => state.campaigns);
    const [cloneSelectedProductsTemp, setCloneSelectedProductsTemp] = useState([])

    useEffect(() => {
        setCloneSelectedProductsTemp(_.cloneDeep(selectedProductsTemp))
    }, [open])

    const categoryList = useSelector(state => state.category.categoryList)
    useEffect(() => {
      const params = {
        page: null,
        category: null
      }
      dispatch(getProductList(params));
    }, []);

    useEffect(() => {
        if (productsSelected.length >= 0) {
            dispatch(addSelectedProductTemp(productsSelected));
        }
    }, [productsSelected]);

    const onSelectedProduct = products => {
        if (!products) {
            dispatch(addSelectedProductTemp([]));
            return;
        }
        dispatch(addSelectedProductTemp(products.map(val => val.id)));
    }

    const closeProductCampaignModal = () => {
        dispatch(addSelectedProductTemp(cloneSelectedProductsTemp));
        onShowModal(false)
    }

    const onSubmitProductCampaign = () => {
        dispatch(addNewProductsToCampaign(selectedProductsTemp));
        onShowModal(false)
    }

    const onAddMoreProduct = data => {
        dispatch(createProduct(data, { page: 1 }))
        setShowAddProductModal(false);
        setOpacityModal(1)
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                css={css`
                    opacity: ${opacityModal};
                `}
            >
                <ProductCampaignModalStyle>
                    <DialogTitle>
                        <Translate value="Products.ProductsCampaignModalTitle" />
                    </DialogTitle>
                    <DialogContent className="product-dialog-content">
                        <ProductPickList
                            products={_.uniqBy(productList, v => v.id)}
                            productsSelected={selectedProductsTemp}
                            onSelectedProduct={onSelectedProduct}
                            onAddMoreProduct={() => {
                                setShowAddProductModal(true)
                                setOpacityModal(0);
                            }}
                        />
                    </DialogContent>
                    <DialogActions className="action-group">
                        <Button
                            variant="outlined"
                            className="cancel-btn"
                            onClick={closeProductCampaignModal}
                        >
                            <CloseIcon />
                            <Translate value="Products.ProductsModalCancelBtn" />
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            className="submit-btn"
                            onClick={onSubmitProductCampaign}
                        >
                            <DoneIcon />
                            <Translate value="Products.ProductsCampaignModalSaveBtn" />
                        </Button>
                    </DialogActions>
                </ProductCampaignModalStyle>
            </Dialog>
            <ProductModal
                isOpen={isShowAddProductModal}
                onCancel={() => {
                    setShowAddProductModal(false);
                    setOpacityModal(1);
                }}
                onCreate={onAddMoreProduct}
                categories={categoryList}
            />
        </React.Fragment>
    )
}

export default ProductCampaignModal;