import { Grid } from "@material-ui/core";
import React from "react";
import { Translate } from "react-redux-i18n";
import { Field } from "redux-form";

const AddressDeliveryInfo = ({ renderTextField, styles: classes, onEdit }) => {
  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
        style={{ marginBottom: 8, position: "relative" }}
      >
        <Field
          label={<Translate value="Profile.ProfileDeliveryPostalCode" />}
          type="text"
          name="deliveryInfo.postcode"
          maxLength="5"
          labelWrapWidth="140px"
          component={renderTextField}
          disabled={!onEdit}
          inputProps={{
            disabled: classes.infoDisabled,
          }}
          normalize={(value, previousValue, allValues) => {
            return value.replace(/[^\d]/g, '')
          }}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
        style={{ marginBottom: 8, position: "relative" }}
      >
        <Field
          label={<Translate value="Profile.ProfileDeliveryCity" />}
          type="text"
          name="deliveryInfo.city"
          maxLength="45"
          labelWrapWidth="140px"
          component={renderTextField}
          disabled={!onEdit}
          inputProps={{
            disabled: classes.infoDisabled,
          }}
          normalize={(value, previousValue, allValues) => {
            return value.replace(/[0-9]/g, '')
          }}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
        style={{ marginBottom: 8, position: "relative" }}
      >
        <Field
          label={<Translate value="Profile.ProfileDeliveryStreet" />}
          type="text"
          name="deliveryInfo.street"
          maxLength="255"
          labelWrapWidth="140px"
          component={renderTextField}
          disabled={!onEdit}
          inputProps={{
            disabled: classes.infoDisabled,
          }}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
        style={{ marginBottom: 8, position: "relative" }}
      >
        <Field
          type="text"
          name="deliveryInfo.building"
          label={<Translate value="Profile.ProfileDeliveryBuilding" />}
          maxLength="60"
          labelWrapWidth="140px"
          component={renderTextField}
          disabled={!onEdit}
          inputProps={{
            disabled: classes.infoDisabled,
          }}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
        style={{ marginBottom: 8, position: "relative" }}
        maxLength="12"
      >
        <Field
          type="tel"
          name="deliveryInfo.flat_number"
          label={<Translate value="Profile.ProfileDeliveryFlat" />}
          maxLength="15"
          labelWrapWidth="140px"
          component={renderTextField}
          disabled={!onEdit}
          inputProps={{
            disabled: classes.infoDisabled,
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

export default AddressDeliveryInfo;
