import apiService from '../../api/apiService';
import { appLoading, stopAppLoading } from './appActions';
import { GET_ERRORS } from './types';

export const requestDeleteAccount = (data) => (dispatch) => {
  dispatch(appLoading());
  apiService
    .requestDeleteAccount(data)
    .then((res) => {
      dispatch(stopAppLoading());
    })
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
      dispatch({
        type: GET_ERRORS,
        payload: 'User with phone/email does not exist'
      });
    });
};
