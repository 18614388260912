import { I18n } from 'react-redux-i18n';
const validateCreation = values => balance => {
  const errors = {};
  if (!values.name) {
    errors.name = I18n.t("CampaignsCreation.CampaignsCreationNameRequired");
  }
  if (!values.start_date) {
    errors.start_date = I18n.t("CampaignsCreation.CampaignsCreationStartDateRequired");
  }
  if (!values.end_date) {
    errors.end_date = I18n.t("CampaignsCreation.CampaignsCreationEndDateRequired");
  }
  if (values.start_date && values.end_date) {
    const date1 = new Date(values.start_date);
    const date2 = new Date(values.end_date);
    const diffTime = date2.getTime() - date1.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (Math.sign(diffDays) === -1) {
      errors.end_date = I18n.t("CampaignsCreation.CampaignsCreationEndDateError");
    }
  }
  if (values.end_date) {
    const date1 = new Date();
    const date2 = new Date(values.end_date);
    const diffTime = date2.getTime() - date1.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (Math.sign(diffDays) === -1) {
      errors.end_date = I18n.t("CampaignsCreation.CampaignsCreationEndDateError");
    }
  }
  if (!values.price_per_lead) {
    errors.price_per_lead = I18n.t("CampaignsCreation.CampaignsCreationCostRequired");
  } else if (values.price_per_lead > balance) {
    errors.price_per_lead = I18n.t("CampaignsCreation.CampaignsCreationBalanceError");
  } else if (+values.price_per_lead === 0) {
    errors.price_per_lead = "should be greater than zero";
  }
  return errors;
};

export default validateCreation;
