import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 14,
  }
});

function AddIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
          <path fill="#0028c2" fillRule="evenodd" d="M7 .25c.435 0 .787.353.787.788v5.175h5.176a.788.788 0 0 1 0 1.575H7.788v5.175a.788.788 0 0 1-1.575 0V7.788H1.037a.788.788 0 0 1 0-1.575h5.176V1.037c0-.434.352-.787.787-.787z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(AddIcon);