import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  withStyles
} from "@material-ui/core";
import DoneIcon from "../Icons/DoneIcon";
import CloseIcon from "../Icons/CloseIcon";
import styles from "./styles";
import { Translate } from "react-redux-i18n";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationsModal = ({ open, handleClose, handleConfirm, classes, confirmData }) => {
  const { message, acceptBtnLabel, cancelBtnLabel } = confirmData;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {<Translate value={message} />}
      </DialogTitle>
      <DialogActions className={classes.actionWrap}>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.cancelBtn}
        >
          <CloseIcon className={classes.extendedIcon} />
          <Translate value={cancelBtnLabel} />
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleConfirm}
          className={classes.saveBtn}
        >
          <DoneIcon className={classes.extendedIcon} />
          <Translate value={acceptBtnLabel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmationsModal);
