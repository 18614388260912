import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PhotoIcon from "../../../../components/Icons/PhotoIcon";
import { Translate } from "react-redux-i18n";
import styles from "./styles";

const AvatarProfile = ({ classes, avatar, onChange }) => (
  <div className={classes.rootWrap}>
    <input
      type="file"
      id="avatar"
      accept="image/*"
      className={classes.fileInput}
      onChange={e => onChange(e, "avatar")}
    />
    <div className={classes.link}>
      <label htmlFor="avatar" className={classes.inputLabel}>
        <div className={classes.middleWrap}>
          <PhotoIcon className={classes.icon} />
          <div className={classes.avatarText}>
            <Translate value="Profile.ProfileSetPhoto" />
          </div>
          <div className={classes.coverBg} />
        </div>
      </label>

      <div className={classes.avatar}>
        <img alt="ava" src={avatar} className={classes.avatarPic} />
      </div>
      
    </div>
  </div>
);

AvatarProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired
};

export default withStyles(styles)(AvatarProfile);
