import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 24,
    "&:hover circle": {
      fill: "#F3F5F8"
    },
    circle: {}
  }
});

function HidePasswordIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path fill="#9FB0CB" fillRule="nonzero" d="M7.076 8.066L4.93 5.92a.7.7 0 1 1 .99-.99L8.2 7.21C9.388 6.436 10.657 6 12 6c2.13 0 4.073 1.097 5.8 2.905a15.414 15.414 0 0 1 1.99 2.6c.072.12.122.206.149.256a.5.5 0 0 1 0 .478 14.02 14.02 0 0 1-.565.902c-.454.666-.98 1.331-1.575 1.954-.286.3-.578.58-.875.839l2.147 2.147a.7.7 0 0 1-.99.99L15.8 16.79C14.612 17.564 13.343 18 12 18c-2.13 0-4.073-1.097-5.8-2.905a15.414 15.414 0 0 1-1.99-2.6 7.298 7.298 0 0 1-.149-.256.5.5 0 0 1 0-.478 14.02 14.02 0 0 1 .565-.902c.454-.666.98-1.331 1.575-1.954.286-.3.578-.58.875-.839zm7.707 7.707l-1.215-1.215a3 3 0 0 1-4.126-4.126L8.069 9.059c-.286.244-.571.515-.855.812A14.002 14.002 0 0 0 5.55 12a14.002 14.002 0 0 0 1.663 2.129C8.784 15.772 10.386 16.6 12 16.6c.933 0 1.862-.276 2.783-.827zm1.148-.832c.286-.244.571-.515.855-.812A14.002 14.002 0 0 0 18.45 12a14.003 14.003 0 0 0-1.663-2.129C15.216 8.228 13.614 7.4 12 7.4c-.933 0-1.862.276-2.783.827l1.215 1.215a3 3 0 0 1 4.126 4.126l1.373 1.373zm-5.444-3.464a1.6 1.6 0 0 0 2.035 2.035l-2.035-2.035zm.99-.99l2.036 2.036a1.6 1.6 0 0 0-2.035-2.035z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(HidePasswordIcon);
