import { Translate } from "react-redux-i18n";
import React from 'react';

const data = [
    {
      width: 9,
      flexGrow: 1.0,
      dataKey: 'index',
    },
    {
      width: 260,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListAddress" />,
      dataKey: 'recipient_address',
    },
    {
      width: 160,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListName" />,
      dataKey: 'recipient_name',
    },
    {
      width: 100,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListPhone" />,
      dataKey: 'recipient_phone',
    },
    {
      width: 80,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListPayment" />,
      dataKey: 'recipient_pay',
    },
    {
      width: 100,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListAssessedValue" />,
      dataKey: "assessed_value"
    },
    {
      width: 100,
      flexGrow: 1.0,
      label: <Translate value="Shipment.ShipmentListTrackingID" />,
      dataKey: 'ukrposhta_tracking_id',
    },
    {
      width: 30,
      flexGrow: 1.0,
      label: '',
      dataKey: 'actions',
    },
  ]

export default data;