import _ from 'lodash';
import {
  SET_CAMPAIGNS_ACTIVE_DATA,
  SET_CAMPAIGNS_FINISHED_DATA,
  UPDATE_CAMPAIGN_ACTIVE_ROW,
  DELETE_CAMPAIGN_ACTIVE_ROW,
  DELETE_CAMPAIGN_FINISHED_ROW,
  UPDATE_CAMPAIGN_CREATION,
  SET_INITIAL_STATE,
  ADD_NEW_TAGS_TO_CAMPAIGN,
  CREATE_NEW_CAMPAIGN,
  ADD_NEW_TAGS_TO_CAMPAIGN_GEO,
  ADD_NEW_KEYWORDS_TO_CAMPAIGN,
  ADD_PRODUCTS_TO_CAMPAIGN,
  ADD_SELECTED_PRODUCT_TEMP,
  ADD_TOTAL_BALANCE
} from "./types";
import { appLoading, openPopupSystem, stopAppLoading } from "./appActions";
import apiService from "../../api/apiService";
import {Getlatlng } from "./latlngAdress"
const handleDataMapping = (data) => {
  const dataClone = _.clone(data);

  // Keywords
  if (dataClone && !dataClone.keywords) {
    dataClone['keywords'] = [];
  } else if (dataClone && dataClone.keywords) {
    dataClone['keywords'] = data.keywords && data.keywords.length > 0 ? data.keywords.map(key => ({ id: key, text: key })) : [];
  }

  // Products
  if (dataClone && !dataClone.products) {
    dataClone['products'] = [];
  } else if (dataClone && dataClone.products) {
    dataClone['products'] = data.products && data.products.length > 0 ? data.products.map(product => product.id) : [];
  }

  return dataClone;
}

export const saveCampaignsActiveTableData = arr => ({
  type: SET_CAMPAIGNS_ACTIVE_DATA,
  payload: arr
});

export const saveCampaignsFinishedTableData = arr => ({
  type: SET_CAMPAIGNS_FINISHED_DATA,
  payload: arr
});

export const updateCampaignActiveRow = obj => ({
  type: UPDATE_CAMPAIGN_ACTIVE_ROW,
  payload: obj
});

export const createNewActiveCampaigns = obj => ({
  type: CREATE_NEW_CAMPAIGN,
  payload: obj
});

export const deleteCampaignActiveRow = id => ({
  type: DELETE_CAMPAIGN_ACTIVE_ROW,
  payload: id
});

export const deleteCampaignFinishedRow = id => ({
  type: DELETE_CAMPAIGN_FINISHED_ROW,
  payload: id
});

export const updateCampaignCreation = obj => ({
  type: UPDATE_CAMPAIGN_CREATION,
  payload: obj

});

export const setInitialStateCampaignCreation = () => ({
  type: SET_INITIAL_STATE
});

export const addNewTagsToCampaign = data => ({
  type: ADD_NEW_TAGS_TO_CAMPAIGN,
  payload: data
});

export const addNewTagsToCampaignGeo = data => ({
  type: ADD_NEW_TAGS_TO_CAMPAIGN_GEO,
  payload: data
});

export const addNewKeywordsToCampaign = data => ({
  type: ADD_NEW_KEYWORDS_TO_CAMPAIGN,
  payload: data
});

export const addNewProductsToCampaign = data => ({
  type: ADD_PRODUCTS_TO_CAMPAIGN,
  payload: data
});

export const addSelectedProductTemp = data => ({
  type: ADD_SELECTED_PRODUCT_TEMP,
  payload: data
});

export const addTotalBalance = data => ({
  type: ADD_TOTAL_BALANCE,
  payload: data
});

export const getCampaignsActiveData = () => dispatch => {
  dispatch(appLoading());
  apiService
    .getActiveCampaigns()
    .then(res => {
      dispatch(saveCampaignsActiveTableData(res.data));
      dispatch(stopAppLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const getCampaignsFinishedData = () => dispatch => {
  dispatch(appLoading());
  apiService
    .getFinishedCampaigns()
    .then(res => {
      dispatch(saveCampaignsFinishedTableData(res.data));
      stopAppLoading();
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const deleteCampaign = (status, id) => dispatch => {
  apiService
    .deleteCampaign(id)
    .then(res => {
      if (status !== 4) dispatch(deleteCampaignActiveRow(id));
      if (status === 4) dispatch(deleteCampaignFinishedRow(id));
    })
    .then(() => dispatch(stopAppLoading()))
    .catch(err => {
      console.log(err);
    });
};

export const editCampaign = id => dispatch => {
  apiService
    .editCampaign(id)
    .then(res => {
      const data = handleDataMapping(res.data);
      dispatch(updateCampaignCreation(data));
    })
    .then(() => {
      dispatch(updateCampaignCreation({ editing: true }));
    })
    .catch(err => {
      console.log(err);
    });
};
export const changeStatusCampaign = (formData, id) => dispatch => {
  dispatch(appLoading());
  apiService
    .updateCampaign(formData, id)
    .then(res => {
      dispatch(updateCampaignActiveRow(res.data));
      dispatch(stopAppLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};
export const restartCampaign = formData => dispatch => {
  apiService
    .restartCampaign(formData)
    .then(res => {
      const data = handleDataMapping(res.data);
      dispatch(updateCampaignCreation(data));
    })
    .then(() => {

      dispatch(updateCampaignCreation({ creation: true }));
      dispatch(Getlatlng(null))
    })
    .catch(err => {
      console.log(err);
      if (err && err.response && err.response.data) {
        dispatch(
          openPopupSystem(
            { 
              message: err.response.data.message,
              status: "fail",
              detail: err.response.data.error_detail
            }
          )
        )
      }
    });
};

export const searchCampaignsActive = data => dispatch => {
  apiService
    .searchCampaignsActive(data)
    .then(res => {
      dispatch(saveCampaignsActiveTableData(res.data));
    })
    .catch(err => {
      console.log(err);
    });
};

export const searchCampaignsFinished = data => dispatch => {
  apiService
    .searchCampaignsFinished(data)
    .then(res => {
      dispatch(saveCampaignsFinishedTableData(res.data));
    })
    .catch(err => {
      console.log(err);
    });
};

export const completeCampaignCreation = formData => dispatch => {
  dispatch(appLoading());
  apiService
    .completeCampaignCreation(formData)
    .then(res => {
      dispatch(createNewActiveCampaigns(res.data));
      dispatch(setInitialStateCampaignCreation());
      dispatch(Getlatlng(null))
    })
    .then(() => {
      dispatch(setInitialStateCampaignCreation());
      dispatch(stopAppLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
      if (err && err.response && err.response.data) {
        dispatch(
          openPopupSystem(
            { 
              message: err.response.data.message,
              status: "fail",
              detail: err.response.data.error_detail
            }
          )
        )
      }
    });
};

export const completeCampaignEditing = (id, formData) => dispatch => {
  apiService
    .completeCampaignEditing(formData, id)
    .then(res => {
      dispatch(updateCampaignActiveRow(res.data));
      dispatch(Getlatlng(null))
    })
    .then(() => {
      dispatch(setInitialStateCampaignCreation());
    })
    .catch(err => {
      console.log(err);
      if (err && err.response && err.response.data) {
        dispatch(
          openPopupSystem(
            { 
              message: err.response.data.message,
              status: "fail",
              detail: err.response.data.error_detail
            }
          )
        )
      }
    });
};

export const sortColumnCampaignsActive = data => dispatch => {
  apiService
    .sortColumnCampaignsActive(data)
    .then(res => {
      const tableDataNormolize = res.data.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      dispatch(saveCampaignsActiveTableData(tableDataNormolize));
    })
    .catch(err => {
      console.log(err);
    });
};

export const sortColumnCampaignsFinished = data => dispatch => {
  apiService
    .sortColumnCampaignsFinished(data)
    .then(res => {
      const tableDataNormolize = res.data.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      dispatch(saveCampaignsFinishedTableData(tableDataNormolize));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTotalBalanceAction = () => dispatch => {
  dispatch(appLoading());
  apiService
    .getBalance()
    .then(res => {
      dispatch(addTotalBalance(res.data));
      dispatch(stopAppLoading());
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};
