const styles = {
  rootWrap: {
    margin: 24
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    position: 'relative',
    overflow: 'hidden'
  },
  avatarPic: {
    width: 'auto',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  link: {
    position: "relative",
    width: 100,
    height: 100,
    borderRadius: "50%",
    zIndex: 1,
    "&:hover": {
      cursor: "pointer",
      "& $middleWrap": {
        display: "flex"
      }
    }
  },
  coverBg: {
    background: "black",
    opacity: 0.6,
    display: "block",
    position: "absolute",
    borderRadius: "50%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
  },
  coverTitle: {
    color: "#000",
    fontSize: 15,
    textAlign: "center",
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2
  },
  middleWrap: {
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  avatarText: {
    marginTop: 8,
    width: '100%',
    height: 32,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: "#ffffff",
    zIndex: 6,
    textAlign: "center"
  },
  icon: {
    zIndex: 6,
    display: "block",
    fontSize: 18
  },
  fileInput: {
    display: "none"
  },
  inputLabel: {
    "&:hover": {
      cursor: "pointer"
    }
  }
};

export default styles;
