const styles = theme => ({
  container: {
    maxWidth: 550,
  },
  closeModalIcon: {
    cursor: "pointer",
    position: "absolute",
    right: 18,
    top: 18,
    "&:hover path": {
      fill: "#9FB0CB"
    }
  },
  cancelBtn: {
    minWidth: 104,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#0028c2",
    border: "1px solid #0028c2",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    "& path": {
      fill: "#0028c2"
    }
  },
  saveBtn: {
    minWidth: 104,
    height: 36,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#ffffff",
    fontSize: 14,
    marginLeft: 24,
    backgroundColor: "#0028c2",
    "& path:nth-child(1)": {
      fill: "#fff"
    },
    "& svg": {
      marginRight: 8,
      marginTop: 3
    }
  },
  title: {
    padding: 15,
    width: '100%',
    whiteSpace: "nowrap",
  },
  actionButtonsWrap: {
    padding: 24,
    borderTop: "1px solid rgba(199, 199, 199, 0.24)"
  },
  modalContent: {
    padding: '0px 15px 15px',
    flexDirection: 'column'
  },
  error: {
    color: '#ff0000',
    marginTop: 10,
    marginLeft: 100,
  },
  topUpCurrency: {
    display: 'flex',
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  extendedIcon: {
    marginRight: 8,
    marginBottom: '1.5px'
  }
});

export default styles;
