import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
    "&:hover circle": {
      fill: "#F3F5F8"
    },
    circle: {}
  }
});

function SettingsIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#FFF" />
      <path
        fill="#9FB0CB"
        d="M27.063 22.382a2.4 2.4 0 0 1-.92-1.889v-.99a2.4 2.4 0 0 1 .909-1.88l1.095-.87-1.214-2.087-1.357.52a2.4 2.4 0 0 1-2.051-.156l-.886-.506a2.4 2.4 0 0 1-1.18-1.711l-.223-1.413h-2.462l-.224 1.416a2.4 2.4 0 0 1-1.18 1.707l-.887.507a2.4 2.4 0 0 1-2.05.157l-1.371-.526-1.233 2.06 1.133.905a2.4 2.4 0 0 1 .903 1.875v.991a2.4 2.4 0 0 1-.923 1.891l-1.116.873 1.237 2.116 1.342-.54a2.4 2.4 0 0 1 2.087.143l.878.502a2.4 2.4 0 0 1 1.18 1.707l.224 1.416h2.462l.223-1.413a2.4 2.4 0 0 1 1.18-1.71l.877-.502a2.4 2.4 0 0 1 2.087-.143l1.34.54 1.233-2.117-1.113-.873zm2.251-.014a1 1 0 0 1 .247 1.29l-1.582 2.717a1 1 0 0 1-1.238.425l-1.66-.67a1 1 0 0 0-.87.06l-.878.502a1 1 0 0 0-.491.713l-.276 1.75a1 1 0 0 1-.988.845h-3.145a1 1 0 0 1-.988-.843l-.278-1.753a1 1 0 0 0-.492-.712l-.878-.502a1 1 0 0 0-.87-.06l-1.661.67a1 1 0 0 1-1.237-.423L10.44 23.66a1 1 0 0 1 .248-1.292l1.391-1.088a1 1 0 0 0 .385-.788v-.99a1 1 0 0 0-.376-.782l-1.406-1.123a1 1 0 0 1-.235-1.295l1.585-2.649a1 1 0 0 1 1.217-.42l1.684.647a1 1 0 0 0 .855-.065l.886-.507a1 1 0 0 0 .492-.712l.278-1.753a1 1 0 0 1 .988-.843h3.145a1 1 0 0 1 .988.845l.276 1.75a1 1 0 0 0 .491.713l.886.507a1 1 0 0 0 .855.065l1.675-.643a1 1 0 0 1 1.222.43l1.56 2.682a1 1 0 0 1-.242 1.286l-1.367 1.085a1 1 0 0 0-.379.783v.99a1 1 0 0 0 .383.787l1.388 1.088zM20 22.1a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2zm0 1.4a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"
      />
    </SvgIcon>
  );
}
export default withStyles(styles)(SettingsIcon);
