import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  TableCell,
  TableSortLabel,
  Grid,
} from "@material-ui/core";
import { AutoSizer, Column, Table } from "react-virtualized";
import { IconButton } from '@material-ui/core';
import TringleIcon from '../../../../components/Icons/TringleIcon';
import styles from "./styles";
import data from "../../../../constants/shipmentTable";
import SortIcon from "../../../../components/Icons/SortIcon";
import ArrowUpIcon from "../../../../components/Icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";

class MuiVirtualizedTable extends React.PureComponent {
  state = {
    column: "",
  };
  getRowClassName = ({ index }) => {
    const { classes, rowClassName } = this.props;
    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1,
    });
  };
  onColumnClick = (columnName) => {
    const { column } = this.state;
    const { sortColumn } = this.props;
    if (column === columnName) {
      switch (columnName) {
        case "recipient_address":
          sortColumn(`-recipient_address`);
          break;
        case "recipient_name":
          sortColumn(`-recipient_name`);
          break;
        case "recipient_phone":
          sortColumn(`-recipient_phone`);
          break;
        case "recipient_pay":
          sortColumn(`-recipient_pay`);
          break;
        case "ukrposhta_tracking_id":
          sortColumn(`-ukrposhta_tracking_id`);
          break;
        default:
          sortColumn(`-${columnName}`);
          break;
      }
      this.setState({
        column: `-${columnName}`,
      });
    } else {
      this.setState({
        column: columnName,
      });
      switch (columnName) {
        case "recipient_address":
          sortColumn(`recipient_address`);
          break;
        case "recipient_name":
          sortColumn(`recipient_name`);
          break;
        case "recipient_phone":
          sortColumn(`recipient_phone`);
          break;
        case "recipient_pay":
          sortColumn(`recipient_pay`);
          break;
        case "ukrposhta_tracking_id":
          sortColumn(`ukrposhta_tracking_id`);
          break;
        default:
          sortColumn(`${columnName}`);
          break;
      }
    }
  };
  tableCellInner = (columnIndex, rowData, cellData) => {
    const { confirmDeleteModal } = this.props;
    switch (columnIndex) {
      case 0:
        return cellData + 1;
      case 4:
        return cellData ? 'Отримувач' : 'Відправник';
      case 7:
        return (
          <IconButton
            classes={{
              root: 'root-icon',
              disabled: 'disable'
            }}
            disabled={false}
            onClick={() => confirmDeleteModal(rowData)}
          >
            <TringleIcon />
          </IconButton>
        );
      default:
        return cellData;
    }
  };

  cellRenderer = ({ cellData, columnIndex = null, rowData }) => {
    const { classes, rowHeight } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {[classes.columnStatus]: columnIndex === 9}, {[classes.inProgressBg]: (rowData.lead && rowData.lead.is_read === false)}, {[classes.completedBg]: (rowData.feedback && rowData.feedback.is_read === false)}, {[classes.fulfilledByAnotherBg]: (!(rowData.lead && rowData.lead.is_read === false) && !(rowData.feedback && rowData.feedback.is_read === false))})}
        variant="body"
        style={{ height: rowHeight, paddingRight: 10, paddingLeft: 10, justifyContent: columnIndex === 0 ? 'center' : '' }}
      >
        <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
          {this.tableCellInner(columnIndex, rowData, cellData)}
        </p>
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, classes } = this.props;
    let inner;
    if (columnIndex === 0 || columnIndex === 7) {
      inner = <TableSortLabel>{label}</TableSortLabel>;
    } else if (this.state.column === dataKey) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowUpIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else if (this.state.column === `-${dataKey}`) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowDownIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else {
      inner = (
        <TableSortLabel
          IconComponent={SortIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    }
    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCellHeader,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight, paddingRight: 10, paddingLeft: 10 }}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            style={{ outline: "none" }}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                { cellContentRenderer = null, className, dataKey, ...other },
                index
              ) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = (cellRendererProps) =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  confirmDeleteModal: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 58,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const ShipmentTable = ({ rows, confirmDeleteModal, sortColumn, locale }) => (
  <Grid style={{ height: 550, width: "100%", padding: "0 24px" }}>
    <WrappedVirtualizedTable
      rowCount={rows.length}
      rowData={rows}
      rowGetter={({ index }) => rows[index]}
      confirmDeleteModal={(event) => confirmDeleteModal(event)}
      columns={data}
      sortColumn={sortColumn}
      locale={locale}
    />
  </Grid>
);
export default ShipmentTable;
