import { Component } from "react";
/**@jsx jsx */
import { css, jsx } from "@emotion/core";
import _ from "lodash";
import { Grid, Paper } from "@material-ui/core";
import {
  updateCampaignCreation,
  completeCampaignCreation,
  setInitialStateCampaignCreation,
  addNewTagsToCampaign,
  completeCampaignEditing,
  addNewTagsToCampaignGeo,
  addNewKeywordsToCampaign,
} from "../../redux/actions/campaignsActions";
import {
  getTagsGeo,
  setSelectedValue,
  getNextOptionsGeo,
  clearNextSelectedValues,
  setAddedTags,
  updateAddedTags,
  getTagsWhat,
  getNextOptionsWhat,
  getTagsWhich,
  setSelectedWhich,
  removeSelectedWhich,
  setAddedTagsWhich,
  clearTagsState,
  setKeywords,
  setSelectedKeywords,
  setAddedKeywords,
} from "../../redux/actions/tagsActions";
import { Getlatlng } from "../../redux/actions/latlngAdress";
import { connect } from "react-redux";
import TagsWrapItem from "./Common/TagsWrapItem";
import moment from "moment-timezone";
import Layout from "../../components/Layout";
import SelectInput from "../../components/SelectInput";
import ModalCreateCampaign from "./Common/ModalCreateCampaign";
import createFormData from "../../utils/createFormData";
import { reduxForm, getFormSyncErrors } from "redux-form";
import GeneralInfo from "./Common/GeneralInfo/GeneralInfo";
// import TimeWrapItem from "./Common/TimeWrapItem";
// import PriceWrapItem from "./Common/PriceWrapItem";
import { Translate } from "react-redux-i18n";
import WhichTagsInner from "./Common/WhichTagsInner";
import HeaderActions from "./Common/HeaderActions";
import validateCreation from "../../validations/validateCreation";
import MultipleKeywords from "./Common/MultipleKeywords";
import ProductWrapItem from "./Common/Product/ProductWrapItem";
import ProductCampaignModal from "./Common/Product/ProductCampaignModal";
import TagWrapItemAddress from "./Common/TagsWrapItem/TagWrapItemAddress";
import ModalCreateCampaignAddress from "./Common/ModalCreateCampaign/ModalCreateCampaignAddress";
let TotalBalanceCount;
let supplier;
class CampaignCreation extends Component {
  state = {
    isShowModal: false,
    modal: "",
    errorCreationGeoTag: false,
    isShowProductModal: false,
    isShowProductModalAddress: false,
    cost_error: false,
    firstTimeTotalTagsCostDefault: false,
    totalPayrisPointBonus: 0,
    bonus_error: true, // for now
    isDeleteTag: false
  };
  handleShowModal = (title) => {
    if (title === "Products") {
      this.setState({
        isShowProductModal: true,
      });
    }
    if (title === "Keywords") {
      if (this.state.modal !== "Keywords") this.props.clearTagsState();
      this.setState({
        isShowModal: !this.state.isShowModal,
        modal: title,
      });
    }
    if (title === "Geo Tags") {
      if (this.state.modal !== "Geo Tags") this.props.clearTagsState();
      this.props.getTagsGeo("geo_tags", "country");
      this.setState({
        isShowModal: !this.state.isShowModal,
        modal: title,
      });
    }
    if (title === "What Tags") {
      if (this.state.modal !== "What Tags") this.props.clearTagsState();
      this.props.getTagsWhat(this.props.profile.supplier.country);
      this.setState({
        isShowModal: !this.state.isShowModal,
        modal: title,
        firstTimeTotalTagsCostDefault: false
      });
    }
    if (title === "Which Tags") {
      if (this.state.modal !== "Which Tags") this.props.clearTagsState();
      this.props.getTagsWhich("which_tags", "country");
      this.setState({
        isShowModal: !this.state.isShowModal,
        modal: title,
      });
    }
  };

  handleShowModalAddress = (title) => {
    if (title === "Address") {
      this.setState({
        isShowProductModalAddress: !this.state.isShowProductModalAddress,
      });
    }
  };

  handleCloseModalAddress = () => {
    this.setState({
      isShowProductModalAddress: !this.state.isShowProductModalAddress,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal,
    });
  };
  cancelEdit = () => {
    this.props.clearTagsState();
    this.handleCloseModal();
  };

  handleChangeInput = (event, newValue, previousValue, name) => {
    console.log('zzzz', newValue, name)
    const { updateCampaignCreation } = this.props;
    if (typeof newValue === "object") {
      const value = moment(newValue).format("YYYY-MM-DD HH:mm:ss");
      updateCampaignCreation({
        [name]: value,
      });
    } else {
      updateCampaignCreation({
        [name]: newValue,
      });
    }
    if (name === 'price_per_lead') {
      if (!this.isCostValid(newValue)) {
        this.setState({ cost_error: true });
      } else {
        this.setState({ cost_error: false });
      }
    } else if(name === 'bonus') {
      const { profile, currencyList } = this.props;
      supplier = profile ? profile.supplier : {};
      const currencyCourse = (supplier && supplier.country === 'UKR') ? currencyList[1].course : currencyList ? currencyList[0].course : 0.01;
      const totalBonus = Math.round(newValue / currencyCourse);
      this.setState({ totalPayrisPointBonus: totalBonus });
      updateCampaignCreation({'bonus_points': totalBonus});
    }
  };

  handleChangeRadiusUnit = (event) => {
    const { updateCampaignCreation } = this.props;
    updateCampaignCreation({
      geo_unit: event.key,
    });
  };

  handleDeleteKeyword = (e, id) => {
    const {
      campaign: { keywords },
      updateCampaignCreation,
    } = this.props;
    const newKeywords = Object.values(keywords).filter((el) => el.id !== id);
    updateCampaignCreation({
      keywords: [...newKeywords],
    });
  };

  handleDeleteTagGeo = (e, id) => {
    const {
      campaign: { geo_tag },
      updateCampaignCreation,
    } = this.props;
    const newTags = Object.values(geo_tag).filter((el) => el.id !== id);
    updateCampaignCreation({
      geo_tag: [...newTags],
    });
  };

  handleDeleteTagModal = (e, id) => {
    const newData = this.props.addedTags.filter((el) => el.id !== id);
    this.props.updateAddedTags(newData);
  };

  handleDeleteTag = (e, id) => {
    this.setState({ isDeleteTag: true})
    const {
      campaign: { tag },
      updateCampaignCreation,
    } = this.props;

    const newTags = Object.values(tag).filter((el) => el.id !== id);
    updateCampaignCreation({
      tag: [...newTags],
    });
  };
  handleDeleteAllTags = (title) => {
    const {
      updateCampaignCreation,
      updateAddedTags,
      campaign: { tag },
    } = this.props;

    if (title === "Keywords") {
      updateCampaignCreation({
        keywords: [],
      });
    }

    if (title === "Which Tags") {
      const newTags = Object.values(tag).reduce((acc, obj) => {
        if (obj.kind !== "WHICH") {
          acc.push(obj);
        }
        return acc;
      }, []);

      updateCampaignCreation({
        tag: [...newTags],
      });
    }
    if (title === "What Tags") {
      const newTags = Object.values(tag).reduce((acc, obj) => {
        if (obj.kind !== "WHAT") {
          acc.push(obj);
        }
        return acc;
      }, []);
      updateCampaignCreation({
        tag: [...newTags],
      });
    }
    if (title === "Geo Tags") {
      updateCampaignCreation({
        geo_tag: [],
      });
    }
    if (title === "addedTags") {
      updateAddedTags([]);
    }
  };

  handleRequestMapping = (param) => {
    const dataClone = _.clone(param);
    if (dataClone && dataClone.keywords) {
      dataClone["keywords"] = param.keywords.map((key) => key.text);
    }
    if (dataClone && dataClone.products) {
      dataClone["product_ids"] = dataClone.products;
      delete dataClone["products"];
    }
    return dataClone;
  };

  isCostValid = (price_per_lead) => {
    const { campaign, qualifiedLeadPoints, qualifiedLeadCommission, currencyList } = this.props;
    const { tag } = campaign;
    if (!tag || (tag && !tag.length)) {
      return false;
    }
    let minimumCost = 0;
    _.forEach(tag, (value) => {
      minimumCost += value.reward;
    });
    const sumTotalTagsAndPayris = minimumCost + qualifiedLeadPoints;
    const qualifiedLeadCommissionToPoint = Math.round(sumTotalTagsAndPayris * qualifiedLeadCommission);
    const totalTagsCost = minimumCost ? sumTotalTagsAndPayris + qualifiedLeadCommissionToPoint : 0;
    const currencyCourse = (supplier && supplier.country === 'UKR') ? currencyList[1].course : currencyList ? currencyList[0].course : 0.01
    const totalCostPerLead = Math.round(currencyCourse * totalTagsCost);
    if (+price_per_lead < totalCostPerLead) {
      return false;
    }
    return true;
  }

  completeCampaignCreation = (values) => {
    console.log(values)
    // debugger
    const { campaign, profile, loading, address } = this.props;
    // console.log("campaign", campaign);
    const { creation, editing, start_date } = campaign;
    const { id } = campaign;
    if (!this.isCostValid(campaign.price_per_lead)) {
      this.setState({ cost_error: true });
      return;
    }
    const { invalid } = this.props;
    const supplier = { supplier: profile.supplier.id };
    const tagsId = campaign.tag.map((el) => el.id);
    // const geoTagsId = campaign.geo_tag.map((el) => el.id);
    const tagClear = { clear_tag: `${!!tagsId.length ? "" : "true"}` };
    // const tagGeoClear = {
    //   clear_geo_tag: `${!!geoTagsId.length ? "" : "true"}`,
    // };
    const startData = moment(start_date, "YYYY-MM-DD");
    const diff = moment().subtract(1, "days").diff(startData, "days");

    const checkDiff = Math.sign(diff);
    const statusCampaign = { status: `${checkDiff === -1 ? 1 : 2}` };
    const internal_tag = campaign.tag.reduce((acc, element) => {
      if (element.tagsChain) {
        acc.push(element.tagsChain);
      }
      if (!element.tagsChain && element.id) {
        acc.push(element.id);
      }
      return acc;
    }, []);

    const newObj = {
      ...campaign,
      ...supplier,
      tags: [],
      tag: tagsId,
      // geo_tag: geoTagsId,
      address: address !== null? address.address : campaign.address,
      lat: address !== null ? address.lat : campaign.lat,
      long: address !==null ? address.long : campaign.long,
      ...tagClear,
      // ...tagGeoClear,
      ...statusCampaign,
      internal_tag,
      is_world_wide: values.is_world_wide
    };
    delete newObj["geo_tag"];
    // debugger;

    // if (campaign.geo_tag.length === 0) {
    //   this.setState({ errorCreationGeoTag: true });
    //   return;
    // } else {
    //   this.setState({ errorCreationGeoTag: false });
    // }

    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
    }
    clean(newObj);

    const params = this.handleRequestMapping(newObj);
    // debugger;ư
    if (creation && !invalid) {
      if (!loading) {
        this.props.completeCampaignCreation(createFormData(params));
      }
    }

    if (editing && !invalid)
      this.props.completeCampaignEditing(id, createFormData(params));
  };

  onChangeSelect = (data) => (value) => {
    const { tagsType, tagsName } = data;
    this.props.setSelectedValue(value, tagsType, tagsName);
    this.props.clearNextSelectedValues(tagsType, tagsName);
    if (value !== null && tagsType === "geo_tags") {
      if (data.tagsName !== "district") {
        this.props.getNextOptionsGeo(value.id, tagsName);
      }
    }
    if (value !== null && tagsType === "what_tags")
      this.props.getNextOptionsWhat(value.id, tagsName);
  };

  handleChangeCheckBox = (value) => (event) => {
    if (!event.target.checked) {
      this.props.removeSelectedWhich(value);
    } else {
      this.props.setSelectedWhich(value);
    }
  };

  handleChangeKeywords = (value) => {
    if (!value) return;
    this.props.setSelectedKeywords(value);
    setTimeout(() => {
      this.handleAddTags("keywords");
    }, 100);
  };

  handleAddTags = (tagsType) => {
    if (tagsType === "keywords") {
      const { setAddedKeywords, keywordsSelected } = this.props;
      setAddedKeywords(keywordsSelected);
    }

    if (tagsType === "what_tags") {

      const tagsData = Object.values(this.props.whatTagsSelected).filter(
        (el) => el !== null
      );

      let totalReward = 0;
      const tagsChain = tagsData.reduce((acc, element) => {
        if (element.id) {
          acc.push(element.id);
          totalReward += element.reward;
        }
        return acc;
      }, []);

      const newTag = {
        ...tagsData[tagsData.length - 1],
        kind: "WHAT",
        tagsChain,
        reward: totalReward
      };
      if (
        tagsData.length > 0 &&
        !this.props.addedTags.find((x) => x.id === newTag.id)
      ) {
        this.props.setAddedTags(newTag);
      }
      this.props.setSelectedValue(null, tagsType, 'levelOne');
      this.props.setSelectedValue(null, tagsType, 'levelTwo');
      this.props.setSelectedValue(null, tagsType, 'levelThree');
      this.props.setSelectedValue(null, tagsType, 'levelFour');
    }

    if (tagsType === "geo_tags") {
      const { geo_tags_others, geo_tags_selected } = this.props;

      const tagsData = Object.values({
        ...geo_tags_selected,
        ...geo_tags_others,
      }).filter((el) => el !== null);

      const newTag = { ...tagsData[tagsData.length - 1] };

      if (
        tagsData.length > 0 &&
        !this.props.addedTags.find((x) => x.id === newTag.id)
      ) {
        this.props.setAddedTags(newTag);
      }
    }
    if (tagsType === "which_tags") {
      this.props.setAddedTagsWhich(this.props.whichTagsSelected);
      this.props.whichTagsSelected.forEach((obj) => {
        this.props.removeSelectedWhich(obj);
      });
    }
  };

  onSubmitEditModal = (name) => {
    if (name === "geo_tags") {
      this.props.addNewTagsToCampaignGeo(this.props.addedTags);
    }
    if (name !== "geo_tags" && name !== "keywords") {
      this.props.addNewTagsToCampaign(this.props.addedTags);
    }
    if (name === "keywords") {
      this.props.addNewKeywordsToCampaign(this.props.addedTags);
    }
    this.props.clearTagsState();
    this.handleCloseModal();
  };

  normalizeTagsData = (obj, name) => {
    const arr = [];
    Object.values(obj).forEach((obj) => {
      if (obj.kind === name) {
        arr.push(obj);
      }
    });
    return arr;
  };

  onDrop = (e) => {
    const id = e.dataTransfer.getData("id");
    const data = this.props.whichTagsOptions.filter(
      (el) => el.id.toString() === id
    );
    this.props.removeSelectedWhich(data[0]);
    this.props.setAddedTagsWhich(data);
  };
  onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  checkErrorBeforeSubmit = () => {
    let valid = this.props.valid;
    if (!valid) {
      this.setState({ errorCreationGeoTag: true });
    } else {
      this.setState({ errorCreationGeoTag: false });
    }
  };

  render() {
    const {
      setInitialStateCampaignCreation,
      // geo_tags_options,
      // geo_tags_selected,
      addedTags,
      whatTagsOptions,
      whatTagsSelected,
      whichTagsOptions,
      handleSubmit,
      // campaign: { tag, creation, editing, keywords, products },
      campaign: { tag, creation, editing, products, is_world_wide },
      locale,
      supplierBalance,
      dataTime,
      geoUnit,
      kmMileRatio,
      profile,
      currencyList,
      qualifiedLeadCommission,
      qualifiedLeadPoints,
      updateCampaignCreation,
      currencyText
    } = this.props;

    // const { errorCreationGeoTag } = this.state;

    TotalBalanceCount = supplierBalance;
    supplier = profile ? profile.supplier : {};

    if (!editing && !this.props.campaign['geo_unit']) {
      updateCampaignCreation({
        geo_unit: supplier.country === "UKR" ? 'KM' : 'MILE'
      });
    }

    // console.log("ABDHADDG", this.props.campaign, profile, whatTagsOptions);

    return (
      <Layout>
        <form onSubmit={handleSubmit(this.completeCampaignCreation)}>
          <HeaderActions
            creation={creation}
            editing={editing}
            setInitialStateCampaignCreation={setInitialStateCampaignCreation}
            locale={locale}
            supplierBalance={supplierBalance}
            currencyText={currencyText}
            supplier={supplier}
            dataTime={dataTime}
            checkErrorBeforeSubmit={this.checkErrorBeforeSubmit}
          />
          <Paper>
            <Grid container wrap="nowrap">
              <GeneralInfo
                handleChangeInput={this.handleChangeInput}
                handleChangeRadiusUnit={this.handleChangeRadiusUnit}
                supplierBalance={supplierBalance}
                campaign={this.props.campaign}
                cost_error={this.state.cost_error}
                setCostError={(val) => this.setState({ cost_error: val })}
                geoUnit={geoUnit}
                profile={profile}
                locale={locale}
                supplier={supplier}
                currencyList={currencyList}
                qualifiedLeadCommission={qualifiedLeadCommission}
                qualifiedLeadPoints={qualifiedLeadPoints}
                firstTimeTotalTagsCostDefault={this.state.firstTimeTotalTagsCostDefault}
                setFirstTimeTotalTagsCostDefault={val => this.setState({firstTimeTotalTagsCostDefault: val})}
                updateCampaignCreation={(val, bonus) => {
                  const currencyCourse = (supplier && supplier.country === 'UKR') ? this.props.currencyList[1].course : this.props.currencyList ? this.props.currencyList[0].course : 0.01;
                  const totalBonus = Math.round(bonus / currencyCourse);
                  this.setState({ totalPayrisPointBonus: totalBonus });
                  updateCampaignCreation({'price_per_lead': val, 'bonus': bonus, 'bonus_points': totalBonus});
                }}
                currencyText={currencyText}
                totalPayrisPointBonus={this.props.campaign.editing ? (this.props.campaign.bonus_points || 0) : this.state.totalPayrisPointBonus}
                bonus_error={this.state.bonus_error}
                isDeleteTag={this.state.isDeleteTag}
                setIsDeleteTag={val => this.setState({ isDeleteTag: val })}
                isWorldWide={is_world_wide}
              />
              <Grid container direction="column" style={{ flexBasis: "65%" }}>
                <ProductWrapItem
                  title="Products"
                  handleShowModal={this.handleShowModal}
                  products={products}
                />
                {/*<TagsWrapItem*/}
                {/*  title="Keywords"*/}
                {/*  label="Keywords"*/}
                {/*  keywords={keywords}*/}
                {/*  handleDeleteTag={this.handleDeleteKeyword}*/}
                {/*  handleDeleteAllTags={this.handleDeleteAllTags}*/}
                {/*  handleShowModal={this.handleShowModal}*/}
                {/*/>*/}
                {/* Temporary disable */}
                {/*<TagsWrapItem*/}
                {/*  title="Which Tags"*/}
                {/*  label={*/}
                {/*    <Translate value="CampaignsCreation.CampaignsCreationWhichTags" />*/}
                {/*  }*/}
                {/*  tags={this.normalizeTagsData(tag, "WHICH")}*/}
                {/*  handleDeleteTag={this.handleDeleteTag}*/}
                {/*  handleDeleteAllTags={this.handleDeleteAllTags}*/}
                {/*  handleShowModal={this.handleShowModal}*/}
                {/*  style={{display: "none !important"}}*/}
                {/*/>*/}
                <TagWrapItemAddress
                  title="Address"
                  handleShowModal={this.handleShowModalAddress}
                  campaignName={this.props.campaign}
                  locale={locale}
                />
                <TagsWrapItem
                  title="What Tags"
                  label={
                    <Translate value="CampaignsCreation.CampaignsCreationWhatTags" />
                  }
                  tags={this.normalizeTagsData(tag, "WHAT")}
                  handleDeleteTag={this.handleDeleteTag}
                  handleDeleteAllTags={this.handleDeleteAllTags}
                  handleShowModal={this.handleShowModal}
                />
              </Grid>
            </Grid>
          </Paper>
          <ModalCreateCampaign
            handleShowModal={
              this.handleShowModal && this.state.modal === "Keywords"
            }
            open={this.state.isShowModal && this.state.modal === "Keywords"}
            cancelEdit={this.cancelEdit}
            onSubmitEdit={() => this.onSubmitEditModal("keywords")}
            addedTags={addedTags}
            // handleDeleteTagModal={this.handleDeleteTagModal}
            handleDeleteAllTags={this.handleDeleteAllTags}
            handleCloseModal={this.handleCloseModal}
            modalName="Keywords"
            useTagsAddedBox={false}
          >
            <MultipleKeywords onValueChange={this.handleChangeKeywords} />
          </ModalCreateCampaign>
          <ModalCreateCampaign
            handleShowModal={
              this.handleShowModal && this.state.modal === "Geo Tags"
            }
            open={this.state.isShowModal && this.state.modal === "Geo Tags"}
            cancelEdit={this.cancelEdit}
            onSubmitEdit={() => this.onSubmitEditModal("geo_tags")}
            handleAddTags={() => this.handleAddTags("geo_tags")}
            addedTags={addedTags}
            handleDeleteTagModal={this.handleDeleteTagModal}
            handleDeleteAllTags={this.handleDeleteAllTags}
            handleCloseModal={this.handleCloseModal}
            useButtonAdd
            modalName={
              <Translate value="CampaignsCreation.CampaignsCreationGeoTagsModalTitle" />
            }
            // classes={{actionButtonsWrap:'abc'}}
          >
            <div
              css={css`
                .input-geo {
                  min-width: 240px;
                }
              `}
            ></div>
          </ModalCreateCampaign>
          <ModalCreateCampaign
            handleShowModal={
              this.handleShowModal && this.state.modal === "What Tags"
            }
            open={this.state.isShowModal && this.state.modal === "What Tags"}
            cancelEdit={this.cancelEdit}
            onSubmitEdit={() => this.onSubmitEditModal("what_tags")}
            handleAddTags={() => this.handleAddTags("what_tags")}
            addedTags={addedTags}
            handleDeleteTagModal={this.handleDeleteTagModal}
            handleDeleteAllTags={this.handleDeleteAllTags}
            handleCloseModal={this.handleCloseModal}
            useButtonAdd
            modalName={
              <Translate value="CampaignsCreation.CampaignsCreationWhatTagsModalTitle" />
            }
            supplier={supplier}
            currencyList={currencyList}
            locale={locale}
            qualifiedLeadCommission={qualifiedLeadCommission}
            qualifiedLeadPoints={qualifiedLeadPoints}
          >
            <SelectInput
              label={
                <Translate value="CampaignsCreation.CampaignsCreationWhatTagsModalSelectlevelOne" />
              }
              selectedValue={whatTagsSelected.levelOne}
              tagsType="what_tags"
              tagsName="levelOne"
              options={whatTagsOptions.levelOne}
              onChangeSelect={this.onChangeSelect}
              isUnfixedHeight={true}
            />
            <SelectInput
              label={
                <Translate value="CampaignsCreation.CampaignsCreationWhatTagsModalSelectlevelTwo" />
              }
              disabled={!whatTagsSelected.levelOne}
              selectedValue={whatTagsSelected.levelTwo}
              tagsType="what_tags"
              tagsName="levelTwo"
              options={whatTagsOptions.levelTwo}
              onChangeSelect={this.onChangeSelect}
              isUnfixedHeight={true}
            />
            <SelectInput
              label={
                <Translate value="CampaignsCreation.CampaignsCreationWhatTagsModalSelectlevelThree" />
              }
              disabled={!whatTagsSelected.levelTwo}
              selectedValue={whatTagsSelected.levelThree}
              tagsType="what_tags"
              tagsName="levelThree"
              options={whatTagsOptions.levelThree}
              onChangeSelect={this.onChangeSelect}
              isUnfixedHeight={true}
            />
            <SelectInput
              label={
                <Translate value="CampaignsCreation.CampaignsCreationWhatTagsModalSelectlevelFour" />
              }
              disabled={!whatTagsSelected.levelThree}
              selectedValue={whatTagsSelected.levelFour}
              tagsType="what_tags"
              tagsName="levelFour"
              options={whatTagsOptions.levelFour}
              onChangeSelect={this.onChangeSelect}
              isUnfixedHeight={true}
            />
          </ModalCreateCampaign>
          <ModalCreateCampaign
            handleShowModal={
              this.handleShowModal && this.state.modal === "Which Tags"
            }
            open={this.state.isShowModal && this.state.modal === "Which Tags"}
            cancelEdit={this.cancelEdit}
            onSubmitEdit={() => this.onSubmitEditModal("which_tags")}
            addedTags={addedTags}
            useButtonAdd={false}
            onDrop={this.onDrop}
            handleDeleteTagModal={this.handleDeleteTagModal}
            handleDeleteAllTags={this.handleDeleteAllTags}
            handleCloseModal={this.handleCloseModal}
            modalName={
              <Translate value="CampaignsCreation.CampaignsCreationWhichTagsModalTitle" />
            }
          >
            <WhichTagsInner
              whichTagsOptions={whichTagsOptions}
              addedTags={addedTags}
              onDragStart={this.onDragStart}
              handleChangeCheckBox={this.handleChangeCheckBox}
              handleAddTags={this.handleAddTags}
            />
          </ModalCreateCampaign>

          <ModalCreateCampaignAddress
            open={this.state.isShowProductModalAddress}
            handleClose={this.handleCloseModalAddress}
            btnSave={this.hanldeSaveAddress}
            campaignName={this.props.campaign}
            kmMileRatio={kmMileRatio}            
            handleChangeInput={this.handleChangeInput}
            handleChangeRadiusUnit={this.handleChangeRadiusUnit}
            campaign={this.props.campaign}
            geoUnit={geoUnit}
            profile={profile}
            locale={locale}
            isWorldWide={is_world_wide}
          />
        </form>
        <ProductCampaignModal
          open={this.state.isShowProductModal}
          onShowModal={(show) => this.setState({ isShowProductModal: show })}
          productsSelected={products}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaigns.campaignCreation,
    loading: state.app.loading,
    addedTags: state.tags.addedTags,
    profile: state.profile,
    geo_tags_options: state.tags.options.geo_tags,
    geo_tags_selected: state.tags.selected.geo_tags,
    geo_tags_others: state.tags.selected.geo_tags_others,
    whatTagsOptions: state.tags.options.what_tags,
    whatTagsSelected: state.tags.selected.what_tags,
    whichTagsOptions: state.tags.options.which_tags,
    whichTagsSelected: state.tags.selected.which_tags,
    keywordsOptions: state.tags.options.keywords,
    keywordsSelected: state.tags.selected.keywords,
    syncErrors: getFormSyncErrors("CampaignCreation")(state),
    initialValues: state.campaigns.campaignCreation,
    campaignsTotalBalance: state.campaigns.totalBalance,
    address: state.GetlatLngReducer.Address,
    geoUnit: state.app.config.geo_unit,
    kmMileRatio: state.app.config.km_mile_ratio,
    currencyList: state.app.config.currency,
    qualifiedLeadCommission: state.app.config.payris_qualified_lead_commission,
    qualifiedLeadPoints: state.app.config.payris_qualified_lead_points,
    locale: state.i18n.locale,
  };
};

const mapDispatchToProps = {
  updateCampaignCreation,
  completeCampaignCreation,
  setInitialStateCampaignCreation,
  getTagsGeo,
  setSelectedValue,
  getNextOptionsGeo,
  getNextOptionsWhat,
  clearNextSelectedValues,
  setAddedTags,
  updateAddedTags,
  getTagsWhat,
  getTagsWhich,
  setSelectedWhich,
  removeSelectedWhich,
  setAddedTagsWhich,
  clearTagsState,
  addNewTagsToCampaign,
  completeCampaignEditing,
  addNewTagsToCampaignGeo,
  setKeywords,
  setSelectedKeywords,
  setAddedKeywords,
  addNewKeywordsToCampaign,
  Getlatlng,
};

CampaignCreation = reduxForm({
  form: "CampaignCreation",
  validate: (val) => validateCreation(val)(TotalBalanceCount),
  enableReinitialize: true,
  touchOnBlur: false,
})(CampaignCreation);

CampaignCreation = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCreation);

export default CampaignCreation;
