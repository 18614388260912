import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 18
  }
});

function BackIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#007FFF"
          d="M4.147 9.787l3.847 3.64a.766.766 0 0 1 .015 1.099.805.805 0 0 1-1.127.013L1.844 9.723a1 1 0 0 1 0-1.446l5.038-4.816a.805.805 0 0 1 1.127.013.766.766 0 0 1-.015 1.098l-3.847 3.64h10.816a.788.788 0 0 1 0 1.575H4.146z"
        />
      </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(BackIcon);
