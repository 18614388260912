const styles = theme => ({
  round: {
    width: 35,
    height: 34,
    border: "solid 1px #007fff",
    background: "transparent",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    "&:hover": {
      background: "#fff",
      cursor: "pointer"
    }
  },
  completeBtn: {
    // width: 243,
    height: 34,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.29,
    letterSpacing: "-0.41px"
  },
  wrapTitle: {
    display: "flex"
  },
  wrapComplete: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    marginBottom: 0
  },
  totalBtn: {
    color: "#fff",
    backgroundColor: "#C7CACB",
    padding: "6px 15px",
    marginRight: "16px",
    marginLeft: "40px",
    "&:hover": {
      backgroundColor: "#a5a8a9"
    },
    "& $priceNumber, & $currency": {
      lineHeight: 1
    }
  },
  priceNumber: {
    lineHeight: 1,
    marginRight: "3px"
  },
  currency: {
    marginRight: "16px",
    lineHeight: 1
  },
  lastUpdate: {
    color: "#9FB0CB",
    font: "500 12px/14px Roboto"
  }
});

export default styles;
