import {
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_FAILED,
  CREATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_FAILED,
  DELETE_CATEGORY_FAILED,
  SET_CURRENT_CATEGORY_PAGE
} from './types';
import apiService from "../../api/apiService";
import { appLoading, stopAppLoading, openToastSystem } from "./appActions";
import store from "../store";
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';

export const setCurrentCategoryPage = (page) => dispatch => {
  dispatch({type: SET_CURRENT_CATEGORY_PAGE, payload: page});
}

export const getCategoryList = (params) => dispatch => {

  dispatch(appLoading());
  apiService
    .getCategoryList(params)
    .then(res => {
      const { data } = res;
      if(params.page !== 1){
        const { categoryList } = store.getState().category;
        const dataTemp = {
          count: data.count,
          next: data.next,
          results: []
        }
        if(data.results && data.results.length > 0){
          dataTemp.results = [...categoryList, ...data.results];
        }
        dataTemp.results = _.uniqBy(dataTemp.results,v => v.id);
        dispatch({
          type: GET_PRODUCT_CATEGORY_SUCCESS,
          payload: dataTemp
        });
      }else{
        dispatch({
          type: GET_PRODUCT_CATEGORY_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(err => dispatch({ type: GET_PRODUCT_CATEGORY_FAILED, payload: err }))
    .finally(() => dispatch(stopAppLoading()));
};

export const createCategory = (data, params) => dispatch => {
  const actionMessage = {
    createSuccess: I18n.t('ProductCategories.ProductCategoriesCreateSuccess'),
    createFailed: I18n.t('ProductCategories.ProductCategoriesCreateFailed'),
  }
  dispatch(appLoading());
  apiService.createCategory(data)
    .then(res => {
      dispatch(openToastSystem({
        message: `${actionMessage.createSuccess}`
      }));
    })
    .then(() => dispatch(getCategoryList(params)))
    .catch(error => {
      console.log('CREATE_CATEGORY_ERROR_', error);
      dispatch({
        type: CREATE_CATEGORY_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: `${actionMessage.createFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}

export const updateCategory = (id, data, params) => dispatch => {
  const actionMessage = {
    updateSuccess: I18n.t('ProductCategories.ProductCategoriesUpdateSuccess'),
    updateFailed: I18n.t('ProductCategories.ProductCategoriesUpdateFailed'),
  }
  dispatch(appLoading());
  apiService.updateCategory(id, data)
    .then(res => {
      dispatch(openToastSystem({
        message: I18n.t('ProductCategories.ProductCategoriesCategory') + ` ${id}: ${actionMessage.updateSuccess}`
      }));
    })
    .then(() => dispatch(getCategoryList(params)))
    .catch(error => {
      console.log('UPDATE_CATEGORY_ERROR_', error);
      dispatch({
        type: UPDATE_CATEGORY_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: I18n.t('ProductCategories.ProductCategoriesCategory') + ` ${id}: ${actionMessage.updateFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}

export const deleteCategory = (id, params) => dispatch => {
  const actionMessage = {
    deleteSuccess: I18n.t('ProductCategories.ProductCategoriesDeleteSuccess'),
    deleteFailed: I18n.t('ProductCategories.ProductCategoriesDeleteFailed'),
  }
  
  dispatch(appLoading());
  apiService.deleteCategory(id)
    .then(res => {
      dispatch(openToastSystem({
        message: I18n.t('ProductCategories.ProductCategoriesCategory') + `: ${id}: ${actionMessage.deleteSuccess}`
      }));
    })
    .then(() => dispatch(getCategoryList(params)))
    .catch(error => {
      console.log('DELETE_CATEGORY_ERROR_', error);
      dispatch({
        type: DELETE_CATEGORY_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: I18n.t('ProductCategories.ProductCategoriesCategory') + `: ${id}: ${actionMessage.deleteFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}