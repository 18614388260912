import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 18
  }
});

function EmptyCheckbox(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
       <g id="icon-/-18-px-/-check-/-empty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="rgb(199, 199, 199)" x="0" y="0" width="18" height="18"></rect>
        <g id="Group" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
            <path d="M1,0 L13,0 C13.5522847,0 14,0.44771525 14,1 L14,13 C14,13.5522847 13.5522847,14 13,14 L1,14 C0.44771525,14 0,13.5522847 0,13 L0,1 C0,0.44771525 0.44771525,0 1,0 Z" id="Rectangle-22" fill="#E3E3E3"></path>
            <path d="M1.16975204,1.13527514 L12.869752,1.13527514 C12.869752,1.13527514 12.869752,1.50943717 12.869752,1.97098942 L12.869752,11.9995609 C12.869752,12.3072624 12.869752,12.5858338 12.869752,12.8352751 L1.16975204,12.8352751 C1.16975204,12.4611131 1.16975204,12.4611131 1.16975204,11.9995609 L1.16975204,1.97098942 C1.16975204,1.50943717 1.16975204,1.67578125 1.16975204,1.13527514 Z" id="Rectangle-22" fill="#FFFFFF"></path>
        </g>
    </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(EmptyCheckbox);
