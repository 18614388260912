import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import CustomInput from "../../../components/CustomInput";
import validation from '../../../validations/common';
import { Translate } from "react-redux-i18n";
import DoneIcon from "../../../components/Icons/DoneIcon";
import CloseIcon from "../../../components/Icons/CloseIcon";
import SelectInput from "../../../components/SelectInput";
import { getCategoryList } from '../../../redux/actions/categoryActions';

const ProductModalStyle = styled.form`
    width: 45rem;

    p[class^="MuiFormHelperText-error"] {
        background: #ffff00;
    }

    .product-dialog-content{
        padding-top: 1rem;
        padding-bottom: 10px;
    }

    .action-group{
        margin: 8px 20px 30px;
    }
    .cancel-btn {
        min-width: 104px;
        border-radius: 24px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #0028c2;
        border: 1px solid #0028c2;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        & svg {
            margin-right: 8px;
            margin-bottom: 2px;
            
            & path {
                fill: #0028c2;
            }
        }
    }
    .submit-btn {
        min-width: 104px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #fff;
        font-size: 14px;
        margin-left: 24px;
        background: #0028c2;
        & path:nth-of-type(1){
            fill: #fff;
        }
        & svg {
            margin-right: 8px;
            margin-top: 3px;
        }
    }
    .form-container{
        min-height: 20rem;
        .field-control{
            margin-bottom: 1rem;
            > div {
              margin-bottom: 0;
            }
        }
        .custom-select {
          p {
            width: 142px !important;
          }
        }
        .category-container{
            width: 100%;
        }
        .product-image-upload{
            font-family: Roboto;
            .fileContainer{
                border: 1px solid rgba(0, 0, 0, 0.05);
                box-shadow: none;
                .deleteImage{
                    position: absolute;
                    top: -9px;
                    right: -9px;
                    color: #fff;
                    background: #3f4257;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 22px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .select-value{
            color: #2b2b2b;
            font-weight: 400;
            padding-left: 0.3rem;
        }
    }
`;

const InputCustomStyle = styled.div`
    width: 100%;
    p[class^="MuiFormHelperText-root"] {
        margin: 8px 0;
    }
`;

const renderTextField = ({
  input,
  serverErrors,
  type,
  placeholder,
  label,
  endadornment,
  labelWrapWidth,
  meta: { error, touched },
  multiline,
  rows,
  rowsMax
}) => {
  return (
    <InputCustomStyle>
      <CustomInput
        // error={!!error || !!serverErrors.length}
        error={touched && !!error}
        type={type}
        label={label}
        labelWrapWidth={labelWrapWidth}
        helperText={(touched && !!error) && error}
        placeholder={placeholder}
        // endadornment={endadornment}
        {...input}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
      />
    </InputCustomStyle>
  )
};

const ProductModal = props => {
  const initialCategory = [
    {
      key: null,
      display: <Translate value="Products.ProductsProductModalDropdown" />
    }
  ]

  const { required, number } = validation;
  const { isOpen, isEdit, onCancel, handleSubmit, reset, initialValues, onEdit, onCreate, supplier } = props;
  const profile = useSelector(state => state.profile);
  const currencyText = (supplier && supplier.country === 'UKR') ? 'UAH' : 'USD';

  // const [method, setMethod] = useState(PurchaseMethod[1]);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const categoryList = useSelector(state => state.category.categoryList)
  const [categorySelected, setCategorySelected] = useState(initialCategory[0]);
  useEffect(() => {
    getCategoryList({ page: null})
  }, [])

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      // setMethod(PurchaseMethod[initialValues.purchase_method]);
      if (initialValues.image) setImageUploaded(initialValues.image);
      if (initialValues.category) {
        const selectCategory = {
          key: initialValues.category.id,
          display: initialValues.category.name,
        }
        setCategorySelected(selectCategory)
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (!isOpen) {
      // setMethod(PurchaseMethod[1]);
      setImageUploaded(null);
      setImageFile(null);
      setCategorySelected(initialCategory[0]);
    }
  }, [isOpen]);

  const onSubmit = values => {
    const { name, description, price, product_url } = values;
    if (!profile || !profile.supplier) return;
    // const methodIndex = Object.values(PurchaseMethod).findIndex(val => val.key === method.key) + 1;
    const priceParse = !!!price ? "NULL" : price;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description)
    formData.append("price", priceParse);
    // formData.append('available', available);
    formData.append('product_url', product_url);
    formData.append("supplier", profile.supplier.id);
    // formData.append("purchase_method", methodIndex);
    formData.append('category', categorySelected.key)

    if (imageFile) formData.append("image", imageFile);

    if (isEdit) {
      onEdit(initialValues.id, formData);
    } else {
      onCreate(formData);
    }

    reset();
  }

  // const onChangeSelectValue = value => {
  //     setMethod({
  //         ...value
  //     })
  // }

  const onDropImage = (images, pictures) => {
    if (images && images.length > 0) {
      setImageFile(images[0]);
      setImageUploaded(pictures[0]);
    } else {
      setImageFile(null);
      setImageUploaded(null);
    }
  }


  return (
    <Dialog open={isOpen}>
      <ProductModalStyle onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Translate value={isEdit ? "Products.ProductsUpdateModalTitle" : "Products.ProductsCreateModalTitle"} />
        </DialogTitle>
        <DialogContent className="product-dialog-content">
          <div className="form-container">
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control custom-select"
            >
              <SelectInput
                className="category-container"
                label={`${I18n.t('Products.ProductsProductModalCategoryLabel')}`}
                selectedValue={categorySelected}
                isNormalSelection
                options={categoryList.map(c => ({ key: c.id, display: c.name}))}
                onChangeSelect={item => { setCategorySelected(item); }}
                classes={{
                  singleValue: "select-value"
                }}
              />
            </Grid>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control"
            >
              <Field
                type="text"
                name="name"
                label={`${I18n.t('Products.ProductsProductModalNameLabel')}`}
                placeholder={I18n.t("Products.ProductsName")}
                labelWrapWidth="140px"
                component={renderTextField}
                validate={[required]}
              />
            </Grid>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control"
            >
              <Field
                // type="text"
                multiline
                rows={1}
                rowsMax={5}
                name="description"
                label={`${I18n.t('Products.ProductsProductModalDescriptionLabel')}`}
                placeholder={I18n.t("Products.ProductsDescription")}
                labelWrapWidth="140px"
                component={renderTextField}
              />
            </Grid>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control"
            >
              <Field
                type="text"
                name="price"
                label={`${I18n.t('Products.ProductsProductModalPriceLabel')}(${currencyText})`}
                placeholder={I18n.t("Products.ProductsPrice")}
                labelWrapWidth="140px"
                component={renderTextField}
                validate={[number]}
              />
            </Grid>
            {/* <Grid
                            container
                            wrap="nowrap"
                            alignItems="center"
                            justify="space-between"
                            className="field-control"
                        >
                            <Field
                                type="text"
                                name="available"
                                label={I18n.t("Products.ProductsAvailable")}
                                placeholder={I18n.t("Products.ProductsAvailable")}
                                labelWrapWidth="140px"
                                component={renderTextField}
                                validate={[required]}
                            />
                        </Grid> */}
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control"
            >
              <Field
                type="text"
                name="product_url"
                label={`${I18n.t('Products.ProductsProductModalUrlLabel')}`}
                placeholder={I18n.t("Products.ProductsUrl")}
                labelWrapWidth="140px"
                component={renderTextField}
                validate={[required]}
              />
            </Grid>
            {/* <Grid
                            container
                            wrap="nowrap"
                            alignItems="center"
                            justify="space-between"
                            className="field-control"
                        >
                            <SelectInput
                                className="purchase-method-container"
                                label="Purchase method"
                                selectedValue={method}
                                isNormalSelection
                                options={Object.values(PurchaseMethod)}
                                onChangeSelect={onChangeSelectValue}
                                classes={{
                                    singleValue: "select-value"
                                }}
                            />
                        </Grid> */}
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
            >
              <ImageUploader
                className="product-image-upload"
                buttonText={`${I18n.t('Products.ProductsProductModalSelectImageButton')}`}
                onChange={onDropImage}
                imgExtension={['.jpg', '.png', '.jpeg']}
                label={`${I18n.t('Products.ProductsProductModalLimitImage')}`}
                maxFileSize={5242880}
                withIcon
                withPreview
                singleImage
                withLabel
                name="image"
                defaultImages={imageUploaded ? [imageUploaded] : undefined}
              />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className="action-group">
          <Button
            variant="outlined"
            className="cancel-btn"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            <CloseIcon />
            <Translate value="Products.ProductsModalCancelBtn" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="submit-btn"
          >
            <DoneIcon />
            <Translate value={isEdit ? "Products.ProductsModalUpdateBtn" : "Products.ProductsModalCreateBtn"} />
          </Button>
        </DialogActions>
      </ProductModalStyle>
    </Dialog>
  )
}

ProductModal.propTypes = {
  isEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func
}

ProductModal.defaultProps = {
  isEdit: false,
  isOpen: false
}

const mapStateToProps = state => ({
  serverErrors: state.errors,
  locale: state.i18n.locale,
  supplier: state.profile.supplier,
});

const mapDispatchToProps = {
  getCategoryList
};

const ProductModalWrapper = connect(
  mapStateToProps, mapDispatchToProps
)(ProductModal);

export default reduxForm({ form: 'ProductForm', enableReinitialize: true })(ProductModalWrapper);