import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { CategoryModalStyle, InputCustomStyle } from './style';
import { Grid } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import validation from '../../../validations/common';
import CustomInput from "../../../components/CustomInput";
import DoneIcon from "../../../components/Icons/DoneIcon";
import CloseIcon from "../../../components/Icons/CloseIcon";

const renderTextField = ({
  input,
  type,
  placeholder,
  label,
  labelWrapWidth,
  meta: { error, touched }
}) => {
  return (
    <InputCustomStyle>
      <CustomInput
        error={touched && !!error}
        type={type}
        label={label}
        labelWrapWidth={labelWrapWidth}
        helperText={(touched && !!error) && error}
        placeholder={placeholder}
        {...input}
      />
    </InputCustomStyle>
  )
};

const CategoryModal = React.memo(props => {
  const profile = useSelector(state => state.profile);
  const { isOpen, isEdit, onCancel, handleSubmit, reset, initialValues, onEdit, onCreate } = props;
  const { required } = validation;

  const onSubmit = values => {
    const { name } = values;
    if (!profile || !profile.supplier) return;

    const formData = new FormData();
    formData.append("name", name);

    if (isEdit) {
      onEdit(initialValues.id, formData);
    } else {
      onCreate(formData);
    }

    reset();
  }

  return (
    <Dialog open={isOpen}>
      <CategoryModalStyle onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Translate value={isEdit ? "ProductCategories.ProductCategoriesUpdateModalTitle" : "ProductCategories.ProductCategoriesCreateModalTitle"} />
        </DialogTitle>
        <DialogContent className="product-dialog-content">
          <div className="form-container">
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className="field-control"
            >
              <Field
                type="text"
                name="name"
                label={I18n.t("ProductCategories.ProductCategoriesLabelName")}
                placeholder={I18n.t("ProductCategories.ProductCategoriesCategoryName")}
                labelWrapWidth="140px"
                component={renderTextField}
                validate={[required]}
              />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className="action-group">
          <Button
            variant="outlined"
            className="cancel-btn"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            <CloseIcon />
            <Translate value="ProductCategories.ProductCategoriesModalCancelBtn" />
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="submit-btn"
          >
            <DoneIcon />
            <Translate value={isEdit ? "ProductCategories.ProductCategoriesModalUpdateBtn" : "ProductCategories.ProductCategoriesModalCreateBtn"} />
          </Button>
        </DialogActions>
      </CategoryModalStyle>
    </Dialog>
  )
});

CategoryModal.propTypes = {
  isEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func
}

CategoryModal.defaultProps = {
  isEdit: false,
  isOpen: false
}

const mapStateToProps = state => ({
  locale: state.i18n.locale,
});

const CategoryModalWrapper = connect(
  mapStateToProps
)(CategoryModal);

export default reduxForm({ form: 'CategoryForm', enableReinitialize: true })(CategoryModalWrapper);