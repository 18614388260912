import React, { Component } from "react";
import { Typography, withStyles, Grid, Button, Paper } from "@material-ui/core";
import Layout from "../../components/Layout";
import {
  searchShipmentList,
  getShipmentListTableData,
  sortColumnShipment,
  deleteShipment
} from "../../redux/actions/shipmentActions";
import ShipmentTable from "./Common/ShipmentTable";
import CustomInputRounded from "../../components/CustomInputRounded";
import ConfirmationsModal from '../../components/ConfirmationsModal';
import { connect } from "react-redux";
import { getNextDataPage } from "../../helpers";
import styles from "./styles";
import { I18n, Translate } from "react-redux-i18n";

class Shipment extends Component {
  state = {
    isShowModal: false,
    searchDelete: false,
    searchInputValue: "",
    showMatch: false,
    rowData: {},
    isOpenConfirmDelete: false,
  };

  componentDidMount() {
    this.props.getShipmentListTableData();
  }

  onOpenConfirmDeleteModal = (rowData) => {
    this.setState({
      rowData,
      isOpenConfirmDelete: true
    });
  }

  onCloseConfirmDeleteModal = () => {
    this.setState({
      rowData: null,
      isOpenConfirmDelete: false
    });
  }

  onDeleteShipment = () => {
    if (this.state.rowData)
      this.props.deleteShipment({id: this.state.rowData.id, ukrposhta_tracking_id: this.state.rowData.ukrposhta_tracking_id})
  }

  editSearchInput = (e) => {
    this.setState({
      searchInputValue: e.target.value,
      searchDelete: e.target.value.length >= 1,
    });
  };

  handleClearSearchInput = () => {
    this.setState({
      searchInputValue: "",
      searchDelete: false,
      showMatch: false,
    });
    this.props.searchShipmentList("");
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.searchShipmentList(e.target.value);
      this.setState({
        showMatch: true,
      });
    }
    if (e.key === "Enter" && e.target.value === "") {
      this.setState({
        showMatch: false,
      });
    }
  };

  loadMoreData = () => {
    const {
      nextPage,
      getDefaultLeast,
      sorting,
      sortingName,
      searching,
    } = this.props.shipment;
    const {
      getShipmentListTableData,
      searchShipmentList,
      sortColumnShipment,
    } = this.props;
    const { searchInputValue } = this.state;
    if (nextPage) {
      let next = getNextDataPage(nextPage);
      if (sorting) {
        sortColumnShipment(sortingName, next);
      } else if (searching) {
        searchShipmentList(searchInputValue, next);
      } else if (getDefaultLeast) {
        getShipmentListTableData(next);
      }
    }
  };

  render() {
    const {
      classes,
      rows,
      shipment,
      loading,
      locale
    } = this.props;

    return (
      <div className={classes.container}>
        <Layout isLoading={loading}>
          <Typography variant="h4" style={{ marginBottom: 16 }}>
            <Translate value="Shipment.ShipmentListTitle" />
          </Typography>
          <Paper style={{ width: "100%", height: "100%" }}>
            <Grid
              container
              justify="space-between"
              style={{ width: "100%", height: "100%", padding: 24 }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomInputRounded
                  type="search"
                  placeholder={I18n.t("Shipment.ShipmentListSearch")}
                  value={this.state.searchInputValue}
                  onKeyPress={this.onKeyPress}
                  onChange={this.editSearchInput}
                  onClick={this.handleClearSearchInput}
                  showMatch={this.state.showMatch}
                  rows={rows}
                  // noTranslate="true"
                />
              </div>
            </Grid>
            <ShipmentTable
              rows={rows}
              sortColumn={this.props.sortColumnShipment}
              confirmDeleteModal={this.onOpenConfirmDeleteModal}
              locale={locale}
            />
            <Grid className={classes.loadMoreContainer}>
              <Button
                className={`${classes.rootBtn} ${classes.loadMoreDataBtn}`}
                variant="contained"
                color="primary"
                onClick={this.loadMoreData}
                disabled={loading || shipment.rows.length === shipment.count}
              >
                {I18n.t("Products.ProductsLoadMore")}
              </Button>
              <div>{`${I18n.t("Products.ProductsLoaded")} ${shipment.rows.length} ${I18n.t(
                "Products.ProductsOf"
              )} ${shipment.count}`}</div>
            </Grid>
          </Paper>
          <ConfirmationsModal
            open={!!this.state.isOpenConfirmDelete}
            handleClose={() => { this.onCloseConfirmDeleteModal(null); }}
            handleConfirm={() => {
              this.onDeleteShipment();
              this.onCloseConfirmDeleteModal(null);
            }}
            confirmData={{
              message: 'Ви впевнені, що хочете видалити категорію?',
              acceptBtnLabel: 'Так',
              cancelBtnLabel: 'Ні'
            }}
          />
        </Layout>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rows: state.shipment.rows,
  shipment: state.shipment,
  loading: state.app.loading,
  locale: state.i18n.locale,
});

const mapDispatchToProps = {
  getShipmentListTableData,
  searchShipmentList,
  sortColumnShipment,
  deleteShipment
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Shipment));
