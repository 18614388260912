const styles = theme => ({
  main: {
    height: "100%",
    width: "auto",
    display: "block",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      minWidth: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 240
  },
  formWrap: {
    display: "flex",
    flexDirection: "column"
  },
  error: {
    width: "100%",
    height: 24,
    paddingLeft: 13,
    color: "#de2323",
    fontSize: 14,
    fontFamily: "Roboto"
  },
  title: {
    height: 22,
    fontFamily: "Helvetica",
    fontSize: 32,
    lineHeight: 0.69,
    letterSpacing: -0.8,
    textAlign: "center",
    color: "#9fb0cb",
    marginBottom: theme.spacing.unit * 3
  },
  logo: {
    marginBottom: theme.spacing.unit * 5
  },
  input: {
    marginBottom: theme.spacing.unit * 2,
    height: 44,
    borderRadius: 24,
    "& fieldset": {
      borderRadius: 24
    }
  },
  buttonRoot: {
    height: 44,
    width: 314,
    borderRadius: 24,
    fontSize: 17,
    marginTop: 32,
    "& span": { color: "#ffffff" }
  }
});

export default styles;
