import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import { clearErrors } from '../../redux/actions/errorsActions';
import { withStyles, Button } from '@material-ui/core';
import MainLogo from '../../components/Icons/MainLogo';
import CustomInputRounded from '../../components/CustomInputRounded';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import createFormData from '../../utils/createFormData';
import { Translate, loadTranslations, setLocale, I18n } from 'react-redux-i18n';
import translationsObject from '../../translations/index';
import styles from './styles';
import store from '../../redux/store';

const renderTextField = ({
  input,
  serverErrors,
  type,
  placeholder,
  meta: { error }
}) => (
  <CustomInputRounded
    error={!!error || !!serverErrors.length}
    type={type}
    placeholder={placeholder}
    {...input}
  />
);

const toLowerCase = value => value.toLowerCase();

class Login extends Component {
  onSubmit = values => {
    if (!values.email) {
      throw new SubmissionError({
        email: I18n.t("Login.LoginEmailRequired"),
        _error: I18n.t("Login.LoginEmailRequired")
      });
    }
    values.email = values.email.trim();
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(values.email)
    ) {
      throw new SubmissionError({
        email: I18n.t("Login.LoginInvalidAddress"),
        _error: I18n.t("Login.LoginInvalidAddress")
      });
    }
    if (!values.password) {
      throw new SubmissionError({
        password: I18n.t("Login.LoginPasswordRequired"),
        _error: I18n.t("Login.LoginPasswordRequired")
      });
    }
    this.props.loginUser(createFormData({ ...values, web: true }));
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isAuthenticated !==
      this.props.auth.isAuthenticated
    ) {
      this.props.history.push('/profile');
      this.props.clearErrors();
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/profile');
      this.props.clearErrors();
    }
    store.dispatch(loadTranslations(translationsObject));
    store.dispatch(setLocale('en'));
    const elementDiv = document.querySelector('div[role="presentation"]');
    if (elementDiv) {
      elementDiv.style.display = 'none';
    }
  }

  clearActionsErrors = () => {
    const { error, serverErrors } = this.props;
    if (!!error || !!serverErrors) {
      this.props.clearSubmitErrors();
      this.props.clearErrors();
    }
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      error,
      serverErrors
    } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.item}>
          <MainLogo className={classes.logo} />
          <span className={classes.title}><Translate value="Login.LoginTitle" /></span>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className={classes.formWrap}>
              <div className={classes.error}>
                <span>{error && error}</span>
                {serverErrors && serverErrors.toString().includes('Your email or password are incorrect') && <span>{I18n.t("Login.LoginEmailOrPasswordIncorrect")}</span>}
                {/* <span>{!!serverErrors.length && serverErrors}</span> */}
              </div>
              <Field
                type="text"
                name="email"
                placeholder={I18n.t("Login.LoginEmail")}
                component={renderTextField}
                serverErrors={serverErrors}
                onChange={this.clearActionsErrors}
                normalize={toLowerCase}
              />
              <Field
                type="password"
                name="password"
                placeholder={I18n.t("Login.LoginPassword")}
                component={renderTextField}
                serverErrors={serverErrors}
                onChange={this.clearActionsErrors}
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                classes={{ root: classes.buttonRoot }}
                disabled={
                  pristine || !!error || !!serverErrors.length
                }
              >
                <Translate value="Login.LoginButton" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  serverErrors: state.errors,
  locale: state.i18n.locale,
});

Login = connect(
  mapStateToProps,
  { loginUser, clearErrors }
)(withStyles(styles)(Login));

export default reduxForm({
  form: 'Login'
})(Login);
