import styled from '@emotion/styled';
import { Button, Grid, MenuItem, Select, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../../../components/CustomInput';
import AddressDeliveryInfo from '../../../Profile/common/AddressDeliveryInfo/AddressDeliveryInfo';
import BranchDeliveryInfo from '../../../Profile/common/BranchDeliveryInfo/BranchDeliveryInfo';
import styles from './style';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import validateLeadInfo from '../../../../validations/validateLeadInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLeadDeliveryInfo,
  updateLeadDeliveryInfo
} from '../../../../redux/actions/leadsListActions';
import { SubmissionError } from 'redux-form';

const LeadInfoFormStyle = styled.form`
  margin-top: 52px;
`;

const renderTextField = ({
  input,
  serverErrors,
  type,
  placeholder,
  label,
  endadornment,
  labelWrapWidth,
  multiline,
  rows,
  rowsMax,
  maxLength,
  disabled,
  meta: { touched, error },
  inputProps
}) => {
  return (
    <>
      <CustomInput
        type={type}
        label={label}
        maxLength={maxLength}
        labelWrapWidth={labelWrapWidth}
        placeholder={placeholder}
        {...input}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        error={touched && !!error}
      />
      {touched && error && (
        <div style={{ position: 'absolute', top: 7, left: 120 }}>
          <Tooltip placement='top-start' title={error}>
            <InfoOutlinedIcon style={{ color: '#FF3D33', fontSize: 18 }} />
          </Tooltip>
        </div>
      )}
    </>
  );
};

const renderSelectDeliveryType = ({ classes, input, disabled }) => {
  return (
    <Select
      classes={{
        root: classes.selectAddRoot,
        select: classes.selectAddSelect
      }}
      value={input.value || 'ADDRESS'}
      onChange={input.onChange}
      disableUnderline
      disabled={disabled}
    >
      <MenuItem value='ADDRESS'>
        <Translate value='Profile.ProfileDeliveryAddress' />
      </MenuItem>
      <MenuItem value='BRANCH'>
        <Translate value='Profile.ProfileDeliveryBranch' />
      </MenuItem>
    </Select>
  );
};

const LeadInfoForm = ({
  handleSubmit,
  classes,
  disabled,
  onEdit,
  wishId,
  deliveryInfo,
  setCanCreateShipment
  // forceUpdate
}) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loading);

  const [deliveryType, setDeliveryType] = React.useState('');

  const [onEditState, setOnEditState] = React.useState(false);

  const [leadId, setLeadId] = React.useState(null);

  React.useEffect(() => {
    setOnEditState(onEdit);
  }, [onEdit]);

  const handleDeliveryTypeChange = (e) => {
    setDeliveryType(e.target.value);
  };

  React.useEffect(() => {
    setDeliveryType(deliveryInfo && deliveryInfo.type);
  }, [deliveryInfo]);

  const onSubmit = (values) => {
    if (JSON.stringify(values) === '') return;
    const {
      id,
      type,
      first_name,
      last_name,
      middle_name,
      phone,
      notes,
      deliveryInfo,
      branchDeliveryInfo
    } = values;

    const { postcode, city, street, building, flat_number } =
      type === 'BRANCH' ? branchDeliveryInfo.value : deliveryInfo;

    const data = {
      type,
      address:
        type === 'BRANCH'
          ? branchDeliveryInfo.value.address
          : `${flat_number} ${building}, ${street}, ${city}, ${postcode}`,
      postcode,
      city,
      street,
      building,
      flat_number,
      wish: wishId,
      first_name,
      last_name,
      middle_name,
      phone,
      notes
    };
    if (onEditState) {
      return dispatch(updateLeadDeliveryInfo(id || leadId, data)).then((r) => {
        if (r && r.code === 1915) {
          throw new SubmissionError({
            phone: I18n.t('LeadsList.LeadsListModalErrorCode1915'),
            _error: I18n.t('LeadsList.LeadsListModalErrorCode1915')
          });
        } else if (r.status === 200) {
          setCanCreateShipment(true);
        }
      });
    } else {
      return dispatch(createLeadDeliveryInfo(data)).then((r) => {
        if (r && r.code === 1915) {
          throw new SubmissionError({
            phone: I18n.t('LeadsList.LeadsListModalErrorCode1915'),
            _error: I18n.t('LeadsList.LeadsListModalErrorCode1915')
          });
        } else if (r.status === 201) {
          setLeadId(r.data.id);
          setOnEditState(true);
          setCanCreateShipment(true);
        }
      });
    }
  };

  return (
    <LeadInfoFormStyle onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='space-between'
        style={{ marginBottom: 8, position: 'relative' }}
      >
        <Field
          type='text'
          name='first_name'
          label={<Translate value='LeadsList.LeadsListModalFirstname' />}
          labelWrapWidth='140px'
          maxLength='250'
          component={renderTextField}
          disabled={disabled}
          inputProps={{
            disabled: classes.infoDisabled
          }}
          normalize={(value, previousValue, allValues) => {
            return value.replace(/[0-9$@#-/:-?{-~!"^_`\\[\]]/g, '');
          }}
        />
      </Grid>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='space-between'
        style={{ marginBottom: 8, position: 'relative' }}
      >
        <Field
          type='text'
          name='last_name'
          label={<Translate value='LeadsList.LeadsListModalLastname' />}
          labelWrapWidth='140px'
          maxLength='250'
          component={renderTextField}
          disabled={disabled}
          inputProps={{
            disabled: classes.infoDisabled
          }}
          normalize={(value, previousValue, allValues) => {
            return value.replace(/[0-9$@#-/:-?{-~!"^_`\\[\]]/g, '');
          }}
        />
      </Grid>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='space-between'
        style={{ marginBottom: 8, position: 'relative' }}
      >
        <Field
          type='text'
          name='middle_name'
          label={<Translate value='LeadsList.LeadsListModalSurname' />}
          labelWrapWidth='140px'
          maxLength='250'
          component={renderTextField}
          disabled={disabled}
          inputProps={{
            disabled: classes.infoDisabled
          }}
          normalize={(value, previousValue, allValues) => {
            return value.replace(/[0-9$@#-/:-?{-~!"^_`\\[\]]/g, '');
          }}
        />
      </Grid>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='space-between'
        style={{ marginBottom: 8, position: 'relative' }}
      >
        <Field
          type='tel'
          name='phone'
          label={<Translate value='LeadsList.LeadsListModalMobileNumber' />}
          labelWrapWidth='140px'
          maxLength='26'
          component={renderTextField}
          disabled={disabled}
          inputProps={{
            disabled: classes.infoDisabled
          }}
          normalize={(value, previousValue, allValues) => {
            if (value === '+') return '+';
            const newValue = `+${value.replace(/[^\d]/g, '')}`;
            return newValue === '+' ? '' : newValue;
          }}
        />
      </Grid>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='flex-end'
        style={{ marginBottom: 8 }}
      >
        <Field
          classes={classes}
          name='type'
          label={'Address'}
          labelWrapWidth='140px'
          component={renderSelectDeliveryType}
          // disabled={!onEdit}
          disabled={disabled}
          onChange={handleDeliveryTypeChange}
        />
      </Grid>
      {deliveryType === 'BRANCH' ? (
        <BranchDeliveryInfo onEdit={!disabled} border />
      ) : (
        <AddressDeliveryInfo
          onEdit={!disabled}
          renderTextField={renderTextField}
          styles={classes}
        />
      )}
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='space-between'
        style={{ marginBottom: 8 }}
      >
        <Field
          // type='text'
          multiline
          rows={1}
          rowsMax={3}
          name='notes'
          label={<Translate value='LeadsList.LeadsListModalNote' />}
          labelWrapWidth='140px'
          maxLength='1000'
          component={renderTextField}
          // disabled={disabled}
          inputProps={{
            disabled: classes.infoDisabled
          }}
        />
      </Grid>
      <Grid
        container
        wrap='nowrap'
        alignItems='center'
        justify='flex-end'
        style={{ marginBottom: 8 }}
      >
        <Button variant='contained' type='submit' color='primary' disabled={loading}>
          <Translate value='LeadsList.LeadsListButtonSave' />
        </Button>
      </Grid>
    </LeadInfoFormStyle>
  );
};

export default reduxForm({
  form: 'LeadInfoForm',
  validate: (values) => validateLeadInfo(values),
  enableReinitialize: true
})(withStyles(styles)(LeadInfoForm));
