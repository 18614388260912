import React, { Component } from "react";
import { Typography, withStyles, Grid, TextField } from "@material-ui/core";
import replaceURLs from '../../utils/replaceURLs'

const styles = (theme) => ({
  rootInput: {
    height: 32,
    maxWidth: "100%",
    borderRadius: 4,
  },
  rootTextarea: {
    paddingTop: 3,
    paddingBottom: 3,
    height: "auto",
    width: '100%',
    borderRadius: 4,
    fontSize: 14,
    backgroundColor: "#ffffff",
    overflow: "hidden",
    "& textarea": {
      paddingTop: 5,
    },
  },

  textareaDisabled: {
    width: 596,
    fontFamily: "Roboto",
    fontSize: 14,
    paddingLeft: 0,
    color: "rgba(80, 89, 103, 0.87)",
    wordWrap: "break-word",
    "& fieldset": {
      borderColor: "transparent !important",
    },
  },

  socialLink: {
    width: "100%",
    paddingLeft: "14px",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

class CustomInput extends Component {
  inputType = (inputProps) => {
    const { classes, type, maxLength, name, endadornment } = this.props;
    if (type === "text" || type === "tel" || type === "textanda") {
      return {
        inputProps: { maxLength: maxLength },
        classes: { ...inputProps, root: classes.rootInput },
        endAdornment: endadornment,
      };
    }
    if (name === "description" || name === "notes") {
      return {
        inputProps: { maxLength: maxLength },
        classes: {
          ...inputProps,
          root: classes.rootTextarea,
        },
      };
    }
  };
  render() {
    const {
      label,
      rows,
      rowsMax,
      type,
      name,
      onChange,
      value,
      multiline,
      classes,
      disabled,
      inputProps,
      labelWrapWidth,
      labelMaxWidth,
      placeholder,
      ...input
    } = this.props;
    return (
      <Grid container wrap="nowrap" alignItems="center" justify="space-between">
        {label && (
          <Grid
            container
            wrap="nowrap"
            style={{ minWidth: labelWrapWidth, maxWidth: labelMaxWidth ? +labelMaxWidth : 68 }}
          >
            <Typography variant="body2">{label}</Typography>
          </Grid>
        )}
        {type === "textanda" && disabled ? (
          <span className={classes.socialLink}>
            <a
              href={
                name === "instagram"
                  ? `${process.env.REACT_APP_INSTAGRAM_URL}/${value}`
                  : `${process.env.REACT_APP_TELEGRAM_URL}/${value}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          </span>
        ) : name === 'supplier.donation' && disabled ? (
          <span className={classes.socialLink} style={{ color: 'rgba(80, 89, 103, 0.87)' }}>
            {replaceURLs(value)}
          </span>
        ) : (
          <TextField
            style={{ width: "100%" }}
            multiline={multiline}
            autoComplete="off"
            type={type === "textanda" ? "text" : type}
            rows={rows}
            rowsMax={rowsMax}
            placeholder={placeholder}
            variant="outlined"
            value={!!value ? value : ""}
            onChange={onChange}
            InputProps={this.inputType(inputProps)}
            disabled={disabled}
            {...input}
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomInput);
