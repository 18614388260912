export const getNextDataPage = (nextData) => {
  if (nextData) {
    let newOffset;
    let startOfSubstr = nextData.indexOf("page");
    let sliceOfStr = nextData.slice(startOfSubstr);
    let endOfSubstr = sliceOfStr.indexOf("&");

    if (endOfSubstr > 0) {
      newOffset = nextData.slice(startOfSubstr, startOfSubstr + endOfSubstr);
    } else {
      newOffset = nextData.slice(startOfSubstr);
    }
    return newOffset;
  }
};
