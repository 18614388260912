import { 
  Grid, 
  Typography, 
  withStyles, 
  FormControlLabel, 
  Checkbox 
} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import _ from 'lodash';
import React from "react";
import NumberFormat from 'react-number-format';
import { I18n, Translate } from "react-redux-i18n";
import { Field } from "redux-form";
import CustomInput from "../../../../components/CustomInput";
import DatePickerInput from "../../../../components/DatePickerInput";
import BonusDisabledInputLogo from "../../../../components/Icons/BonusDisabledInputLogo";
import BonusInputLogo from "../../../../components/Icons/BonusInputLogo";
import SelectInput from "../../../../components/SelectInput";
import { getUKTextMile } from '../../../../helpers';
import styles from "./styles";


const renderTextField = ({
  input,
  serverErrors,
  type,
  placeholder,
  label,
  endadornment,
  labelWrapWidth,
  meta: { error, touched }
}) => (
  <>
    <CustomInput
      // error={!!error || !!serverErrors.length}
      error={touched && !!error}
      type={type}
      label={label}
      labelWrapWidth={labelWrapWidth}
      // helperText={(touched && !!error) && error}
      placeholder={placeholder}
      // endadornment={endadornment}
      {...input}
    />
    {touched && (
      error && (
        <div style={{ position: 'absolute', left: 132, top: 7 }} >
          <Tooltip placement="top-start" title={error}>
            <InfoOutlinedIcon style={{ color: "#FF3D33", fontSize: 18 }}/>
          </Tooltip>
        </div>
      )
    )}
  </>
  );

  const renderCheckbox = ({ input, label, disabled, styles: classes }) => {
    return (
      <FormControlLabel
        disabled={disabled}
        label={label}
        classes={{
          root: classes.formControlLabelRoot,
          label: classes.formControlLabelLabel
        }}
        labelPlacement='start'
        control={
          <Checkbox
            checked={input.checked}
            color='default'
            classes={{
              root: classes.checkboxRoot,
              checked: classes.checkboxChecked
            }}
            onChange={input.onChange}
          />
        }
      />
    );
  };

const renderDatePicker = ({
  input,
  label,
  locale,
  disablePast,
  labelWrapWidth,
  meta: { touched, error }
}) => (
  <>
    <DatePickerInput
      label={label}
      locale={locale}
      labelWrapWidth={labelWrapWidth}
      disablePast={!!disablePast}
      error={touched && !!error}
      {...input}
    />
    {touched && (
      error && (
        <div style={{ position: 'absolute', left: 132, top: 7 }} >
          <Tooltip placement="top-start" title={error}>
            <InfoOutlinedIcon style={{ color: "#FF3D33", fontSize: 18 }}/>
          </Tooltip>
        </div>
      )
    )}
  </>
  );

const NumberFormatCurrency = ({
  input,
  serverErrors,
  type,
  placeholder,
  disabled,
  label,
  endadornment,
  labelWrapWidth,
  meta: { error, touched },
  supplierBalance,
  totalCostPerLead,
  currentCost
}) => (
  <>
    <NumberFormat
      customInput={CustomInput}
      error={(touched && !!error) || (currentCost && totalCostPerLead && currentCost < totalCostPerLead)}
      type={type}
      label={label}
      labelWrapWidth={labelWrapWidth}
      {...input}
      placeholder={placeholder}
      disabled={disabled}
      // prefix={'$'}
      decimalScale={2}
      allowNegative={false}
      isAllowed={(values) => {
        const { value } = values;
        if (value <= supplierBalance) return true;
      }}
    />
    {touched && (
      error && (
        <div style={{ position: 'absolute', left: 132, top: 7 }} >
          <Tooltip placement="top-start" title={error}>
            <InfoOutlinedIcon style={{ color: "#FF3D33", fontSize: 18 }}/>
          </Tooltip>
        </div>
      )
    )}
  </>
  )

  const renderNumberField = ({
    input,
    serverErrors,
    type,
    placeholder,
    disabled,
    label,
    endadornment,
    labelWrapWidth,
    meta: { error, touched },
  }) => (
    <NumberFormat
      customInput={CustomInput}
      error={(touched && !!error)}
      type={type}
      label={label}
      labelWrapWidth={labelWrapWidth}
      {...input}
      placeholder={placeholder}
      disabled={disabled}
      // prefix={'$'}
      decimalScale={0}
      allowNegative={false}
    />
)
const NumberFormatCurrencyBonus = ({
  input,
  serverErrors,
  type,
  placeholder,
  disabled,
  label,
  endadornment,
  labelWrapWidth,
  meta: { error, touched },
  supplierBalance,
  totalCostPerLead,
  currentCost
  }) => (
    <NumberFormat
      customInput={CustomInput}
      error={(touched && !!error) || (currentCost && totalCostPerLead && currentCost < totalCostPerLead)}
      type={type}
      label={label}
      labelWrapWidth={labelWrapWidth}
      {...input}
      placeholder={placeholder}
      disabled={disabled}
      // prefix={'$'}
      decimalScale={2}
      allowNegative={false}
      isAllowed={(values) => {
        const { value } = values;
        if (value <= supplierBalance) return true;
      }}
    />
  )

const GeneralInfo = ({
  classes,
  handleChangeInput, 
  supplierBalance, 
  campaign, 
  handleChangeRadiusUnit, 
  geoUnit, 
  profile, 
  locale, 
  cost_error, 
  setCostError, 
  supplier, 
  currencyList, 
  qualifiedLeadCommission, 
  qualifiedLeadPoints, 
  firstTimeTotalTagsCostDefault, 
  setFirstTimeTotalTagsCostDefault, 
  updateCampaignCreation, 
  currencyText, 
  totalPayrisPointBonus, 
  bonus_error,
  isDeleteTag,
  setIsDeleteTag,
  isWorldWide
}) => {

  const [radiusOptions, setRadiusOptions] = React.useState([]);
  const [totalCostPerLead, setTotalCostPerLead] = React.useState(0);
  const [isShowCostPlaceholder, setIsShowCostPlaceholder] = React.useState(true);
  const [isFirstEditing, setIsFirstEditing] = React.useState(true);
  React.useEffect(() => {
    if (!_.isEmpty(geoUnit)) {
      let rOptions = [];
      _.forEach(geoUnit, (value) => {
        rOptions.push({
          key: value[0],
          display: getUKTextMile(value[1], locale)
        });
      });
      setRadiusOptions(rOptions);
    }
  }, [geoUnit]);

  React.useEffect(() => {
    if (campaign && campaign.tag && campaign.tag.length) {
      let minimumCost = 0;
      _.forEach(campaign.tag, (value) => {
        minimumCost += value.reward;
      });
      const sumTotalTagsAndPayris = minimumCost + qualifiedLeadPoints;
      const qualifiedLeadCommissionToPoint = Math.ceil(sumTotalTagsAndPayris * qualifiedLeadCommission);
      const totalTagsCost = minimumCost ? sumTotalTagsAndPayris + qualifiedLeadCommissionToPoint : 0;
      const currencyCourse = (supplier && supplier.country === 'UKR') ? currencyList[1].course : currencyList ? currencyList[0].course : 0.01
      const totalCostPerLead = Math.ceil(currencyCourse * totalTagsCost);
      if (!firstTimeTotalTagsCostDefault) {
        if (!campaign.editing) {
          updateCampaignCreation(totalCostPerLead, totalCostPerLead - totalCostPerLead*qualifiedLeadCommission);
        } else {
          if (!isFirstEditing) {
            updateCampaignCreation(totalCostPerLead, totalCostPerLead - totalCostPerLead*qualifiedLeadCommission);
          }
          setIsFirstEditing(false);
        }
        setTotalCostPerLead(totalCostPerLead);
        setFirstTimeTotalTagsCostDefault(true);
      }
      if (isDeleteTag) {
        updateCampaignCreation(totalCostPerLead, totalCostPerLead - totalCostPerLead*qualifiedLeadCommission);
        setTotalCostPerLead(totalCostPerLead);
        setIsDeleteTag(false)
      }
      setIsShowCostPlaceholder(false);
    } else {
      updateCampaignCreation('', '');
      setCostError(false);
      setIsShowCostPlaceholder(true);
    }
  }, [campaign]);

  React.useEffect(() => {
    if (radiusOptions && radiusOptions.length) {
      let radiusOptionsTemp = [...radiusOptions];
      radiusOptionsTemp = radiusOptionsTemp.map((ro) => {
        return {
          ...ro,
          display: getUKTextMile(ro.display, locale)
        }
      })
      setRadiusOptions(radiusOptionsTemp);
    }
  }, [locale])
  
  return (
  <Grid container direction="column" className={classes.container}>
    <Typography variant="h6" className={classes.title}>
      <Translate value="CampaignsCreation.CampaignsCreationInfoTitle" />
    </Typography>
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      className={classes.name + ' ' +  classes.positionRelative}
    >
      <Field
        type="text"
        name="name"
        label={
          <Translate value="CampaignsCreation.CampaignsCreationInfoName" />
        }
        labelWrapWidth="155px"
        component={renderTextField}
        onChange={handleChangeInput}
      />
    </Grid>
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      className={classes.marginBottom + ' ' +  classes.positionRelative}
    >
      <Field
        label={
          <Translate value="CampaignsCreation.CampaignsCreationInfoStartDate" />
        }
        name="start_date"
        component={renderDatePicker}
        locale={locale}
        labelWrapWidth="155px"
        onChange={handleChangeInput}
      />
    </Grid>
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      className={classes.marginBottom + ' ' +  classes.positionRelative}
    >
      <Field
        label={
          <Translate value="CampaignsCreation.CampaignsCreationInfoEndDate" />
        }
        name="end_date"
        component={renderDatePicker}
        locale={locale}
        labelWrapWidth="155px"
        disablePast={true}
        onChange={handleChangeInput}
      />
    </Grid>
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      direction="column"
      className={classes.marginBottom + ' ' +  classes.positionRelative}
    >
      <Field
        type="text"
        name="price_per_lead"
        label={
          <span><Translate value="CampaignsCreation.CampaignsCreationInfoCost" /><span>{currencyText}</span></span>
        }
        labelWrapWidth="155px"
        component={NumberFormatCurrency}
        onChange={handleChangeInput}
        supplierBalance={supplierBalance}
        placeholder={isShowCostPlaceholder ? I18n.t("CampaignsCreation.CampaignsCreationInfoCostPlaceholder") : ''}
        disabled={!(campaign && campaign.tag && campaign.tag.length)}
        totalCostPerLead={totalCostPerLead}
        currentCost={+campaign.price_per_lead}
      />
      {cost_error && <div className={classes.costError}><Tooltip placement="top-start" classes={classes} title={I18n.t('CampaignsCreation.CampaignsCreationInfoCostError')}><InfoOutlinedIcon className={classes.errorInfoIcon}/></Tooltip></div>}
    </Grid>
    <Grid
      container
      wrap="nowrap"
      justify="space-between"
      direction="column"
      className={classes.marginBottom + ' ' +  classes.positionRelative}
    >
      <div className={classes.bonusInputCover}>
        <Field
          type="text"
          name="bonus"
          label={
            <span><Translate value="CampaignsCreation.CampaignsCreationInfoBonus" /><span>{currencyText}</span></span>
          }
          labelWrapWidth="155px"
          component={NumberFormatCurrencyBonus}
          onChange={handleChangeInput}
          supplierBalance={supplierBalance}
          placeholder={isShowCostPlaceholder ? I18n.t("CampaignsCreation.CampaignsCreationInfoBonusPlaceholder") : ''}
          disabled={!(campaign && campaign.tag && campaign.tag.length)}
        />
        <div className={classes.totalBonus}>= {!(campaign && campaign.tag && campaign.tag.length) ? <BonusDisabledInputLogo/> : <BonusInputLogo />} {totalPayrisPointBonus}</div>
      </div>
      {bonus_error && <div className={classes.bonusError}>{I18n.t('CampaignsCreation.CampaignsCreationInfoBonusError')}</div>}
    </Grid>
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      className={classes.marginBottom}
    >
      <Field
        type="text"
        name="radius"
        label={
          <Translate value="CampaignsCreation.CampaignsCreationInfoRadius" />
        }
        labelWrapWidth="155px"
        component={renderNumberField}
        onChange={handleChangeInput}
        disabled={isWorldWide}
      />
      {!_.isEmpty(radiusOptions) && <SelectInput
        classes={classes}
        isNormalSelection={true}
        className={classes.groupDropdown}
        selectedValue={campaign.geo_unit ? {key: campaign.geo_unit, display: getUKTextMile(_.filter(radiusOptions, x => x.key === campaign.geo_unit)[0].display, locale) } : (profile && profile.supplier && profile.supplier.country === "UKR") ? {key: 'KM', display: 'KM'} : {key: 'MILE', display: getUKTextMile('MILE', locale)}}
        options={radiusOptions}
        onChangeSelect={handleChangeRadiusUnit}
        disabled={isWorldWide}
      />}
    </Grid>
    <Grid
      container
      wrap='nowrap'
      alignItems="center"
      justify="space-between"
      className={classes.marginBottom}
    >
      <Field
        type="checkbox"
        name="is_world_wide"
        label={
          <Translate value="CampaignsCreation.CampaignsCreationWorldwide" />
        }
        labelWrapWidth="155px"
        component={renderCheckbox}
        styles={classes}
        onChange={handleChangeInput}
      />
    </Grid>
  </Grid>
)};
export default withStyles(styles)(GeneralInfo);
