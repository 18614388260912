import setAuthToken from "../../utils/setAuthToken";
import { GET_ERRORS, SET_USER_AUTH } from "./types";
import { getProfileInfo } from "./profileActions";
import { getLeadsTableData } from "./leadsListActions";
import { appLoading, stopAppLoading, getConfig } from "./appActions";
import apiService from "../../api/apiService";
import {reset} from 'redux-form';

export const loginUser = userData => dispatch => {
  dispatch(appLoading());
  apiService
    .loginUser(userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to localStorage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      //const decoded = jwt_decode(token);
      dispatch(setCurrentUserAuth(true));
      dispatch(getProfileInfo());
      dispatch(getLeadsTableData());
      dispatch(getConfig());
      dispatch(stopAppLoading());
    }).catch(err => {
      dispatch(stopAppLoading());
      dispatch({
        type: GET_ERRORS,
        payload: "Your email or password are incorrect"
      });
    });
};

export const setCurrentUserAuth = bool => {
  return {
    type: SET_USER_AUTH,
    payload: bool
  };
};
// Logout user
export const logoutUser = () => dispatch => {
  dispatch(appLoading());
  dispatch({ type: 'USER_LOGOUT' })
  // remove token from local storeage
  localStorage.removeItem("jwtToken");
  // remove auth header for futer request
  setAuthToken(false);
  // set current user to {} witch set isAuth false
  dispatch(setCurrentUserAuth(false));
  dispatch(stopAppLoading());
};

export const changePassword = (data) => dispatch => {
  apiService.changePassword(data)
    .then(res => {
      if(res.status === 200){
        dispatch(reset('Settings'));
      }
    }).catch(err=> {
      const value = Object.values(err.response.data).toString();
      // const key = Object.keys(err.response.data).toString();
      dispatch({
        type: GET_ERRORS,
        payload: `${value}`
      });
    })
}