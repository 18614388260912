import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { setCurrentUserAuth, logoutUser } from './redux/actions/authActions';
import { getProfileInfo } from './redux/actions/profileActions';
import { getConfig, getIpGeo } from './redux/actions/appActions';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import store from './redux/store';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import LeadsList from './pages/LeadsList/LeadsList';
import Shipment from './pages/Shipment/Shipment';
import Campaigns from './pages/Campaigns';
import Settings from './pages/Settings';
import Products from './pages/Products';
import ProductCategory from './pages/ProductCategory';
import { setLocaleLanguage } from './redux/actions/translationActions';
import RequestDeleteAccount from './pages/RequestDeleteAccount/RequestDeleteAccount';
// check for token
if (localStorage.jwtToken) {
  // set auth token
  setAuthToken(localStorage.jwtToken);
  // decode user and get user info
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(getConfig());

  //set current user and isAuth
  store.dispatch(setCurrentUserAuth(true));
  store.dispatch(getProfileInfo());

  // check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // user logout
    store.dispatch(logoutUser());
    // clear current profile
    //   store.dispatch(clearCurrentProfile());
    // redirect to login
    window.location.href = '/';
  }
} else {
  store.dispatch(logoutUser());
}

if (!localStorage.lang) {
  var userLang = navigator.language || navigator.userLanguage;
  if (userLang && userLang.toLowerCase().startsWith('uk')) {
    localStorage.setItem('lang', 'uk');
  } else {
    store.dispatch(getIpGeo());
  }
}

class Root extends Component {
  render() {
    const {
      auth: { isAuthenticated },
      ipGeo
    } = this.props;
    if (ipGeo && ipGeo.result) {
      localStorage.setItem('lang', ipGeo.result === 'UKR' ? 'uk' : 'en');
    }
    if (localStorage.lang && localStorage.lang === 'uk')
      store.dispatch(setLocaleLanguage(localStorage.lang));
    return (
      <Router>
        <>
          <Switch>
            <Route exact path='/' component={Login} />
            <ProtectedRoute
              exact
              path='/dashboard'
              redirectTo='/'
              component={Dashboard}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/profile'
              redirectTo='/'
              component={Profile}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/list'
              redirectTo='/'
              component={LeadsList}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/campaigns'
              redirectTo='/'
              component={Campaigns}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/products'
              redirectTo='/'
              component={Products}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/product-category'
              redirectTo='/'
              component={ProductCategory}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/shipment'
              redirectTo='/'
              component={Shipment}
              authenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path='/settings'
              redirectTo='/'
              component={Settings}
              authenticated={isAuthenticated}
            />
            <Route path='/request-delete-account' component={RequestDeleteAccount} />
          </Switch>
        </>
      </Router>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  ipGeo: state.app.ipGeo
});

export default connect(mapStateToProps)(Root);
