import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  TableCell,
  TableSortLabel,
  Button,
  Grid,
  Chip,
} from "@material-ui/core";
import { AutoSizer, Column, Table } from "react-virtualized";
import moment from "moment-timezone";
import { Translate, I18n } from "react-redux-i18n";
import styles from "./styles";
import data from "../../../../constants/leadTable";
import SortIcon from "../../../../components/Icons/SortIcon";
import ArrowUpIcon from "../../../../components/Icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

class MuiVirtualizedTable extends React.PureComponent {
  state = {
    column: "",
  };
  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;
    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };
  onButtonClick = (e, rowData) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("is_confirmed", true);
    this.props.patchLeadsRead(rowData.lead.pk, {is_read: true});
    this.props.patchFeedbackLeadsRead(rowData.feedback.id, {is_read: true});
    this.props.patchLeadsConfirm(formData, rowData.id);
  };
  onColumnClick = (columnName) => {
    const { column } = this.state;
    const { sortColumn } = this.props;
    if (column === columnName) {
      switch (columnName) {
        case "customer_name":
          sortColumn(`-user__display_name`);
          break;
        case "customer_email":
          sortColumn(`-user__email`);
          break;
        case "customer_phone":
          sortColumn(`-user__phone`);
          break;
        case "customer_city":
          sortColumn(`-user__customers__city`);
          break;
        case "campaign_name":
          sortColumn(`-campaign__name`);
          break;
        case "tags_count":
          sortColumn(`-tag_count`);
          break;
        default:
          sortColumn(`-${columnName}`);
          break;
      }
      this.setState({
        column: `-${columnName}`,
      });
    } else {
      this.setState({
        column: columnName,
      });
      switch (columnName) {
        case "customer_name":
          sortColumn(`user__display_name`);
          break;
        case "customer_email":
          sortColumn(`user__email`);
          break;
        case "customer_phone":
          sortColumn(`user__phone`);
          break;
        case "customer_city":
          sortColumn(`user__customers__city`);
          break;
        case "campaign_name":
          sortColumn(`campaign__name`);
          break;
        case "tags_count":
          sortColumn(`tag_count`);
          break;
        default:
          sortColumn(`${columnName}`);
          break;
      }
    }
  };
  tableCellInner = (columnIndex, rowData, cellData) => {
    const { classes } = this.props;
    switch (columnIndex) {
      case 0:
        return cellData + 1;
      case 1:
        return (
          this.props.locale === 'uk' ? moment(cellData)
      .tz("Europe/Kiev")
      .format("DD.MM.YY HH:mm Z") : moment(cellData)
            // .utc()
            .tz("America/New_York")
            .format("DD.MM.YY HH:mm Z")
        );
      case 7:
        return <Chip label={cellData} className={classes.chip} />;
      case 9:
        return rowData.fulfilled.fulfilled_by_another ? (<span className={classes.fulfilledByAnother}><Translate value="LeadsList.LeadsListTableFulfilledByAnother" /></span>) : (cellData === 1 ? (
          <span className={classes.inProgress}>
            <Translate value="LeadsList.LeadsListTableInProgress" />
          </span>
        ) : (
          <span className={classes.completed}>
            <Translate value="LeadsList.LeadsListTableCompleted" />
          </span>
        ));
      case 10:
        return (
          (rowData.lead_status === 2 ||
            (rowData.lead_status === 1 && rowData.is_confirmed)) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.rootBtn}
              disabled={cellData}
              onClick={(e) => this.onButtonClick(e, rowData)}
            >
              {cellData ? (
                <Translate value="LeadsList.LeadsListButtonConfirmed" />
              ) : (
                <Translate value="LeadsList.LeadsListButtonConfirm" />
              )}
            </Button>
          )
        );
      default:
        return cellData;
    }
  };

  cellRenderer = ({ cellData, columnIndex = null, rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        }, {[classes.columnStatus]: columnIndex === 9}, {[classes.inProgressBg]: (rowData.lead && rowData.lead.is_read === false)}, {[classes.completedBg]: (rowData.feedback && rowData.feedback.is_read === false)}, {[classes.fulfilledByAnotherBg]: (!(rowData.lead && rowData.lead.is_read === false) && !(rowData.feedback && rowData.feedback.is_read === false))})}
        variant="body"
        style={{ height: rowHeight, paddingRight: 10, paddingLeft: 10, justifyContent: columnIndex === 0 ? 'center' : '' }}
      >
        {this.tableCellInner(columnIndex, rowData, cellData)}
      </TableCell>
    );
  };

  InfoCustomIcon = () => {
    const { classes } = this.props;
    return (
      <Tooltip title={I18n.t('LeadsList.LeadsListTooltipOfLeads')}><InfoOutlinedIcon className={classes.customInfoIcon}/></Tooltip>
    )
  }

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, classes } = this.props;
    let inner;
    if (columnIndex === 0 || columnIndex === 10) {
      inner = <TableSortLabel>{label}</TableSortLabel>;
    } else if (columnIndex === 8) {
      inner = <TableSortLabel IconComponent={this.InfoCustomIcon} active={true} classes={classes}>{label}</TableSortLabel>;
    } else if (this.state.column === dataKey) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowUpIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else if (this.state.column === `-${dataKey}`) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowDownIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else {
      inner = (
        <TableSortLabel
          IconComponent={SortIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    }
    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCellHeader,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight, paddingRight: 10, paddingLeft: 10 }}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            style={{ outline: "none" }}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                { cellContentRenderer = null, className, dataKey, ...other },
                index
              ) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = (cellRendererProps) =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 58,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const LeadListTable = ({ rows, patchLeadsConfirm, showModal, sortColumn, patchLeadsRead, patchFeedbackLeadsRead, locale }) => (
  <Grid style={{ height: 550, width: "100%", padding: "0 24px" }}>
    <WrappedVirtualizedTable
      rowCount={rows.length}
      rowData={rows}
      patchLeadsConfirm={patchLeadsConfirm}
      patchLeadsRead={patchLeadsRead}
      patchFeedbackLeadsRead={patchFeedbackLeadsRead}
      rowGetter={({ index }) => rows[index]}
      onRowClick={(event) => showModal(event)}
      columns={data}
      sortColumn={sortColumn}
      locale={locale}
    />
  </Grid>
);
export default LeadListTable;
