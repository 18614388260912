const styles = (theme) => ({
  selectAddRoot: {
    width: '140px',
    background: 'rgba(159, 176, 203, 0.12)',
    borderRadius: '4px',
    // position: 'absolute',
    // right: 0,
    '&:before': {
      content: 'unset'
    }
  },
  selectAddSelect: {
    padding: '10px 16px',
    fontSize: '14px'
  },
  infoDisabled: {
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "rgba(80, 89, 103, 0.87)",
    "& fieldset": {
      borderColor: "transparent !important",
    },
  },
});

export default styles;
