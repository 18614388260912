/** @jsx jsx */
import { ClassNames, css, jsx } from '@emotion/core';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import instagramUrl from '../../../../assets/img/instagram.png';
import telegramUrl from '../../../../assets/img/telegram.png';
import viberUrl from '../../../../assets/img/viber.png';
import whatsappUrl from '../../../../assets/img/whatsapp.png';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import { createShipment } from '../../../../redux/actions/shipmentActions';
import { patchLeadsConfirm } from '../../../../redux/actions/leadsListActions';
import LeadInfoForm from '../LeadInfoForm/LeadInfoForm';
import styles from './styles';

// import ReactStars from 'react-stars';

const feedbackStyle = css`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  .feedback-img {
    width: 100%;
    height: 17rem;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    padding: 0.5rem;
  }
  .star {
    margin: 0 auto;
  }
`;

const InfoLeadsItem = ({ classes, data, translateValue, style }) => (
  <Grid
    container
    alignItems='center'
    justify='space-between'
    wrap='nowrap'
    style={{ ...style, marginBottom: 8 }}
  >
    <Typography variant='body2' className={classes.textInfo}>
      <Translate value={`LeadsList.${translateValue}`} />
    </Typography>
    <div className={classes.textContainer}>{data}</div>
  </Grid>
);

const InfoShipmentNumberItem = ({
  classes,
  data,
  translateValue,
  style,
  placeholder,
  canCreateShipment,
  createShipmentClick,
  isCreatingShipment,
  assessedValue
}) => (
  <Grid
    container
    alignItems='center'
    justify='space-between'
    wrap='nowrap'
    style={{ ...style, marginBottom: 8 }}
  >
    <Typography variant='body2' className={classes.textInfo}>
      <Translate value={`LeadsList.${translateValue}`} />
    </Typography>
    <div className={classes.rightTextContainer}>
      <div className={classes.customShipmentNumber}>{data ? data : placeholder}</div>
      <Button
        variant='contained'
        color='primary'
        className={classes.rootBtnModal}
        disabled={!canCreateShipment || isCreatingShipment || data || assessedValue === ''}
        onClick={() => createShipmentClick()}
      >
        {isCreatingShipment ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Сформувати'}
      </Button>
    </div>
  </Grid>
);

const ModalLeadList = ({
  classes,
  handleCloseModal,
  data,
  onButtonClick,
  isShowModal,
  profile,
  createShipment,
  leadDataShipmentNumber
}) => {
  const dispatch = useDispatch();
  const { viber, whatsapp, instagram, telegram } = data;
  const [currentSupplierCountry, setCurrentSupplierCountry] = React.useState('');
  const [currentUkrposhtaTrackingId, setCurrentUkrposhtaTrackingId] = React.useState(null);
  const [isCreatingShipment, setIsCreatingShipment] = React.useState(false);

  const [assessedValue, setAssessedValue] = React.useState('');

  const [canCreateShipment, setCanCreateShipment] = React.useState(false);

  const [isConfirmed, setIsConfirmed] = React.useState(false);

  React.useEffect(() => {
    setCanCreateShipment(data.can_create_shipment);
    setIsConfirmed(data.is_confirmed);
  }, [data]);

  const [errorAssessedValue, setErrorAssessedValue] = React.useState(null);

  const { lead_delivery_info: lInfo, wish_delivery_info: wInfo } = data;

  const postcode = lInfo ? lInfo.postcode : wInfo && wInfo.postcode;
  const city = lInfo ? lInfo.city : wInfo && wInfo.city;
  const street = lInfo ? lInfo.street : wInfo && wInfo.street;
  const building = lInfo ? lInfo.building : wInfo && wInfo.building;
  const flat_number = lInfo ? lInfo.flat_number : wInfo && wInfo.flat_number;
  const address = lInfo ? lInfo.address : wInfo && wInfo.address;
  const type = lInfo ? lInfo.type : wInfo && wInfo.type;

  const deliInfo = lInfo ? { ...lInfo } : { ...wInfo };

  const initialValues = {
    ...deliInfo,
    deliveryInfo: {
      postcode,
      city,
      street,
      building,
      flat_number
    },
    branchDeliveryInfo:
      type === 'BRANCH'
        ? {
            label: address,
            value: {
              postcode,
              city,
              street,
              building,
              flat_number,
              address
            }
          }
        : null
  };

  const handleChangeAssessedValue = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    if (value < 0 || value > 100000) {
      setErrorAssessedValue('Assessed value must between 1 and 100000');
    } else {
      setErrorAssessedValue(null);
    }
    setAssessedValue(value);
  };

  const handleConfirmClick = () => {
    const formData = new FormData();
    formData.append('is_confirmed', true);
    dispatch(patchLeadsConfirm(formData, data.id)).then(() => {
      setIsConfirmed(true);
    });
  };

  const onCloseModal = () => {
    handleCloseModal();
    setErrorAssessedValue(null);
    setAssessedValue('');
  };

  const createShipmentHandler = () => {
    setIsCreatingShipment(true);
    createShipment(data.id, Number(assessedValue)).then(() => {
      setIsCreatingShipment(false);
    });
  };

  React.useEffect(() => {
    const supplierCountry = profile && profile.supplier ? profile.supplier.country : '';
    setCurrentSupplierCountry(supplierCountry);
  }, [profile]);

  React.useEffect(() => {
    if (data) {
      setCurrentUkrposhtaTrackingId(data.ukrposhta_tracking_id);
      if (leadDataShipmentNumber && leadDataShipmentNumber.length) {
        const sn = leadDataShipmentNumber.find((s) => s.id === data.id);
        if (sn) {
          setCurrentUkrposhtaTrackingId(sn.ukrposhta_tracking_id);
        }
        setIsCreatingShipment(false);
      }
    }
  }, [leadDataShipmentNumber, data]);

  return (
    <Dialog
      onClose={onCloseModal}
      aria-labelledby='customized-dialog-title'
      open={isShowModal}
      className={classes.modalPaper}
    >
      <Grid container className={classes.modal}>
        <Grid className={classes.modalItemInfo}>
          <Grid container justify='space-between' alignItems='center' wrap='nowrap'>
            <Typography variant='h6'>
              <Translate value='LeadsList.LeadsListModalTitle' />
            </Typography>
            <div className={classes.socialContainer}>
              <ClassNames>
                {({ cx }) => (
                  <a
                    href={`viber://chat?number=${data.phone}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={cx({
                      [classes.socialLink]: true,
                      [classes.socialLinkDisabled]: !viber
                    })}
                  >
                    <img src={viberUrl} alt='viber' />
                  </a>
                )}
              </ClassNames>
              <ClassNames>
                {({ cx }) => (
                  <a
                    href={`${process.env.REACT_APP_TELEGRAM_URL}/${telegram}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={cx({
                      [classes.socialLink]: true,
                      [classes.socialLinkDisabled]: !telegram
                    })}
                  >
                    <img style={{ width: '20px' }} src={telegramUrl} alt='telegram' />
                  </a>
                )}
              </ClassNames>

              <ClassNames>
                {({ cx }) => (
                  <a
                    href={`https://wa.me/${data.phone}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={cx({
                      [classes.socialLink]: true,
                      [classes.socialLinkDisabled]: !whatsapp
                    })}
                  >
                    <img style={{ width: '20px' }} src={whatsappUrl} alt='whatsapp' />
                  </a>
                )}
              </ClassNames>
              <ClassNames>
                {({ cx }) => (
                  <a
                    href={`${process.env.REACT_APP_INSTAGRAM_URL}/${instagram}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={cx({
                      [classes.socialLink]: true,
                      [classes.socialLinkDisabled]: !instagram
                    })}
                  >
                    <img src={instagramUrl} alt='instagram' />
                  </a>
                )}
              </ClassNames>
            </div>
          </Grid>
          <InfoLeadsItem
            data={data.date}
            translateValue='LeadsListModalTime'
            classes={classes}
            style={{ marginTop: 19 }}
          />
          <InfoLeadsItem
            data={data.customer_name}
            translateValue='LeadsListModalCustomerName'
            classes={classes}
          />
          <InfoLeadsItem
            data={data.customer_email}
            translateValue='LeadsListModalEmail'
            classes={classes}
          />
          <InfoLeadsItem
            data={data.customer_phone}
            translateValue='LeadsListModalPhone'
            classes={classes}
          />
          <InfoLeadsItem
            data={data.customer_city}
            translateValue='LeadsListModalCity'
            classes={classes}
          />
          <InfoLeadsItem data={data.price} translateValue='LeadsListModalPrice' classes={classes} />
          <InfoLeadsItem
            data={data.dateWish}
            translateValue='LeadsListModalDate'
            classes={classes}
          />
          <InfoLeadsItem
            data={data.pickAddress}
            translateValue='LeadsListModalAddress'
            classes={classes}
          />
          <InfoLeadsItem
            data={data.campaign_name}
            translateValue='LeadsListModalCampaignName'
            classes={classes}
          />
          <Grid
            container
            alignItems='flex-start'
            justify='space-between'
            wrap='nowrap'
            style={{ marginBottom: 8 }}
          >
            <Typography variant='body2' className={classes.textInfo} style={{ paddingTop: 12 }}>
              <Translate value='LeadsList.LeadsListModalTags' />
            </Typography>
            <div className={classes.tagsWrap}>
              {data.tags &&
                data.tags.map((el, i) => <Chip key={i} label={el} className={classes.chip} />)}
            </div>
          </Grid>
          {data.lead_status === 2 && (
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 20 }}
            >
              <Typography variant='body2'>
                <Translate value='LeadsList.LeadsListConfirmation' />
              </Typography>
              <div className={classes.textContainer}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.rootBtnModal}
                  disabled={isConfirmed}
                  // onClick={onButtonClick}
                  onClick={handleConfirmClick}
                >
                  {isConfirmed ? (
                    <Translate value='LeadsList.LeadsListButtonConfirmed' />
                  ) : (
                    <Translate value='LeadsList.LeadsListButtonConfirm' />
                  )}
                </Button>
              </div>
            </Grid>
          )}
          {false && (
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8 }}
            >
              <Typography
                variant='body2'
                className={classes.textInfo}
                style={{ paddingRight: '10px' }}
              >
                <Translate value='LeadsList.LeadsListModalAssessedValue' />
              </Typography>
              <div style={{ position: 'relative', flexBasis: '70%' }}>
                <TextField
                  variant='outlined'
                  classes={{
                    root: classes.inputRoot
                  }}
                  disabled={!canCreateShipment || currentUkrposhtaTrackingId}
                  value={
                    data.assessed_value ||
                    (!canCreateShipment && I18n.t('LeadsList.LeadsListCannotCreateShipment')) ||
                    assessedValue
                  }
                  onChange={handleChangeAssessedValue}
                  error={errorAssessedValue && true}
                  InputProps={{
                    classes: {
                      input: classes.inputInput
                    },
                    inputProps: { maxLength: 6 }
                  }}
                />
                {errorAssessedValue && (
                  <div style={{ position: 'absolute', left: '-22px', top: '8px' }}>
                    <Tooltip placement='top-start' title={errorAssessedValue}>
                      <InfoOutlinedIcon style={{ color: '#FF3D33', fontSize: 18 }} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          )}
          {false && currentSupplierCountry === 'UKR' && (
            <InfoShipmentNumberItem
              data={currentUkrposhtaTrackingId}
              translateValue='№ відправлення (ШКІ)'
              classes={classes}
              placeholder={'Сформуйте №ШКІ'}
              canCreateShipment={canCreateShipment}
              assessedValue={assessedValue}
              createShipmentClick={createShipmentHandler}
              isCreatingShipment={isCreatingShipment}
            />
          )}
          {false && (
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8 }}
            >
              <div style={{ flexBasis: '30%' }}></div>
              <div style={{ flexBasis: '70%', fontSize: '12px', color: '#7C7C7C' }}>
                Для формування №відправлення, спочатку необхідно заповнити поле Оціночної вартості.
              </div>
            </Grid>
          )}
        </Grid>
        <Grid style={{ width: '50%' }} className={classes.modalItemPhoto}>
          <CloseIcon onClick={onCloseModal} className={classes.closeModalIcon} />
          {false && currentSupplierCountry === 'UKR' && (
            <LeadInfoForm
              disabled={currentUkrposhtaTrackingId}
              initialValues={initialValues}
              onEdit={!!lInfo}
              wishId={data.id}
              deliveryInfo={lInfo || wInfo}
              setCanCreateShipment={setCanCreateShipment}
            />
          )}

          <Typography variant='h6'>
            <Translate value='LeadsList.LeadsListModalPhoto' />
          </Typography>
          <div className={classes.wishPhotoWrap}>
            {data.feedback && data.feedback.photo && data.feedback.photo.preview ? (
              <img src={data.feedback.photo.preview} alt='wish' className={classes.wishPhoto} />
            ) : (
              data.image && <img src={data.image} alt='wish' className={classes.wishPhoto} />
            )}
          </div>
          <Typography variant='h6'>
            <Translate value='LeadsList.LeadsListModalDescription' />
          </Typography>
          <div className={classes.descriptionWrap}>{data.description}</div>
          <Typography variant='h6' className={classes.typo}>
            <Translate value='LeadsList.LeadsListModalFeedback' />
          </Typography>
          <div className={classes.feedbackWrap}>
            {data.feedback && data.feedback.comment ? (
              <div css={feedbackStyle}>{data.feedback.comment}</div>
            ) : (
              <span>
                <Translate value='LeadsList.LeadsListModalNoFeedback' />
              </span>
            )}
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    leadDataShipmentNumber: state.shipment.leadDataShipmentNumber
  };
};

const mapDispatchToProps = {
  createShipment
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalLeadList));
