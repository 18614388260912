import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 24
  }
});

function TringleIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill="#8A96C1"
        fillRule="nonzero"
        d="M8 6V3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v3h5.3a.7.7 0 0 1 0 1.4h-1.387l-.854 13.662a1 1 0 0 1-.998.938H5.939a1 1 0 0 1-.998-.938L4.088 7.4H2.7a.7.7 0 0 1 0-1.4H8zm1.4 0h5.2V3.4H9.4V6zM5.49 7.4l.825 13.2h11.37l.825-13.2H5.49zm3.974 2.106a.7.7 0 0 1 .736.663l.398 7.59a.7.7 0 1 1-1.398.072l-.398-7.59a.7.7 0 0 1 .662-.735zm5.072 0a.7.7 0 0 1 .662.736l-.398 7.59a.7.7 0 1 1-1.398-.074l.398-7.59a.7.7 0 0 1 .736-.662z"
      />
    </SvgIcon>
  );
}
export default withStyles(styles)(TringleIcon);
