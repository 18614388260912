import {
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILED,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILED,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILED,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILED
} from '../actions/types';
import queryString from 'query-string';

const initialState = {
    productList: [],
    createdProduct: {},
    pagination: {
      total: null,
      count: null,
      next: null
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_LIST_SUCCESS: {
          const pagination = {
            count: action.payload.results.length,
            total: action.payload.count,
            next: action.payload.next,
          }
          
          if(action.payload.next){
            const queryStringTemp = queryString.parseUrl(action.payload.next);
            pagination.next = parseInt(queryStringTemp.query.page);
          }
          
          return {
              ...state,
              productList: action.payload.results,
              pagination
          };
        }

        case GET_PRODUCT_LIST_FAILED: {
            return {
                ...state,
                productList: []
            }
        }

        case CREATE_PRODUCT_SUCCESS: {
            return {
                ...state
            }
        }

        case CREATE_PRODUCT_FAILED: {
            return {
                ...state
            }
        }

        case UPDATE_PRODUCT_SUCCESS: {
            return {
                ...state,
            }
        }

        case UPDATE_PRODUCT_FAILED: {
            return {
                ...state,
            }
        }

        case DELETE_PRODUCT_SUCCESS: {
            return {
                ...state
            }
        }

        case DELETE_PRODUCT_FAILED: {
            return {
                ...state
            }
        }

        default:
            return state;
    }
}