import apiService from "../../api/apiService";
import { appLoading, openPopupSystem, stopAppLoading } from "./appActions";
import { SET_DELIVERY_INFO, SET_BRANCH_DELIVERY_INFO } from "./types";
import { reset } from "redux-form";
import { I18n } from 'react-redux-i18n';

export const setDeliveryInfo = (payload) => ({
  type: SET_DELIVERY_INFO,
  payload,
});

export const setBranchDeliveryInfo = (payload) => ({
  type: SET_BRANCH_DELIVERY_INFO,
  payload,
});

export const getDeliveryInfo = () => async (dispatch) => {
  dispatch(appLoading());
  try {
    const response = await apiService.getDeliveryInfo();
    response.data[0].type === "BRANCH"
      ? dispatch(setBranchDeliveryInfo(response.data[0]))
      : dispatch(setDeliveryInfo(response.data[0]));
    dispatch(stopAppLoading());
  } catch (error) {
    console.log(error);
    dispatch(stopAppLoading());
  }
};

export const updateDeliveryInfo = (data) => async (dispatch) => {
  dispatch(appLoading());
  try {
    await apiService.updateDeliveryInfo(data.id, data);
    dispatch(getDeliveryInfo());
    dispatch(stopAppLoading());
  } catch (err) {
    dispatch(reset("Profile"))
    dispatch(stopAppLoading());
    if (err && err.response && err.response.data) {
      dispatch(
        openPopupSystem(
          { 
            message: I18n.t('Profile.ProfileErrorsCreateUpdateDeliveryInfo'),
            status: "fail",
            detail: err.response.data.error_detail
          }
        )
      )
    } 
  }
};

export const addDeliveryInfo = (data) => async (dispatch) => {
  dispatch(appLoading());
  try {
    await apiService.addDeliveryInfo(data);
    dispatch(getDeliveryInfo());
    dispatch(stopAppLoading());
  } catch (err) {
    dispatch(reset("Profile"))
    dispatch(stopAppLoading());
    if (err && err.response && err.response.data) {
      dispatch(
        openPopupSystem(
          { 
            message: I18n.t('Profile.ProfileErrorsCreateUpdateDeliveryInfo'),
            status: "fail",
            detail: err.response.data.error_detail
          }
        )
      )
    } 
  }
};
