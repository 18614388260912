const styles = theme => ({
  container: {
    maxWidth: 750,
  },
  containerTags:{
    maxWidth: 550
  },
  closeModalIcon: {
    cursor: "pointer",
    position: "absolute",
    right: 18,
    top: 18,
    "&:hover path": {
      fill: "#9FB0CB"
    }
  },
  wrapRight: {
    height: 280,
    borderRadius: 4,
    margin: "71px 24px 32px 0",
    border: "solid 1px #c7c7c7",
    backgroundColor: "#ffffff"
  },
  tagsWrap:{
    height: 155,
     overflow: "hidden",
    overflowY: "scroll",
    position: 'relative'
  },
  clearAllWrap: {
    borderBottom: "1px solid rgba(199, 199, 199, 0.24)",
    padding: 8
  },
  clearAllBtn: {
    color: "#0028c2",
    marginRight: 27,
    "& svg": {
      marginRight: 8,
      marginBottom: 1
    }
  },
  totalPointsWrap: {
    borderTop: "1px solid rgba(199, 199, 199, 0.24)",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 500,
    padding: 8
  },
  descriptionTotal: {
    padding: '5px 0px 10px',
    fontSize: 10,
    fontWeight: 400,
    color: '#7C7C7C'
  },
  totalLabel: {
    color: "#9fb0cb",
  },
  totalCurrencyCharacter: {
    color: "#007FFF",
  },
  extendedIcon: {
    marginRight: 8,
    marginBottom: "1.5px"
  },
  addIcon: {
    marginLeft: 8,
    marginBottom: 0
  },
  cancelBtn: {
    minWidth: 104,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#0028c2",
    border: "1px solid #0028c2",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    "& path": {
      fill: "#0028c2"
    }
  },
  saveBtn: {
    minWidth: 104,
    height: 36,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#ffffff",
    fontSize: 14,
    marginLeft: 24,
    backgroundColor: "#0028c2",
    "& path:nth-child(1)": {
      fill: "#fff"
    },
    "& svg": {
      marginRight: 8,
      marginTop: 3
    }
  },
  title: {
    paddingBottom: 18,
    whiteSpace: "nowrap",
    position: "absolute",
    top: "29px",
    left: "24px"
  },
  leftContainer: {
    padding: "71px 32px 0 24px",
    width: "52%"
  },
  fullWidth:{
    width: "100%"
  },
  addButtonWrap: {
    padding: "10px 0"
  },
  rightContainer: {
    width: "48%"
  },
  actionButtonsWrap: {
    padding: 24,
    borderTop: "1px solid rgba(199, 199, 199, 0.24)"
  },
  dragDropText:{
    position: 'absolute',
    left: '50%',
    width: 129,
    top: '35%',
    marginLeft: -(129/2),
    height: 48,
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#9fb0cb'
  }
});

export default styles;
