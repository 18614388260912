const styles = (theme) => ({
  selectBranch: {
    width: "100%",
    "& .select__control--is-disabled": {
      border: "none",
      backgroundColor: "white",
    },
    "& .select__control--menu-is-open": {
      boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
    },
    "& .select__control--is-focused": {
      boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
    },
    "& .select__control": {
      height: "32px",
      fontFamily: "Roboto",
      fontSize: "14px",
      minHeight: "unset",
      "&:hover": {
        borderColor: "#007fff",
      },
      "&:focus": {
        boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
      },
    },
  },
  selectBranchBorder: { 
    width: "100%",
    "& .select__control--is-disabled": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      backgroundColor: "white",
    },
    "& .select__control--menu-is-open": {
      boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
    },
    "& .select__control--is-focused": {
      boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
    },
    "& .select__control": {
      height: "32px",
      fontFamily: "Roboto",
      fontSize: "14px",
      minHeight: "unset",
      "&:hover": {
        borderColor: "#007fff",
      },
      "&:focus": {
        boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)",
      },
    },
  },
  menuList: {
    maxHeight: "200px",
    overflowY: "scroll",
    fontFamily: "Roboto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

export default styles;
