import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { List, ListItem, Paper, Button, CircularProgress } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import AddIcon from '../../../../components/Icons/AddIcon';
import CustomInputRounded from '../../../../components/CustomInputRounded';
import { ProductPickItemStyle, ProductPickListStyle, ActionPick } from './styles';
import truncateString from '../../../../utils/truncateString';
import simpleContainsSearch from '../../../../utils/simpleContainsSearch';

const listType = ["SOURCE", "SELECTED"];

const ProductPickItem = props => {
    const { id, name, price, imgUrl, active, listType, onItemClick } = props;

    const handleActiveItem = () => {
        onItemClick({ id, name, price, imgUrl, listType });
    }

    return (
        <ProductPickItemStyle src={imgUrl} active={active} onClick={handleActiveItem}>
            <ListItem className="list-item-custom" role="listitem" button>
                <div className="product-img" />
                <div className="info">
                    <span className="product-name">{name}</span>
                    <span className="price">{price}</span>
                </div>
                <CheckCircle />
            </ListItem>
        </ProductPickItemStyle>
    )
}

const ProductPickList = props => {
    const { productsSelected = [], products = [], onSelectedProduct, onAddMoreProduct, profile } = props;
    const { loading } = useSelector(state => state.app);
    const [sourceList, setSourceList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [activeItemList, setActiveItemList] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [currencyUnitText, setCurrencyUnitText] = useState('USD');

    useEffect(() => {
        const { sources, selected } = checkSelectedProducts(_.clone(products));
        if (!sources || !selected) return;
        setSourceList(sources);
        setSelectedList(selected);
    }, [products]);

    const onItemClick = (item) => {
        let newList
        if (activeItemList.findIndex(i => i.id === item.id) !== -1) {
            newList = activeItemList.filter(i => i.id !== item.id)
        } else {
            newList = activeItemList.concat([item])
        }
        setActiveItemList(newList)
    }

    const checkSelectedProducts = (productsRoot) => {
        const sources = productsRoot, selected = [];
        let indexSelectedProduct = -1;
        productsSelected.forEach(id => {
            const productTemp = productsRoot.find((v, i) => {
                indexSelectedProduct = i;
                return v.id === id;
            });
            selected.push(productTemp);
            sources.splice(indexSelectedProduct, 1);
        })
        return {
            sources,
            selected
        }
    }

    const onAddToSelectedList = () => {
        const newSetData = [...selectedList, ...activeItemList];
        setSelectedList(newSetData);
        setActiveItemList([]);
        onSelectedProduct(newSetData);
    }

    const onAddToSourceList = () => {
        const newSetData = selectedList.filter(item => activeItemList.findIndex(i => i.id === item.id) < 0)
        setSelectedList(newSetData);
        setActiveItemList([]);
        onSelectedProduct(newSetData);
    }

    const onChangeSearch = e => {
        setSearchString(e.target.value);
    }

    const handleClearSearchInput = () => {
        setSearchString('');
    }

    const sourceListMappingBySearch = (sources) => {
        return sources.filter(value => {
            const { name, description, price } = value;
            const textToSearch = `${name} ${description} ${price}`;
            return simpleContainsSearch(searchString, textToSearch);
        })
    }

    useEffect(() => {
        if (profile && profile.supplier) {
            setCurrencyUnitText(profile.supplier.country === 'UKR' ? 'UAH' : 'USD')
        }
    }, [profile])

    return (
        <ProductPickListStyle>
            <Paper className="list product-list">
                <div className="search-group">
                    <CustomInputRounded
                        name="productSearch"
                        type="search"
                        placeholder={I18n.t("LeadsList.LeadsListSearch")}
                        value={searchString}
                        onChange={onChangeSearch}
                        onClick={handleClearSearchInput}
                        classes={{
                            inputSearch: "search-input",
                        }}
                    />
                    <div className="add-icon">
                        {
                            loading ? <CircularProgress size="13px" style={{ color: "#0029c2" }} /> : <AddIcon onClick={onAddMoreProduct} />
                        }
                    </div>
                </div>
                <List className="custom-list" dense component="div" role="list" >
                    {
                        sourceList.length === 0 && (
                            `${I18n.t("Products.ProductsNoAvailableItem")}`
                        )
                    }
                    {
                        sourceListMappingBySearch(sourceList).map(item => {
                            const { id, image, price, name } = item;
                            return (
                                <ProductPickItem
                                    key={id}
                                    id={id}
                                    name={truncateString(name, 40)}
                                    price={`${price} ${currencyUnitText}`}
                                    imgUrl={image}
                                    listType={listType[0]}
                                    onItemClick={onItemClick}
                                    active={activeItemList.findIndex(i => i.id === id) >= 0}
                                />
                            )
                        })
                    }
                </List>
            </Paper>
            <ActionPick>
                <Button
                    className="btn-control"
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={onAddToSelectedList}
                >{`>`}
                </Button>
                <Button
                    className="btn-control"
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={onAddToSourceList}
                >{`<`}
                </Button>
            </ActionPick>
            <Paper className="list selected-list">
                <List className="custom-list selected" dense component="div" role="list">
                    {
                        selectedList.length === 0 && (
                            `${I18n.t("Products.ProductsProductPickListNoSelectedItem")}`
                        )
                    }
                    {
                        selectedList.map(item => {
                            const { id, image, price, name } = item;
                            return (
                                <ProductPickItem
                                    key={id}
                                    id={id}
                                    name={truncateString(name, 40)}
                                    price={`${price} ${currencyUnitText}`}
                                    imgUrl={image}
                                    listType={listType[1]}
                                    onItemClick={onItemClick}
                                    active={activeItemList.findIndex(i => i.id === id) >= 0}
                                />
                            )
                        })
                    }
                </List>
            </Paper>
        </ProductPickListStyle >
    )
}

const mapStateToProps = state => ({
    locale: state.i18n.locale,
    profile: state.profile,
});

export default connect(
    mapStateToProps,
  )(ProductPickList);