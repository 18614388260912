const styles = theme => ({
  helperTextInput: {
    fontSize: 14,
    color: "#c7c7c7",
    paddingRight: 8
  },

  buttonAdd: {
    width: 104,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    color: "#0028c2",
    border: "1px solid #0028c2",
    fontSize: 14,
    textAlign: "center",
    "& svg": {
      marginLeft: 8
    },
    "& path": {
      fill: "#0028c2"
    }
  },

  container: {
    minWidth: 320,
    height: 206,
    border: "solid 1px #c7c7c7",
    borderRadius: 4
  },
  addButtonContainer: {
    borderBottom: "1px solid rgba(199, 199, 199, 0.24)",
    padding: 8
  },
  tagWrap: {
    overflow: "hidden",
    overflowY: "scroll",
    height: "100%"
  }
});

export default styles;
