import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 24,
    "&:hover circle": {
      fill: "#F3F5F8"
    },
    circle: {}
  }
});

function ShowPasswordIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
         <path fill="#9FB0CB" fillRule="nonzero" d="M4.061 11.76a14.02 14.02 0 0 1 .565-.902c.454-.665.98-1.33 1.575-1.953C7.927 7.097 9.87 6 12 6c2.13 0 4.073 1.097 5.8 2.905a15.414 15.414 0 0 1 1.99 2.6c.072.12.122.206.149.256a.5.5 0 0 1 0 .478 14.02 14.02 0 0 1-.565.902c-.454.666-.98 1.331-1.575 1.954C16.073 16.903 14.13 18 12 18c-2.13 0-4.073-1.097-5.8-2.905a15.414 15.414 0 0 1-1.99-2.6 7.298 7.298 0 0 1-.149-.256.5.5 0 0 1 0-.478zm1.722.592c.433.635.914 1.235 1.43 1.777C8.784 15.772 10.386 16.6 12 16.6c1.615 0 3.218-.828 4.786-2.471A14.002 14.002 0 0 0 18.45 12a14.003 14.003 0 0 0-1.663-2.129C15.216 8.228 13.614 7.4 12 7.4c-1.615 0-3.218.828-4.786 2.471A14.002 14.002 0 0 0 5.55 12c.073.114.15.232.233.352zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1.4a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(ShowPasswordIcon);
