import React, { Component } from "react";
import withRoot from "./utils/withRoot";
import { Provider } from "react-redux";
import store from "./redux/store";
import Root from "./Root";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import './assets/style/style.css'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Root />
          <NotificationContainer />
        </MuiPickersUtilsProvider>
      </Provider>
    );
  }
}

export default withRoot(App);
