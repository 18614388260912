import React from 'react';
import {withStyles} from '@material-ui/core'

const styles = theme => ({
    root: {
        fontSize: 20,
    },
  });

function SearchIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 26 24">
        <path fill="#9FB0CB" fillRule="nonzero" d="M17.14 17.13a8 8 0 1 1 .99-.99l3.77 3.77a.7.7 0 1 1-.99.99l-3.77-3.77zm-.473-1.463a6.6 6.6 0 1 0-9.334-9.334 6.6 6.6 0 0 0 9.334 9.334z"/>
      </svg>
    );
  }
  export default withStyles(styles)(SearchIcon);