const main = {
  ProductCategoriesProductCategoryTitle: 'Product Category',
  ProductCategoriesCategoryName: "Category name",
  ProductCategoriesLabelName: "Name",
  ProductCategoriesValidateRequired: "This field is not empty",
  ProductCategoriesValidateMustNumber: "Must be a number",
  ProductCategoriesButtonCreateNew: "Create new Category",
  ProductCategoriesCategory: "Category",
  ProductCategoriesCreateSuccess: "Created Successfully",
  ProductCategoriesCreateFailed: "Created Failed",
  ProductCategoriesUpdateSuccess: "Updated Successfully",
  ProductCategoriesUpdateFailed: "Updated Failed",
  ProductCategoriesDeleteSuccess: "Deleted Successfully",
  ProductCategoriesDeleteFailed: "Deleted Failed",
}

const productFilter = {
  ProductCategoriesFilterLabel: 'Filter By Category',
  ProductCategoriesFilterAll: 'All',
}
const modal = {
  ProductCategoriesCreateModalTitle: 'Create new category',
  ProductCategoriesUpdateModalTitle: 'Update category info',
  ProductCategoriesModalCancelBtn: 'Cancel',
  ProductCategoriesModalCreateBtn: 'Create',
  ProductCategoriesModalUpdateBtn: 'Update'
}
const table = {
  ProductCategoriesOrder: 'ID',
  ProductCategoriesName: 'Name',
  ProductCategoriesActions: "Actions"
}
const deleteConfirm = {
  ProductCategoriesConfirmDeleteMessage: "Are you sure you want to remove the product?",
  ProductCategoriesAcceptBtnLabel: "Yes",
  ProductCategoriesCancelBtnLabel: "No"
}

const ProductCategories = {
  ...main,
  ...productFilter,
  ...modal,
  ...table,
  ...deleteConfirm
}

export default ProductCategories;