const styles = (theme) => ({
  popupPaper: {
    width: 300
  },
  popupTitle: {
    display: 'flex',
    padding: 24,
    color: '#26CF36',
    alignItems: 'center'
  },

  title: {
    color: '#26CF36',
    marginLeft: 10
  },
  titleFail: {
    color: '#D0011B'
  },
  iconFail: {
    color: '#D0011B'
  },
  button: {
    color: '#26CF36'
  },
  buttonFail: {
    color: '#D0011B'
  }
});
export default styles;
