import { START_LOADING, STOP_LOADING, OPEN_TOAST_SYSTEM, CLOSE_TOAST_SYSTEM, SET_CONFIG, GET_IP_GEO, OPEN_POPUP_SYSTEM, CLOSE_POPUP_SYSTEM } from "../actions/types";

const initialState = {
  loading: false,
  config: {},
  toastSystemState: {
    message: null
  },
  popupSystemState : {
    message: null,
    status: null,
    detail: null,
  },
  ipGeo: {},
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_LOADING:
      return {
        ...state,
        loading: false
      };

    case OPEN_TOAST_SYSTEM:
      return {
        ...state,
        toastSystemState: { ...action.payload }
      }

    case CLOSE_TOAST_SYSTEM:
      return {
        ...state,
        toastSystemState: {
          message: null
        }
      }

    case OPEN_POPUP_SYSTEM: 
      return {
        ...state,
        popupSystemState : { ...action.payload }
      }

    case CLOSE_POPUP_SYSTEM:
      return {
        ...state,
        popupSystemState: {
          message: null,
          status: null,
          detail: null
        }
      }

    case SET_CONFIG:
      return {
        ...state,
        config: { ...action.payload }
      }

    case GET_IP_GEO:
      return {
        ...state,
        ipGeo: { ...action.payload }
      }

    default:
      return state;
  }
}
