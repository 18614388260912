import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  withStyles
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Field } from 'redux-form';
import apiService from '../../../../api/apiService';
import avatar from '../../../../assets/img/avatar.png';
import CustomInput from '../../../../components/CustomInput';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import DoneIcon from '../../../../components/Icons/DoneIcon';
import EditIcon from '../../../../components/Icons/EditIcon';
import { getDeliveryInfo } from '../../../../redux/actions/deliveryActions';
import { getProfileInfo } from '../../../../redux/actions/profileActions';
import AddressDeliveryInfo from '../AddressDeliveryInfo/AddressDeliveryInfo';
import AvatarProfile from '../AvatarProfile';
import BranchDeliveryInfo from '../BranchDeliveryInfo/BranchDeliveryInfo';
import styles from './style';
import AgreementModal from '../AgreementModal/AgreementModal';

const onlyNums = (value) => value.replace(/[^\d]/g, '');

const renderSelectDeliveryType = ({ classes, input, disabled }) => {
  return (
    <Select
      classes={{
        root: classes.selectAddRoot,
        select: classes.selectAddSelect
      }}
      value={input.value || 'ADDRESS'}
      onChange={input.onChange}
      disableUnderline
      disabled={disabled}
    >
      <MenuItem value='ADDRESS'>
        <Translate value='Profile.ProfileDeliveryAddress' />
      </MenuItem>
      <MenuItem value='BRANCH'>
        <Translate value='Profile.ProfileDeliveryBranch' />
      </MenuItem>
    </Select>
  );
};

const renderTextField = ({
  type,
  label,
  multiline,
  inputProps,
  errors,
  disabled,
  maxLength,
  rows,
  input,
  placeholder,
  labelMaxWidth,
  labelWrapWidth,
  meta: { touched, error }
}) => {
  const { name } = input;
  const errorStyle = {
    position: 'absolute',
    top: name === 'description' ? 4 : name === 'displayName' ? 46 : 7,
    left: name === 'description' ? 106 : name === 'displayName' ? 40 : 78
  };
  return (
    <>
      <CustomInput
        multiline={multiline}
        rows={rows}
        type={type}
        label={label}
        maxLength={maxLength}
        error={touched && !!error}
        disabled={disabled}
        placeholder={placeholder}
        labelWrapWidth={labelWrapWidth}
        labelMaxWidth={labelMaxWidth}
        inputProps={inputProps}
        {...input}
      />
      {touched && error && (
        <div style={errorStyle}>
          <Tooltip placement='top-start' title={error}>
            <InfoOutlinedIcon style={{ color: '#FF3D33', fontSize: 18 }} />
          </Tooltip>
        </div>
      )}
    </>
  );
};

const renderCheckbox = ({ input, label, disabled, styles: classes }) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      classes={{
        root: classes.formControlLabelRoot
      }}
      control={
        <Checkbox
          checked={input.checked}
          color='default'
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked
          }}
          onChange={input.onChange}
        />
      }
    />
  );
};

const renderSwitchInput = ({ classes, input, disabled }) => {
  return (
    <Switch
      checked={input.value}
      onChange={input.onChange}
      disabled={disabled}
      classes={{
        root: classes.switchRoot,
        bar: classes.switchBar,
        icon: classes.switchIcon,
        iconChecked: classes.switchIconChecked,
        checked: classes.switchChecked,
        switchBase: classes.switchBase
      }}
    />
  );
};

const renderRadioButton = ({ label, labelMaxWidth, classes, disabled, input }) => {
  return (
    <Grid container wrap='nowrap' alignItems='center' justify='space-between'>
      <Grid container wrap='nowrap' style={{ maxWidth: +labelMaxWidth }}>
        <Typography variant='body2'>{label}</Typography>
      </Grid>
      <Grid container>
        <RadioGroup defaultValue='false' value={`${input.value}`} style={{ flexDirection: 'row' }}>
          <FormControlLabel
            value={'false'}
            disabled={disabled}
            control={
              <Radio
                onChange={input.onChange}
                classes={{
                  root: classes.radioRoot,
                  checked: classes.radioChecked,
                  colorSecondary: classes.radioColorSecondary
                }}
              />
            }
            label={<Translate value='Profile.ProfileDeliverySender' />}
          />
          <FormControlLabel
            value={'true'}
            disabled={disabled}
            control={
              <Radio
                onChange={input.onChange}
                classes={{
                  root: classes.radioRoot,
                  checked: classes.radioChecked,
                  colorSecondary: classes.radioColorSecondary
                }}
              />
            }
            label={<Translate value='Profile.ProfileDeliveryReceiver' />}
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const PaperContactInfo = ({
  classes,
  supplier_logo,
  onEdit,
  onFileLoad,
  handleEditClick,
  clearSubmitErrors,
  error,
  errors,
  formValues,
  country
}) => {
  const dispatch = useDispatch();

  const [deliveryType, setDeliveryType] = React.useState('');

  const deliveryInfo = useSelector((state) => state.profile.deliveryInfo);

  const agreementRead = useSelector((state) => state.profile.agreementRead);

  const [isShowAgreementModal, setIsShowAgreementModal] = React.useState(false);

  const handleDeliveryTypeChange = (e) => {
    setDeliveryType(e.target.value);
  };

  const handleAgreementClick = () => {
    apiService.updateReadAgreement().then(dispatch(getProfileInfo()));
  };
  React.useEffect(() => {
    dispatch(getDeliveryInfo());
  }, []);
  React.useEffect(() => {
    setDeliveryType(deliveryInfo.type);
  }, [deliveryInfo]);

  return (
    <Paper className={classes.paperRoot}>
      <div className={classes.paperItemMain}>
        <AvatarProfile
          avatar={supplier_logo ? supplier_logo : avatar}
          // avatar={(supplier_logo && supplier_logo.photo) ? supplier_logo.photo : avatar}
          onChange={onFileLoad}
        />
        <Grid container alignContent='center' style={{ paddingRight: 22, position: 'relative' }}>
          <Typography variant='body2' style={{ paddingBottom: 4 }}>
            <Translate value='Profile.ProfileName' />
          </Typography>
          <Field
            type='text'
            name='displayName'
            labelMaxWidth='86'
            component={renderTextField}
            disabled={!onEdit}
            maxLength='50'
            inputProps={{
              disabled: classes.nameDisabled
            }}
          />
        </Grid>
        <div className={classes.paperItemEdit}>
          {!onEdit && (
            <Button className={classes.buttonEdit} onClick={handleEditClick}>
              <EditIcon className={classes.buttonEditIcon} />
              <Translate value='Profile.ProfileButtonEdit' />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.paperBody}>
        <div className={classes.bodyLeft}>
          <div className={classes.paperItemInfo}>
            <Grid container alignItems='center' justify='space-between' wrap='nowrap'>
              <Typography variant='h6'>
                <Translate value='Profile.ProfileInfoTitle' />
              </Typography>
              <Button
                color='secondary'
                variant='contained'
                onClick={() => setIsShowAgreementModal(true)}
                style={agreementRead ? { backgroundColor: '#C7CACB', color: '#fff' } : {}}
              >
                <Translate value='Profile.ProfileButtonAgreement' />
              </Button>
            </Grid>
            <div className={classes.error}>
              <span>{error && error}</span>
            </div>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                label={<Translate value='Profile.ProfileInfoWebsite' />}
                type='text'
                name='website'
                maxLength='60'
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                label={<Translate value='Profile.ProfileInfoInstagram' />}
                type='textanda'
                name='instagram'
                placeholder={I18n.t('Profile.ProfileInfoInstagramPlaceholder')}
                maxLength='60'
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                label={<Translate value='Profile.ProfileInfoTelegram' />}
                type='textanda'
                name='telegram'
                placeholder={I18n.t('Profile.ProfileInfoTelegramPlaceholder')}
                maxLength='60'
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                type='text'
                name='email'
                label={<Translate value='Profile.ProfileInfoEmail' />}
                maxLength='60'
                labelWrapWidth='105px'
                component={renderTextField}
                onFocus={clearSubmitErrors}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
              maxLength='12'
            >
              <Field
                type='tel'
                name='phone'
                label={<Translate value='Profile.ProfileInfoPhone' />}
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
                normalize={(value, previousValue, allValues) => {
                  const lastSymbol = value.slice(-1);
                  const firstSymbol = value.slice(0, 1);

                  if (value.length > 16) return previousValue;
                  if (firstSymbol !== '+') {
                    return '+';
                  }

                  if (previousValue && value.length < previousValue.length) {
                    return value;
                  } else {
                    if (value.length > 1 && firstSymbol === '+') {
                      return value.slice(0, value.length - 1) + onlyNums(lastSymbol);
                    } else if (value === '+') {
                      return value;
                    }
                  }
                }}
              />
              {errors && errors.phone && (
                <div className={classes.error}>
                  {I18n.t('Profile.ProfileInfoPhoneFormatError')} <span>(+123456789000)</span>
                </div>
              )}
              <Field
                type='checkbox'
                name='viber'
                label='Viber'
                component={renderCheckbox}
                disabled={!onEdit}
                styles={classes}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
              <Field
                type='checkbox'
                name='whatsapp'
                label='Whatsapp'
                component={renderCheckbox}
                disabled={!onEdit}
                styles={classes}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                type='text'
                name='address'
                label={<Translate value='Profile.ProfileInfoAddress' />}
                maxLength='64'
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              wrap='nowrap'
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Field
                type='text'
                name='supplier.donation'
                label={<Translate value='Profile.ProfileInfoDonation' />}
                maxLength='64'
                labelWrapWidth='105px'
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled
                }}
              />
            </Grid>
            {/* <Grid
              container
              alignItems="center"
              justify="space-between"
              wrap="nowrap"
              style={{ marginBottom: 8, position: "relative" }}
            >
              <Field
                type="text"
                name="available"
                label={<Translate value="Profile.ProfileInfoAvailable" />}
                maxLength="64"
                labelWrapWidth="102px"
                component={renderTextField}
                disabled={!onEdit}
                inputProps={{
                  disabled: classes.infoDisabled,
                }}
              />
            </Grid> */}
          </div>
          <div className={classes.horizontalDivider} />
          <div className={classes.paperItemDesc} style={{ position: 'relative' }}>
            <Typography variant='h6' style={{ marginBottom: 14 }}>
              <Translate value='Profile.ProfileInfoDescription' />
            </Typography>
            <Field
              multiline
              rows={6}
              name='description'
              labelMaxWidth='86'
              component={renderTextField}
              disabled={!onEdit}
              maxLength='460'
              inputProps={{
                disabled: classes.infoDisabled
              }}
            />
          </div>
        </div>
        {false && country === 'UKR' && (
          <div className={classes.bodyRight}>
            <div className={classes.paperItemDeliveryInfo}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h6'>
                  <Translate value='Profile.ProfileDeliveryInfo' />
                </Typography>
                <Field
                  classes={classes}
                  component={renderSelectDeliveryType}
                  name='deliveryInfo.type'
                  disabled={!onEdit}
                  onChange={handleDeliveryTypeChange}
                />
              </div>
              <div className={classes.error}>
                <span>{error && error}</span>
              </div>
              {deliveryType === 'BRANCH' ? (
                <BranchDeliveryInfo onEdit={onEdit} />
              ) : (
                <AddressDeliveryInfo
                  onEdit={onEdit}
                  renderTextField={renderTextField}
                  styles={classes}
                />
              )}
            </div>
            <div className={classes.horizontalDivider} />
            <div className={classes.paperItemDeliveryUkr}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 14
                }}
              >
                <Typography variant='h6'>
                  <Translate value='Profile.ProfileDeliveryByUkrposhta' />
                </Typography>
                <Field
                  name='deliveryInfo.is_active'
                  classes={classes}
                  disabled={!onEdit}
                  component={renderSwitchInput}
                />
              </div>
              <Grid container alignItems='center' justify='space-between' wrap='nowrap'>
                <Field
                  label={<Translate value='Profile.ProfileDeliveryPayment' />}
                  name='deliveryInfo.recipient_pay'
                  classes={classes}
                  labelMaxWidth={200}
                  component={renderRadioButton}
                  disabled={!onEdit || !(formValues.deliveryInfo && formValues.deliveryInfo.is_active && formValues.deliveryInfo.is_active)}
                  inputProps={{
                    disabled: classes.infoDisabled
                  }}
                />
              </Grid>
              <Grid
                container
                alignItems='center'
                justify='space-between'
                wrap='nowrap'
                style={{ marginBottom: 8 }}
              >
                <Typography variant='caption'>
                  <Translate value='Profile.ProfileDeliveryApproximateCost' />
                </Typography>
              </Grid>
              <Grid container alignItems='center' justify='space-between' wrap='nowrap'>
                <div className={classes.deliveryInformation}>
                  <Typography variant='caption'>
                    <Translate value='Profile.ProfileDeliveryInformation' />
                  </Typography>
                </div>
              </Grid>
            </div>
          </div>
        )}
      </div>
      {onEdit && (
        <Grid
          container
          justify='flex-end'
          style={{
            padding: 24,
            borderTop: '1px solid rgba(199, 199, 199, 0.24)'
          }}
        >
          <Button variant='outlined' onClick={handleEditClick} className={classes.cancelBtn}>
            <CloseIcon className={classes.extendedIcon} />
            <Translate value='Profile.ProfileButtonCancel' />
          </Button>
          <Button variant='contained' type='submit' color='primary' className={classes.saveBtn}>
            <DoneIcon className={classes.extendedIcon} />
            <Translate value='Profile.ProfileButtonSave' />
          </Button>
        </Grid>
      )}
      <AgreementModal
        open={isShowAgreementModal}
        handleCloseModal={() => setIsShowAgreementModal(false)}
        handleSubmit={handleAgreementClick}
        isAgree={agreementRead}
      />
    </Paper>
  );
};

export default withStyles(styles)(PaperContactInfo);
