import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 14,
  }
});

function CloseIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
        <path fill="#C7C7C7" fillRule="nonzero" d="M12.243 3.757a.7.7 0 0 1 0 .99L8.99 8l3.253 3.253a.7.7 0 0 1-.99.99L8 8.99l-3.253 3.253a.7.7 0 0 1-.99-.99L7.01 8 3.757 4.747a.7.7 0 0 1 .99-.99L8 7.01l3.253-3.253a.7.7 0 0 1 .99 0z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(CloseIcon);