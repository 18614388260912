
const styles = theme => ({
    table: {
      fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      minWidth: 60
    },
    tableRow: {
      outline: 'none',
      cursor:'pointer',
      '&:hover':{
        backgroundColor: '#eaeff5',
        '& $inProgressBg': {
          backgroundColor: '#cfe4d6'
        },
        '& $completedBg': {
          backgroundColor: '#ffd8d6'
        },
        '& $fulfilledByAnotherBg': {
          backgroundColor: '#eaeff5'
        },
      }
    },
    tableCell: {
      flex: 1,
      flexWrap: 'nowrap',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tableCellHeader: {
      color:'#9fb0cb',
      flex: 1,
    },
    noClick: {
      cursor: 'pointer',
      outline: 'none',
    },
    columnStatus: {
      whiteSpace: 'break-spaces'
    },
    rootBtn:{
      width: 105,
      minWidth: 105,
      height: 24,
      borderRadius: 12,
      fontSize: 14,
      fontWeight: 500,
      color: '#ffffff',
      padding: 0,
      background: "#00a0c9"
    },
    chip:{
      minWidth:24,
      height: 24,
      backgroundColor: '#9fb0cb',
      fontSize: 14,
      color: '#ffffff',
      margin: 0,
      '& span':{
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    inProgress:{
      fontFamily: 'Roboto',
      fontSize: 14,
      color: '#e19b45',
      fontWeight: 500
    },
    completed:{
      fontFamily: 'Roboto',
      fontSize: 14,
      color: '#00a0c9',
      fontWeight: 500
    },
    fulfilledByAnother:{
      fontFamily: 'Roboto',
      fontSize: 14,
      color: '#109b45',
      fontWeight: 500
    },
    inProgressBg: {
      backgroundColor: '#e7f2eb'
    },
    completedBg: {
      backgroundColor: '#ffeceb'
    },
    fulfilledByAnotherBg: {
      backgroundColor: '#fff'
    },
    customInfoIcon: {
      marginLeft: 5,
      marginRight: 5,
      fontSize: 16
    }
  });

  export default styles;