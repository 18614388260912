import { Document, Font, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import React from 'react';
import robotoLight from '../../../../assets/font/Roboto-Light.ttf';
import robotoMedium from '../../../../assets/font/Roboto-Medium.ttf';
import { styles } from './style';
import momentTimezone from 'moment-timezone';
import numberToWordsRu from 'number-to-words-ru';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: robotoLight,
      fontWeight: 300
    },
    {
      src: robotoMedium,
      fontWeight: 500
    }
  ]
});

export const InvoiceDocument = ({ name, sum }) => {
  const currentDate = momentTimezone(new Date()).tz('Europe/Kiev').format('DD MMMM YYYY');

  const convertedSum = numberToWordsRu.convert(sum, {
    currency: {
      currencyNameCases: ['гривен', 'гривен', 'гривен'],
      fractionalPartNameCases: ['копеек', 'копеек', 'копеек']
    }
  });

  const formatedSum = (Math.round(sum * 100) / 100).toFixed(2);

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Text style={{ fontSize: '7px', fontWeight: '300' }}>
            Внимание! Оплата данного счета означает согласие с условиями поставки товара.
            Уведомление об оплате обязательно, в противном случае не гарантируется наличие товара на
            складе. Товар отпускается по факту прихода денег на т/с Поставщика, самовывозом, при
            наличии доверенности и паспорта.
          </Text>
        </View>
        <View style={styles.infoSection}>
          <Text style={styles.infoTitle}>Образец заполнения платежного поручения</Text>
          <div style={styles.flex}>
            <div style={{ width: '45%' }}>
              <div style={{ ...styles.flex, alignItems: 'flex-end' }}>
                <Text style={{ fontSize: '8px', fontWeight: '300', width: '30%' }}>Одержувач</Text>
                <Text style={{ fontSize: '10px', fontWeight: '500', width: '70%' }}>
                  ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ДРІМ ФЕКТОРІ УКРАЇНА"
                </Text>
              </div>
              <div style={styles.flex}>
                <Text style={{ fontSize: '8px', fontWeight: '300', width: '30%' }}>Код</Text>
                <div
                  style={{
                    border: '1.5px solid black',
                    textAlign: 'center',
                    padding: '5px',
                    width: '70%'
                  }}
                >
                  <Text style={{ fontSize: '8px', fontWeight: '500' }}>42855250</Text>
                </div>
              </div>
              <div style={styles.flex}>
                <Text style={{ fontSize: '8px', fontWeight: '300' }}>Банк одержувача</Text>
              </div>
              <div style={{ ...styles.flex, marginTop: '10px' }}>
                <Text style={{ fontSize: '8px', fontWeight: '500' }}>Банк АТ"АЛЬФА-БАНК"</Text>
              </div>
            </div>
            <div style={{ width: '20%', alignSelf: 'flex-end' }}>
              <Text
                style={{ fontSize: '8px', width: '100%', textAlign: 'center', fontWeight: '300' }}
              >
                Код банку
              </Text>
              <div
                style={{
                  fontWeight: '500',
                  textAlign: 'center',
                  border: '1.5px solid, black',
                  margin: '0 auto',
                  padding: '5px',
                  width: '60%'
                }}
              >
                <Text style={{ fontSize: '8px' }}>300346</Text>
              </div>
            </div>
            <div style={{ width: '30%', alignSelf: 'flex-end' }}>
              <div
                style={{
                  borderRight: '1.5px solid, black',
                  paddingTop: '30px'
                }}
              >
                <Text style={{ fontSize: '8px', fontWeight: '300', textAlign: 'center' }}>
                  КРЕДИТ рах. N
                </Text>
              </div>
              <div style={{ border: '1.5px solid, black', borderBottom: 'none' }}>
                <Text> </Text>
              </div>
              <div style={{ border: '1.5px solid, black' }}>
                <Text> </Text>
              </div>
            </div>
          </div>
        </View>
        <View style={styles.detailSection}>
          <Text style={styles.detailTitle}>Счет на оплату No 1 от {currentDate}</Text>
          <div style={styles.detailItem}>
            <Text style={{ width: '15%', paddingLeft: '5px' }}>Поставщик:</Text>
            <div style={{ width: '85%' }}>
              <Text style={{ fontWeight: '500' }}>
                ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ДРІМ ФЕКТОРІ УКРАЇНА"
              </Text>
              <Text>Т/с UA053003460000026001027065801, Банк Банк АТ"АЛЬФА-БАНК", МФО 300346</Text>
              <Text>04119, м.Київ, ВУЛИЦЯ СІМ'Ї ХОХЛОВИХ, будинок 8, офіс 204,</Text>
              <Text>код по ЕГРПОУ 42855250,</Text>
              <Text>Платник податку на прибуток на загальних підставах</Text>
            </div>
          </div>
          <div style={styles.detailItem}>
            <Text style={{ width: '15%', paddingLeft: '5px' }}>Покупатель:</Text>
            <Text style={{ fontWeight: '500' }}>{name}</Text>
          </div>
          <div style={styles.detailItem}>
            <Text style={{ width: '15%', paddingLeft: '5px' }}>Договор:</Text>
            <Text>Основной договор</Text>
          </div>
          <div style={styles.detailTable}>
            <div style={styles.detailTableHeader}>
              <Text style={{ ...styles.col1, ...styles.headerCell }}>No</Text>
              <Text style={{ ...styles.col2, ...styles.headerCell }}>Товары (работы, услуги)</Text>
              <Text style={{ ...styles.col3, ...styles.headerCell }}>Кол-во</Text>
              <Text style={{ ...styles.col4, ...styles.headerCell }}>Ед.</Text>
              <Text style={{ ...styles.col5, ...styles.headerCell }}>Цена</Text>
              <Text style={{ ...styles.col6, ...styles.headerCell, borderRight: 'none' }}>
                Сумма
              </Text>
            </div>
            <div style={styles.detailTableBody}>
              <Text style={{ ...styles.col1, ...styles.bodyCell, textAlign: 'center' }}>1</Text>
              <Text style={{ ...styles.col2, ...styles.bodyCell }}>
                Оброблення данних, розміщення інформації
              </Text>
              <Text style={{ ...styles.col3, ...styles.bodyCell, textAlign: 'right' }}>1</Text>
              <Text style={{ ...styles.col4, ...styles.bodyCell }}>шт</Text>
              <Text style={{ ...styles.col5, ...styles.bodyCell, textAlign: 'right' }}>
                {formatedSum}
              </Text>
              <Text
                style={{
                  ...styles.col6,
                  ...styles.bodyCell,
                  borderRight: 'none',
                  textAlign: 'right'
                }}
              >
                {formatedSum}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              fontSize: '9px',
              fontWeight: '500',
              textAlign: 'right'
            }}
          >
            <Text style={{ width: '10%', padding: '4.5px' }}>Итого:</Text>
            <Text style={{ width: '15%', padding: '4.5px' }}>{formatedSum}</Text>
          </div>
          <div style={{ fontSize: '8px', fontWeight: '300', paddingLeft: '5px' }}>
            <Text>Всего наименований 1, на сумму {formatedSum} грн.</Text>
            <Text style={{ fontWeight: '500' }}>{convertedSum}</Text>
          </div>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontSize: '8px',
            fontWeight: '500',
            marginTop: '15px'
          }}
        >
          <Text style={{ width: '15%' }}>Виписал(а):</Text>
          <div style={{ borderBottom: '1px solid black', width: '30%', height: '15px' }}></div>
        </View>
      </Page>
    </Document>
  );
};

const InvoiceView = () => {
  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <InvoiceDocument />
      </PDFViewer>
    </div>
  );
};

export default InvoiceView;
