
import React from 'react';
import { Translate } from "react-redux-i18n";

const productHeaderData = (currencyText) => [
    {
        width: 120,
        flexGrow: 1.0,
        label: <Translate value="Products.ProductsImage" />,
        dataKey: 'image',
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: <Translate value="Products.ProductsName" />,
        dataKey: 'name',
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: <Translate value="Products.ProductsDescription" />,
        dataKey: 'description',
    },
    {
        width: 35,
        flexGrow: 1.0,
        label: <span><Translate value="Products.ProductsPrice"/>({currencyText})</span>,
        dataKey: 'price',
    },
    // {
    //     width: 120,
    //     flexGrow: 1.0,
    //     label: <Translate value="Products.PurchaseMethod" />,
    //     dataKey: 'purchase_method',
    // },
    // {
    //     width: 35,
    //     flexGrow: 1.0,
    //     label: <Translate value="Products.ProductsAvailable" />,
    //     dataKey: 'available',
    // },
    {
        width: 35,
        flexGrow: 1.0,
        label: <Translate value="Products.ProductsURL" />,
        dataKey: 'product_url',
    },
    {
        width: 120,
        flexGrow: 1.0,
        label: <Translate value="Products.ProductsActions" />,
        dataKey: 'action',
    }
]

export default productHeaderData;