import React, { useState } from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import { connect } from 'react-redux';
import { Typography, Paper, Button, Grid } from '@material-ui/core';
import { Translate, I18n } from 'react-redux-i18n';
import Layout from "../../components/Layout";
import { ProductCategoryPageContainer } from './style';
import CategoryTable from './Table';
import AddIcon from '../../components/Icons/AddIcon';
import { getCategoryList, createCategory, updateCategory, deleteCategory } from '../../redux/actions/categoryActions';
import { useEffect } from 'react';
import CategoryModal from './Modal';
import ConfirmationsModal from '../../components/ConfirmationsModal';

const ProductCategory = React.memo(props => {
  const initialModalState = {
    selectedCategory: {},
    isOpenCategoryModal: false,
    isEditCategory: false
  }
  const { loading, categoryList, getCategoryList, createCategory, updateCategory, deleteCategory, categoryPagination } = props;
  const { total, count, next, currentPage } = categoryPagination;
  const [modalState, setModalState] = useState({ ...initialModalState });
  const [categoryRemoved, setCategoryRemoved] = useState(null);

  const onDeleteProductCategory = (data) => {
    setCategoryRemoved(data);
  }

  const onEditProductCategory = (category) => {
    setModalState({
      selectedCategory: category,
      isEditCategory: true,
      isOpenCategoryModal: true
    })
  }

  const onCreateProductCategory = () => {
    setModalState({
      ...modalState,
      isOpenCategoryModal: true,
      selectedCategory: {},
      isEditCategory: false
    })
  }

  const handleCloseModal = () => {
    setModalState({ ...initialModalState });
  }

  const handleCreateModal = (data) => {
    
    handleCloseModal();
    const params = {
      page: currentPage
    }
    
    createCategory(data, params);
  }

  const handleEditModal = (id, data) => {
    handleCloseModal();
    const params = {
      page: currentPage
    }
    updateCategory(id, data, params);
  }

  const loadMoreData = () => {
    const params = {
      page: next
    }
    getCategoryList(params);
  }

  useEffect(() => {
    const params = {
      page: 1
    }
    getCategoryList(params);
  }, [])

  return (
    <Layout isLoading={loading}>
      <ProductCategoryPageContainer>
        <Typography className="title" variant="h4">
          <Translate value="ProductCategories.ProductCategoriesProductCategoryTitle" />
        </Typography>
        <Paper className="paper">
          <div className="action-group">
            <Button
              variant="contained"
              onClick={onCreateProductCategory}
              color="secondary"
              className="btn-control"
            >
              <AddIcon className="add-icon" />
              <Translate value="ProductCategories.ProductCategoriesButtonCreateNew" />
            </Button>
          </div>
          <CategoryTable
            rows={categoryList}
            onDeleteProductCategory={onDeleteProductCategory}
            onEditProductCategory={onEditProductCategory}
          />
          <Grid css={css`
              text-align: center;
              padding: 20px 0;
          `}>
            <Button
              css={css`
                  width: 156;
                  height: 36;
                  border-radius: 24px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #ffffff;
                  padding: 0;
                  background: #00a0c9;
                  padding: 5px 15px;
                  margin-bottom: 20px;
              `}
              variant="contained"
              color="primary"
              onClick={loadMoreData}
              disabled={loading || count === total}
            >
              {I18n.t("Products.ProductsLoadMore")}
            </Button>
            <div>{`${I18n.t("Products.ProductsLoaded")} ${count || 0} ${I18n.t("Products.ProductsOf")} ${total || 0}`}</div>
          </Grid>
        </Paper>
      </ProductCategoryPageContainer>
      <CategoryModal
        isOpen={modalState.isOpenCategoryModal}
        isEdit={modalState.isEditCategory}
        initialValues={modalState.selectedCategory}
        onCancel={handleCloseModal}
        onCreate={handleCreateModal}
        onEdit={handleEditModal}
      />
      <ConfirmationsModal
        open={!!categoryRemoved}
        handleClose={() => { setCategoryRemoved(null); }}
        handleConfirm={() => {
          setCategoryRemoved(null);
          const params = {
            page: currentPage
          }
          deleteCategory(categoryRemoved.id, params);
        }}
        confirmData={{
          message: I18n.t("ProductCategories.ProductCategoriesConfirmDeleteMessage"),
          acceptBtnLabel: I18n.t("ProductCategories.ProductCategoriesAcceptBtnLabel"),
          cancelBtnLabel: I18n.t("ProductCategories.ProductCategoriesCancelBtnLabel")
        }}
      />
    </Layout>
  )
});

const mapStateToProps = state => ({
  loading: state.app.loading,
  categoryList: state.category.categoryList,
  categoryPagination: state.category.pagination,
  locale: state.i18n.locale,
});

const mapDispatchToProps = {
  getCategoryList,
  createCategory,
  updateCategory,
  deleteCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);