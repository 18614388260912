import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: '60px'
  },
  header: {
    textAlign: 'center',
    width: '75%',
    margin: '20px auto'
  },
  infoSection: {
    border: '1px solid black',
    width: '100%',
    padding: '5px'
  },
  infoTitle: {
    fontSize: '9px',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '5px'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  detailSection: {
    marginTop: '25px',
    borderBottom: '1.5px solid black',
    paddingBottom: '10px'
  },
  detailTitle: {
    fontSize: '16px',
    padding: '3px 5px',
    borderBottom: '1.5px solid black',
    fontWeight: '500',
    marginBottom: '15px'
  },
  detailItem: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '8px',
    fontWeight: '300',
    marginBottom: '10px'
  },
  detailTable: {
    border: '1.5px solid black',
    fontSize: '8px',
    fontWeight: '300'
  },
  detailTableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    textAlign: 'center',
    borderBottom: '1.5px solid black',
    backgroundColor: '#FCFAEB'
  },
  detailTableBody: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerCell: {
    padding: '5px 0',
    borderRight: '1.5px solid black'
  },
  bodyCell: {
    padding: '3px',
    borderRight: '1.5px solid black'
  },
  col1: {
    width: '5%'
  },
  col2: {
    width: '45%'
  },
  col3: {
    width: '10%'
  },
  col4: { width: '10%' },
  col5: { width: '15%' },
  col6: { width: '15%' }
});
