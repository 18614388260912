import { Grid, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Translate } from "react-redux-i18n";
import Select from "react-select";
import { Field } from "redux-form";
import apiService from "../../../../api/apiService";
import { getNextDataPage } from "../../../../helpers/getNextPage";
import styles from "./style";
import { I18n } from 'react-redux-i18n';

const MenuList = ({ children, cx, isMulti, selectProps }) => {
  const menuRef = React.useRef();

  const loadMode = () => {
    if (menuRef.current.offsetHeight + menuRef.current.scrollTop >= menuRef.current.scrollHeight) {
      selectProps.handleLoadmore();
    }
  };

  React.useEffect(() => {
    const ref = menuRef.current;
    ref.addEventListener("scroll", loadMode);
    return () => {
      ref.removeEventListener("scroll", loadMode);
    };
  }, []);

  return (
    <div
      className={cx(
        {
          "menu-list": true,
          "menu-list--is-multi": isMulti,
        },
        selectProps.classes.menuList
      )}
      ref={menuRef}
      data-role="menuList"
    >
      {children}
    </div>
  );
};

const mapSelectList = (branchList) => {
  return branchList.map((branch) => ({
    value: {
      post_office_id: branch.id,
      address: `${branch.postcode}, ${branch.street_ua_vpz === null ? '' : branch.street_ua_vpz + ','} ${branch.postoffice_ua}`,
      postcode: branch.postcode,
      city: branch.city_ua,
      street: branch.street_ua_vpz,
      building: branch.street_ua_vpz,
      type: "BRANCH",
      flat_number: "",
    },
    label: `${branch.postcode}, ${branch.street_ua_vpz === null ? '' : branch.street_ua_vpz + ','} ${branch.postoffice_ua}`,
  }));
};

const BranchSearchSelect = ({ classes, labelMaxWidth, label, disabled, input, labelWrapWidth, border }) => {
  const typingTimeout = React.useRef(null);

  const [branchList, setBranchList] = React.useState([]);

  const [nextPage, setNextPage] = React.useState(undefined);

  const [searchTerm, setSearchTerm] = React.useState("");

  const [isLoadmore, setIsLoadmore] = React.useState(false);

  const handleInputChange = (value) => {
    const searchString = value;

    if (searchString.length < 2) return;

    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      setSearchTerm(searchString);
      apiService.searchDeliveryInfo(searchString).then((response) => {
        setNextPage(getNextDataPage(response.data.next));
        setBranchList(branchList.concat(mapSelectList(response.data.results)));
      });
    }, 500);
  };

  const handleLoadmore = () => {
    setIsLoadmore(true);
  };

  React.useEffect(() => {
    if (isLoadmore) {
      apiService.searchDeliveryInfo(searchTerm, nextPage).then((response) => {
        setNextPage(getNextDataPage(response.data.next));
        setBranchList(branchList.concat(mapSelectList(response.data.results)));
        setIsLoadmore(false);
      });
    }
  }, [isLoadmore]);

  return (
    <Grid container wrap='nowrap' alignItems='center' justify='space-between'>
      <Grid
        container
        wrap='nowrap'
        style={{ minWidth: labelWrapWidth, maxWidth: labelMaxWidth ? +labelMaxWidth : 68 }}
      >
        <Typography variant='body2'>{label}</Typography>
      </Grid>
      <Grid container>
        <Select
          className={border ? classes.selectBranchBorder : classes.selectBranch}
          classNamePrefix="select"
          isDisabled={disabled}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name="branch"
          options={branchList}
          value={input.value ? { value: input.value.value, label: input.value.label } : null}
          onInputChange={handleInputChange}
          handleLoadmore={handleLoadmore}
          onChange={input.onChange}
          classes={classes}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            MenuList,
          }}
          placeholder={I18n.t('Profile.ProfileDeliverySearchBranch')}
        />
      </Grid>
    </Grid>
  );
};

const BranchDeliveryInfo = ({ onEdit, classes, border = false }) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
      style={{ marginBottom: 8 }}
    >
      <Field
        label={<Translate value='Profile.ProfileDeliveryBranch' />}
        type='text'
        name='branchDeliveryInfo'
        maxLength='225'
        // labelMaxWidth='140px'
        labelWrapWidth='140px'
        component={BranchSearchSelect}
        disabled={!onEdit}
        classes={classes}
        inputProps={{
          disabled: classes.infoDisabled,
        }}
        border={border}
      />
    </Grid>
  );
};

export default withStyles(styles)(BranchDeliveryInfo);
