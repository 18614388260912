import App from './app';
import Profile from './profile';
import Login from './login';
import LeadsList from './leadslist';
import Campaigns from './campaigns';
import CampaignsCreation from './creation';
import Settings from './settings';
import Products from './product';
import ProductCategories from './productCategory';
import Shipment from './shipment';
import RequestDeleteAccount from './requestDeleteAccount';

const translationsObject = {
  en: {
    Profile: { ...Profile },
    Login: { ...Login },
    LeadsList: { ...LeadsList },
    Campaigns: { ...Campaigns },
    CampaignsCreation: { ...CampaignsCreation },
    App: { ...App },
    Settings: { ...Settings },
    Products: { ...Products },
    ProductCategories: { ...ProductCategories },
    Shipment: { ...Shipment },
    RequestDeleteAccount: { ...RequestDeleteAccount }
  }
};

export default translationsObject;
