import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import appReducer from "./appReducer";
import profileReducer from "./profileReducer";
import leadsListReducer from './leadsListReducer';
import campaignsReducer from "./campaignsReducer";
import tagsReducer from './tagsReducer';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import {GetlatLngReducer}from "./addressReducer"
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'react-redux-i18n';
import shipmentReducer from './shipmentReducer';

const indexReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  profile: profileReducer,
  leadsList: leadsListReducer,
  campaigns: campaignsReducer,
  errors: errorReducer,
  tags: tagsReducer,
  product: productReducer,
  category: categoryReducer,
  form: formReducer,
  i18n: i18nReducer,
  GetlatLngReducer,
  shipment: shipmentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  return indexReducer(state, action)
}


export default rootReducer;