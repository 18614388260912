import React from 'react';
import { Link } from '@material-ui/core';

function replaceURLs(message) {
  if (!message) return '';

  const wordArr = message.split(' ');

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  let resultArr = [];

  wordArr.forEach((e, i) => {
    if (e.match(urlRegex)) {
      var hyperlink = e;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      resultArr.push(
        <>
          <Link href={hyperlink} target='_blank' rel='noopener noreferrer'>
            {e}
          </Link>
          &nbsp;
        </>
      );
      return;
    }
    resultArr.push(<span>{e}&nbsp;</span>);
  });
  return resultArr;
}

export default replaceURLs;
