import {
  GET_LAT_LONG_SUCCESS,
  MOVE_LAT_LONG,
  SELECT_ADDRESS,
  SEARCH_ADDRESS_SUCCESS,
  GET_PLACES_ID_ADDRESS,
  DELETE_PLACE_DEFAULT,
  DELETE_PLACEID_CREATION
} from "./types";
import { appLoading, stopAppLoading } from "./appActions";
import apiService from "../../api/apiService";

export const saveAddressGoogle = (arr) => ({
  type: SEARCH_ADDRESS_SUCCESS,
  payload: arr,
});

export const saveLatLongAddress = (arr) => ({
  type : GET_PLACES_ID_ADDRESS,
  payload : arr,
})
export const Getlatlng = (value) => {
  return (dispatch) => {
    dispatch({
      type: GET_LAT_LONG_SUCCESS,
      payload: value,
    });
  };
};

export const MoveMaker = (value) => {
  return (dispatch) => {
    dispatch({
      type: MOVE_LAT_LONG,
      payload: value,
    });
  };
};

export const SeLectMaker = (address) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_ADDRESS,
      payload: address,
    });
  };
};
export const DelectePlaceIdDefault = (value) =>{
  return (dispatch) => {
    dispatch({
      type :DELETE_PLACE_DEFAULT,
      payload : value
    })
  }
}
export const DelectePlaceIdCreation = value =>{
  return (dispatch) => {
    dispatch({
      type :DELETE_PLACEID_CREATION,
      payload : value
    })
  }
}
export const SearchAddress = (value) => (dispatch) => {
  dispatch(appLoading());
  apiService
    .getAddressGoogleMap(value)
    .then((res) => {
      dispatch(saveAddressGoogle(res.data.addresses));
      dispatch(stopAppLoading());
    })
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};
export const getPlacesIdAddress = (places_id_) => (dispatch) => {
  dispatch(appLoading);
  apiService
    .getLatLongGoogleMap(places_id_)
    .then((response) => {
      dispatch(saveLatLongAddress(response.data.address_data));
      dispatch(stopAppLoading());
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(stopAppLoading());
    });
};
