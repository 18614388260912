import {
  GET_LAT_LONG_SUCCESS,
  MOVE_LAT_LONG,
  SELECT_ADDRESS,
  SEARCH_ADDRESS_SUCCESS,
  GET_PLACES_ID_ADDRESS,
  DELETE_PLACE_DEFAULT,
  DELETE_PLACEID_CREATION
} from "../actions/types";

const initialState = {
  Address: null,
  moveMakerAddress: null,
  selectAddress: "",
  searchAddress : [],
  placeId : null,
  deletePlaceID : null,
};

export const GetlatLngReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAT_LONG_SUCCESS: {
      if(action.payload === null){
        return {
          ...state,
         Address : null
        };
      }
      const getLat = String(action.payload.lat);
      const getLng = String(action.payload.long);
      const cutGetLat = getLat.split(".");
      const cutGetLng = getLng.split(".");
      const resultLat = cutGetLat[1] ? cutGetLat[1].substring(0, 6) : '';
      const resultLng = cutGetLng[1] ? cutGetLng[1].substring(0, 6) : '';
      const joinLat = cutGetLat[0] + "." + resultLat;
      const joinLng = cutGetLng[0] + "." + resultLng;
      return {
        ...state,
        Address: {
          address: action.payload.address,
          lat: joinLat,
          long: joinLng,
        },
      };
    }
    case MOVE_LAT_LONG: {
    
      return {
        ...state,
        moveMakerAddress: action.payload,
      };
    }
    case SELECT_ADDRESS: {
      return {
        ...state,
        selectAddress: action.payload,
      };
    }
    case SEARCH_ADDRESS_SUCCESS: {
      return {
        ...state, 
        searchAddress: action.payload
      }
    }
    case GET_PLACES_ID_ADDRESS : {
      return {
        ...state,
        placeId : action.payload

      }
    }
    case DELETE_PLACE_DEFAULT  : {
      return {
        ...state , 
        deletePlaceID : action.payload
      }
    }
    case DELETE_PLACEID_CREATION : {
      return {
        ...state , 
        placeId : action.payload
      }
    }
    default:
      return state;
  }
};
