import { loadTranslations, setLocale } from 'react-redux-i18n';
import translationsObject from '../../translations/index';
import apiService from '../../api/apiService';

export const setLocaleLanguage = (key) => dispatch => {
    apiService.getNewLanguage(key)
        .then(res => {
            const newLanguage = res.data;
            const newObj = {...translationsObject, [key]:{ ...newLanguage }};
            dispatch(loadTranslations(newObj));
            dispatch(setLocale(key)); 
        })
        .catch(err => {
            console.log(err);
        });
};