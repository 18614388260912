import React, { useState, useEffect } from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import { connect } from 'react-redux';
import { Typography, Paper, Button, Grid } from '@material-ui/core';
import { Translate, I18n } from 'react-redux-i18n';
import Layout from "../../components/Layout";
import { ProductPageContainer } from './style';
import ProductTable from './ProductTable';
import CustomInputRounded from '../../components/CustomInputRounded';
import AddIcon from '../../components/Icons/AddIcon';
import ProductModal from './ProductModal';
import ConfirmationsModal from '../../components/ConfirmationsModal';
import { getProductList, createProduct, updateProduct, deleteProduct } from '../../redux/actions/productActions'
import SelectInput from '../../components/SelectInput';
import _ from 'lodash';

const initialCategory = {
  key: null,
  display: <Translate value="ProductCategories.ProductCategoriesFilterAll" />
}
const Product = React.memo(props => {
    const { 
      loading, 
      productList, 
      productPagination,  
      getProductList, 
      createProduct, 
      updateProduct, 
      deleteProduct, 
      categoryList,
      supplier
    } = props;
    const { total, count, next } = productPagination;
    const initialModalState = {
        selectedProduct: {},
        isOpenProductModal: false,
        isEditProduct: false
    }
    const [searchState, setSearchState] = useState({
        searchInputValue: '',
        showMatch: false,
    })
    const [modalState, setModalState] = useState({ ...initialModalState });
    const [productRemoved, setProductRemoved] = useState(null);
    const [categorySelected, setCategorySelected] = useState(initialCategory);
    const currentPage = 1;

    useEffect(() => {
      const params = { page: 1, category: null };
      getProductList(params);
    }, []);

    const onKeyPress = () => {

    }

    const onChangeSearch = event => {
        setSearchState({
            ...searchState,
            searchInputValue: event.target.value
        });
    }

    const handleClearSearchInput = () => {
        setSearchState({
            ...searchState,
            searchInputValue: ""
        });
    }

    const handleCloseProductModal = () => {
        setModalState({ ...initialModalState });
    }

    const onCreateProduct = (data) => {
        handleCloseProductModal();
        const params = {
          category: categorySelected.key,
          page: currentPage
        }
        
        createProduct(data, params);
    }

    const onEditProduct = (id, data) => {
        handleCloseProductModal();
        const params = {
          category: categorySelected.key,
          page: currentPage
        }
        updateProduct(id, data, params);
    }

    const onDeleteProduct = data => setProductRemoved(data);
    
    const categoryDisplayMapping = (categories) => {
      let cloneData = _.clone(categories);
      cloneData = cloneData.map(v => ({key: v.id, display: v.name}))
      cloneData.unshift(initialCategory);
      return cloneData;
    }

    const onFilterByCategory = (category) => {
      setCategorySelected(category);
      const params = {
        category: category.key,
        page: 1
      }
      getProductList(params);
    }

    const loadMoreData = () => {
      const params = {
        category: categorySelected.key,
        page: next
      }

      getProductList(params);
    }

    return (
        <Layout isLoading={loading}>
            <ProductPageContainer>
                <Typography className="title" variant="h4">
                    <Translate value="Products.ProductsTitle" />
                </Typography>
                <Paper className="paper">
                    <div className="action-group">
                        <CustomInputRounded
                            name="productSearch"
                            type="search"
                            placeholder={I18n.t("LeadsList.LeadsListSearch")}
                            value={searchState.searchInputValue}
                            onKeyPress={onKeyPress}
                            onChange={onChangeSearch}
                            onClick={handleClearSearchInput}
                            showMatch={searchState.showMatch}
                            rows={productList}
                        />
                        <div>
                          <SelectInput
                            className="category-container"
                            label={<Translate value="ProductCategories.ProductCategoriesFilterLabel" />}
                            selectedValue={categorySelected}
                            isNormalSelection
                            options={categoryDisplayMapping(categoryList)}
                            onChangeSelect={onFilterByCategory}
                            classes={{
                              singleValue: "select-value"
                            }}
                          />
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalState({
                                    ...modalState,
                                    isOpenProductModal: true,
                                    selectedProduct: {},
                                    isEditProduct: false
                                })
                            }}
                            color="secondary"
                            className="btn-control"
                            disabled={false}
                        >
                            <AddIcon className="add-icon" />
                            <Translate value="Products.ProductsButtonCreateNew" />
                        </Button>
                    </div>

                    <ProductTable
                        rows={_.uniqBy(productList, v => v.id)}
                        onDeleteProduct={onDeleteProduct}
                        onEditProduct={product => {
                            setModalState({
                                selectedProduct: product,
                                isEditProduct: true,
                                isOpenProductModal: true
                            })
                        }}
                        supplier={supplier}
                    />
                    <Grid css={css`
                        text-align: center;
                        padding: 20px 0;
                    `}>
                      <Button
                        css={css`
                            width: 156;
                            height: 36;
                            border-radius: 24px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #ffffff;
                            padding: 0;
                            background: #00a0c9;
                            padding: 5px 15px;
                            margin-bottom: 20px;
                        `}
                        variant="contained"
                        color="primary"
                        onClick={loadMoreData}
                        disabled={loading || count === total}
                      >
                        {I18n.t("Products.ProductsLoadMore")}
                      </Button>
                      <div>{`${I18n.t("Products.ProductsLoaded")} ${count || 0} ${I18n.t("Products.ProductsOf")} ${total || 0}`}</div>
                    </Grid>
                </Paper>
            </ProductPageContainer>
            <ProductModal
                isOpen={modalState.isOpenProductModal}
                isEdit={modalState.isEditProduct}
                initialValues={modalState.selectedProduct}
                onCancel={handleCloseProductModal}
                onCreate={onCreateProduct}
                onEdit={onEditProduct}
            />

            <ConfirmationsModal
                open={!!productRemoved}
                handleClose={() => { setProductRemoved(null); }}
                handleConfirm={() => {
                    setProductRemoved(null);
                    const params = {
                      category: categorySelected.key,
                      page: currentPage
                    }
                    deleteProduct(productRemoved.id, params);
                }}
                confirmData={{
                    message: "Products.ProductsConfirmDeleteMessage",
                    acceptBtnLabel: "Products.ProductsAcceptBtnLabel",
                    cancelBtnLabel: "Products.ProductsCancelBtnLabel"
                }}
            />
        </Layout>
    )
});

const mapStateToProps = state => ({
    loading: state.app.loading,
    productList: state.product.productList,
    categoryList: state.category.categoryList,
    productPagination: state.product.pagination,
    locale: state.i18n.locale,
    supplier: state.profile.supplier,
});

const mapDispatchToProps = {
    getProductList,
    createProduct,
    updateProduct,
    deleteProduct,
};


export default connect(mapStateToProps, mapDispatchToProps)(Product);