const main = {
  ShipmentListTitle: 'My Shipments',
  ShipmentListSearch: 'Search',
  ShipmentListCreateFailed: "Creation failed. Please try again later.",
};

const table = {
  ShipmentListAddress: 'Where (address)',
  ShipmentListName: 'Who (Name, surname)',
  ShipmentListPhone: 'Phone number',
  ShipmentListPayment: 'Delivery payer',
  ShipmentListAssessedValue: 'Cost of the parcel',
  ShipmentListTrackingID: 'Delivery number'
}

const Shipment = {
  ...main,
  ...table
};

export default Shipment;
