import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 14,
  }
});

function DoneIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path fill="#C7C7C7" fillRule="evenodd" d="M.476 4.708a.762.762 0 0 1 1.096.018l3.635 3.88L12.81.74a.774.774 0 0 1 1.104-.01.8.8 0 0 1 .009 1.122l-7.99 8.234a1 1 0 0 1-1.444-.008L.462 5.836a.808.808 0 0 1 .014-1.128z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(DoneIcon);