const main = {
  LeadsListTitle: 'Leads List',
  LeadsListSearch: 'Search',
  LeadsListConfirm: 'Confirm',
  LeadsListConfirmed: 'Confirmed',
  LeadsListConfirmation: 'Confirmation',
  LeadsListNumberMatchedSearch: 'Matches found',
  LeadsListTooltipOfLeads: 'Number Of Suppliers that caught a Lead',
  LeadsListConfirmAllError: 'Supplier balance not enough.'
};

const button = {
  LeadsListButtonDownload: 'Download',
  LeadsListButtonConfirmAll: 'Confirm All:',
  LeadsListButtonConfirm: 'Confirm',
  LeadsListButtonConfirmed: 'Confirmed',
  LeadsListButtonSave: 'Save'
};

const table = {
  LeadsListTableDateTime: 'Date & Time',
  LeadsListTableCustomerName: 'Customer Name',
  LeadsListTableEmail: 'Email',
  LeadsListTablePhone: 'Phone',
  LeadsListTableCity: 'City',
  LeadsListTableCampaignName: 'Campaign Name',
  LeadsListTableTags: 'Tags',
  LeadsListTableLeadCaught: 'Leads',
  LeadsListTableLeadStatus: 'Lead Status',
  LeadsListTableInProgress: 'In Progress',
  LeadsListTableConfirmation: 'Confirmation',
  LeadsListTableCompleted: 'Completed',
  LeadsListTableFulfilledByAnother: 'Fulfilled by another Supplier'
};

const modal = {
  LeadsListModalTitle: 'Lead Info',
  LeadsListModalDate: 'Date',
  LeadsListModalTime: 'Time',
  LeadsListModalCustomerName: 'Customer Name',
  LeadsListModalEmail: 'Email',
  LeadsListModalPhone: 'Phone',
  LeadsListModalCity: 'City',
  LeadsListModalCampaignName: 'Campaign Name',
  LeadsListModalTags: 'Tags',
  LeadsListModalPhoto: 'Photo',
  LeadsListModalDescription: 'Description',
  LeadsListModalPrice: 'Price',
  LeadsListModalAddress: 'Address',
  LeadsListModalFeedback: 'Feedback',
  LeadsListModalNoFeedback: 'No feedback available',
  LeadsListModalAssessedValue: 'Assessed value',
  LeadsListModalFirstname: 'Firstname',
  LeadsListModalLastname: 'Lastname',
  LeadsListModalSurname: 'Surname',
  LeadsListModalMobileNumber: 'Mobile Number',
  LeadsListModalNote: 'Note',
  LeadsListCannotCreateShipment: "Can't create shipment"
};

const error = {
  LeadsListModalRequiredField: 'This field is required.',
  LeadsListModalErrorCode1915: "You've entered incorrect phone number.",
  LeadsListModalPhoneError: "Mobile number must be start with 380",
  LeadsListModalAtLeastRequired: "Ensure this field has at least 2 characters."
};

const LeadsList = {
  ...main,
  ...modal,
  ...table,
  ...button,
  ...error
};

export default LeadsList;
