import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  TableCell,
  TableSortLabel,
  Button,
  Grid,
  Chip,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AutoSizer, Column, Table } from "react-virtualized";
import TringleIcon from "../../../../components/Icons/TringleIcon";
import EditIcon from "../../../../components/Icons/EditIcon";
import moment from "moment-timezone";
import { Translate } from "react-redux-i18n";
import styles from "./styles";
import data, { finishedData } from "../../../../constants/campaignsTable";
import SortIcon from "../../../../components/Icons/SortIcon";
import ArrowUpIcon from "../../../../components/Icons/ArrowUpIcon";
import ArrowDownIcon from "../../../../components/Icons/ArrowDownIcon";

const RenderCellActions = ({
  rowData,
  classes,
  handleCampaignEdit,
  onClickButtonCampaign,
  handleCampaignDelete,
  supplierBalance,
}) => (
  <Grid container alignItems="center" wrap="nowrap">
    <Button
      variant="contained"
      color="primary"
      disabled={
        rowData.status === 1 ||
        !supplierBalance ||
        supplierBalance < rowData.price_per_lead
      }
      onClick={() => onClickButtonCampaign(rowData)}
      className={classNames(classes.rootBtn, {
        [classes.btnPaused]: rowData.status === 2,
        [classes.btnRestart]: rowData.status !== 2,
      })}
    >
      {rowData.status === 1 && (
        <Translate value="Campaigns.CampaignsButtonStart" />
      )}
      {rowData.status === 2 && (
        <Translate value="Campaigns.CampaignsButtonPause" />
      )}
      {rowData.status === 3 && (
        <Translate value="Campaigns.CampaignsButtonStart" />
      )}
      {rowData.status === 4 && (
        <Translate value="Campaigns.CampaignsButtonRestart" />
      )}
    </Button>
    <Grid>
      <IconButton
        classes={{
          root: classes.iconButton,
          disabled: classes.iconButtonDisabled,
        }}
        // disabled={rowData.status === 4 || rowData.status === 2}
        disabled={
          !supplierBalance || supplierBalance < rowData.price_per_lead
            ? null
            : rowData.status === 4 || rowData.status === 2
        }
        onClick={() => handleCampaignEdit(rowData)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        classes={{
          root: classes.iconButton,
          disabled: classes.iconButtonDisabled,
        }}
        disabled={rowData.status === 2 || rowData.leads_count > 0}
        onClick={() => handleCampaignDelete(rowData)}
      >
        <TringleIcon />
      </IconButton>
    </Grid>
  </Grid>
);
class MuiVirtualizedTable extends React.PureComponent {
  state = {
    column: "",
  };
  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;
    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };
  onButtonClick = (e, rowData) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("is_confirmed", true);
    this.props.patchLeadsConfirm(formData, rowData.id);
  };
  onColumnClick = (columnName) => {
    const { column } = this.state;
    const { sortColumn } = this.props;
    if (column === columnName) {
      switch (columnName) {
        case "end_date":
          sortColumn(`-end_date`);
          break;
        case "leads_count":
          sortColumn(`-lead_count`);
          break;
        case "tags":
          sortColumn(`-tag_count`);
          break;
        case "actions":
          sortColumn(`-status`);
          break;
        default:
          sortColumn(`-${columnName}`);
          break;
      }
      this.setState({
        column: `-${columnName}`,
      });
    } else {
      this.setState({
        column: columnName,
      });
      switch (columnName) {
        case "end_date":
          sortColumn(`end_date`);
          break;
        case "leads_count":
          sortColumn(`lead_count`);
          break;
        case "tags":
          sortColumn(`tag_count`);
          break;
        case "actions":
          sortColumn(`status`);
          break;
        default:
          sortColumn(`${columnName}`);
          break;
      }
    }
  };
  getFormattedDate = (cellData) => {
    const now = moment();
    const expiration = moment(cellData);
    // get the difference between the moments
    const diff = expiration.diff(now);
    //express as a duration
    const diffDuration = moment.duration(diff);

    let formated;
    let abbrMonth = diffDuration.months() > 1 ? "mth" : "mo";

    if (diffDuration.years() > 0) {
      formated = `${diffDuration.years()}y ${diffDuration.months()}${abbrMonth} ${diffDuration.days()}d ${diffDuration.hours()}h ${diffDuration.minutes()}m`;
    } else if (diffDuration.months() > 0) {
      formated = `${diffDuration.months()}${abbrMonth} ${diffDuration.days()}d ${diffDuration.hours()}h ${diffDuration.minutes()}m`;
    } else {
      formated = `${diffDuration.days()}d ${diffDuration.hours()}h ${diffDuration.minutes()}m`;
    }

    return formated;
  };

  tableCellInner = (columnIndex, rowData, cellData) => {
    const {
      classes,
      handleCampaignDelete,
      handleCampaignEdit,
      onClickButtonCampaign,
      supplierBalance,
      type
    } = this.props;

    if (type === 'finished' && columnIndex > 4) {
      columnIndex--
    }
    switch (columnIndex) {
      case 1: 
        return cellData ? <CheckCircleIcon color="secondary" /> : ''
      case 2:
        return <Chip label={cellData} className={classes.chip} />;
      case 6:
        return rowData.status === 4 ? (
          <span className={classes.finishedData}>{"0d 0h 0m"}</span>
        ) : (
          <span
            className={classNames({
              [classes.newDate]: rowData.status === 1,
              [classes.simpleDate]: rowData.status !== 1,
            })}
          >
            {rowData.status === 1
              ? this.getFormattedDate(rowData.start_date)
              : this.getFormattedDate(cellData)}
          </span>
        );
      case 7:
        if (cellData === 1)
          return (
            <span className={classes.newStatus}>
              <Translate value="Campaigns.CampaignsStatusNew" />
            </span>
          );
        if (cellData === 2)
          return (
            <span className={classes.inProgress}>
              <Translate value="Campaigns.CampaignsStatusInProgress" />
            </span>
          );
        if (cellData === 3)
          return (
            <span className={classes.paused}>
              <Translate value="Campaigns.CampaignsStatusPaused" />
            </span>
          );
        if (cellData === 4)
          return (
            <span className={classes.finishedStatus}>
              <Translate value="Campaigns.CampaignsStatusFinished" />
            </span>
          );
        break;
      case 8:
        return (
          <RenderCellActions
            rowData={rowData}
            classes={classes}
            handleCampaignEdit={handleCampaignEdit}
            onClickButtonCampaign={onClickButtonCampaign}
            handleCampaignDelete={handleCampaignDelete}
            supplierBalance={supplierBalance}
          />
        );
      default:
        return cellData;
    }
  };

  cellRenderer = ({ cellData, columnIndex = null, rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        onClick={(e) => e.stopPropagation()}
      >
        {this.tableCellInner(columnIndex, rowData, cellData)}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, classes } = this.props;
    let inner;
    if (columnIndex === 6) {
      inner = <TableSortLabel>{label}</TableSortLabel>;
    } else if (this.state.column === dataKey) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowUpIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else if (this.state.column === `-${dataKey}`) {
      inner = (
        <TableSortLabel
          IconComponent={ArrowDownIcon}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    } else {
      inner = (
        <TableSortLabel
          IconComponent={SortIcon}
          classes={{ icon: classes.icon }}
          active={true}
          onClick={() => this.onColumnClick(dataKey)}
        >
          {label}
        </TableSortLabel>
      );
    }
    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCellHeader,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight, paddingRight: 0 }}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            style={{ outline: "none" }}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                { cellContentRenderer = null, className, dataKey, ...other },
                index
              ) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = (cellRendererProps) =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 48,
  rowHeight: 48,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const LeadListTable = ({
  rows,
  handleCampaignDelete,
  handleCampaignEdit,
  onClickButtonCampaign,
  sortColumn,
  supplierBalance,
  currencyText,
  type
}) => (
  <Grid style={{ height: 300, width: "100%", padding: "0 24px" }}>
    <WrappedVirtualizedTable
      rowCount={rows.length}
      rowData={rows}
      handleCampaignDelete={handleCampaignDelete}
      handleCampaignEdit={handleCampaignEdit}
      onClickButtonCampaign={onClickButtonCampaign}
      rowGetter={({ index }) => rows[index]}
      columns={type === 'finished' ? finishedData(currencyText) : data(currencyText)}
      sortColumn={sortColumn}
      supplierBalance={supplierBalance}
      type={type}
    />
  </Grid>
);

export default LeadListTable;
