const settings = {
    SettingsTitle: "Change password",
    SettingsButtonChange: "Change",
    SettingsPassword: "Password",
    SettingsOldPassword: "Old Password",
    SettingsRepeatPassword: "Repeat Password"
}

const errors = {
    SettingsPasswordRequired: "Password is required",
    SettingsOldPasswordRequired: "Old Password is required",
    SettingsNewPasswordInvalid: "New password is invalid",
    SettingsPasswordMustIdentical: "Password field must be identical",
    SettingsPasswordChangeErrorCode1015: "Password must contain at least 12 characters and include a letter, digit and symbol.",
    SettingsPasswordChangeErrorCode1016: "Please enter the current password correctly",
    SettingsPasswordChangeErrorCode1929: "Password format is incorrect. Please, enter the password according to the requirements: Password must contain at least 1 letter.",
    SettingsPasswordChangeErrorCode1930: "Password format is incorrect. Please, enter the password according to the requirements: Password must contain at least 1 symbol: ()[]{}|`~!@#$%%^&*_-+=;:'\",<>./?"
}

const Settings = {
    ...settings,
    ...errors
}

export default Settings;