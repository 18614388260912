import React, { Component } from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import momentTimezone from "moment-timezone";
import MomentUtils from "@date-io/moment";
import "moment/locale/uk";

const styles = theme => ({
  datePickerRoot: {
    height: 32,
    maxWidth: "100%",
    borderRadius: 4,
    paddingRight: 0,
  }
});

class DatePickerInput extends Component {
  render() {
    const {
      label,
      locale,
      rows,
      type,
      name,
      disabled,
      error,
      classes,
      labelWrapWidth,
      ...input
    } = this.props;
    if (locale) {
      moment.locale(locale === 'uk' ? 'uk' : 'en');
    }
    return (
      <Grid container wrap="nowrap" alignItems="center" justify="space-between">
        {label && (
          <Grid container wrap="nowrap" style={{ minWidth: labelWrapWidth, maxWidth: 120 }}>
            <Typography variant="body2">{label}</Typography>
          </Grid>
        )}
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale === 'uk' ? 'uk' : 'en'}>
          <InlineDatePicker
            keyboard
            clearable
            style={{ width: "100%" }}
            mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
            variant="outlined"
            helperText={null}
            error={!!error}
            InputProps={{
              classes: {
                root: classes.datePickerRoot
              }
            }}
            format="DD.MM.YYYY"
            placeholder="dd.mm.yyyy"
            {...input}
            value={!!input.value ? locale === 'uk' ? momentTimezone(input.value)
            .tz("Europe/Kiev")
            .format() : momentTimezone(input.value)
            // .utc()
            .tz("America/New_York")
            .format() : null}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }
}

export default withStyles(styles)(DatePickerInput);
