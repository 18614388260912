import { GET_ERRORS, CLEAR_ERRORS } from "./types";

export const getErrors = err => ({
  type: GET_ERRORS,
  payload: err
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});
