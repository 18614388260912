import React, { Component } from "react";
import {Typography,withStyles} from '@material-ui/core'
import Layout from "../../components/Layout";
import { Translate } from "react-redux-i18n";

const styles = theme => ({
  container: {},
});

class Dashboard extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Layout>
            <Typography variant="h4"><Translate value="App.AppDashboardTitle" /></Typography>
        </Layout>
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);