const styles = theme => ({
    main: {
      height: "100%",
      width: "auto",
      display: "block",
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        minWidth: 500,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 240
    },
  
    errorwrap: {
      width: "100%",
      maxWidth: 300,
      bottom: 0,
      paddingLeft: 13,
      color: "#de2323",
      fontSize: 14,
      fontFamily: "Roboto"
    },
    buttonRoot: {
      height: 44,
      width: 314,
      borderRadius: 24,
      fontSize: 17,
      marginTop: 32,
      "& span": { color: "#ffffff" }
    }
  });

export default styles;