const main = {
  ProfileTitle: "Profile",
  ProfileSetPhoto: "Set the Photo",
  ProfileName: "Name",
  ProfilePhoto: "Photo",
  ProfileDragAndDrop: "Drag & Drop Photo here"
};
const info = {
  ProfileInfoTitle: "Contact info",
  ProfileInfoWebsite: "Website",
  ProfileInfoInstagram: "Instagram",
  ProfileInfoTelegram: "Telegram",
  ProfileInfoInstagramPlaceholder: "username (Ex: jesuscroisant)",
  ProfileInfoTelegramPlaceholder: "username (Ex: jesuscroisant)",
  ProfileInfoEmail: "Email",
  ProfileInfoPhone: "Phone",
  ProfileInfoAddress: "Address",
  ProfileInfoDescription: "Description",
  ProfileInfoAvailable: "Available",
  ProfileInfoPhoneFormatError:
    "Please use an international phone number format",
  ProfileDeliveryInfo: "Delivery Info",
  ProfileDeliveryPostalCode: "Postal code",
  ProfileDeliveryCity: "City",
  ProfileDeliveryStreet: "Street",
  ProfileDeliveryBuilding: "Building",
  ProfileDeliveryFlat: "Flat",
  ProfileDeliveryByUkrposhta: "Delivery by Ukrposhta",
  ProfileDeliveryPayment: "Payment for delivery services",
  ProfileDeliverySender: "Sender",
  ProfileDeliveryReceiver: "Receiver",
  ProfileDeliveryApproximateCost:
    "Price for delivery parcels with a weight up to 10 kg and the size up to 70 cm costs 35 uah",
  ProfileDeliveryInformation:
    "For cashless transaction, you need to sign an additional agreement with Ukrposhta",
  ProfileDeliveryAddress: "Address",
  ProfileDeliveryBranch: "Branch",
  ProfileDeliverySearchBranch: "Search",
  ProfileInfoDonation: 'Donation'
};
const buttons = {
  ProfileButtonCancel: "Cancel",
  ProfileButtonSave: "Save",
  ProfileButtonAdd: "Add",
  ProfileButtonDelete: "Delete",
  ProfileButtonEdit: "Edit",
  ProfileButtonAgreement: "Read agreement"
};
const errors = {
  ProfileErrorsName: "Invalid name",
  ProfileErrorsWebsite: "Website is required",
  ProfileErrorsAddress: "Address is required",
  ProfileErrorsAvailable: "Available is required",
  ProfileErrorsEmail: "Invalid email address",
  ProfileErrorsPhone: "Invalid phone number",
  ProfileErrorsDescription: "Invalid description",
  ProfileErrorsDeliveryPostcode: "Postcode is required",
  ProfileErrorsDeliveryPostcodeInvalid: "Invalid postcode",
  ProfileErrorsDeliveryCity: "City is required",
  ProfileErrorsDeliveryStreet: "Street is required",
  ProfileErrorsDeliveryBuilding: "Building is required",
  ProfileErrorsCreateUpdateDeliveryInfo: "Cannot register UKRPoshta address with your information!.",
  ProfileErrors2307: "First name, last name and phone numbers are required!."
};

const Profile = {
  ...main,
  ...info,
  ...buttons,
  ...errors
};
export default Profile;
