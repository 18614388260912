import { Button, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, SubmissionError, getFormValues, reduxForm } from 'redux-form';
import CustomInputRounded from '../../components/CustomInputRounded';
import { clearErrors } from '../../redux/actions/errorsActions';
import { requestDeleteAccount } from '../../redux/actions/requestDeleteActions';

import { I18n, Translate, loadTranslations, setLocale } from 'react-redux-i18n';
import store from '../../redux/store';
import translationsObject from '../../translations/index';
import styles from './styles';
import step_2 from '../../assets/img/stepsToDeleteAccount/step_2.jpeg';
import step_3 from '../../assets/img/stepsToDeleteAccount/step_3.jpg';
import step_4 from '../../assets/img/stepsToDeleteAccount/step_4.jpg';
import logoSvg from '../../assets/img/logo.svg';

const renderTextField = ({ input, serverErrors, type, placeholder, meta: { error } }) => (
  <CustomInputRounded
    error={!!error || !!serverErrors.length}
    type={type}
    placeholder={placeholder}
    {...input}
  />
);

const renderCheckbox = ({ input, label, disabled, styles: classes }) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      classes={{
        root: classes.formControlLabelRoot,
        label: classes.formControlLabelLabel
      }}
      labelPlacement='end'
      control={
        <Checkbox
          checked={input.checked}
          color='default'
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked
          }}
          onChange={input.onChange}
        />
      }
    />
  );
};

const toLowerCase = (value) => value.toLowerCase();

class RequestDeleteAccount extends Component {
  state = {
    submitDisabled: true
  };

  onSubmit = (values) => {
    if (values.email) {
      values.email = values.email.trim();
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(values.email)) {
        throw new SubmissionError({
          email: I18n.t('Login.LoginInvalidAddress'),
          _error: I18n.t('Login.LoginInvalidAddress')
        });
      }
    }

    const { agree, ...rest } = values;

    this.props.requestDeleteAccount(rest);
  };

  componentDidMount() {
    store.dispatch(loadTranslations(translationsObject));
    store.dispatch(setLocale('en'));
  }

  clearActionsErrors = (event, value) => {
    const { error, serverErrors } = this.props;
    if (!!error || !!serverErrors) {
      this.props.clearSubmitErrors();
      this.props.clearErrors();
    }

    let isHasValue = false;
    if (event.target.name === 'email') {
      if (
        value ||
        (this.props.formValues && this.props.formValues.phone && this.props.formValues.phone !== '')
      ) {
        isHasValue = true;
      }
    } else if (event.target.name === 'phone') {
      if (
        value ||
        (this.props.formValues && this.props.formValues.email && this.props.formValues.email !== '')
      ) {
        isHasValue = true;
      }
    }

    let isAgree = false;
    if (this.props.formValues && this.props.formValues.agree) {
      isAgree = true;
    }

    this.setState({
      ...this.state,
      submitDisabled: !(isAgree && isHasValue)
    });
  };

  handleAgree = (event, value) => {
    let isHasValue = false;
    if (
      (this.props.formValues && this.props.formValues.email) ||
      (this.props.formValues && this.props.formValues.phone)
    ) {
      isHasValue = true;
    }

    this.setState({
      ...this.state,
      submitDisabled: !(value && isHasValue)
    });
  };

  render() {
    const { classes, handleSubmit, pristine, error, serverErrors } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.item}>
          <div className={classes.header}>
            <div className={classes.headerWrapper}>
              <a href='https://payris.app/' target='_blank' rel='noopener noreferrer'>
                <img className={classes.headerLogo} src={logoSvg} alt='Payris_logo' />
              </a>
            </div>
          </div>
          <h1 className={classes.title}>
            To delete your account manually, you need to follow these steps:
          </h1>
          <div className={classes.step}>
            <li className={classes.stepTitle}>Step 1: Open the app and log in to your account</li>
          </div>
          <div className={classes.step}>
            <li className={classes.stepTitle}>
              Step 2: Select user icon in the upper right corner of the screen
            </li>
            <div className={classes.stepImgWrapper}>
              <img src={step_2} alt='step_2' className={classes.stepImg} />
            </div>
          </div>
          <div className={classes.step}>
            <li className={classes.stepTitle}>
              Step 3: Scroll to the bottom and select Delete Account (trash can icon)
            </li>
            <div className={classes.stepImgWrapper}>
              <img src={step_3} alt='step_3' className={classes.stepImg} />
            </div>
          </div>
          <div className={classes.step}>
            <li className={classes.stepTitle}>Step 4: Click on Delete button</li>
            <div className={classes.stepImgWrapper}>
              <img src={step_4} alt='step_4' className={classes.stepImg} />
            </div>
          </div>

          <div style={{ height: '48px' }} />

          <h1 className={classes.title}>
            <Translate value='RequestDeleteAccount.RequestDeleteAccountTitle' />
          </h1>
          <p className={classes.subTitle}>
            Please enter your email address and phone number associated with your account. This will
            enable us to verify your identity and proceed with the deletion promptly.
          </p>
          <form onSubmit={handleSubmit(this.onSubmit)} className={classes.formContainer}>
            <div className={classes.formWrap}>
              <div className={classes.error}>
                <span>{error && error}</span>
                {serverErrors &&
                  serverErrors.toString().includes('User with phone/email does not exist') && (
                    <span>
                      {I18n.t(
                        'RequestDeleteAccount.RequestDeleteAccountUserWithPhoneEmailDoesNotExist'
                      )}
                    </span>
                  )}
              </div>
              <Field
                type='text'
                name='email'
                placeholder={I18n.t('RequestDeleteAccount.RequestDeleteAccountEmail')}
                component={renderTextField}
                serverErrors={serverErrors}
                onChange={this.clearActionsErrors}
                normalize={toLowerCase}
              />
              <Field
                type='text'
                name='phone'
                placeholder={I18n.t('RequestDeleteAccount.RequestDeleteAccountPhone')}
                component={renderTextField}
                serverErrors={serverErrors}
                onChange={this.clearActionsErrors}
                normalize={(value, previousValue) => {
                  const lastSymbol = value.slice(-1);
                  const firstSymbol = value.slice(0, 1);
                  if (value.length > 16) return previousValue;
                  if (firstSymbol !== '+' && firstSymbol !== '') {
                    return `+${firstSymbol.replace(/[^\d]/g, '')}`;
                  }

                  if (previousValue && value.length < previousValue.length) {
                    return value;
                  } else {
                    if (value.length > 1 && firstSymbol === '+') {
                      return value.slice(0, value.length - 1) + lastSymbol.replace(/[^\d]/g, '');
                    } else if (value === '+') {
                      return value;
                    }
                  }
                }}
              />
              <Field
                type='checkbox'
                name='agree'
                component={renderCheckbox}
                styles={classes}
                defaultValue='false'
                label={I18n.t('RequestDeleteAccount.RequestDeleteAccountCheckbox')}
                onChange={this.handleAgree}
              />
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                classes={{ root: classes.buttonRoot }}
                disabled={pristine || !!error || !!serverErrors.length || this.state.submitDisabled}
              >
                <Translate value='RequestDeleteAccount.RequestDeleteAccountSubmitButton' />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serverErrors: state.errors,
  formValues: getFormValues('RequestDeleteAccount')(state),
  locale: state.i18n.locale
});

RequestDeleteAccount = connect(mapStateToProps, { requestDeleteAccount, clearErrors })(
  withStyles(styles)(RequestDeleteAccount)
);

export default reduxForm({
  form: 'RequestDeleteAccount'
})(RequestDeleteAccount);
