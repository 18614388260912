import React from "react";
import {
  withStyles,
  Grid,
  Chip,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Translate } from "react-redux-i18n";
import ArrowIcon from "../../../../components/Icons/ArrowIcon";
import EmptyCheckbox from "../../../../components/Icons/EmptyCheckbox";
import CheckedCheckbox from "../../../../components/Icons/CheckedCheckbox";
import compareArrays from "../../../../utils/compareArrays";
import styles from "./styles";

const WhichTagsInner = ({
  classes,
  whichTagsOptions,
  addedTags,
  handleChangeCheckBox,
  handleAddTags,
  onDragStart
}) => {
  return (
    <Grid
      container
      justify="flex-start"
      wrap="nowrap"
      direction="column"
      className={classes.container}
    >
      <Grid item className={classes.addButtonContainer}>
        <Grid container justify="flex-end">
          <Button
            variant="outlined"
            onClick={() => handleAddTags("which_tags")}
            className={classes.buttonAdd}
          >
            <Translate value="CampaignsCreation.CampaignsCreationButtonAdd" />
            <ArrowIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.tagWrap}>
        {compareArrays(whichTagsOptions, addedTags).map((value, index) => (
          <Chip
            label={value.display_name}
            draggable
            onDragStart={(e)=>onDragStart(e, value.id)}
            key={value.id}
            avatar={
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<EmptyCheckbox />}
                      checkedIcon={<CheckedCheckbox />}
                    />
                  }
                  onChange={handleChangeCheckBox(value)}
                />
              </>
            }
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(WhichTagsInner);
