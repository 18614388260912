import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: 79,
    height: 82,
  }
});

function ShowPasswordIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 79 82">
        <g fill="#FF3D33" fillRule="nonzero">
            <path d="M76.086 36.367c-14.562-6.214-26.14-18.193-32.175-33.29C43.18 1.218 41.436 0 39.501 0c-1.937 0-3.68 1.217-4.412 3.078-6.022 15.1-17.598 27.083-32.162 33.289-3.898 1.66-3.907 7.46 0 9.133 14.552 6.278 26.108 18.298 32.126 33.418.73 1.864 2.475 3.082 4.412 3.082 1.938 0 3.682-1.218 4.413-3.082 6.018-15.12 17.578-27.139 32.135-33.408 3.937-1.689 4.004-7.464.073-9.143zM63.457 43.35c-4.136 3.643-13.26 10.43-23.855 10.43-10.593 0-19.743-6.806-23.87-10.445a2.983 2.983 0 0 1-.993-2.235c0-.86.363-1.677.994-2.235 4.126-3.64 13.264-10.442 23.87-10.442 10.605 0 19.718 6.783 23.854 10.426a2.996 2.996 0 0 1 1 2.253 2.995 2.995 0 0 1-1.01 2.248h.01z"/>
            <ellipse cx="39.696" cy="41.101" rx="12.124" ry="12.686"/>
        </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(ShowPasswordIcon);