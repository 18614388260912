
const styles = theme => ({
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        minWidth: 60
    },
    tableRow: {
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eaeff5'
        }
    },
    tableCell: {
        flex: 1,
        flexWrap: 'nowrap',
        '& span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    },
    tableCellHeader: {
        color: '#9fb0cb',
        flex: 1,
    },
    noClick: {
        cursor: 'pointer',
        outline: 'none',
    },
    rootBtn: {
        width: 105,
        height: 24,
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 500,
        color: '#ffffff',
        padding: 0,
        background: "#00a0c9"
    },
    chip: {
        minWidth: 24,
        height: 24,
        backgroundColor: '#9fb0cb',
        fontSize: 14,
        color: '#ffffff',
        '& span': {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    inProgress: {
        fontFamily: 'Roboto',
        fontSize: 14,
        color: '#e19b45',
        fontWeight: 500
    },
    completed: {
        fontFamily: 'Roboto',
        fontSize: 14,
        color: '#00a0c9',
        fontWeight: 500
    },
    lineClamp: {
        flex: 1,
        overflowWrap: 'break-word',
        '& span' : {
            display: '-webkit-box',
            '-webkit-line-clamp': 5,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden'
        }
    }
});

export default styles;