import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 14
  }
});

function ArrowIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <g
        id="Progress"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="5.3-Campaigns---Create-Campaign---Add-Tags-V2"
          transform="translate(-670.000000, -520.000000)"
          fill="#007FFF"
        >
          <g id="Popup" transform="translate(390.000000, 263.000000)">
            <g id="Settings" transform="translate(24.000000, 71.000000)">
              <g id="Clear-btn" transform="translate(184.000000, 176.000000)">
                <g
                  id="icon-/-18px-/-back"
                  transform="translate(79.000000, 16.000000) scale(-1, 1) translate(-79.000000, -16.000000) translate(70.000000, 7.000000)"
                >
                  <path
                    d="M4.14726614,9.7875 L7.99350585,13.4277019 C8.30081393,13.7185479 8.31415908,14.2034476 8.02331308,14.5107556 C8.01848303,14.5158591 8.013583,14.5208958 8.00861438,14.5258644 C7.69906234,14.8354163 7.19894986,14.8410659 6.88248392,14.5385859 L1.84413231,9.72290033 C1.44488548,9.34129791 1.43058186,8.70829451 1.81218429,8.30904768 C1.82259167,8.2981591 1.83324372,8.28750704 1.84413231,8.27709967 L6.88248392,3.46141407 C7.19894986,3.15893409 7.69906234,3.16458368 8.00861438,3.47413556 C8.30780474,3.77332577 8.30780486,4.25840907 8.00861465,4.55759943 C8.00364603,4.56256805 7.99860929,4.56746808 7.99350585,4.57229813 L4.14726614,8.2125 L14.9625,8.2125 C15.3974242,8.2125 15.75,8.56507576 15.75,9 C15.75,9.43492424 15.3974242,9.7875 14.9625,9.7875 L4.14726614,9.7875 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(ArrowIcon);
