import { SET_PROFILE_INFO } from "./types";
import { appLoading, openPopupSystem, stopAppLoading } from "./appActions";
import apiService from "../../api/apiService";

export const saveProfileInfo = obj => ({
  type: SET_PROFILE_INFO,
  payload: obj
});

export const getProfileInfo = () => dispatch => {
  dispatch(appLoading());
  apiService
    .getProfile()
    .then(res => {
      dispatch(saveProfileInfo(res.data));
    })
    .then(() => dispatch(stopAppLoading()))
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const updateProfileInfo = data => (dispatch, getState) => {
  const {
    profile: { email }
  } = getState();
  const putData = { ...{ email }, ...data };
  dispatch(appLoading());
  apiService
    .updateProfile(putData)
    .then(res => {
      dispatch(saveProfileInfo(res.data));
    })
    .then(() => dispatch(stopAppLoading()))
    .catch(err => {
      dispatch(stopAppLoading());
      if (err && err.response && err.response.data) {
        dispatch(
          openPopupSystem(
            { 
              message: err.response.data.message,
              status: "fail",
              detail: err.response.data.error_detail
            }
          )
        )
      }
    });
};

export const updateProfileImages = putData => dispatch => {
  dispatch(appLoading());
  apiService
    .updateProfile(putData)
    .then(res => {
      dispatch(saveProfileInfo(res.data));
    })
    .then(() => dispatch(stopAppLoading()))
    .catch(err => {
      dispatch(stopAppLoading());
      if (err && err.response && err.response.data) {
        dispatch(
          openPopupSystem(
            { 
              message: err.response.data.message,
              status: "fail",
              detail: err.response.data.error_detail
            }
          )
        )
      }
    });
};
