import { SET_BRANCH_DELIVERY_INFO, SET_DELIVERY_INFO, SET_PROFILE_INFO } from '../actions/types';

const initialState = {
  username: '',
  displayName: '',
  email: '',
  phone: '',
  customer: null,
  address: '',
  website: '',
  description: '',
  photo: null,
  avatar: {},
  deliveryInfo: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_INFO: {
      return {
        ...state,
        username: action.payload.username,
        displayName: action.payload.display_name,
        email: action.payload.supplier.email,
        phone: action.payload.phone,
        customer: action.payload.customer,
        website: action.payload.supplier.website,
        instagram: (action.payload.instagram || '')
          .replaceAll(process.env.REACT_APP_INSTAGRAM_URL, '')
          .replaceAll('/', ''),
        telegram: (action.payload.telegram || '')
          .replaceAll(process.env.REACT_APP_TELEGRAM_URL, '')
          .replaceAll('/', ''),
        address: action.payload.supplier.address,
        description: action.payload.supplier.description,
        photo: action.payload.supplier.photo,
        available: action.payload.supplier.available,
        agreementRead: action.payload.supplier.read_agreement,
        avatar: { ...action.payload.avatar },
        supplier: { ...action.payload.supplier },
        viber: action.payload.viber,
        whatsapp: action.payload.whatsapp
      };
    }
    case SET_DELIVERY_INFO: {
      return {
        ...state,
        deliveryInfo: action.payload
      };
    }
    case SET_BRANCH_DELIVERY_INFO: {
      const { is_active, recipient_pay, ...values } = action.payload;
      const branchDeliveryInfo = {
        label: action.payload.address,
        value: { ...values }
      };
      return {
        ...state,
        branchDeliveryInfo: { ...branchDeliveryInfo },
        deliveryInfo: action.payload
      };
    }
    default:
      return state;
  }
}
