
const deleteConfirm = {
    ProductsConfirmDeleteMessage: "Are you sure you want to remove the product?",
    ProductsAcceptBtnLabel: "Yes",
    ProductsCancelBtnLabel: "No"
}

const modal = {
    ProductsCreateModalTitle: 'Create new product',
    ProductsUpdateModalTitle: 'Update product info',
    ProductsModalCancelBtn: 'Cancel',
    ProductsModalCreateBtn: 'Create',
    ProductsModalUpdateBtn: 'Update',
    ProductsName: "Product name",
    ProductsDescription: "Description",
    ProductsPrice: "Price",
    ProductsUrl: "Product Url",
}
const main = {
    ProductsTitle: 'Product List',
    ProductsLoadMore: "Load more",
    ProductsLoaded: "Loaded",
    ProductsOf: "of",
    ProductsNoAvailableItem: "No available items",
    ProductsProductPickListNoSelectedItem: "No item selected",
    ProductsTagsItem1: "At least one geo tag",
    ProductsTagsItem2: "should be added",
    ProductsTagsItemAddress: "Address",
    ProductsProductItemTitle: "Products",
    ProductsProductModalDropdown: "Select a category",
    ProductsProductModalCategoryLabel: "Category",
    ProductsProductModalNameLabel: "Name",
    ProductsProductModalDescriptionLabel: "Description",
    ProductsProductModalPriceLabel: "Price",
    ProductsProductModalUrlLabel: "URL",
    ProductsProductModalSelectImageButton: "Select an image",
    ProductsProductModalLimitImage: "Max file size: 5MB, accepted: jpg|jpeg|png",
    ProductsButtonCreateNew: "Create new Product",
    ProductsProduct: "Product",
    ProductsCreateSuccess: "Created Successfully",
    ProductsCreateFailed: "Created Failed",
    ProductsUpdateSuccess: "Updated Successfully",
    ProductsUpdateFailed: "Updated Failed",
    ProductsDeleteSuccess: "Deleted Successfully",
    ProductsDeleteFailed: "Deleted Failed",
}

const table = {
    ProductsName: 'Name',
    ProductsDescription: 'Description',
    ProductsImage: 'Image',
    ProductsPrice: 'Price',
    ProductsAvailable: 'Available',
    ProductsURL: 'URL',
    // PurchaseMethod: 'Purchase Method',
    ProductsActions: "Actions"
}

const campaign = {
    ProductsCampaignWrapperAddButton: "Add",
    ProductsCampaignModalTitle: "Manage products",
    ProductsCampaignModalSaveBtn: "Save"
}

const Products = {
    ...main,
    ...table,
    ...modal,
    ...deleteConfirm,
    ...campaign
}

export default Products