import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
  }
});

function HomeActiveIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
        <circle cx="20" cy="20" r="20" fill="#6144b9"/>
        <path fill="#FFF" fillRule="nonzero" d="M17.6 22.4v-8.872a7.6 7.6 0 1 0 8.872 8.872H17.6zM19 30a9 9 0 0 1-1-17.945c.328-.036 1-.036 1 .964V21h7.999c1.072 0 .982.676.945 1.006A9.001 9.001 0 0 1 19 30zm9.473-12.398A7.609 7.609 0 0 0 22.4 11.53l.001 6.072h6.072zm-6.472 1.4a1 1 0 0 1-1-1l-.001-7c0-.945.675-.981 1.003-.945a9.004 9.004 0 0 1 7.946 7.968c.035.32 0 .977-.947.977h-7z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(HomeActiveIcon);
