import React from "react";
import {
  withStyles,
  Dialog,
  Grid,
  Button,
  Typography,
  Chip,
} from "@material-ui/core";

import CloseIcon from "../../../../components/Icons/CloseIcon";
import ClearAllIcon from "../../../../components/Icons/ClearAllIcon";
import DoneIcon from "../../../../components/Icons/DoneIcon";
import ArrowIcon from "../../../../components/Icons/ArrowIcon";
import TagLogo from "../../../../components/Icons/TagLogo";
// import InputLogo from "../../../../components/Icons/InputLogo";
import _ from 'lodash';
import NumberFormat from 'react-number-format';

import styles from "./styles";
import { Translate } from "react-redux-i18n";

const calcTotalPointsOfTags = (tags) => {
  if (!tags || !tags.length) return 0;
  let totalPoints = 0;
  _.forEach(tags, t => {
    totalPoints += t.reward;
  });
  return totalPoints;
}

const ModalCreateCampaign = ({
  classes,
  modalName,
  handleAddTags,
  cancelEdit,
  onSubmitEdit,
  open,
  addedTags,
  children,
  handleDeleteTagModal,
  handleDeleteAllTags,
  handleCloseModal,
  useButtonAdd,
  onDrop,
  useTagsAddedBox = true,
  supplier,
  currencyList,
  locale,
  qualifiedLeadCommission,
  qualifiedLeadPoints,
}) => {
  const currency = (supplier && supplier.country === 'UKR') ? currencyList[1].currency : currencyList ? currencyList[0].currency : 'USD'
  const currencyCourse = (supplier && supplier.country === 'UKR') ? currencyList[1].course : currencyList ? currencyList[0].course : 0.01
  const totalReward = calcTotalPointsOfTags(addedTags);
  let totalTagsCost = 0;
  if (qualifiedLeadPoints && qualifiedLeadCommission) {
    const sumTotalTagsAndPayris = totalReward + qualifiedLeadPoints;
    const qualifiedLeadCommissionToPoint = Math.ceil(sumTotalTagsAndPayris * qualifiedLeadCommission);
    totalTagsCost = totalReward ? sumTotalTagsAndPayris + qualifiedLeadCommissionToPoint : 0;
  }
  return (
    <Dialog onClose={handleCloseModal} open={open}>
      <Grid
        container
        className={useTagsAddedBox ? classes.container : classes.containerTags}
      >
        <Typography variant="h6" className={classes.title}>
          {modalName}
        </Typography>
        <Grid container wrap="nowrap">
          <Grid
            item
            className={
              useTagsAddedBox
                ? classes.leftContainer
                : `${classes.leftContainer} ${classes.fullWidth}`
            }
          >
            {children}
            {useButtonAdd && (
              <Grid
                container
                justify="flex-end"
                className={classes.addButtonWrap}
              >
                <Button
                  variant="outlined"
                  onClick={handleAddTags}
                  className={classes.cancelBtn}
                >
                  <Translate value="CampaignsCreation.CampaignsCreationButtonAdd" />
                  <ArrowIcon className={classes.addIcon} />
                </Button>
              </Grid>
            )}
          </Grid>
          {useTagsAddedBox && (
            <>
              <Grid item className={classes.rightContainer}>
                <CloseIcon
                  onClick={handleCloseModal}
                  className={classes.closeModalIcon}
                />
                <Grid
                  className={classes.wrapRight}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => onDrop(e)}
                >
                  <Grid className={classes.clearAllWrap}>
                    <Button
                      className={classes.clearAllBtn}
                      onClick={(event) => handleDeleteAllTags("addedTags")}
                    >
                      <ClearAllIcon />
                      <Translate value="CampaignsCreation.CampaignsCreationButtonClearAll" />
                    </Button>
                  </Grid>
                  <Grid className={classes.tagsWrap}>
                    {(!useButtonAdd && !addedTags.length) && <div className={classes.dragDropText}>Drag & Drop<br/> or use Add</div>}
                    {addedTags.map(item => (
                      <Chip
                        label={<span>{item.path} <TagLogo /> {item.reward ? item.reward : ''}</span>}
                        key={item.id}
                        deleteIcon={<CloseIcon />}
                        onDelete={event => handleDeleteTagModal(event, item.id)}
                      />
                    ))}
                    
                   
                  </Grid>
                  <Grid className={classes.totalPointsWrap}>
                    <div className={classes.descriptionTotal}><Translate value="CampaignsCreation.CampaignsCreationDescriptionTotalPointTags" /></div>
                    <label className={classes.totalLabel}><Translate value="CampaignsCreation.CampaignsCreationTotalPointTags" />: </label><span>{currency === 'UAH' ? <NumberFormat value={Math.ceil(currencyCourse * totalTagsCost)} displayType={'text'} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} decimalScale={0} /> : <NumberFormat value={Math.ceil(currencyCourse * totalTagsCost)} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} />} {currency}</span>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          container
          justify="flex-end"
          className={classes.actionButtonsWrap}
        >
          <Button
            variant="outlined"
            onClick={cancelEdit}
            className={classes.cancelBtn}
          >
            <CloseIcon className={classes.extendedIcon} />
            <Translate value="CampaignsCreation.CampaignsCreationButtonCancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitEdit}
            className={classes.saveBtn}
          >
            <DoneIcon className={classes.extendedIcon} />
            <Translate value="CampaignsCreation.CampaignsCreationButtonSave" />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(ModalCreateCampaign);
