const main = {
  CampaignsTitle: "Campaigns",
  CampaignsActive: "Active",
  CampaignsFinished: "Finished",
  CampaignsTypeAddress: "Type Address",
  CampaignsTopUpTitle: "Top Up",
  CampaignsTopUpAmount: "Amount",
  CampaignsTopUpErrorAmountIsRequired: "Amount is required",
  CampaignsTopUpErrorNoWidgetUrl: "No widget url",
  CampaignsTopUpErrorOutOfRangValue: "Min 1 UAH Max 5000 UAH",
  CampaignsInvoiceName: "Name",
  CampaignsInvoiceSum: "Sum",
  CampaignsInvoiceTitle: "Print Invoice"
};
const table = {
  CampaignsTableName: "Name",
  CampaignsTableTags: "Tags",
  CampaignsTablePrice: "Cost",
  CampaignsTableLeads: "Leads",
  CampaignsTableTotalCost: "Total cost",
  CampaignsTableRemaining: "Remaining",
  CampaignsTableStatus: "Status",
  CampaignsTableActions: "Actions",
  CampaignsTableConfirmDeleteMessage: "Are you sure you want to remove the campaign?",
  CampaignsTableModalYes: "Yes",
  CampaignsTableModalNo: "No",
  CampaignsTableBonusPrice: "Bonus Cost",
  CampaignsTableWorldwide: 'Worldwide'
};
const button = {
  CampaignsButtonCreateNew: "Create new Campaign",
  CampaignsButtonTopUp: "Top up balance",
  CampaignsButtonStart: "Start",
  CampaignsButtonPause: "Pause",
  CampaignsButtonRestart: "Restart",
  CampaignsTotalBalance: "Total balance",
  CampaignsTopUpBtnCancel: "Cancel",
  CampaignsTopUpBtnSubmit: "Submit",
  CampaignsInvoiceCancel: "Cancel",
  CampaignsInvoiceSubmit: "Submit"
};
const status = {
  CampaignsStatusFinished: "Finished",
  CampaignsStatusInProgress: "In Progress",
  CampaignsStatusPaused: "Paused",
  CampaignsStatusNew: "New",
  CampaignsLastUpdate: "Last Update"
};

const Campaigns = {
  ...main,
  ...table,
  ...button,
  ...status
};

export default Campaigns;
