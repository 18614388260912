import { I18n } from 'react-redux-i18n';
import isEmpty from './isEmpty';

const validateLeadInfo = (values) => {
  const errors = {
    deliveryInfo: {}
  };
  const { deliveryInfo } = values;

  if (values.phone && values.phone.length > 0) {
    if (values.phone && !values.phone.startsWith('+380')) {
      errors.phone = I18n.t('LeadsList.LeadsListModalPhoneError');
    }
  }

  if (isEmpty(values.phone)) {
    errors.phone = I18n.t('LeadsList.LeadsListModalRequiredField');
  }
  if (isEmpty(values.first_name)) {
    errors.first_name = I18n.t('LeadsList.LeadsListModalRequiredField');
  }

  if (isEmpty(values.last_name)) {
    errors.last_name = I18n.t('LeadsList.LeadsListModalRequiredField');
  }

  if (isEmpty(values.middle_name)) {
    errors.middle_name = I18n.t('LeadsList.LeadsListModalRequiredField');
  }

  if(values.first_name && values.first_name.length < 2) {
    errors.first_name = I18n.t('LeadsList.LeadsListModalAtLeastRequired')
  }

  if(values.last_name && values.last_name.length < 2) {
    errors.last_name = I18n.t('LeadsList.LeadsListModalAtLeastRequired')
  }

  if(values.middle_name && values.middle_name.length < 2) {
    errors.middle_name = I18n.t('LeadsList.LeadsListModalAtLeastRequired')
  }

  if (deliveryInfo !== null && deliveryInfo !== undefined && deliveryInfo.type !== 'BRANCH') {
    if (isEmpty(deliveryInfo.postcode)) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcode');
    }

    if (deliveryInfo.postcode && deliveryInfo.postcode.length < 5) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcodeInvalid');
    }

    if (!/^\d+$/.test(deliveryInfo.postcode)) {
      errors.deliveryInfo.postcode = I18n.t('Profile.ProfileErrorsDeliveryPostcodeInvalid');
    }

    if (isEmpty(deliveryInfo.city)) {
      errors.deliveryInfo.city = I18n.t('Profile.ProfileErrorsDeliveryCity');
    }

    if (isEmpty(deliveryInfo.street)) {
      errors.deliveryInfo.street = I18n.t('Profile.ProfileErrorsDeliveryStreet');
    }

    if (isEmpty(deliveryInfo.building)) {
      errors.deliveryInfo.building = I18n.t('Profile.ProfileErrorsDeliveryBuilding');
    }
  }

  return errors;
};

export default validateLeadInfo;
