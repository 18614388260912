const styles = theme => ({
  root: {
    position: "absolute",
    background: "rgba(255, 255, 255, 0.64)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  loader: {
    position: "absolute",
    top: "45%"
  }
});

export default styles;
