export function getUniqueArray(arr, comp) {
     if (!arr) return [];
     const unique = arr
          .map(e => e[comp])

          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter(e => arr[e]).map(e => arr[e]);

     return unique;
}

export default getUniqueArray;