import React, { Component } from "react";
import {
  IconButton,
  InputAdornment,
  withStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { connect } from 'react-redux';
import ShowPasswordIcon from "../Icons/ShowPasswordIcon";
import HidePasswordIcon from "../Icons/HidePasswordIcon";
import SearchIcon from "../Icons/SearchIcon";
import CloseIcon from "../Icons/CloseIcon";
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  inputLogin: {
    marginBottom: theme.spacing.unit * 2,
    height: 44,
    borderRadius: 24,
    "& fieldset": {
      borderRadius: 24
    }
  },
  inputSearch: {
    padding: 10,
    height: 34,
    width: 496,
    borderRadius: 24,
    fontSize: 14,
    "& fieldset": {
      borderRadius: 24
    }
  },
  "@media (max-width: 1280px) and (min-width: 1145px)": {
    inputSearch: {
      width: '380px',
    }
  },
  "@media (max-width: 1145px)": {
    inputSearch: {
      width: 'auto',
    }
  },
  matchFound: {
    fontSize: 14,
    fontWeight: 500,
    color: "#9fb0cb",
    marginLeft: 16
  },
  searchWrap: {
    display: "flex",
    alignItems: "center"
  },
  labelText: {
    marginRight: 38
  }
});

const ShowPassword = ({ showPassword, handleShowPassword }) => (
  <InputAdornment position="end">
    <IconButton onClick={handleShowPassword}>
      {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
    </IconButton>
  </InputAdornment>
);

const Search = () => (
  <InputAdornment position="start">
    <SearchIcon />
  </InputAdornment>
);
const SearchDelete = ({ showDelete, className, onClick }) => (
  <InputAdornment position="end">
    {showDelete && (
      <IconButton onClick={onClick} className={className}>
        <CloseIcon />
      </IconButton>
    )}
  </InputAdornment>
);

class CustomInputRounded extends Component {
  state = {
    showPassword: false
  };
  
  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  inputType = () => {
    const { showPassword } = this.state;
    const { classes, value, type, onClick } = this.props;
    if (type === "password") {
      return {
        classes: { root: classes.inputLogin },
        endAdornment: (
          <ShowPassword
            handleShowPassword={this.handleShowPassword}
            showPassword={showPassword}
            onClick={onClick}
          />
        )
      };
    }
    if (type === "search") {
      return {
        classes: { root: classes.inputSearch },
        startAdornment: <Search />,
        endAdornment: (
          <SearchDelete showDelete={!!value.length} onClick={onClick} />
        )
      };
    }
    if (type === "text") {
      return {
        classes: { root: classes.inputLogin }
      };
    }
  };

  render() {
    const { showPassword } = this.state;
    const {
      classes,
      error,
      placeholder,
      type,
      onKeyPress,
      showMatch,
      rows,
      label,
      noTranslate,
      ...input
    } = this.props;
    return (
      <>
        {label && (
          <Typography variant="h6" className={classes.labelText}>
            {label}
          </Typography>
        )}
        <TextField
          error={error}
          variant="outlined"
          autoComplete="off"
          onKeyPress={onKeyPress}
          type={
            showPassword || type === "text" || type === "search"
              ? "text"
              : "password"
          }
          placeholder={placeholder}
          InputProps={this.inputType()}
          onChange={input.onChange}
          value={input.value}
          name={input.name}
        />
        {showMatch && (
          <div className={classes.matchFound}>{noTranslate ? 'Кількість співпадінь' : I18n.t('LeadsList.LeadsListNumberMatchedSearch')}: {rows.length}</div>
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  locale: state.i18n.locale,
});
export default connect(
  mapStateToProps,
)(withStyles(styles)(CustomInputRounded));
