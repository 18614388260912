import {
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED
} from './types';
import apiService from "../../api/apiService";
import { appLoading, stopAppLoading, openToastSystem } from "./appActions";
import store from "../store";
import { I18n } from 'react-redux-i18n';

export const getProductList = (params) => dispatch => {
  dispatch(appLoading());
  apiService
    .getProductList(params)
    .then((res) => {
      const { data } = res;
      if (params.page !== 1) {
        const { productList } = store.getState().product;
        const dataTemp = {
          count: data.count,
          next: data.next,
          results: []
        }
        if(data.results && data.results.length > 0){
          dataTemp.results = [...productList, ...data.results];
        }
        dispatch({
          type: GET_PRODUCT_LIST_SUCCESS,
          payload: dataTemp
        });
      } else {
        dispatch({
          type: GET_PRODUCT_LIST_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_PRODUCT_LIST_FAILED,
        payload: err
      });
    })
    .finally(() => dispatch(stopAppLoading()));
};

export const createProduct = (data, params) => dispatch => {
  const actionMessage = {
    createSuccess: I18n.t('Products.ProductsCreateSuccess'),
    createFailed: I18n.t('Products.ProductsCreateFailed'),
  }
  dispatch(appLoading());
  apiService.createProduct(data)
    .then(res => {
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: res.data
      });
      dispatch(openToastSystem({
        message: `${actionMessage.createSuccess}`
      }));
    })
    .then(() => dispatch(getProductList(params)))
    .catch(error => {
      console.log('CREATE_PRODUCT_ERROR_', error);
      dispatch({
        type: CREATE_PRODUCT_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: `${actionMessage.createFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}

export const updateProduct = (id, data, params) => dispatch => {
  const actionMessage = {
    updateSuccess: I18n.t('Products.ProductsUpdateSuccess'),
    updateFailed: I18n.t('Products.ProductsUpdateFailed'),
  }
  dispatch(appLoading());
  apiService.updateProduct(id, data)
    .then(res => {
      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: res.data
      });
      dispatch(openToastSystem({
        message: I18n.t('Products.ProductsProduct') + ` ${id}: ${actionMessage.updateSuccess}`
      }));
    })
    .then(() => dispatch(getProductList(params)))
    .catch(error => {
      console.log('UPDATE_PRODUCT_ERROR_', error);
      dispatch({
        type: UPDATE_PRODUCT_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: I18n.t('Products.ProductsProduct') + ` ${id}: ${actionMessage.updateFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}

export const deleteProduct = (id, params) => dispatch => {
  const actionMessage = {
    deleteSuccess: I18n.t('Products.ProductsDeleteSuccess'),
    deleteFailed: I18n.t('Products.ProductsDeleteFailed'),
  }
  dispatch(appLoading());
  apiService.deleteProduct(id)
    .then(res => {
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: res.data
      });
      dispatch(openToastSystem({
        message: I18n.t('Products.ProductsProduct') + `: ${id}: ${actionMessage.deleteSuccess}`
      }));
    })
    .then(() => dispatch(getProductList(params)))
    .catch(error => {
      console.log('DELETE_PRODUCT_ERROR_', error);
      dispatch({
        type: DELETE_PRODUCT_FAILED,
        payload: error
      });
      dispatch(openToastSystem({
        message: I18n.t('Products.ProductsProduct') + `: ${id}: ${actionMessage.deleteFailed}`
      }));
    })
    .finally(() => dispatch(stopAppLoading()));
}
