const drawerWidth = 56;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: "hidden",
    width: theme.spacing.unit * 7 + 1
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: 54,
  },
  content: {
    flexGrow: 1,
    padding: 32
  },
  logo: {
    width: 28,
    height: 28,
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 0,
    maxHeight: "90%",
    minHeight: "90%"
  },
  iconHover: {
    color: "#FFF",
    fontSize: 40,
    "&:hover": {
      cursor: "pointer",
      color: "#F3F5F8"
    }
  },
  iconActive: {
    fontSize: 40,
    "&:hover": {
      cursor: "pointer"
    }
  },
  langSwitcher: {
    backgroundColor: 'rgba(138, 150, 193, 0.12)',
    marginBottom: 8,
    '& span':{
      color: '#8a96c1',
      fontSize: 11,
      fontWeight: 900,
      letterSpacing: '-0.6px',
      paddingLeft: 10, 
      paddingTop: 2   
    },
      "&:hover":{
        backgroundColor: 'rgba(138, 150, 193, 0.12) !important',
      }
  },
});

export default styles;