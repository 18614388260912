import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import store from '../../redux/store';
import {
  withStyles,
  Drawer,
  List,
  Divider,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid
} from "@material-ui/core";
import MainLogo from "../Icons/MainLogo";
import ProfileIcon from "../Icons/ProfileIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import SettingsActiveIcon from "../Icons/SettingsActiveIcon";
import ListIcon from "../Icons/ListIcon";
import ShapeIcon from "../Icons/ShapeIcon";
import ProductIcon from '../Icons/ProductIcon';
import ShipmentIcon from '../Icons/ShipmentIcon';
import ProfileActiveIcon from "../Icons/ProfileActiveIcon";
import ShapeActiveIcon from "../Icons/ShapeActiveIcon";
import ListActiveIcon from "../Icons/ListActiveIcon";
import ProductActiveIcon from '../Icons/ProductActiveIcon';
import ShipmentActiveIcon from '../Icons/ShipmentActiveIcon';
import CategoryIcon from '../Icons/CategoryIcon';
import CategoryActiveIcon from '../Icons/CategoryActiveIcon';
import styles from "./styles";
import LogoutIcon from "../Icons/LogoutIcon";
import ConfirmationsModal from '../ConfirmationsModal';
import { setLocale } from 'react-redux-i18n';
import { setLocaleLanguage } from '../../redux/actions/translationActions';
import { logoutUser } from "../../redux/actions/authActions";


const SidebarComponent = ({ classes, profile }) => {
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState('UKR');
  const [currentSupplierCountry, setCurrentSupplierCountry] = React.useState('');

  function handleOpenModal() {
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
  }

  function handleChangeLanguage() {
    localStorage.setItem('lang', language === 'ENG' ? 'en' : 'uk');
    if (language === 'UKR') {
      setLanguage('ENG');
      store.dispatch(setLocaleLanguage('uk'));
    } else {
      setLanguage('UKR'); 
      store.dispatch(setLocale('en'));
    }
  }

  React.useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (lang && lang === 'uk') {
      setLanguage('ENG');
    } else {
      setLanguage('UKR'); 
    }
  }, []);

  React.useEffect(() => {
    const supplierCountry = profile && profile.supplier ? profile.supplier.country : '';
    setCurrentSupplierCountry(supplierCountry)
  }, [profile]);

  const { pathname } = window.location;
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={classes.drawerClose}
        classes={{
          paper: classes.drawerClose
        }}
        open={false}
      >
        <div className={classes.toolbar}>
          <ListItemIcon>
            <MainLogo className={classes.logo} />
          </ListItemIcon>
        </div>
        <Divider />
        <List className={classes.list}>
          <div>
            {/* <MenuItem component={Link} to="/dashboard">
              <ListItemIcon>
                {pathname === "/dashboard" ? <HomeActiveIcon /> : <HomeIcon />}
              </ListItemIcon>
              <ListItemText/>
            </MenuItem> */}
            <MenuItem component={Link} to="/profile" style={{ marginTop: 8 }}>
              <ListItemIcon>
                {pathname === "/profile" ? (
                  <ProfileActiveIcon />
                ) : (
                    <ProfileIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
            <MenuItem component={Link} to="/list">
              <ListItemIcon>
                {pathname === "/list" ? <ListActiveIcon /> : <ListIcon />}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
            <MenuItem component={Link} to="/campaigns">
              <ListItemIcon>
                {pathname === "/campaigns" ? (
                  <ShapeActiveIcon />
                ) : (
                    <ShapeIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
            <MenuItem component={Link} to="/product-category">
              <ListItemIcon>
                {pathname === "/product-category" ? (
                  <CategoryActiveIcon />
                ) : (
                    <CategoryIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
            <MenuItem component={Link} to="/products">
              <ListItemIcon>
                {pathname === "/products" ? (
                  <ProductActiveIcon />
                ) : (
                    <ProductIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
            {false && currentSupplierCountry === 'UKR' && <MenuItem component={Link} to="/shipment">
              <ListItemIcon>
                {pathname === "/shipment" ? (
                  <ShipmentActiveIcon />
                ) : (
                    <ShipmentIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>}
            <MenuItem component={Link} to="/settings">
              <ListItemIcon>
                {pathname === "/settings" ? (
                  <SettingsActiveIcon />
                ) : (
                    <SettingsIcon />
                  )}
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
          </div>
          <Grid container direction='column' >
            <MenuItem className={classes.langSwitcher}>
              <ListItemText onClick={handleChangeLanguage} className={classes.langSwitcherText}>
                {language}
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleOpenModal} >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText />
            </MenuItem>
          </Grid>
          <ConfirmationsModal
            open={open}
            handleClose={handleCloseModal}
            handleConfirm={() => store.dispatch(logoutUser())}
            confirmData={{
              message: "App.AppSidebarModal",
              acceptBtnLabel: "App.AppSidebarModalYes",
              cancelBtnLabel: "App.AppSidebarModalNo"
            }}
          />
        </List>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = {
};

SidebarComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SidebarComponent));
