import React from "react";
import {
  withStyles,
  Dialog,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import CustomInput from "../../../../components/CustomInput";
import CloseIcon from "../../../../components/Icons/CloseIcon";
import DoneIcon from "../../../../components/Icons/DoneIcon";
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from "./styles";
import { Translate } from "react-redux-i18n";

const renderTextField = ({
    input,
    serverErrors,
    type,
    placeholder,
    label,
    endadornment,
    labelWrapWidth,
    meta: { error, touched }
  }) => (
      <CustomInput
        // error={!!error || !!serverErrors.length}
        error={touched && !!error}
        type={type}
        label={label}
        labelWrapWidth={labelWrapWidth}
        // helperText={(touched && !!error) && error}
        placeholder={placeholder}
        endadornment={endadornment}
        {...input}
      />
    );

const onlyNums = value => value.replace(/[^\d.]/g, "");

const CampaignsTopUpModal = ({
  classes,
  onSubmitTopUp,
  open,
  topUpError,
  handleCloseModal,
  handleChangeInput,
  supplier
}) => {
  const currencyText = 'UAH';//(supplier && supplier.country === 'UKR') ? 'UAH' : 'USD';
  return (
    <Dialog onClose={handleCloseModal} open={open}>
      <Grid
        container
        className={classes.container}
      >
        <Typography variant="h6" className={classes.title}>
            <Translate value="Campaigns.CampaignsTopUpTitle" />
        </Typography>
        <Grid container className={classes.modalContent} wrap="nowrap">
          <Grid container wrap="nowrap">
            <Field
                type="text"
                name="amount"
                label={
                    <Translate value="Campaigns.CampaignsTopUpAmount" />
                }
                labelWrapWidth="100px"
                component={renderTextField}
                normalize={onlyNums}
                endadornment={<InputAdornment position="end">{currencyText}</InputAdornment>}
                onChange={handleChangeInput}
            />
          </Grid>
          {topUpError && (<div className={classes.error}>{topUpError}</div>)}
        </Grid>
        <Grid
          container
          justify="flex-end"
          className={classes.actionButtonsWrap}
        >
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            className={classes.cancelBtn}
          >
            <CloseIcon className={classes.extendedIcon} />
            <Translate value="Campaigns.CampaignsTopUpBtnCancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitTopUp}
            className={classes.saveBtn}
          >
            <DoneIcon className={classes.extendedIcon} />
            <Translate value="Campaigns.CampaignsTopUpBtnSubmit" />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(reduxForm({ form: 'TopUpForm', enableReinitialize: true })(CampaignsTopUpModal));
