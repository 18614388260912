import React from "react";
import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007fff",
      light: "#007fff",
      mainGradient: "linear-gradient(125deg, #ffffff, #eef3f5)"
    },
    secondary: {
      light: "#3398ff",
      main: "#ff3d33"
    },
    error: {
      main: "#de2323"
    }
  },
  typography: {
    htmlFontSize: 16,
    useNextVariants: true,
    fontFamily: "Roboto",
    h4: {
      color: "#9fb0cb",
      letterSpacing: -0.8
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      color: "#9fb0cb"
    },
    h6: {
      color: "#9fb0cb",
      fontWeight: 700,
      lineHeight: 1.25
    },
    caption: {
      color: "#7C7C7C",
      fontWeight: 400,
      fontSize: 14
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        boxShadow: "0 2px 50px 0 #ced9ea",
        backgroundColor: "#fefefe"
      }
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "7px !important",
        "&$selected": {
          backgroundColor: "transparent !important"
        },
        "&:hover": {
          backgroundColor: "transparent !important"
        }
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "100%"
      }
    },
    MuiChip: {
      label: {
        fontFamily: "Roboto",
        fontSize: 14,
        color: "#ffffff"
      },
      root: {
        height: 24,
        margin: 4,
        backgroundColor: "#9fb0cb"
      },
      deleteIcon: {
        marginRight: 8,
        "&:hover": {
          "& path": { fill: "#3333" }
        },
        "& path": {
          fill: "#ffffff"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#007fff"
        },
        padding: 0,
        width: "100%",
        fontSize: 14,
        backgroundColor: "#ffffff",
        "$&focused": {
          boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)"
        },
        "$&error": {
          boxShadow: "0 0 6px 0 rgba(222, 35, 35, 0.64)",
          "&:hover": {
            borderColor: "#de2323"
          }
        },
        "$&disabled": {},
        "$&notchedOutline": {}
      }
    },
    MuiInputBase: {
      root: {
        "&$focused": {
          boxShadow: "0 0 6px 0 rgba(0, 127, 255, 0.72)"
        },
        "&$error": {
          boxShadow: "0 0 6px 0 rgba(222, 35, 35, 0.64)",
          "&:hover": {
            borderColor: "#de2323"
          }
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 24,
        textTransform: "capitalize",
        color: "#ffffff",
        lineHeight: 1.29,
        letterSpacing: -0.4,
        fontWeight: 500,
        "&$disabled": {
          background: "#d5d8da !important",
          color: "#ffffff"
        },
        "& span": {
          textTransform: "capitalize",
          fontFamily: "Roboto",
          fontWeight: 500,
          lineHeight: 1.29,
          letterSpacing: -0.4
        }
      }
    },
    MuiFab: {
      root: {
        textTransform: "capitalize",
        fontFamily: "Roboto"
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    MuiListItem: {
      button: {
        "&:focus": {
          backgroundColor: "transpatent"
        }
      }
    },
    MuiTableSortLabel: {
      active: {
        color: "#9fb0cb",
        "&:focus": {
          color: "#9fb0cb"
        }
      },
      root: {
        "&:hover": {
          color: "#9fb0cb"
        }
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
