import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Sidebar from "../Sidebar";
import Loading from "../Loading";
import ToastSystem from '../ToastSystem';
import PopupSystem from '../PopupSystem';

const styles = theme => ({
  root: {
    padding: "38px 32px 32px 88px",
    height: "100%",
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
  }
});

class Layout extends Component {
  render() {
    const { classes, isLoading } = this.props;
    return (
      <>
        <Sidebar />
        <main className={classes.root}>{this.props.children}</main>
        {isLoading && <Loading />}
        <ToastSystem
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        />
        <PopupSystem />
      </>
    );
  }
}

export default withStyles(styles)(Layout);
