/**@jsx jsx */
import { useSelector } from "react-redux";
import { css, jsx } from "@emotion/core";
import { Grid, Button, Chip } from "@material-ui/core";
import { Translate, I18n } from "react-redux-i18n";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
function TagWrapItemAddress({
  classes,
  title,
  handleShowModal,
  campaignName,
  locale
}) {
  const { Address } = useSelector((state) => state.GetlatLngReducer);
  return (
    <Grid
      style={{
        paddingTop: "30px",
        marginBottom: "30px",
      }}
    >
      <Grid
        container
        alignItems="center"
        style={{
          paddingBottom: 24,
          borderBottom: "1px solid rgba(199, 199, 199, 0.24)",
        }}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            h1 {
              color: #9fb0cb;
            }
          `}
        >
          <h1
            style={{
              fontSize: "1.4rem",
              fontWeight: "700",
              lineHeight: "1.25",
              marginLeft: "25px",
              fontFamily: "Roboto",
              display: "block",
            }}
          >
            <Translate value="Products.ProductsTagsItemAddress" />
          </h1>
          {Address !== null ? (
            Address.lat === null ? (
              <Button
                variant="contained"
                onClick={() => handleShowModal(title)}
                color="prary"
                style={{
                  minWidth: 104,
                  height: 32,
                  textTransform: "capitalize",
                  backgroundColor: "#0028c2",
                  marginRight: "20px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <AddIcon style={{ color: "#C0D6EC" }} />
                <Translate value="CampaignsCreation.CampaignsCreationButtonAdd" />
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleShowModal(title)}
                color="prary"
                style={{
                  minWidth: 104,
                  height: 32,
                  textTransform: "capitalize",
                  backgroundColor: "#0028c2",
                  marginRight: "20px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <AddIcon style={{ color: "#C0D6EC" }} />
                <Translate value="Edit" />
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              onClick={() => handleShowModal(title)}
              color="prary"
              style={{
                minWidth: 104,
                height: 32,
                textTransform: "capitalize",
                backgroundColor: "#0028c2",
                marginRight: "20px",
                color: "#fff",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <AddIcon style={{ color: "#C0D6EC" }} />
              <Translate value="CampaignsCreation.CampaignsCreationButtonAdd" />
            </Button>
          )}
        </div>
        {campaignName.creation ? (
          Address !== null ? (
            <Chip
              style={{ marginLeft: "20px" }}
              label={
                I18n.t('CampaignsCreation.CampaignsCreationAddress') + ": " +
                " " +
                Address.address +
                "; " +
                "Lat:" +
                " " +
                Address.lat +
                "; Lng:" +
                " " +
                Address.long
              }
              deleteIcon={<DoneIcon />}
            />
          ) : (
            ""
          )
        ) : Address !== null ? (
          <Chip
            style={{ marginLeft: "20px" }}
            label={
              I18n.t('CampaignsCreation.CampaignsCreationAddress') + ": " +
              " " +
              Address.address +
              "; " +
              "Lat:" +
              " " +
              Address.lat +
              "; Lng:" +
              " " +
              Address.long
            }
            deleteIcon={<DoneIcon />}
          />
        ) : Address !== null ? (
          <Chip
            style={{ marginLeft: "20px" }}
            label={
              I18n.t('CampaignsCreation.CampaignsCreationAddress') + ": " +
              " " +
              campaignName.address +
              "; " +
              "Lat:" +
              " " +
              campaignName.lat +
              "; Lng:" +
              " " +
              campaignName.long
            }
            deleteIcon={<DoneIcon />}
          />
        ) : (
          <Chip
            style={{ marginLeft: "20px" }}
            label={
              I18n.t('CampaignsCreation.CampaignsCreationAddress') + ": " +
              " " +
              campaignName.address +
              "; " +
              "Lat:" +
              " " +
              campaignName.lat +
              "; Lng:" +
              " " +
              campaignName.long
            }
            deleteIcon={<DoneIcon />}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default TagWrapItemAddress;
