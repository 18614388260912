const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  tableRow: {
    outline: 'none'
  },
  tableCell: {
    flex: 1,
    flexWrap: 'nowrap',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    color: '#9fb0cb',
    flex: 1
  },
  noClick: {
    cursor: 'pointer',
    outline: 'none'
  },
  rootBtn: {
    width: '105px',
    height: 24,
    borderRadius: 12,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    color: '#ffffff',
    padding: '0px 15px',
    textTransform: 'capitalize'
  },
  chip: {
    minWidth: 24,
    height: 24,
    backgroundColor: '#9fb0cb',
    fontSize: 14,
    color: '#ffffff',
    '& span': {
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  checkIcon: {
    minWidth: 24,
    height: 24,
    backgroundColor: '#9fb0cb',
  },
  inProgress: {
    fontSize: 14,
    color: '#ff6700',
    fontWeight: 500
  },
  paused: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#6144b9',
    fontWeight: 500
  },
  newStatus: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: 'green',
    fontWeight: 500
  },
  finishedStatus: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#d5d8da',
    fontWeight: 500
  },
  btnPaused: {
    backgroundColor: '#6144b9'
  },
  btnRestart: {
    backgroundColor: '#0e7731'
  },
  finishedData: {
    fontSize: 14,
    color: '#d5d8da'
  },
  newDate: {
    color: 'green'
  },
  simpleDate: {
    color: '#4797e0'
  },
  iconButton: {
    padding: 5,
    marginLeft: 10
  },
  iconButtonDisabled: {
    '& svg': {
      '& path': {
        fill: '#D5D8DA'
      }
    }
  }
});

export default styles;
