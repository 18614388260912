import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 18
  }
});

function ClearAllIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0028c2"
          d="M10.616 7.397l3.889-4.635a.7.7 0 0 1 .986-.086l.134.112a.7.7 0 0 1 .086.986L11.822 8.41l1.446 1.213a.7.7 0 0 1 .087.986l-.113.134a.7.7 0 0 1-.986.087l-4.098-3.44a.7.7 0 0 1-.087-.985l.113-.134a.7.7 0 0 1 .986-.087l1.446 1.214zm-2.567.783l3.448 2.893c.475.399.538 1.109.138 1.585l-1.868 2.226a1 1 0 0 1-.77.357l-5.916-.02a1.125 1.125 0 0 1-.858-1.848L6.464 8.32A1.125 1.125 0 0 1 8.05 8.18zm-4.002 5.47l4.686.015 1.406-1.675-2.757-2.314-3.335 3.974z"
        />
      </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(ClearAllIcon);
