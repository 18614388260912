import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
      fontSize: 40,
      circle: {},
      '&:hover circle':{
        fill:"#F3F5F8"
      },
      '&:link circle':{
        fill:"red"
      }
    },
  });
  

function LogoutIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#FFF"/>
      <path fill="#8A96C1" d="M11 10h14a1 1 0 0 1 1 1v2.3a.7.7 0 0 1-1.4 0v-1.9H11.4v17.2h13.2v-1.9a.7.7 0 0 1 1.4 0V29a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1zm16.351 10.7H16.7a.7.7 0 0 1 0-1.4h10.66l-1.355-1.355a.7.7 0 1 1 .99-.99l2.333 2.333a1 1 0 0 1 0 1.415l-2.333 2.333a.7.7 0 1 1-.99-.99l1.346-1.346z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(LogoutIcon);