import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import TringleIcon from '../../../components/Icons/TringleIcon';
import EditIcon from '../../../components/Icons/EditIcon';

const ActionGroupStyle = styled.div`
    .root-icon{
        padding: 8px;
        margin-left: 26px;
    }
    .disable {
        & svg {
            & path {
                fill: '#D5D8DA'
            }
        }
    }
`;

const ActionGroupCell = ({ onDeleteProductCategory, onEditProductCategory, rowData }) => {

  return (
    <ActionGroupStyle>
      <IconButton
        classes={{
          root: 'root-icon',
          disabled: 'disable'
        }}
        disabled={false}
        onClick={() => onEditProductCategory(rowData)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        classes={{
          root: 'root-icon',
          disabled: 'disable'
        }}
        disabled={false}
        onClick={() => onDeleteProductCategory(rowData)}
      >
        <TringleIcon />
      </IconButton>
    </ActionGroupStyle>
  )
}

ActionGroupCell.propTypes = {
  onDeleteProductCategory: PropTypes.func,
  onEditProductCategory: PropTypes.func,
  rowData: PropTypes.any.isRequired
}

export default ActionGroupCell;