const styles = theme => ({
  addBtn: {
    height: 34,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    "& svg": {
      fontSize: 16,
      marginRight: 8
    },
    "& path": {
      fill: "#ffffff"
    }
  },
  topUpBtn: {
    marginLeft: 15,
    height: 34,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    "& svg": {
      fontSize: 16,
      marginRight: 8
    },
    "& path": {
      fill: "#ffffff"
    }
  },
  invoiceBtn: {
    marginLeft: 15,
    height: 34,
    borderRadius: 24,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
    "& svg": {
      fontSize: 16,
      marginRight: 8
    },
    "& path": {
      fill: "#ffffff"
    }
  },
  paperWrap: {
    width: "100%",
    height: "100%",
    marginBottom: 16,
    boxShadow: "0 2px 8px -2px rgba(0, 0, 0, 0.16)"
  },
  paperActions: {
    width: "100%",
    height: "100%",
    padding: 24
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "34px"
  },
  totalBtn: {
    color: "#fff",
    backgroundColor: "#C7CACB",
    padding: "6px 15px",
    marginLeft: "40px",
    marginRight: "16px",
    "&:hover": {
      backgroundColor: "#a5a8a9"
    },
    "& $priceNumber, & $currency": {
      lineHeight: 1
    }
  },
  priceNumber: {
    lineHeight: 1,
    marginRight: "3px"
  },
  currency: {
    marginRight: "16px",
    lineHeight: 1
  },
  lastUpdate: {
    color: "#9FB0CB",
    font: "500 12px/14px Roboto"
  }
});

export default styles;
