import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 18
  }
});

function CheckedCheckbox(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
        <defs>
        <path d="M3,2 L15,2 C15.5522847,2 16,2.44771525 16,3 L16,15 C16,15.5522847 15.5522847,16 15,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M7.66355277,11.4676332 L4.34120541,8.14528585 L3.4859602,8.99053106 L7.66174664,13.1663175 L15.1588853,5.66935809 L14.3103566,4.82082941 L7.66355277,11.4676332 Z" id="path-1"></path>
    </defs>
    <g id="icon-/-18-px-/-check-/-selected" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="rgb(199, 199, 199)" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
        </mask>
        <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-1"></use>
    </g>
    </SvgIcon>
  );
}
export default withStyles(styles)(CheckedCheckbox);
