import axios from 'axios';

const { REACT_APP_API_HOST } = process.env;
class ApiService {
  async getResourse(endpoint, options) {
    const res = await axios.request({
      url: `${REACT_APP_API_HOST}${endpoint}`,
      method: options.method,
      params: options.params,
      headers: { ...options.headers },
      data: options.data
    });
    return res;
  }

  loginUser(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/api/token-access`, options);
  }

  getActiveCampaigns() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=active`, options);
  }

  getFinishedCampaigns() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=4`, options);
  }

  deleteCampaign(id) {
    const options = {
      method: 'DELETE'
    };
    return this.getResourse(`/api/campaign/${id}`, options);
  }

  editCampaign(id) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign/${id}`, options);
  }

  updateCampaign(data, id) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/campaign/${id}`, options);
  }

  restartCampaign(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/api/campaign-copy`, options);
  }

  searchCampaignsActive(data) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=active&search=${data}`, options);
  }

  searchCampaignsFinished(data) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=4&search=${data}`, options);
  }

  completeCampaignCreation(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/api/campaign`, options);
  }

  completeCampaignEditing(data, id) {
    const options = {
      method: 'PUT',
      data
    };
    return this.getResourse(`/api/campaign/${id}`, options);
  }

  getLeadsListData(next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/lead-list?${next}`, options);
    } else {
      return this.getResourse(`/api/lead-list`, options);
    }
  }

  // getLeadInfo(id) {
  //   const options = {
  //     method: "GET"
  //   }
  //   return this.getResourse(`/api/lead/${id}`, options)
  // }

  patchLeadsConfirm(data, id) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/lead/${id}`, options);
  }

  patchLeadsConfirmAll(data) {
    const options = {
      method: 'PUT',
      data
    };
    return this.getResourse(`/api/lead-list/confirm-all`, options);
  }

  searchLeadsList(data, next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/lead-list?search=${data}&${next}`, options);
    } else {
      return this.getResourse(`/api/lead-list?search=${data}`, options);
    }
  }

  getProfile() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/auth/me`, options);
  }

  updateProfile(data) {
    const options = {
      method: 'PUT',
      data
    };
    return this.getResourse(`/api/auth/me`, options);
  }

  requestDeleteAccount(data) {
    const options = {
      method: 'POST',
      data
    };

    return this.getResourse('/api/auth/request-delete', options);
  }

  getConfig() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/config`, options);
  }

  getIpGeo() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/localization/ip-geo`, options);
  }
  getGeoTags() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/geo-tag/tree`, options);
  }

  getWhatTags(country) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/tag-list?kind=1&country=${country}`, options);
  }

  getWhichTags(id) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/tag-list?kind=5&id=${id}`, options);
  }

  getNextOptionsGeo(id) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/geo-tag/tree?id=${id}`, options);
  }

  getNextOptionsWhat(id) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/tag-list?kind=1&id=${id}`, options);
  }

  getNewLanguage(key) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/localization?language=${key}&app=SUPPLIER_WEB`, options);
  }

  changePassword(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/rest-auth/password/change/`, options);
  }

  sortColumnLeadsList(nameColumn, next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/lead-list?ordering=${nameColumn}&${next}`, options);
    } else {
      return this.getResourse(`/api/lead-list?ordering=${nameColumn}`, options);
    }
  }

  sortColumnCampaignsActive(nameColumn) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=active&ordering=${nameColumn}`, options);
  }

  sortColumnCampaignsFinished(nameColumn) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-list?status=4&ordering=${nameColumn}`, options);
  }

  getCampaign() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/campaign-name-list`, options);
  }

  getBalance() {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/supplier-balance`, options);
  }

  getProductList(params) {
    const options = {
      method: 'GET'
    };
    const isGetAll = params.page === null;
    if (isGetAll) {
      return this.getResourse(`/api/products?page_size=1`, options).then((res) => {
        return this.getResourse(`/api/products?page_size=${res.data.count}`, options);
      });
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key];
    });

    const qs = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return this.getResourse(`/api/products?${qs}`, options);
  }

  createProduct(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/api/products`, options);
  }
  updateProduct(id, data) {
    const options = {
      method: 'PUT',
      data,
      id
    };
    return this.getResourse(`/api/products/${id}`, options);
  }
  deleteProduct(id) {
    const options = {
      method: 'DELETE',
      id
    };
    return this.getResourse(`/api/products/${id}`, options);
  }
  getCategoryList(params) {
    const options = {
      method: 'GET'
    };

    const isGetAll = params.page === null;

    if (isGetAll) {
      return this.getResourse(`/api/categories?page_size=1`, options).then((res) => {
        return this.getResourse(`/api/categories?page_size=${res.data.count}`, options);
      });
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key];
    });

    const qs = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return this.getResourse(`/api/categories?${qs}`, options);
  }
  createCategory(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse(`/api/categories`, options);
  }
  updateCategory(id, data) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/categories/${id}`, options);
  }
  deleteCategory(id) {
    const options = {
      method: 'DELETE',
      id
    };
    return this.getResourse(`/api/categories/${id}`, options);
  }
  getAddressGoogleMap(data) {
    const options = {
      method: 'GET',
      data
    };
    return this.getResourse(`/api/search-address?search_text=${data}`, options);
  }
  getLatLongGoogleMap(data) {
    const options = {
      method: 'GET',
      data
    };
    return this.getResourse(`/api/search-address?place_id=${data}`, options);
  }
  getAddressGoogleMapByLatLong({ lat, long }) {
    const options = {
      method: 'GET'
    };
    return this.getResourse(`/api/search-address?lat=${lat}&long=${long}`, options);
  }
  async getWidgetPayrun() {
    const options = {
      method: 'GET'
    };
    return this.getResourse('/api/payments/payrun/widget', options);
  }
  async paymentTopUpPayrun(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse('/api/payments/payrun/top-up', options);
  }
  patchLeadsRead(id, data) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/matched-wishes/${id}`, options);
  }
  patchFeedbackLeadsRead(id, data) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/wish/feedback/${id}`, options);
  }

  getShipmentListData(next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/delivery/shipments?${next}`, options);
    } else {
      return this.getResourse(`/api/delivery/shipments`, options);
    }
  }
  searchShipmentList(data, next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/delivery/shipments?search_text=${data}&${next}`, options);
    } else {
      return this.getResourse(`/api/delivery/shipments?search_text=${data}`, options);
    }
  }
  sortColumnShipmentList(nameColumn, next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(`/api/delivery/shipments?ordering=${nameColumn}&${next}`, options);
    } else {
      return this.getResourse(`/api/delivery/shipments?ordering=${nameColumn}`, options);
    }
  }
  deleteShipment(id) {
    const options = {
      method: 'DELETE'
    };
    return this.getResourse(`/api/delivery/shipment/${id}`, options);
  }
  createShipment(wishId, assessedValue) {
    const options = {
      method: 'POST',
      data: {
        wish_id: wishId,
        assessed_value: assessedValue
      }
    };
    return this.getResourse(`/api/delivery/shipment`, options);
  }
  getDeliveryInfo() {
    const options = {
      method: 'GET'
    };
    return this.getResourse('/api/delivery/supplier', options);
  }

  updateDeliveryInfo(id, data) {
    const options = {
      method: 'PUT',
      data
    };
    return this.getResourse(`/api/delivery/${id}/supplier`, options);
  }

  addDeliveryInfo(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse('/api/delivery/supplier', options);
  }

  searchDeliveryInfo(data, next) {
    const options = {
      method: 'GET'
    };
    if (next) {
      return this.getResourse(
        `/api/delivery/search-post-office?search_text=${data}&${next}`,
        options
      );
    } else {
      return this.getResourse(`/api/delivery/search-post-office?search_text=${data}`, options);
    }
  }

  updateReadAgreement() {
    const options = {
      method: 'POST'
    };
    return this.getResourse('/api/suppliers/read-agreement', options);
  }

  printInvoice(name, sum) {
    const options = {
      method: 'POST',
      data: {
        name,
        total: sum
      }
    };
    return this.getResourse('/api/suppliers/print-invoice', options);
  }

  createLeadDeliveryInfo(data) {
    const options = {
      method: 'POST',
      data
    };
    return this.getResourse('/api/delivery/supplier/lead', options);
  }

  updateLeadDeliveryInfo(id, data) {
    const options = {
      method: 'PATCH',
      data
    };
    return this.getResourse(`/api/delivery/${id}/supplier/lead`, options);
  }
}
const apiService = new ApiService();

export default apiService;
