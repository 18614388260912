import React from "react";
import {
    Button,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import BackIcon from "../../../../components/Icons/BackIcon";
import { Translate } from "react-redux-i18n";
import styles from './styles';
import NumberFormat from 'react-number-format';

const HeaderActions = ({
  classes,
  creation,
  editing,
  setInitialStateCampaignCreation,
  locale,
  supplierBalance,
  currencyText,
  supplier,
  dataTime,
  checkErrorBeforeSubmit
}) => (
  <Grid container justify="space-between" wrap="nowrap" style={{ marginBottom: 17,}}>
    <div className={classes.wrapTitle}
    >
      <div
        className={classes.round}
        onClick={setInitialStateCampaignCreation}
      >
        <BackIcon />
      </div>
      <Typography variant="h4" className={classes.title}>
        {creation && (
          <Translate value="CampaignsCreation.CampaignsCreationTitle" />
        )}
        {editing && (
          <Translate value="CampaignsCreation.CampaignsCreationEditingTitle" />
        )}
      </Typography>
    </div>

      <div className={classes.wrapComplete}>

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.completeBtn}
          onClick={checkErrorBeforeSubmit}
        >
          {creation && (
            <Translate value="CampaignsCreation.CampaignsCreationButtonComplete" />
          )}
          {editing && (
            <Translate value="CampaignsCreation.CampaignsCreationButtonCompleteEdit" />
          )}
        </Button>
          
        <Button className={classes.totalBtn}>
          <span className={classes.priceNumber}>{(supplier && supplier.country === 'UKR') ? <NumberFormat value={supplierBalance} displayType={'text'} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} /> : <NumberFormat value={supplierBalance} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />}</span>
          <span className={classes.currency}>{currencyText}</span>
          <Translate value="Campaigns.CampaignsTotalBalance" />
        </Button>
        <div className={classes.lastUpdate}>
          <div>
            <Translate value="Campaigns.CampaignsLastUpdate" />:
          </div>
          <div>
            {dataTime(new Date(), locale)}
          </div>
        </div>
                    

    </div>  
  </Grid>
);

export default withStyles(styles)(HeaderActions);
