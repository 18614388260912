import { 
  START_LOADING, 
  STOP_LOADING, 
  OPEN_TOAST_SYSTEM, 
  CLOSE_TOAST_SYSTEM, 
  SET_CONFIG, 
  GET_IP_GEO, 
  OPEN_POPUP_SYSTEM, 
  CLOSE_POPUP_SYSTEM 
} from "./types";
import apiService from "../../api/apiService";
export const appLoading = () => ({
  type: START_LOADING
});

export const stopAppLoading = () => ({
  type: STOP_LOADING
});

export const openToastSystem = toastData => ({
  type: OPEN_TOAST_SYSTEM,
  payload: toastData
});

export const closeToastSystem = () => ({
  type: CLOSE_TOAST_SYSTEM
});

export const openPopupSystem = payload => ({
  type: OPEN_POPUP_SYSTEM,
  payload
})

export const closePopupSystem = () => ({
  type: CLOSE_POPUP_SYSTEM
})

export const getConfig = () => dispatch => {
  dispatch(appLoading());
  apiService
    .getConfig()
    .then(res => {
      dispatch({
        type: SET_CONFIG,
        payload: res.data.results
      });
      dispatch(stopAppLoading());
    }).catch(err => {
      dispatch(stopAppLoading());
    });
};

export const getIpGeo = () => async (dispatch) => {
  const res = await apiService.getIpGeo();
    dispatch({
      type: GET_IP_GEO,
      payload: res.data
    });
};
