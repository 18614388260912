const styles = (theme) => ({
  rootBtnModal: {
    width: 104,
    height: 32,
    borderRadius: 24,
    fontSize: 14,
    fontWeight: 500,
    color: "#ffffff",
    background: "#00a0c9",
    padding: 0,
    boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
  },
  modal: {
    width: 927,
    padding: "24px 24px 32px 24px",
    // minHeight: "40rem",
  },
  modalItemInfo: {
    width: "50%",
    paddingRight: 24,
    borderRight: "1px solid rgba(199, 199, 199, 0.24)",
  },
  modalItemPhoto: {
    width: "50%",
    paddingLeft: 24,
  },
  textContainer: {
    width: 294,
    minHeight: 32,
    fontSize: 14,
    color: "rgba(80, 89, 103, 0.87)",
    fontFamily: "Roboto",
    flexBasis: "70%",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderRadius: 4,
    padding: "8px 0 8px 6px",
    border: "solid 1px #c7c7c7",
  },
  tagsWrap: {
    borderRadius: 4,
    border: "solid 1px #c7c7c7",
    padding: "6px 0 6px 6px",
    flexBasis: "70%",
    maxHeight: 144,
    overflow: "auto",
    minHeight: 45,
  },
  chip: {
    minWidth: 24,
    height: 24,
    backgroundColor: "#9fb0cb",
    fontSize: 14,
    color: "#ffffff",
    margin: 4,
    "& span": {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  wishPhotoWrap: {
    width: 415,
    height: 266,
    margin: "24px 0 32px 0",
    borderRadius: 4,
  },
  wishPhoto: {
    width: "100%",
    height: "100%",
    borderRadius: 4,
  },
  closeModalIcon: {
    cursor: "pointer",
    position: "absolute",
    right: 18,
    top: 18,
    "&:hover path": {
      fill: "#9FB0CB",
    },
  },
  descriptionWrap: {
    maxWidth: 415,
    // maxHeight: 95,
    fontSize: 14,
    color: "rgba(80, 89, 103, 0.87)",
    marginTop: 24,
    overflow: "hidden",
    fontFamily: "Roboto",
  },
  textInfo: {
    flexBasis: "30%",
  },
  typo: {
    marginTop: "1.5rem",
  },
  feedbackWrap: {
    marginTop: "1rem",
    color: "rgba(80, 89, 103, 0.87)",
    overflow: "hidden",
    fontSize: 14,
    fontFamily: "Roboto",
  },

  socialContainer: {
    display: "flex",
    alignItems: "center",
  },
  socialLink: {
    marginLeft: "20px",
  },
  socialLinkDisabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
  rightTextContainer: {
    display: 'flex',
    width: 294,
  },
  customShipmentNumber: {
    backgroundColor: '#F6F6F6',
    width: 154,
    minHeight: 32,
    fontSize: 14,
    color: 'rgba(80, 89, 103, 0.87)',
    fontFamily: 'Roboto',
    flexBasis: '70%',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: 4,
    padding: '8px 0 8px 6px',
    border: 'solid 1px #c7c7c7',
    marginRight: 5
  },

  inputRoot: {
    height: 32,
    width: "100%",
    borderRadius: 4,
  },
  
  inputInput: {
    padding: "8px 0 8px 6px",
  }
});

export default styles;
