import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { WithContext as ReactTags } from 'react-tag-input';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const MultipleKeywordsStyle = styled.div`
    font-family: Roboto;
    padding: 1rem 0;

    .ReactTags__tags{
        margin-top: 1rem;
    }
    .ReactTags__tagInput{
        height: 32px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.87);
        cursor: text;
        font-size: 1rem;
        background-color: #ffffff;
        border: 1px solid #c4c4c4;
    }
    .ReactTags__tagInputField{
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        border-radius: 10px;
        padding: 10px;
    }
    .ReactTags__selected{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
    }
    .tag-wrapper.ReactTags__tag{
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0.25rem 0.5rem;
        background: #4CAF50;
        color: #fff;
        border-radius: 20px;
    }

    .ReactTags__remove{
        margin-left: 0.2rem;
        cursor: pointer;
    }

    .ReactTags__suggestions{

    }

    .ReactTags__activeSuggestion{
        
    }
`;

const MultipleKeywords = props => {
    const { onValueChange } = props;

    const [tags, setTags] = useState([])
    // const [suggestions, setSuggestions] = useState([
    //     { id: 'USA', text: 'USA' },
    //     { id: 'Germany', text: 'Germany' },
    //     { id: 'Austria', text: 'Austria' },
    //     { id: 'Costa Rica', text: 'Costa Rica' },
    //     { id: 'Sri Lanka', text: 'Sri Lanka' },
    //     { id: 'Thailand', text: 'Thailand' }
    // ])

    useEffect(() => {
        onValueChange && onValueChange(tags);
    }, [tags])

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i))
    }

    const handleAddition = tag => {
        setTags([...tags, tag]);
    }

    const handleDrag = (tag, currPos, newPos) => {
        const tagClone = [...tags];
        const newTags = tagClone.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags);
    }

    return (
        <MultipleKeywordsStyle>
            <span><Translate value="CampaignsCreation.CampaignsMultipleKeywordAddAndEnter" /></span>

            <ReactTags
                tags={tags}
                // suggestions={suggestions}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters}
                inline={false}
                placeholder={I18n.t("CampaignsCreation.CampaignsMultipleKeywordAddNew")}
            />
        </MultipleKeywordsStyle>
    )
}

MultipleKeywords.propTypes = {
    onValueChange: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    locale: state.i18n.locale,
});
export default connect(
    mapStateToProps,
  )(MultipleKeywords);