import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { reduxForm, getFormValues } from 'redux-form';
import Layout from '../../components/Layout';
import {
  addDeliveryInfo, getDeliveryInfo,
  updateDeliveryInfo
} from '../../redux/actions/deliveryActions';
import { clearErrors } from '../../redux/actions/errorsActions';
import {
  getProfileInfo,
  updateProfileImages,
  updateProfileInfo
} from '../../redux/actions/profileActions';
import validateProfileInfo from '../../validations/validateProfileInfo';
import PaperContactInfo from './common/PaperContactInfo/PaperContactInfo';
import _ from 'lodash'

class Profile extends Component {
  state = {
    onEdit: false,
    formValues: {}
  };

  handleEditClick = () => {
    this.props.reset();
    this.setState({
      onEdit: !this.state.onEdit,
      formValues: _.cloneDeep(this.props.formValues)
    });
  };

  onSubmitEdit = (values) => {
    const { deliveryInfo, branchDeliveryInfo, ...profileInfo } = values;
    
    const { deliveryInfo: stateDeliveryInfo, ...stateProfileInfo } = this.state.formValues;

    const trimedEmail = values.email && values.email.length ? values.email.trim() : "";

    const {
      clearErrors,
      clearSubmitErrors,
      updateProfileInfo,
      updateDeliveryInfo,
      addDeliveryInfo,
    } = this.props;

    this.setState({ onEdit: false });
    clearSubmitErrors();
    if (!_.isEqual(profileInfo, stateProfileInfo)) {
      updateProfileInfo({
        display_name: values.displayName,
        email: trimedEmail,
        phone: values.phone,
        address: values.address,
        website: values.website,
        instagram: values.instagram,
        telegram: values.telegram,
        description: values.description,
        available: values.available,
        viber: values.viber,
        whatsapp: values.whatsapp,
        donation: values.supplier.donation
      });
    }
    
    if (this.props.supplier.country === 'USA') {
      clearErrors();
      return
    }
    
    if (false) {
      if (!_.isEqual(stateDeliveryInfo, deliveryInfo) || !_.isEqual(stateDeliveryInfo, branchDeliveryInfo)) {
        console.log("yes")
        if (deliveryInfo.type === "BRANCH") {
          deliveryInfo.id
            ? updateDeliveryInfo({ ...deliveryInfo, ...branchDeliveryInfo.value })
            : addDeliveryInfo({ ...deliveryInfo, ...branchDeliveryInfo.value });
        } else {
          deliveryInfo.id ? updateDeliveryInfo(deliveryInfo) : addDeliveryInfo(deliveryInfo);
        }
      }
    }

    clearErrors();
  };

  onFileLoad = (e, type) => {
    const { displayName } = this.props.profile;
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    if (file && file.type.indexOf("image/") !== -1) {
      const formData = new FormData();
      formData.append(type, file);
      formData.append("display_name", displayName);
      this.props.updateProfileImages(formData);
    }
    return null;
  };

  componentDidMount() {
    const elementDiv = document.querySelector('div[role="presentation"]');
    if (elementDiv) {
      elementDiv.style.display = 'block';
    }
  }
  removeLoadedPhoto = () => {
    const { displayName } = this.props.profile;
    const formData = new FormData();
    formData.append("photo", null);
    formData.append("display_name", displayName);
    this.props.updateProfileImages(formData);
  };

  render() {
    const {
      loading,
      reset,
      error,
      errors,
      handleSubmit,
      clearSubmitErrors,
      avatar: { supplier_logo },
      supplier,
    } = this.props;

    let country = "USA";

    if (supplier) country = supplier.country;

    const { onEdit } = this.state;
    return (
      <Layout isLoading={loading}>
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          <Translate value="Profile.ProfileTitle" />
        </Typography>
        <form onSubmit={handleSubmit(this.onSubmitEdit)}>
          <Grid container wrap="nowrap">
            <PaperContactInfo
              clearSubmitErrors={clearSubmitErrors}
              handleEditClick={this.handleEditClick}
              onFileLoad={this.onFileLoad}
              supplier_logo={supplier_logo}
              // supplier_logo={supplier}
              onEdit={onEdit}
              reset={reset}
              errors={errors}
              error={error}
              country={country}
              formValues={this.props.formValues}
            />
            {/* remove drag and drop component */}
            {/* <PaperDragDrop
              onFileLoad={this.onFileLoad}
              photo={photo}
              removeLoadedPhoto={this.removeLoadedPhoto}
            /> */}
          </Grid>
        </form>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  avatar: state.profile.avatar,
  loading: state.app.loading,
  errors: state.errors,
  initialValues: state.profile,
  supplier: state.profile.supplier,
  formValues: getFormValues("Profile")(state)
});

const mapDispatchToProps = {
  getProfileInfo,
  updateProfileImages,
  updateProfileInfo,
  clearErrors,
  getDeliveryInfo,
  updateDeliveryInfo,
  addDeliveryInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'Profile',
    validate: (vals) => validateProfileInfo(vals),
    enableReinitialize: true
  })(Profile)
);
