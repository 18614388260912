
import React from 'react';
import { Translate } from "react-redux-i18n";

const categoryHeaderData = [
  {
    width: 220,
    flexGrow: 1.0,
    label: <Translate value="ProductCategories.ProductCategoriesName" />,
    dataKey: 'name',
  },
  {
    width: 120,
    flexGrow: 1.0,
    label: <Translate value="ProductCategories.ProductCategoriesActions" />,
    dataKey: 'action',
  }
]

export default categoryHeaderData;