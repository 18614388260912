import React, { Component } from 'react';
import {
  Typography,
  withStyles,
  Button,
  Grid
} from '@material-ui/core';
import Layout from '../../components/Layout';
import AddIcon from '../../components/Icons/AddIcon';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getCampaignsActiveData,
  getCampaignsFinishedData,
  editCampaign,
  deleteCampaign,
  changeStatusCampaign,
  restartCampaign,
  searchCampaignsActive,
  searchCampaignsFinished,
  updateCampaignCreation,
  sortColumnCampaignsActive,
  sortColumnCampaignsFinished,
  getTotalBalanceAction
} from '../../redux/actions/campaignsActions';
import {Getlatlng} from "../../redux/actions/latlngAdress"
import CampaignsTable from './Common/CampaignsTable';
import CampaignCreation from '../CampaignCreation/CampaignCreation';
import CustomInputRounded from '../../components/CustomInputRounded';
import { Translate, I18n } from 'react-redux-i18n';
import styles from './styles';
import NumberFormat from 'react-number-format';
import CampaignsTopUpModal from './Common/CampaignsModal/CampaignsTopUpModal';
import ConfirmationsModal from '../../components/ConfirmationsModal';
import apiService from '../../api';
import queryString from "query-string"
import PaymentPopup from './Common/PaymentPopup/PaymentPopup';
import InvoiceModal from './Common/InvoiceModal/InvoiceModal'

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
    this.handleCampaignDelete = this.handleCampaignDelete.bind(this);
    this.handleCampaignDeleteConfirmed = this.handleCampaignDeleteConfirmed.bind(this);
  }
  state = {
    searchActive: '',
    searchFinished: '',
    showMatchActive: false,
    showMatchFinished: false,
    isShowModal: false,
    topUpAmount: 0,
    topUpError: I18n.t('Campaigns.CampaignsTopUpErrorOutOfRangValue'),
    confirmModalOpen: false,
    currentDataRow: {},
    isShowPaymentPopup: false,
    paymentStatus: "",
    isShowInvoiceModal: false
  };

  onKeyPressActive = e => {
    if (e.key === 'Enter') {
      this.setState({
        showMatchActive: true
      });
      this.props.searchCampaignsActive(e.target.value);
    }
    if (e.key === 'Enter' && e.target.value === '') {
      this.setState({
        showMatchActive: false
      });
      this.props.searchCampaignsActive('');
    }
  };

  onKeyPressFinished = e => {
    if (e.key === 'Enter') {
      this.setState({
        showMatchFinished: true
      });
      this.props.searchCampaignsFinished(e.target.value);
    }
    if (e.key === 'Enter' && e.target.value === '') {
      this.setState({
        showMatchFinished: false
      });
      this.props.searchCampaignsFinished('');
    }
  };

  onChangeSearchInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClearSearchInputActive = () => {
    this.setState({
      searchActive: '',
      showMatchActive: false
    });
    this.props.searchCampaignsActive('');
  };
  handleClearSearchInputFinished = () => {
    this.setState({
      searchFinished: '',
      showMatchFinished: false
    });
    this.props.searchCampaignsFinished('');
  };

  componentDidMount() {
    this.props.getCampaignsFinishedData();
    this.props.getCampaignsActiveData();
    this.props.getTotalBalanceAction();
    this.handlePaymentStatus()
  }

  handlePaymentStatus() {
    const { payment } = queryString.parse(this.props.location.search) || {};
    if (!this.state.isShowPaymentPopup) {
      this.setState(() => {
        return payment ? { isShowPaymentPopup: true, paymentStatus: payment } : { isShowPaymentPopup: false };
      });
    }
  }

  handleClosePaymentPopup = () => {
    this.setState({ isShowPaymentPopup: false });
    this.props.history.replace('/campaigns');
  };

  handleCampaignDelete = data => {
    this.setState({
      confirmModalOpen: true,
      currentDataRow: data
    });
  };

  handleCampaignDeleteConfirmed = () => {
    const { status, id } = this.state.currentDataRow;
    this.props.deleteCampaign(status, id);
    this.handleCloseConfirmModal();
  }

  handleCloseConfirmModal() {
    this.setState({
      confirmModalOpen: false,
      currentDataRow: {}
    });
  }

  handleCampaignEdit = data => {
    this.props.editCampaign(data.id);
    // this.props.history.push(`/campaign/${data.id}`)
  };

  onClickButtonCampaign = data => {
    const { status, id } = data;
    const formData = new FormData();
    if(!this.props.loading) {
      switch (status) {
        case 2:
          formData.append('status', 3);
          this.props.changeStatusCampaign(formData, id);
          break;
        case 3:
          formData.append('status', 2);
          this.props.changeStatusCampaign(formData, id);
          break;
        case 4:
          formData.append('campaign_id', id);
          this.props.restartCampaign(formData);
          break;
        default:
          break;
      }
    }
  };

  getStringDataTime = (date, lang) => {
    if (!date) return;
    let language;
    if(lang && lang === 'uk') {
      language = 'uk-UA';
    }else {
      language = 'en-US';
    }
    let month = date.toLocaleString(language, {month:'short'})
    let day = date.toLocaleString(language, {day: 'numeric'});
    let time = date.toLocaleString(language, { hour: 'numeric', hour12: true, minute: '2-digit' })
    if(month.slice(-1) === '.') month = month.slice(0, -1);
    month = month.toUpperCase();
    return `${month} ${day}, ${time}`
  };

  onSubmitTopUp = async () => {
    if (this.state.topUpAmount) {
      if (+this.state.topUpAmount < 1 || +this.state.topUpAmount > 5000) {
        this.setState({
          topUpError: I18n.t('Campaigns.CampaignsTopUpErrorOutOfRangValue'),
        });
        return;
      }
      const data = {
        amount: this.state.topUpAmount
      }
      const payrunData = await apiService.paymentTopUpPayrun(data);
      if (payrunData && payrunData.data.widget_url) {
        window.location.href = payrunData.data.widget_url;
      } else {
        this.setState({
          topUpError: I18n.t('Campaigns.CampaignsTopUpErrorNoWidgetUrl'),
        });
        return;
      }
    } else {
      this.setState({
        topUpError: I18n.t('Campaigns.CampaignsTopUpErrorAmountIsRequired'),
      });
      return;
    }
  }

  handleShowInvoiceModal = () => {
    this.setState({
      isShowInvoiceModal: true
    })
  }

  handleCloseInvoiceModal = () => {
    this.setState({
      isShowInvoiceModal: false
    })
  }

  handleShowModal = () => {
    this.setState({
      isShowModal: true,
      topUpError: I18n.t('Campaigns.CampaignsTopUpErrorOutOfRangValue')
    });
  };
  handleCloseModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal,
      topUpError: ''
    });
    if (+this.state.topUpAmount < 1 || +this.state.topUpAmount > 5000) {
      this.setState({
        topUpError: I18n.t('Campaigns.CampaignsTopUpErrorOutOfRangValue'),
      });
    }
  };
  handleChangeInput = (event) => {
    const reValue = event.target.value.replace(/[^\d.]/g, "");
    if (reValue) {
      this.setState({
        topUpAmount: reValue,
        topUpError: ''
      });
    } else {
      this.setState({
        topUpAmount: 0
      });
    }
    if (+reValue < 1 || +reValue > 5000) {
      this.setState({
        topUpError: I18n.t('Campaigns.CampaignsTopUpErrorOutOfRangValue'),
      });
    }
    if (!reValue) {
      this.setState({
        topUpError: I18n.t('Campaigns.CampaignsTopUpErrorAmountIsRequired'),
      });
    }
  }

  render() {
    const {
      classes,
      loading,
      activeRows,
      finishedRows,
      sortColumnCampaignsActive,
      sortColumnCampaignsFinished,
      campaign: { creation, editing },
      locale,
      totalBalance,
      supplier,
    } = this.props;

    let supplierBalance = (supplier && supplier.country === 'UKR' && totalBalance)? totalBalance[1].balance : totalBalance ? totalBalance[0].balance : 0;
    const currencyText = (supplier && supplier.country === 'UKR') ? 'UAH' : 'USD';
    // console.log("creation" , creation, totalBalance, supplier);
    
    
    return (
      <>
        {creation || editing ? (
          <CampaignCreation locale={locale} supplierBalance={supplierBalance} currencyText={currencyText} dataTime={this.getStringDataTime}/>
        ) : (
          <div className={classes.container}>
            <Layout isLoading={loading}>
              <Grid
                container
                justify="space-between"
                style={{ marginBottom: 16 }}
              >
                <Typography variant="h4">
                  <Translate value="Campaigns.CampaignsTitle" />
                </Typography>
                <div className={classes.alignCenter}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.props.updateCampaignCreation({
                        creation: true
                      })
                      
                    }
                    color="secondary"
                    className={classes.addBtn}
                    disabled={!supplierBalance}
                  >
                    <AddIcon className={classes.extendedIcon} />
                    <Translate value="Campaigns.CampaignsButtonCreateNew" />
                  </Button>

                  {false && supplier && supplier.country === 'UKR' && (<Button
                    variant="contained"
                    onClick={this.handleShowModal}
                    color="secondary"
                    className={classes.topUpBtn}
                  >
                    <AddIcon className={classes.extendedIcon} />
                    <Translate value="Campaigns.CampaignsButtonTopUp" /> 
                  </Button>)}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.invoiceBtn}
                    onClick={this.handleShowInvoiceModal}
                  >
                    <Translate value='Campaigns.CampaignsInvoiceTitle' />
                  </Button>

                  <Button className={classes.totalBtn}>
                    <span className={classes.priceNumber}>{(supplier && supplier.country === 'UKR') ? <NumberFormat value={supplierBalance} displayType={'text'} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} /> : <NumberFormat value={supplierBalance} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />}</span>
                    <span className={classes.currency}>{currencyText}</span>
                    <Translate value="Campaigns.CampaignsTotalBalance" />
                  </Button>
                  <div className={classes.lastUpdate}>
                    <div>
                      <Translate value="Campaigns.CampaignsLastUpdate" />:
                    </div>
                    <div>
                      {this.getStringDataTime(new Date(), locale)}
                    </div>
                  </div>
                </div>
              </Grid>

              <>
                <Paper className={classes.paperWrap}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.paperActions}
                  >
                    <CustomInputRounded
                      label={
                        <Translate value="Campaigns.CampaignsActive" />
                      }
                      name="searchActive"
                      type="search"
                      placeholder={I18n.t("LeadsList.LeadsListSearch")}
                      value={this.state.searchActive}
                      onKeyPress={this.onKeyPressActive}
                      onChange={this.onChangeSearchInput}
                      onClick={this.handleClearSearchInputActive}
                      showMatch={this.state.showMatchActive}
                      rows={activeRows}
                    />
                  </Grid>
                  <CampaignsTable
                    type="current"
                    rows={activeRows}
                    handleCampaignDelete={this.handleCampaignDelete}
                    handleCampaignEdit={this.handleCampaignEdit}
                    onClickButtonCampaign={this.onClickButtonCampaign}
                    sortColumn={sortColumnCampaignsActive}
                    supplierBalance={supplierBalance}
                    currencyText={currencyText}
                  />
                </Paper>
                <Paper className={classes.paperWrap}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.paperActions}
                  >
                    <CustomInputRounded
                      label={
                        <Translate value="Campaigns.CampaignsFinished" />
                      }
                      name="searchFinished"
                      type="search"
                      placeholder={I18n.t("LeadsList.LeadsListSearch")}
                      value={this.state.searchFinished}
                      onKeyPress={this.onKeyPressFinished}
                      onChange={this.onChangeSearchInput}
                      onClick={this.handleClearSearchInputFinished}
                      showMatch={this.state.showMatchFinished}
                      rows={finishedRows}
                    />
                  </Grid>
                  <CampaignsTable
                    type="finished"
                    rows={finishedRows}
                    onClickButtonCampaign={this.onClickButtonCampaign}
                    handleCampaignDelete={this.handleCampaignDelete}
                    sortColumn={sortColumnCampaignsFinished}
                    supplierBalance={supplierBalance}
                    handleCampaignEdit={this.handleCampaignEdit}
                    currencyText={currencyText}
                  />
                </Paper>
              </>
            </Layout>
          </div>
        )}
        <CampaignsTopUpModal
          modalName="Top Up"
          topUpError={this.state.topUpError}
          open={this.state.isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSubmitTopUp={this.onSubmitTopUp}
          handleChangeInput={this.handleChangeInput}
          supplier={supplier}
        />
        <ConfirmationsModal
            open={this.state.confirmModalOpen}
            handleClose={this.handleCloseConfirmModal}
            handleConfirm={this.handleCampaignDeleteConfirmed}
            confirmData={{
              message: "Campaigns.CampaignsTableConfirmDeleteMessage",
              acceptBtnLabel: "Campaigns.CampaignsTableModalYes",
              cancelBtnLabel: "Campaigns.CampaignsTableModalNo"
            }}
          />
        <PaymentPopup
            onClose={this.handleClosePaymentPopup}
            open={this.state.isShowPaymentPopup}
            status={this.state.paymentStatus}
        />
        <InvoiceModal
          open={this.state.isShowInvoiceModal}
          handleCloseModal={this.handleCloseInvoiceModal}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeRows: state.campaigns.rowsActive,
  finishedRows: state.campaigns.rowsFinished,
  campaign: state.campaigns.campaignCreation,
  loading: state.app.loading,
  locale: state.i18n.locale,
  totalBalance: state.campaigns.totalBalance,
  supplier: state.profile.supplier,
});

const mapDispatchToProps = {
  getCampaignsActiveData,
  getCampaignsFinishedData,
  editCampaign,
  deleteCampaign,
  changeStatusCampaign,
  restartCampaign,
  searchCampaignsActive,
  searchCampaignsFinished,
  updateCampaignCreation,
  sortColumnCampaignsActive,
  sortColumnCampaignsFinished,
  getTotalBalanceAction,
  Getlatlng
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Campaigns));
