import { Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import CustomInput from '../../../../components/CustomInput';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import DoneIcon from '../../../../components/Icons/DoneIcon';
import { InvoiceDocument } from '../InvoiceTemplate/InvoiceTemplate';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import apiService from '../../../../api/apiService';

const InvoiceModal = ({ classes, open, handleCloseModal }) => {
  const [invoiceName, setInvoiceName] = React.useState('');

  const [invoiceSum, setInvoiceSum] = React.useState(0);

  const [isShowLink, setIsShowLink] = React.useState(false);

  const [link, setLink] = React.useState(null);

  const [errorName, setErrorName] = React.useState(null);

  const [errorSum, setErrorSum] = React.useState(null);

  const handleSubmit = () => {
    let err = false;

    if (invoiceName === '') {
      err = true;
      setErrorName("Потрібен Ім'я");
    } else {
      setErrorName(null);
    }

    if (invoiceSum === '' || invoiceSum === 0 || invoiceSum === '0') {
      err = true;
      setErrorSum('Потрібен Сума');
    } else {
      setErrorSum(null);
    }

    if (err) return;

    setIsShowLink(true);
    setLink(
      <PDFDownloadLink
        document={<InvoiceDocument name={invoiceName} sum={invoiceSum} />}
        fileName='invoice.pdf'
      >
        {({ url }) => {
          if (url === null) {
            return <Typography variant='caption'>Printing ...</Typography>;
          }
          apiService.printInvoice(invoiceName, invoiceSum);
          return <Typography variant='caption'>Download now!</Typography>;
        }}
      </PDFDownloadLink>
    );
  };

  const onClose = () => {
    setInvoiceName('');
    setInvoiceSum(0);
    setIsShowLink(false);
    setErrorName(null);
    setErrorSum(null);
    handleCloseModal();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Grid container className={classes.container}>
        <Typography variant='h6' className={classes.title}>
          <Translate value='Campaigns.CampaignsInvoiceTitle' />
        </Typography>
        <Grid container className={classes.modalContent} wrap='nowrap'>
          <div style={{ marginBottom: '10px', position: 'relative' }}>
            <CustomInput
              error={errorName && true}
              type='text'
              label={<Translate value='Campaigns.CampaignsInvoiceName' />}
              labelWrapWidth='70px'
              value={invoiceName}
              onChange={(e) => {
                setIsShowLink(false);
                setInvoiceName(e.target.value);
              }}
              maxLength='20'
            />
            {errorName && (
              <div style={{ position: 'absolute', top: 7, left: 50 }}>
                <Tooltip placement='top-start' title={errorName}>
                  <InfoOutlinedIcon style={{ color: '#FF3D33', fontSize: 18 }} />
                </Tooltip>
              </div>
            )}
          </div>
          <div style={{ marginBottom: '10px', position: 'relative' }}>
            <CustomInput
              error={errorSum && true}
              type='text'
              label={<Translate value='Campaigns.CampaignsInvoiceSum' />}
              labelWrapWidth='70px'
              value={invoiceSum}
              onChange={(e) => {
                setIsShowLink(false);
                setInvoiceSum(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
              }}
              maxLength='10'
              // onChange={(e) => setInvoiceSum(e.target.value.replace(/[^\d]/g, ''))}
            />
            {errorSum && (
              <div style={{ position: 'absolute', top: 7, left: 50 }}>
                <Tooltip placement='top-start' title={errorSum}>
                  <InfoOutlinedIcon style={{ color: '#FF3D33', fontSize: 18 }} />
                </Tooltip>
              </div>
            )}
          </div>
        </Grid>
        <Grid container justify='flex-end' className={classes.actionButtonsWrap}>
          <Button variant='outlined' onClick={onClose} className={classes.cancelBtn}>
            <CloseIcon className={classes.extendedIcon} />
            <Translate value='Campaigns.CampaignsInvoiceCancel' />
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.saveBtn}
            onClick={handleSubmit}
          >
            <DoneIcon className={classes.extendedIcon} />
            <Translate value='Campaigns.CampaignsInvoiceSubmit' />
          </Button>
        </Grid>
      </Grid>
      {isShowLink && <div style={{ margin: '10px auto' }}>{link}</div>}
    </Dialog>
  );
};

export default withStyles(styles)(InvoiceModal);
