import {
  SET_GEO_TAGS,
  SET_SELECTED_GEO,
  SET_SELECTED_GEO_OTHERS,
  SET_ADDED_TAGS,
  UPDATE_ADDED_TAGS,
  SET_WHAT_TAGS,
  SET_SELECTED_WHAT,
  SET_WHICH_TAGS,
  SET_SELECTED_WHICH,
  REMOVE_SELECTED_WHICH,
  SET_ADDED_TAGS_WHICH,
  CLEAR_TAGS_STATE,
  SET_KEYWORDS,
  SET_SELECTED_KEYWORDS,
  SET_ADDED_KEYWORDS
} from "../actions/types";

const initialState = {
  options: {
    geo_tags: {
      country: [],
      state: [],
      city: [],
      district: []
    },
    what_tags: {
      levelOne: [],
      levelTwo: [],
      levelThree: [],
      levelFour: []
    },
    which_tags: [],
    keywords: []
  },
  selected: {
    geo_tags: {
      country: null,
      state: null,
      city: null,
      district: null,
    },
    what_tags: {
      levelOne: null,
      levelTwo: null,
      levelThree: null,
      levelFour: null
    },
    which_tags: [],
    keywords: [],
    geo_tags_others: {}
  },
  addedTags: [],
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WHAT_TAGS:
    case SET_GEO_TAGS: {
      return {
        ...state,
        options: {
          ...state.options,
          [action.tagType]: {
            ...state.options[action.tagType],
            [action.tagName]: [...action.payload]
          }
        }
      };
    }
    case SET_WHICH_TAGS: {
      return {
        ...state,
        options: {
          ...state.options,
          which_tags: [...action.payload]
        }
      };
    }
    case SET_KEYWORDS: {
      return {
        ...state,
        options: {
          ...state.options,
          keywords: action.payload
        }
      }
    }
    case SET_SELECTED_KEYWORDS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          keywords: action.payload
        }
      }
    }

    case SET_ADDED_KEYWORDS: {
      const newState = [...action.payload];
      return {
        ...state,
        addedTags: newState
      }
    }

    case SET_SELECTED_WHAT:
    case SET_SELECTED_GEO: {
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.tagType]: {
            ...state.selected[action.tagType],
            [action.tagName]: action.payload
          }
        }
      };
    }

    case SET_SELECTED_GEO_OTHERS: {
      if (!action.payload) {
        const entries = Object.entries(state.selected.geo_tags_others).map(v => ({
          key: v[0],
          value: v[1]
        }))
        const index = entries.findIndex(v => v.key === action.tagName);
        if (index >= 0) {
          entries.splice(index, entries.length);
        }
        const temp = {};
        entries.forEach(v => {
          temp[v.key] = v.value;
        })
        return {
          ...state,
          selected: {
            ...state.selected,
            geo_tags_others: {
              ...temp
            }
          }
        };
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          geo_tags_others: {
            ...state.selected.geo_tags_others,
            [action.tagName]: action.payload
          }
        }
      }
    }

    case SET_SELECTED_WHICH: {
      const newState = [...state.selected.which_tags];
      newState.push(action.payload);
      return {
        ...state,
        selected: {
          ...state.selected,
          which_tags: newState
        }
      };
    }

    case REMOVE_SELECTED_WHICH: {
      const currentState = [...state.selected.which_tags];
      const newState = currentState.filter(obj => obj.id !== action.payload.id);
      return {
        ...state,
        selected: {
          ...state.selected,
          which_tags: newState
        }
      };
    }
    case SET_ADDED_TAGS: {
      const newState = [...state.addedTags];
      newState.push(action.payload)
      return {
        ...state,
        addedTags: newState
      };
    }

    case SET_ADDED_TAGS_WHICH: {
      const newState = [...state.addedTags, ...action.payload]
      return {
        ...state,
        addedTags: newState
      }
    }
    case UPDATE_ADDED_TAGS: {
      return {
        ...state,
        addedTags: [...action.payload]
      };
    }
    case CLEAR_TAGS_STATE: {
      return {
        ...state,
        options: { ...initialState.options },
        selected: { ...initialState.selected },
        addedTags: [...initialState.addedTags],
      }
    }
    default:
      return state;
  }
}
