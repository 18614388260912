import React from 'react';
import styles from './styles';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ClassNames } from '@emotion/core';

const PaymentPopup = ({ onClose, open, classes, status }) => {
  const isSuccess = status === 'success';
  const title = isSuccess ? 'Success!' : 'Failed!';
  return (
    <Dialog classes={{ paper: classes.popupPaper }} onClose={onClose} open={open}>
      <div className={classes.popupTitle}>
        {isSuccess ? (
          <CheckCircleIcon fontSize='large' />
        ) : (
          <CancelIcon className={classes.iconFail} fontSize='large' />
        )}
        <ClassNames>
          {({ cx }) => (
            <Typography
              variant='h5'
              className={cx({
                [classes.title]: true,
                [classes.titleFail]: !isSuccess
              })}
            >
              {title}
            </Typography>
          )}
        </ClassNames>
      </div>
      <DialogContent>
        {isSuccess ? (
          <DialogContentText>Your payment process successfully</DialogContentText>
        ) : (
          <>
            <DialogContentText>Your payment failed!</DialogContentText>
            <DialogContentText>Please try again!</DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <ClassNames>
          {({ cx }) => (
            <Button
              onClick={onClose}
              className={cx({
                [classes.button]: true,
                [classes.buttonFail]: !isSuccess
              })}
            >
              OK
            </Button>
          )}
        </ClassNames>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PaymentPopup);
