import { Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import DoneIcon from '../../../../components/Icons/DoneIcon';
import { AgreementBody } from './AgreementBody';
import styles from './styles';

const AgreementModal = ({ classes, open, handleCloseModal, handleSubmit, isAgree }) => {
  const loading = useSelector(state => state.app.loading)

  const onSubmit = () => {
    handleSubmit();
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      open={open}
      scroll='paper'
      className={classes.container}
      classes={{ paperScrollPaper: classes.paperScrollPaper }}
    >
      <DialogTitle>
        <Typography variant='h6' className={classes.title}>
          Договір
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AgreementBody />
        </DialogContentText>
        <Grid container justify='flex-end' className={classes.actionButtonsWrap}>
          <Button variant='outlined' onClick={handleCloseModal} className={classes.cancelBtn}>
            <CloseIcon className={classes.extendedIcon} />
            Закрити
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.saveBtn}
            onClick={onSubmit}
            disabled={isAgree || loading}
          >
            <DoneIcon className={classes.extendedIcon} disabled={isAgree} />
            {isAgree ? 'Прийнято' : 'Прийняти'}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AgreementModal);
