import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeToastSystem } from '../../redux/actions/appActions';

const ToastSystem = props => {
    const { anchorOrigin, autoHideDuration } = props;
    const runAction = useDispatch();
    const { message } = useSelector(state => state.app.toastSystemState);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (message) {
            setIsOpen(true);
        }
    }, [message]);

    const closeToastAction = () => {
        setIsOpen(false);
        runAction(closeToastSystem());
    }

    return (
        <Snackbar
            anchorOrigin={{ ...anchorOrigin }}
            open={isOpen}
            autoHideDuration={autoHideDuration || 6000}
            onClose={closeToastAction}
            message={message}
        />
    )
}
ToastSystem.propTypes = {
    anchorOrigin: PropTypes.shape({
        vertical: "top" | "bottom",
        horizontal: "left" | "right" | "center"
    }).isRequired,
    autoHideDuration: PropTypes.number
}

export default ToastSystem;