import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import TringleIcon from '../../../components/Icons/TringleIcon';
import EditIcon from '../../../components/Icons/EditIcon';

const ActionGroupStyle = styled.div`
    .root-icon{
        padding: 8px;
        margin-left: 26px;
    }
    .disable {
        & svg {
            & path {
                fill: '#D5D8DA'
            }
        }
    }
`;

const ActionGroupCell = ({ onDeleteProduct, onEditProduct, rowData }) => {

    return (
        <ActionGroupStyle>
            <IconButton
                classes={{
                    root: 'root-icon',
                    disabled: 'disable'
                }}
                disabled={false}
                onClick={() => onEditProduct(rowData)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                classes={{
                    root: 'root-icon',
                    disabled: 'disable'
                }}
                disabled={false}
                onClick={() => onDeleteProduct(rowData)}
            >
                <TringleIcon />
            </IconButton>
        </ActionGroupStyle>
    )
}

ActionGroupCell.propTypes = {
    onDeleteProduct: PropTypes.func,
    onEditProduct: PropTypes.func,
    rowData: PropTypes.any.isRequired
}

export default ActionGroupCell;