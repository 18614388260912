import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import OptionItemLogo from "../Icons/OptionItemLogo";
import InputLogo from "../Icons/InputLogo";
import { I18n } from "react-redux-i18n";

const styles = theme => ({
  wrapSelect: {
    marginBottom: 8
  },
  label: {
    marginRight: 16
  },
  input: {
    display: "flex",
    padding: 0,
    minWidth: 240,
    height: 32,
    "&:hover": {
      cursor: "pointer"
    }
  },
  inputUnfixedHeight: {
    display: "flex",
    padding: 0,
    width: 240,
    height: 'unset',
    minHeight: 32,
    justifyContent: 'space-between',
    "&:hover": {
      cursor: "pointer"
    }
  },
  valueContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flex: 1,
    alignItems: "center",
    padding: '8px'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontFamily: "Roboto",
    fontSize: 14
  },
  placeholder: {
    position: "absolute",
    fontFamily: "Roboto",
    left: 2,
    fontSize: 14
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    height: 150,
    overflowY: 'hidden',
    '&>div': {
      maxHeight: '100%',
      overflowY: 'auto'
    }

  },
  divider: {
    height: theme.spacing.unit * 2
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {`${I18n.t('CampaignsCreation.CampaignsCreationWhatTagsNoOptions')}`}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.isUnfixedHeight
            ? props.selectProps.classes.inputUnfixedHeight
            : props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <span>{props.children} {props.data.reward ? <span><OptionItemLogo /> {props.data.reward}</span> : ''}</span>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  const { classes, isDisabled } = props.selectProps
  const classname = isDisabled ? classes.singleValueDisable : classes.singleValue
  return (
    <Typography
      className={classname}
    >
      <span>{props.children} {props.data.reward ? <span><InputLogo /> {props.data.reward}</span> : ''}</span>
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.hasLabel ? props.selectProps.classes.paper : props.selectProps.classes.customPaper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class SelectInput extends React.Component {
  render() {
    const {
      classes,
      label,
      options,
      onChangeSelect,
      selectedValue,
      tagsType,
      tagsName,
      disabled,
      isNormalSelection,
      className,
      isUnfixedHeight
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: "black",
        "& svg:hover": {
          cursor: "pointer"
        },
        "& input": {
          font: "inherit",
          color: "transparent!important"
        }
      })
    };

    const getOptionValue = val => val.key;

    const getOptionLabel = val => val.display

    const onChangeSelection = !isNormalSelection ? onChangeSelect({ tagsName, tagsType }) : (val) => { onChangeSelect(val) };


    return (
      <NoSsr>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="space-between"
          className={label ? classes.wrapSelect : classes.customWrapSelect}
        >
          {label && <Typography style={isNormalSelection && { width: 140, marginRight: 28 }} variant="body2" className={classes.label}>
            {label}
          </Typography>}
          <Select
            hasLabel={label ? true : false}
            className={className}
            isClearable={!isNormalSelection}
            classes={classes}
            styles={selectStyles}
            options={isNormalSelection ? options : options.map(suggestion => ({
              id: suggestion.id,
              label: suggestion.display_name,
              path: suggestion.path ? suggestion.path : "",
              reward: suggestion.reward ? suggestion.reward : 0,
            }))}
            components={components}
            value={selectedValue}
            isDisabled={disabled}
            onChange={onChangeSelection}
            placeholder=" "
            getOptionValue={isNormalSelection ? getOptionValue : undefined}
            getOptionLabel={isNormalSelection ? getOptionLabel : undefined}
            isUnfixedHeight={isUnfixedHeight}
          />
        </Grid>
      </NoSsr>
    );
  }
}

SelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
  isNormalSelection: PropTypes.bool,
  isUnfixedHeight: PropTypes.bool
};

export default withStyles(styles, { theme: true })(SelectInput);
