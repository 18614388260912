import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
const styleModules = {
    clearAllBtn: {
        color: '#0028c2',
        marginRight: 27,
        '& svg': {
            marginRight: 8,
            marginBottom: 1
        }
    },
    addBtn: {
        minWidth: 104,
        height: 32,
        textTransform: 'capitalize',
        background: "#0028c2",
        '& svg': {
            marginRight: 12
        },
        '& path': {
            fill: '#ffffff'
        }
    },
    container: {
        padding: '30px 24px', borderBottom: "1px solid rgba(199, 199, 199, 0.24)"
    }

};

const ProductCampaignModalStyle = styled.div`
    width: 55rem;
    .product-dialog-content{
        padding-top: 1rem;
    }

    .action-group{
        margin: 20px;
    }
    .cancel-btn {
        min-width: 104px;
        border-radius: 24px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #0028c2;
        border: 1px solid #0028c2;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        & svg {
            margin-right: 8px;
            margin-bottom: 2px;
            
            & path {
                fill: #0028c2;
            }
        }
    }
    .submit-btn {
        min-width: 104px;
        box-shadow: 0 8px 12px -8px rgba(0, 127, 255, 0.24);
        color: #fff;
        font-size: 14px;
        margin-left: 24px;
        background: #0028c2;
        & path:nth-of-type(1){
            fill: #fff;
        }
        & svg {
            margin-right: 8px;
            margin-top: 3px;
        }
    }
    /* width */
    & ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    & ::-webkit-scrollbar-track {
        background: #fff; 
    }
    
    /* Handle */
    & ::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
        background: #dfdfdf;
    }
`;

const ProductPickListStyle = styled(Grid)`
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    color: #a0b1cb;
    display: flex;
    justify-content: space-between;

    .add-icon{
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
        font-size: 1.2rem;
        cursor: pointer;
    }
    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 1.2rem;
        padding: 0.75rem;
        & .custom-list {
            overflow-y: scroll;
            max-height: 20rem;
            min-height: 20rem;
            padding-left: 4px;
            &.selected{
                min-height: 22.5rem;
            }
        }
    }
    .search-group{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        & div:first-child {
            width: 100%;
        }
        .search-input{
            width: 100%;
        }
    }
    .btn-control{
        margin-bottom: 0.25rem;
        color: #153bc7;
        border-color: #153bc7;
    }
`;

const ActionPick = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ProductPickItemStyle = styled.div`
    font-family: Roboto;
    font-weight: 400;
    font-size: 1rem;
    border-bottom: 1px solid #dfdfdf;

    .list-item-custom{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 0;
        padding-right: 8px;
    }

    .product-img{
        min-width: 5rem;
        height: 5rem;
        border-radius: 4px;
        margin-right: 0.4rem;
        background: url(${props => props.src});
        background-size: cover;
    }

    .info{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-top: 0.2rem;
        & .product-name {
            color: #333;
            padding-right: 5px;
            margin-bottom: 5px;
        }
        & .price {
            font-size: 0.8rem;
            color: #3e3e3e;
        }
    }
    & svg {
        fill: ${props => props.active ? '#0029c2' : '#dfdfdf'};
    }
`;

export {
    styleModules,
    ProductPickItemStyle,
    ActionPick,
    ProductPickListStyle,
    ProductCampaignModalStyle
};