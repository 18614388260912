import React, { Component } from "react";
import { Typography, withStyles, Grid, Button, Paper } from "@material-ui/core";
import Layout from "../../components/Layout";
import { CSVLink } from "react-csv";
import {
  getLeadsTableData,
  patchLeadsConfirm,
  patchLeadsConfirmAll,
  searchLeadList,
  sortColumnLeadsList,
  patchLeadsRead,
  patchFeedbackLeadsRead,
} from "../../redux/actions/leadsListActions";
import LeadListTable from "./Common/LeadListTable";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import CustomInputRounded from "../../components/CustomInputRounded";
// import SelectInputCampaign from '../../components/SelectInput/SelectInputCampaign';
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import ModalLeadList from "./Common/ModalLeadList";
import { getNextDataPage } from "../../helpers";
import styles from "./styles";

class LeadsList extends Component {
  state = {
    isShowModal: false,
    searchDelete: false,
    searchInputValue: "",
    showMatch: false,
    data: {},
    leadId: null
  };

  componentDidMount() {
    this.props.getLeadsTableData();
  }

  countConfirmLength = (rows) =>
    rows.reduce((acc, item) => {
      if (!item.is_confirmed && item.lead_status === 2) {
        acc++;
      }
      return acc;
    }, 0);

  confirmAll = (rows) => {
    const isNotConfirmedArr = rows.reduce((acc, item) => {
      if (!item.is_confirmed && item.lead_status === 2) {
        acc.push(item.id);
      }
      return acc;
    }, []);
    const formData = new FormData();
    formData.append("list_to_confirm", isNotConfirmedArr);
    this.props.patchLeadsConfirmAll(formData);
  };

  showModal_ = (e) => {
    this.setState({
      isShowModal: true,
      leadId: e.rowData.id
    })
  }
  showModal = (e) => {
    const { rowData } = e;
    const { address } = rowData;

    this.props.patchLeadsRead(rowData.lead.pk, { is_read: true });
    if (rowData.feedback) {
      this.props.patchFeedbackLeadsRead(rowData.feedback.id, { is_read: true });
    }

    let geoAddress = `${address.country ? address.country + "," : ""} ${
      address.region ? address.region + "," : ""
    } ${address.city ? address.city + "," : ""} ${address.district ? address.district + "," : ""}`;
    // remove last comma from string
    geoAddress = geoAddress.replace(/,\s*$/, "");

    this.setState({
      isShowModal: true,
      data: {
        id: rowData.id,
        date:
          this.props.locale === "uk"
            ? moment(rowData.created_at).tz("Europe/Kiev").format("DD.MM.YY HH:mm Z")
            : moment(rowData.created_at)
                // .utc()
                .tz("America/New_York")
                .format("DD.MM.YY HH:mm Z"),
        customer_name: rowData.customer_name,
        customer_phone: rowData.customer_phone,
        phone: rowData.customer_phone && rowData.customer_phone.replace("+", ""),
        customer_city: rowData.customer_city,
        customer_email: rowData.customer_email,
        campaign_name: rowData.campaign_name,
        tags: rowData.tags,
        is_confirmed: rowData.is_confirmed,
        lead_status: rowData.lead_status,
        description: rowData.description,
        image: rowData.image,
        pickAddress: geoAddress,
        price: rowData.price,
        instagram:
          rowData.user.instagram &&
          rowData.user.instagram.replaceAll("@", "").replaceAll("https://www.instagram.com/",""),
        telegram:
          rowData.user.telegram &&
          rowData.user.telegram.replaceAll("t.me/", "").replaceAll("https://", ""),
        viber: rowData.user.viber,
        whatsapp: rowData.user.whatsapp,
        dateWish: rowData.date ? rowData.date.split("-").reverse().join(".") : "",
        feedback: rowData.feedback,
        can_create_shipment: rowData.can_create_shipment,
        ukrposhta_tracking_id: rowData.shipment && rowData.shipment.ukrposhta_tracking_id,
        assessed_value: rowData.shipment && rowData.shipment.assessed_value,
        lead_delivery_info: rowData.lead_delivery_info,
        wish_delivery_info: rowData.wish_delivery_info
      },
    });
  };

  editSearchInput = (e) => {
    this.setState({
      searchInputValue: e.target.value,
      searchDelete: e.target.value.length >= 1,
    });
  };

  handleClearSearchInput = () => {
    this.setState({
      searchInputValue: "",
      searchDelete: false,
      showMatch: false,
    });
    this.props.searchLeadList("");
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.searchLeadList(e.target.value);
      this.setState({
        showMatch: true,
      });
    }
    if (e.key === "Enter" && e.target.value === "") {
      this.setState({
        showMatch: false,
      });
    }
  };

  onButtonClick = () => {
    const {
      data: { id },
    } = this.state;
    const formData = new FormData();
    formData.append("is_confirmed", true);
    this.props.patchLeadsConfirm(formData, id);
    this.setState({
      data: {
        ...this.state.data,
        is_confirmed: !this.state.data.is_confirmed,
      },
    });
  };

  getDataCSV = () => {
    const IdColumn = "Id";
    const DateTimeColumn = I18n.t("LeadsList.LeadsListTableDateTime");
    const CustomerNameColumn = I18n.t("LeadsList.LeadsListTableCustomerName");
    const CustomerEmailColumn = I18n.t("LeadsList.LeadsListTableEmail");
    const CustomerPhoneColumn = I18n.t("LeadsList.LeadsListTablePhone");
    const CustomerCityColumn = I18n.t("LeadsList.LeadsListTableCity");
    const CampaignNameColumn = I18n.t("LeadsList.LeadsListTableCampaignName");
    const TagsColumn = I18n.t("LeadsList.LeadsListTableTags");
    const LeadCaughtColumn = I18n.t("LeadsList.LeadsListTableLeadCaught");
    const LeadStatusColumn = I18n.t("LeadsList.LeadsListTableLeadStatus");
    const ConfirmationColumn = I18n.t("LeadsList.LeadsListTableConfirmation");
    const FulfilledByAnotherText = I18n.t("LeadsList.LeadsListTableFulfilledByAnother");
    const InprogressText = I18n.t("LeadsList.LeadsListTableInProgress");
    const CompletedText = I18n.t("LeadsList.LeadsListTableCompleted");
    return this.props.rows.map((x) => ({
      [IdColumn]: x.id,
      [DateTimeColumn]:
        this.props.locale === "uk"
          ? moment(x.created_at).tz("Europe/Kiev").format("DD.MM.YY HH:mm Z")
          : moment(x.created_at)
              // .utc()
              .tz("America/New_York")
              .format("DD.MM.YY HH:mm Z"),
      [CustomerNameColumn]: x.customer_name,
      [CustomerEmailColumn]: x.customer_email,
      [CustomerPhoneColumn]: x.customer_phone,
      [CustomerCityColumn]: x.customer_city,
      [CampaignNameColumn]: x.campaign_name,
      [TagsColumn]: x.tags_count,
      [LeadCaughtColumn]: x.suppliers_caught_count,
      [LeadStatusColumn]: x.fulfilled.fulfilled_by_another
        ? FulfilledByAnotherText
        : x.lead_status === 1
        ? InprogressText
        : CompletedText,
      [ConfirmationColumn]: x.is_confirmed,
    }));
  };

  loadMoreData = () => {
    const { nextPage, getDefaultLeast, sorting, sortingName, searching } = this.props.leadsList;
    const { getLeadsTableData, searchLeadList, sortColumnLeadsList } = this.props;
    const { searchInputValue } = this.state;
    if (nextPage) {
      let next = getNextDataPage(nextPage);
      if (sorting) {
        sortColumnLeadsList(sortingName, next);
      } else if (searching) {
        searchLeadList(searchInputValue, next);
      } else if (getDefaultLeast) {
        getLeadsTableData(next);
      }
    }
  };

  render() {
    const {
      classes,
      rows,
      leadsList,
      loading,
      patchLeadsConfirm,
      sortColumnLeadsList,
      patchLeadsRead,
      patchFeedbackLeadsRead,
      locale,
    } = this.props;

    return (
      <div className={classes.container}>
        <Layout isLoading={loading}>
          <Typography variant="h4" style={{ marginBottom: 16 }}>
            <Translate value="LeadsList.LeadsListTitle" />
          </Typography>
          <Paper style={{ width: "100%", height: "100%" }}>
            <Grid
              container
              justify="space-between"
              style={{ width: "100%", height: "100%", padding: 24 }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomInputRounded
                  type="search"
                  placeholder={I18n.t("LeadsList.LeadsListSearch")}
                  value={this.state.searchInputValue}
                  onKeyPress={this.onKeyPress}
                  onChange={this.editSearchInput}
                  onClick={this.handleClearSearchInput}
                  showMatch={this.state.showMatch}
                  rows={rows}
                />
              </div>
              <Grid item>
                <CSVLink
                  className={classes.buttonDownload}
                  filename={"lead-list-file.csv"}
                  data={this.getDataCSV()}
                >
                  <DownloadIcon className={classes.buttonDownloadIcon} />
                  <Translate value="LeadsList.LeadsListButtonDownload" />
                </CSVLink>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.rootBtn}
                  onClick={() => this.confirmAll(rows)}
                  disabled={this.countConfirmLength(rows) < 1}
                >
                  <Translate value="LeadsList.LeadsListButtonConfirmAll" />
                  {this.countConfirmLength(rows)}
                </Button>
              </Grid>
            </Grid>
            <LeadListTable
              rows={rows}
              patchLeadsConfirm={patchLeadsConfirm}
              patchLeadsRead={patchLeadsRead}
              patchFeedbackLeadsRead={patchFeedbackLeadsRead}
              showModal={this.showModal}
              sortColumn={sortColumnLeadsList}
              locale={locale}
            />
            <Grid className={classes.loadMoreContainer}>
              <Button
                className={`${classes.rootBtn} ${classes.loadMoreDataBtn}`}
                variant="contained"
                color="primary"
                onClick={this.loadMoreData}
                disabled={loading || leadsList.rows.length === leadsList.count}
              >
                {I18n.t("Products.ProductsLoadMore")}
              </Button>
              <div>{`${I18n.t("Products.ProductsLoaded")} ${leadsList.rows.length} ${I18n.t(
                "Products.ProductsOf"
              )} ${leadsList.count}`}</div>
            </Grid>
            <ModalLeadList
              isShowModal={this.state.isShowModal}
              data={this.state.data}
              handleCloseModal={this.handleCloseModal}
              onButtonClick={this.onButtonClick}
            />
          </Paper>
        </Layout>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rows: state.leadsList.rows,
  leadsList: state.leadsList,
  selectCampaign: state.leadsList.selectCampaign,
  loading: state.app.loading,
  locale: state.i18n.locale,
  downloadData: state.leadsList.downloadData,
});

const mapDispatchToProps = {
  getLeadsTableData,
  patchLeadsConfirm,
  patchLeadsConfirmAll,
  searchLeadList,
  sortColumnLeadsList,
  patchLeadsRead,
  patchFeedbackLeadsRead,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeadsList));
