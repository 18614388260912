const styles = {
    clearAllBtn:{
        color: '#0028c2',
        marginRight: 27,
        '& svg':{
            marginRight: 8,
            marginBottom: 1
        }
    },
    addBtn:{
        minWidth: 104,
        height: 32,
        textTransform: 'capitalize',
        background:"#0028c2",
        color: "#fff",
        '& svg':{
            marginRight: 12
        },
        '& path':{
            fill: '#ffffff'
        }
    },
    container:{
      padding: '30px 24px', borderBottom: "1px solid rgba(199, 199, 199, 0.24)"
    },

   
  };

export default styles;