import {
  SET_GEO_TAGS,
  SET_SELECTED_GEO,
  SET_SELECTED_GEO_OTHERS,
  SET_ADDED_TAGS,
  UPDATE_ADDED_TAGS,
  SET_WHAT_TAGS,
  SET_WHICH_TAGS,
  SET_SELECTED_WHICH,
  REMOVE_SELECTED_WHICH,
  SET_ADDED_TAGS_WHICH,
  CLEAR_TAGS_STATE,
  SET_ADDED_TAGS_GEO,
  SET_KEYWORDS,
  SET_SELECTED_KEYWORDS,
  SET_ADDED_KEYWORDS
} from "./types";
import apiService from "../../api/apiService";
// import { appLoading, stopAppLoading } from "./appActions";

export const setOptionsGeoTags = (arr, tagType, tagName) => ({
  type: SET_GEO_TAGS,
  payload: arr,
  tagType,
  tagName
});

export const setOptionsWhatTags = (arr, tagType, tagName) => ({
  type: SET_WHAT_TAGS,
  payload: arr,
  tagType,
  tagName
});

export const setSelectedGeoOthers = (tagName, value) => ({
  type: SET_SELECTED_GEO_OTHERS,
  payload: value,
  tagName
})

export const setSelectedValue = (obj, tagType, tagName) => ({
  type: SET_SELECTED_GEO,
  payload: obj,
  tagType,
  tagName
});

export const setOptionsWhichTags = arr => ({
  type: SET_WHICH_TAGS,
  payload: arr
});

export const updateAddedTags = arr => ({
  type: UPDATE_ADDED_TAGS,
  payload: arr
});

export const setSelectedWhich = obj => ({
  type: SET_SELECTED_WHICH,
  payload: obj
});

export const removeSelectedWhich = obj => ({
  type: REMOVE_SELECTED_WHICH,
  payload: obj
});

export const clearTagsState = () => ({
  type: CLEAR_TAGS_STATE
});

export const setAddedTags = arr => ({
  type: SET_ADDED_TAGS,
  payload: arr
});

export const setAddedTagsWhich = arr => ({
  type: SET_ADDED_TAGS_WHICH,
  payload: arr
});

export const setAddedKeywords = arr => ({
  type: SET_ADDED_KEYWORDS,
  payload: arr
});

export const setAddedTagsGeo = arr => ({
  type: SET_ADDED_TAGS_GEO,
  payload: arr
});

export const setKeywords = keywords => ({
  type: SET_KEYWORDS,
  payload: keywords
});

export const setSelectedKeywords = selectedKeywords => ({
  type: SET_SELECTED_KEYWORDS,
  payload: selectedKeywords
});

export const getTagsGeo = () => dispatch => {
  apiService
    .getGeoTags()
    .then(res => {
      dispatch(setOptionsGeoTags(res.data, "geo_tags", "country"));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTagsWhat = (country) => dispatch => {
  apiService
    .getWhatTags(country)
    .then(res => {
      dispatch(setOptionsWhatTags(res.data, "what_tags", "levelOne"));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTagsWhich = () => (dispatch, getState) => {
  const {
    campaigns: {
      campaignCreation: {
        tag
      }
    }
  } = getState();

  const TagsWhatId = tag.reduce((acc, item) => {
    if (item.kind === 'WHAT') {
      acc.push(item.id)
    }
    return acc;
  }, []);
  const id = `${!!TagsWhatId.length ? TagsWhatId : 'none'}`;
  apiService
    .getWhichTags(id)
    .then(res => {
      dispatch(setOptionsWhichTags(res.data));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getNextOptionsGeo = (id, name) => dispatch => {
  apiService
    .getNextOptionsGeo(id)
    .then(res => {
      if (name === "country") {
        dispatch(setOptionsGeoTags(res.data, "geo_tags", "state"));
      }
      if (name === "state") {
        dispatch(setOptionsGeoTags(res.data, "geo_tags", "city"));
      }
      if (name === "city") {
        dispatch(setOptionsGeoTags(res.data, "geo_tags", "district"));
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getNextOptionsWhat = (id, name) => dispatch => {
  apiService.getNextOptionsWhat(id).then(res => {
    if (name === "levelOne") {
      dispatch(setOptionsGeoTags(res.data, "what_tags", "levelTwo"));
    }
    if (name === "levelTwo") {
      dispatch(setOptionsGeoTags(res.data, "what_tags", "levelThree"));
    }
    if (name === "levelThree") {
      dispatch(setOptionsGeoTags(res.data, "what_tags", "levelFour"));
    }
  });
};

export const clearNextSelectedValues = (tagsType, tagsName) => (
  dispatch,
  getState
) => {
  const {
    tags: {
      selected: { geo_tags, what_tags }
    }
  } = getState();
  if (tagsType === "geo_tags") {
    const arr = Object.keys(geo_tags);
    const index = Object.keys(geo_tags).indexOf(tagsName);
    arr.splice(0, index + 1);
    arr.forEach(value => {
      dispatch(setSelectedValue(null, "geo_tags", value));
    });
  }
  if (tagsType === "what_tags") {
    const arr = Object.keys(what_tags);
    const index = Object.keys(what_tags).indexOf(tagsName);
    arr.splice(0, index + 1);
    arr.forEach(value => {
      dispatch(setSelectedValue(null, "what_tags", value));
    });
  }
};
