import React from 'react';
import { withStyles, Grid, Typography, Button } from "@material-ui/core";
import { Translate } from "react-redux-i18n";
import { styleModules } from './styles';
import AddIcon from '../../../../components/Icons/AddIcon';

const ProductWrapItem = props => {
    const { title, products = [], classes, handleShowModal } = props;

    return (
        <Grid
            className={classes.container}
        >
            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{ paddingBottom: 24 }}
            >
                {
                    products && (
                        <Typography variant="h6" style={{ marginBottom: 0 }}>
                            <Translate value="Products.ProductsProductItemTitle" />{`(${products.length})`}
                        </Typography>
                    )
                }

                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => handleShowModal(title)}
                        color="primary"
                        className={classes.addBtn}
                    >
                        <AddIcon />
                        <Translate value="Products.ProductsCampaignWrapperAddButton" />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styleModules)(ProductWrapItem);