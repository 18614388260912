/**@jsx jsx */
import { css, jsx } from '@emotion/core';
import getUniqueArray from "../../../../utils/getUniqeArray";
import { withStyles, Grid, Typography, Button, Chip } from "@material-ui/core";
import ClearAllIcon from "../../../../components/Icons/ClearAllIcon";
import AddIcon from "../../../../components/Icons/AddIcon";
import CloseIcon from "../../../../components/Icons/CloseIcon";

import styles from "./styles";
import { Translate, I18n } from "react-redux-i18n";

const TagsWrapItem = ({
  classes,
  label,
  title,
  tags,
  handleDeleteTag,
  handleDeleteAllTags,
  handleShowModal,
  errorCreationGeoTag,
  keywords,
 
}) => {
  return (
    <Grid className={classes.container}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ paddingBottom: 24 }}
      >
        {
          tags && (
            <Typography variant="h6" style={{ marginBottom: 0 }}>
              {label}{`(${getUniqueArray(tags, 'id').length})`}
            </Typography>
          )
        }
        {
          keywords && (
            <Typography variant="h6" style={{ marginBottom: 0 }}>
              {label}{`(${getUniqueArray(keywords, 'id').length})`}
            </Typography>
          )
        }
        {title === "Geo Tags" &&
          errorCreationGeoTag &&
          getUniqueArray(tags, "id").length === 0 && (
            <span
              variant="span"
              style={{
                color: "#ff3d33",
                fontFamily: "Roboto",
                fontSize: "0.8em",
                textAlign: "center",
              }}
            >
              {I18n.t("Products.ProductsTagsItem1")} <br /> {I18n.t("Products.ProductsTagsItem2")}
            </span>
          )}
        <Grid item>
          <Button className={classes.clearAllBtn} onClick={() => handleDeleteAllTags(title)}>
            <ClearAllIcon />
            <Translate value="CampaignsCreation.CampaignsCreationButtonClearAll" />
          </Button>
          <Button
            variant="contained"
            onClick={() => handleShowModal(title)}
            color="primary"
            className={classes.addBtn}
          >
            <AddIcon />
            <Translate value="CampaignsCreation.CampaignsCreationButtonAdd" />
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        {tags && getUniqueArray(tags, 'id').map((tag, index) => (
          <Chip
            css={css`
              height: auto;
              padding: 8px 5px;
              span{
                white-space: pre-wrap;
              }
            `}
            classes={{ root: 'chip-root' }} key={tag.id} label={tag.path} deleteIcon={<CloseIcon />} onDelete={(event) => handleDeleteTag(event, tag.id)} />
        ))}

        {
          keywords && getUniqueArray(keywords, 'id').map(key => {
            return (
              <Chip
                css={css`
                  height: auto;
                  padding: 8px 5px;
                  span{
                    white-space: pre-wrap;
                  }
                `}
                key={key.id} label={key.text} deleteIcon={<CloseIcon />} onDelete={(event) => handleDeleteTag(event, key.id)} />
            )
          })
        }
      </Grid>
    </Grid>
  )};

export default withStyles(styles)(TagsWrapItem);
