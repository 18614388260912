import { Typography } from '@material-ui/core';
import React from 'react';

export const AgreementBody = () => {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Typography variant='body2'>PAYRIS</Typography>
        <Typography variant='body2'>ПУБЛІЧНА ОФЕРТА ДЛЯ ПАРТНЕРІВ</Typography>
      </div>
      <div style={{ fontStyle: 'italic', textAlign: 'right' }}>
        <Typography variant='caption'>Останнє оновлення: 21 серпня 2021 року</Typography>
      </div>
      <div style={{ fontSize: '14px' }}>
        <ol style={{ fontWeight: 'bold' }}>
          <li>
            Загальні положення
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Цей документ являє собою відкриту пропозицію (надалі – "Оферту") ТОВАРИСТВА 3
                ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ДРІМ ФЕКТОРІ УКРАЇНА", юридичної особи зареєстрованої та
                діючої за законодавством України, код ЄДРПОУ 42855250, місцезнаходження якої: 04119,
                м. Київ, вул. Сім'ї Хохлових, буд. 8, офіс 204 та яка діє в Україні з використанням
                знаку для товарів та послуг PAYRIS (свідоцтво на знак для товарів і послуг № 294230)
                (надалі – "PAYRIS") щодо укладення Договору про надання послуг із використання
                Платформи, мобільного та/або веб-додатку PAYRIS, наявної в них інформації та
                зображень, а також пов'язаних із ними додаткових послуг (надалі – "Договір") на
                викладених у цій Оферті умовах.
              </li>
              <li>
                Договір є публічним договором приєднання у розумінні статей 633 та 634 Цивільного
                кодексу України та встановлює однакові умови для всіх Партнерів, які до нього
                приєдналися. Всі умови Договору, викладені в цій Оферті, є обов'язковими для сторін
                Договору.
              </li>
              <li>
                Цей Договір за своєю суттю є змішаним договором у розумінні частини другої статті
                628 Цивільного кодексу України.
              </li>
              <li>
                Відповідно до статті 638 Цивільного кодексу України, у випадку прийняття викладених
                в Оферті умов і оплати послуг, фізична особа-підприємець або юридична особа, яка
                акцептує цю Оферту, стає Партнером (акцепт Оферти рівнозначний укладенню договору на
                умовах, викладених в Оферті) (надалі – "Партнер" або “Ви”).
              </li>
              <li>
                Цей Договір вважається укладеним в порядку статті 642 ЦК України та набуває
                юридичної сили договору з моменту акцептування Партнером Оферти у порядку,
                передбаченому вище. У випадку акцептування Оферти Партнер та PAYRIS гарантують одне
                одному, що кожен з них володіє необхідною дієздатністю, а також усіма правами і
                повноваженнями, необхідними і достатніми для укладання і виконання вимог цієї
                Оферти. Реєструючи особистий кабінет на Платформі уповноважений представник Партнера
                підтверджує, що він/вона ознайомлений/ознайомлена та погоджується із цією Офертою в
                редакції, чинній на момент реєстрації Облікового запису Партнера.
              </li>
              <li>
                Отримуючи доступ до Послуг і користуючись ними Партнер погоджується із цією Офертою.
                Якщо Партнер не погоджується з умовами цієї Оферти, то він/вона не може отримувати
                доступ до Послуг або не може користуватися ними. Ця Оферта повністю замінює всі та
                будь-які попередні угоди або домовленості з Партнером. Партнер надає свою
                безвідкличну згоду на те, що PAYRIS може скасувати цю Оферту або перестати надавати
                Послуги частково або повністю або позбавити Партнера доступу до Платформи або Послуг
                або будь-якої їх частини в будь-який час і з будь-якої причини.
              </li>
              <li>
                Послуги PAYRIS надаються та виплата Користувачам винагороди здійснюється із
                використанням фінансових послуг, що надаються фінансовою компанією Товариство з
                обмеженою відповідальністю "Пейран" (код ЄДРПОУ 39981324), що діє на підставі
                свідоцтва про реєстрацію фінансової установи серії ФК 34758 від 07 червня 2016 року,
                ліцензії на переказ коштів у національній валюті без відкриття рахунків № 50 від 05
                грудня 2016 року (надалі – "Payrun"). Приймаючи умови цієї Оферти Партнер тим самим
                гарантує, що він/вона ознайомився/ознайомилась із умовами публічної пропозиції
                (оферти) Payrun на укладення Договору про надання послуги переказу з картки на
                картку, розміщеної за посиланням: https://payrun.org.
              </li>
              <li>
                PAYRIS може час від часу змінювати Оферту. Зміни набувають чинності після публікації
                PAYRIS оновленої Оферти з внесеними виправленнями або додатковими умовами надання
                відповідних Послуг. Постійний доступ Партнера до Послуг або користування ними після
                такої публікації – свідчення того, що Партнер погоджується зі зміненою Офертою та
                оновленими умовами користування Послугами.
              </li>
              <li>
                Партнер погоджується з умовами цієї Оферти та Політики конфіденційності
                (payris.ua/privacy-policy), яка є невід’ємною частиною Договору.
              </li>
              <li>
                Партнер, ознайомившись із положеннями цієї Оферти, діючи з метою популяризації
                власних товарів / послуг шляхом їх просування за допомогою технічних можливостей
                Платформи, акцептує/приймає Оферту через надання повної та безумовної згоди з її
                умовами шляхом:
                <ol>
                  <li>реєстрації Партнером на Платформи, де Партнер заповнює відповідну заяву;</li>
                  <li>укладення заяви-приєднання до Договору.</li>
                </ol>
              </li>
              <li>
                ВСІ КОРИСТУВАЧІ ЗОБОВ'ЯЗАНІ УВАЖНО ОЗНАЙОМИТИСЯ З УМОВАМИ ЦІЄЇ ОФЕРТИ ДО ПОЧАТКУ
                ОТРИМАННЯ ДОСТУПУ ДО ПОСЛУГ ТА ПЛАТФОРМИ.
              </li>
            </ol>
          </li>
          <li>
            Визначення термінів, що застосовуються у Договорі
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Винагорода – бонусні бали, які Користувачі отримують від PAYRIS за зроблені за
                допомогою технічних можливостей Платформи пошукові запити та взяту у маркетингових
                дослідженнях участь.
              </li>
              <li>
                Веб-сайт – веб-сайт за адресою: https://payris.ua, що управляється та належить
                PAYRIS.
              </li>
              <li>
                Користувач – фізична особа, фізична особа-підприємець або юридична особа, яка
                пройшла реєстрацію на Платформі (в мобільному або веб-додатку PAYRIS або на
                веб-сайті PAYRIS) та яка може за допомогою технічних можливостей Платформи
                придбавати розміщені Партнерами замовлення та брати участь у маркетингових та інших
                дослідження PAYRIS.
              </li>
              <li>
                Обліковий запис – особливий розділ Веб-сайту, який дозволяє Користувачу або Партнеру
                отримати доступ до даних про опубліковані Користувачами бажання (пошукові запити),
                про участь у маркетингових дослідженнях, про накопичені бонусні бали та іншої
                інформації, пов'язаної із використанням Платформою.
              </li>
              <li>
                Партнер – фізична особа-підприємець або юридична особа, який/яка пройшов/пройшла
                реєстрацію на інтернет-сайті PAYRIS, та який/яка надає послуги або здійснює продаж
                товарів Користувачам за допомогою технічних можливостей Платформи.
              </li>
              <li>
                Платформа – це електронна платформа, яка дозволяє Користувачам розміщувати пошукові
                запити та брати участь у маркетингових дослідженнях, обирати та замовляти розміщені
                Партнерами на Платформі товари/послуги. При цьому Платформа дозволяє публікувати
                інформацію про товари/послуги Партнерів, приймати замовлення та платежі
                Користувачів. Окрім цього, Платформа виконує функцію маркетплейсу (віртуального
                простору для торгівлі) та створена з метою налагодження комунікації між Партнерами,
                з однієї сторони, та Користувачами, з іншої сторони.
              </li>
              <li>
                Обліковий запис – створений уповноваженим та дієздатним представником Партнера на
                підставі відповідної заяви активний особистий обліковий запис на Платформі, за
                допомогою якого Партнер може здійснювати управління порядком та умовами надання
                власних послуг/товарів Користувачам.
              </li>
              <li>
                Послуги – інформаційні, маркетингові та інші послуги PAYRIS, що надаються останньою
                Користувачам та Партнерам із використання Платформи, мобільного та/або веб-додатку
                PAYRIS, наявної в них інформації та зображень, а також пов'язаних із ними додаткових
                послуг.
              </li>
              <li>Сторона / Сторони – окремо або разом PAYRIS та Партнер.</li>
            </ol>
          </li>
          <li>
            Предмет Оферти та Договору
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                В порядку та на умовах визначених в цій Оферті PAYRIS надає Партнерам та
                Користувачам доступ до Платформи. Предметом цієї Оферти є надання PAYRIS
                інформаційних, маркетингових та інших супутніх послуг Партнерам.
              </li>
              <li>
                Акцептом цієї Оферти та підтвердженням факту укладення Договору між Payris та
                Партнером є факт реєстрації Партнером або його уповноваженим представником
                Облікового запису.
              </li>
              <li>
                Користуючись Платформою Партнер підтверджує, що повністю прочитав, зрозумів та
                беззастережно прийняв умови цієї Оферти та Договору. Якщо Партнер частково або
                повністю не згоден з умовами цієї Оферти або Договору, то Партнер не може
                користуватися Послугами.
              </li>
              <li>
                Партнер визнає та погоджуються з тим, що умови цієї Оферти або Договору та будь-які
                супровідні документи можуть бути змінені, виправлені або доповнені PAYRIS на власний
                розсуд у будь-який час без попереднього письмового повідомлення Партнера. Подальше
                користування Партнером Платформою свідчитиме про прийняття останнім будь-яких змін
                до цієї Оферти або Договору.
              </li>
            </ol>
          </li>
          <li>
            Порядок надання Послуг
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                PAYRIS перевіряє надані дані в заявці Партнера на створення Облікового запису та
                затверджує таку заявку Партнеру або відхиляє її без пояснення причин такого
                відхилення. Затвердження вказаної заявки Партнера відбувається шляхом створення
                Облікового запису Партнера на Платформі.
              </li>
              <li>
                PAYRIS має право запросити до затвердження Заявки Партнера будь-яку додаткову
                інформацію що стосується Послуг Партнера та/або самого Партнера.
              </li>
              <li>
                Відразу після створення Облікового запису Партнера, Партнер отримує на електронну
                пошту або на мобільний телефон (месенджер) вказаний в Заявці Партнера доступ до
                вказаного кабінету та такому Партнеру присвоюється унікальний ідентифікаційний номер
                (ID номер).
              </li>
              <li>
                Після отримання доступу до Облікового запису Партнера, Партнер повинен
                авторизуватися в своєму кабінеті та особисто активувати свій Публічний профіль
                Партнера.
              </li>
              <li>
                Послуги Партнера стають доступними для Користувачів виключно після активації
                Публічного профілю Партнера. Інформація про Послуги Партнера має бути точною,
                достовірною, відповідати всім вимогам передбаченим чинним законодавством України.
              </li>
              <li>
                PAYRIS має право рекомендувати Партнеру внести зміни до інформації з метою
                приведення інформації про Послуги Партнера у відповідність до чинного законодавства
                України або відредагувати самостійно таку інформацію до моменту її публікації на
                Платформі. У випадку, якщо вищезазначене з будь-яких причин здійснити неможливо
                доступ до Публічного профілю Партнера Користувачам буде тимчасово обмежено до
                моменту приведення інформації про Послуги Партнера до вимог чинного законодавства.
              </li>
              <li>
                Після активації Публічного профілю Партнера та надання доступу Користувачам до
                інформації про Послуги Партнера на Платформі, Партнер не має права самостійно, без
                згоди PAYRIS, змінювати істотні умови (вартість, строки, місце надання послуг та
                інше) опублікованих Послуг.
              </li>
              <li>
                Перед публікацією інформації про Послуги Партнера на Платформі, PAYRIS та Партнер
                погоджують умови надання таких послуг Користувачам в тому числі мінімальні та
                максимальні строки надання послуг. У випадку якщо Партнер, не погоджується з
                лімітами або пропозиціями PAYRIS, остання має право відмовити в публікації такої
                послуги на Платформі.
              </li>
              <li>
                Партнер зобов’язується не передавати, не продавати доступ до свого Облікового запису
                третім особам без згоди PAYRIS.
              </li>
              <li>
                Партнер несе повну відповідальність за дотримання майнових та немайнових прав третіх
                осіб щодо інформації переданої PAYRIS та опублікованої на Платформі при оформленні
                Заявки Партнера та реєстрації на Платформі.
              </li>
              <li>
                Користуючись Послугами PAYRIS та/або Платформою, Партнер надає Payris невиключну,
                безвідкличну, безстрокову ліцензію на використання будь-яких об’єктів права
                інтелектуальної власності Партнера, що пов’язані з його діяльністю, зокрема але не
                виключно, право на використання та/або розповсюдження у зв’язку з наданням Послуг
                PAYRIS: відповідних зображень Партнера, назви та/або комерційного та/або фірмового
                найменування Партнера, знаку для товарів та послуг Партнера та/або знаків для
                товарів та послуг, що асоціюються із Партнером, тощо.
              </li>
              <li>
                Користуючись Послугами Партнер зацікавлений в отриманні інформації про Користувачів,
                які є потенційними покупцями послуг / товарів Партнера (інформації про лідів /
                leads). При цьому інформація про таких Користувачів, їх геолокацію, вподобання та
                бажання за ключовими словами ("тегами") збирається та аналізується PAYRIS в процесі
                проведення відповідних маркетингових, соціологічних та інших досліджень та
                опитувань.
              </li>
              <li>
                Порядок, вартість та умови надання PAYRIS інформаційних та інших послуг Партнеру
                визначається індивідуально, в залежності від індустрії та вимог Партнера, що
                відображається у відповідному Обліковому записі Партнера.
              </li>
              <li>
                Необхідний технічний функціонал надається Партнерові через його Обліковий запис.
              </li>
            </ol>
          </li>
          <li>
            Права та обов’язки Сторін
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                PAYRIS зобов’язується забезпечити можливість отримання Партнером Послуг в порядку,
                визначеному цією Офертою та Договором.
              </li>
              <li>
                У випадку порушення Партнером умов викладених в цьому Договорі, PAYRIS має право
                обмежити доступ Партнера до Платформи та заблокувати Обліковий запис Партнера.
              </li>
              <li>
                PAYRIS має право направляти повідомлення Партнеру, що стосуються надання Послуг
                Виконавцем, на мобільний телефон, електронну пошту та в Обліковому записі Партнера в
                залежності від типу повідомлення.
              </li>
              <li>
                PAYRIS зобов’язується не розголошувати дані Партнера, що отримані в процесі
                створення Облікового запису Партнера та не використовувати такі дані в цілях, що не
                передбачені цим Договором. Розголошення отриманих даних можливе виключно в порядку,
                передбаченому чинним законодавством України.
              </li>
              <li>
                Партнер, акцептуючи цей Договір погоджується, що PAYRIS має право модерації
                будь-якої інформації що буде опублікована Партнером у Публічному профілі Партнер
              </li>
              <li>
                Партнер зобов’язаний надати Послуги Користувачу якісно та в строки, визначені
                відповідно до умов, опублікованих в Публічному профілі Партнера.
              </li>
              <li>
                Партнер зобов'язаний самостійно врегулювати будь-які претензії третіх осіб, в тому
                числі претензії Користувача, щодо Послуг Партнера.
              </li>
              <li>
                Партнер зобов’язаний негайно повідомити PAYRIS у випадку несанкціонованого доступу
                до Особистого кабінету Партнера третіми особами.
              </li>
              <li>
                Партнер надаючи послуги Користувачу зобов'язується суворо дотримуватися положень
                Закону України “Про захист прав споживачів” та будь-яких нормативно-правових актів
                направлених на виконання вимог законодавства щодо захисту прав споживачів. Payris не
                несе жодної відповідальності за можливі порушення прав споживачів з боку Партнера.
              </li>
            </ol>
          </li>
          <li>
            Строк дії Договору та припинення Договору
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Договір набирає чинності з моменту акцепту Оферти Партнером і діє до повного
                виконання Сторонами своїх зобов'язань за цим Договором.
              </li>
              <li>
                Договір може бути достроково розірваний у будь-який час:
                <ol>
                  <li>за згодою Сторін;</li>
                  <li>
                    за ініціативою однієї із Сторін в разі порушення іншою Стороною умов Договору з
                    письмовим повідомленням іншої Сторони. У такому випадку Договір вважається
                    розірваним з моменту отримання Стороною (в тому числі електронною поштою), яка
                    порушила умови Договору, відповідного письмового повідомлення від іншої Сторони;
                  </li>
                  <li>
                    за ініціативою однієї із Сторін за умови письмового повідомлення іншої Сторони
                    за 10 (десять) календарних днів до дати розірвання Договору; або
                  </li>
                  <li>з інших підстав, передбачених цією Офертою або Договором.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Відомості про конфіденційність та гарантії
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Вся інформація, якою Сторони обмінюються з метою виконання умов цієї Оферти та
                Договору, включаючи інформацію про технології та технічні рішення, використані
                PAYRIS, а також будь-яка комерційна інформація про умови співпраці Сторін, є
                конфіденційною і не підлягає розголошенню та/або використанню без письмової згоди
                іншої Сторони, за винятком випадків вимушеного розголошення на вимогу уповноважених
                державних органів. У разі вимушеного розголошення, Сторони зобов'язані негайно, але
                не пізніше, ніж протягом 3 (трьох) робочих днів з моменту вимушеного розголошення,
                письмово повідомити про це іншу Сторону. Незважаючи на обмеження, встановлені цим
                пунктом Оферти, Сторони погоджуються, що PAYRIS має право розміщувати зображення
                логотипу Партнера та його найменування на інтернет-сайті PAYRIS.
              </li>
              <li>
                Погоджуючись з умовами та приймаючи умови цієї Оферти шляхом її Акцепту, Партнер
                засвідчує і гарантує PAYRIS, що:
                <ol>
                  <li>
                    Партнер вказав достовірні дані (в тому числі персональні дані) при реєстрації в
                    якості Партнера на інтернет-сайті https://vchasno.com.ua і при оформленні
                    платіжних документів та при оплаті Послуг;
                  </li>
                  <li>
                    Партнер акцептує цю Оферту та тим самим укладає Договір добровільно, при цьому
                    Партнер: а) повністю ознайомився з умовами цієї Оферти, б) повністю розуміє
                    предмет цієї Оферти та Договору;
                  </li>
                  <li>
                    Партнер має всі права і повноваження, необхідні для акцептування цієї Оферти,
                    укладення та виконання Договору.
                  </li>
                </ol>
              </li>
              <li>
                Приймаючи цю Оферту Партнер погоджується, на використання електронного цифрового
                підпису в документообігу з PAYRIS в межах виконання Договору. Сторонами погоджено
                використання такого електронного цифрового підпису у порядку і на умовах,
                передбачених Законом України “Про електронний цифровий підпис”. При цьому, Сторони
                погодили, що додаткове використання печатки (електронної печатки) для документів в
                межах виконання Оферти або Договору не є потрібним.
              </li>
              <li>
                Партнер гарантує, що особа, яка використовує електронний цифровий підпис Партнера, є
                належним чином уповноваженим представником Партнера на законних підставах.
              </li>
              <li>
                Партнер зобов’язаний зберігати свій логін та пароль доступу до Платформи у таємниці
                та захищати від доступу будь-яких осіб, не уповноважених представляти Партнера.
                Партнер погоджується, що всі дії, вчинені з використанням його логіну та паролю
                вважаються вчиненими належним чином його уповноваженими представниками.
              </li>
              <li>
                Партнер не має права посилатись на вчинення від його імені дій неуповноваженими на
                це особами як на підставу уникнення відповідальності за результат таких дій та/або
                при вирішенні спірних ситуацій, за виключенням випадків, коли до моменту вчинення
                таких дій Партнер письмово повідомив PAYRIS про втрату паролю.
              </li>
            </ol>
          </li>
          <li>
            Персональні дані
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                З моменту відправлення Заявки Партнера PAYRIS, Партнер дає згоду і погоджується на
                обробку Виконавцем персональних даних Партнера, що зазначені останнім при оформленні
                Заявки. Обробка персональних даних (збір, реєстрація, накопичення, зберігання,
                адаптування, зміна, поновлення, уточнення, поширення та використання, передача,
                знеособлення, знищення) здійснюється з метою надання Послуг PAYRIS, а також з метою
                інформаційного обслуговування Користувача та Партнера.
              </li>
              <li>
                Шляхом акцептування цієї Оферти та укладення Договору Сторони надають одна одній
                право та згоду на обробку їх персональних даних безстроково відповідно до Закону
                України "Про захист персональних даних". Для цілей цього пункту під персональними
                даними Сторін маються на увазі персональні дані представника(-ів) Сторін, їх
                посадових осіб, власників/засновників та/або акціонерів/учасників. Використання і
                поширення інформації, що становить персональні дані Сторін здійснюється виключно в
                межах необхідних для забезпечення діяльності та/або захисту інтересів Сторін та
                виконання Договору.
              </li>
              <li>
                Укладенням Договору Сторони стверджують, що вся надана інформація, що становить
                персональні дані, надана Сторонами на законних підставах і вони мають право її
                використовувати та розпоряджатися нею.
              </li>
            </ol>
          </li>
          <li>
            Відповідальність і обмеження відповідальності
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                За порушення умов цієї Оферти або Договору Сторони несуть відповідальність,
                встановлену цією Офертою та/або чинним законодавством України.
              </li>
              <li>
                Партнер в повному обсязі несе відповідальність за:
                <ol>
                  <li>
                    зміст інформації, що зазначається під час реєстрації, зміст документів, що
                    завантажуються та пересилаються при використанні Платформи.
                  </li>
                  <li>
                    достовірність та повноту відомостей, зазначених Партнером при реєстрації в
                    якості користувача і достовірність гарантій і засвідчень Партнера, що містяться
                    у розділі 7 Оферти;
                  </li>
                  <li>
                    свої дії, які вчиняються ним під час використання Платформи, та дії осіб, які
                    вчиняються від його імені з використанням його даних персональної ідентифікації.
                  </li>
                </ol>
              </li>
              <li>
                Беручи до уваги умови розділу 9 Оферти, Партнер зобов'язується своїми силами і за
                свій рахунок вирішувати спори і врегулювати претензії третіх осіб щодо
                розміщеної/відправленої/отриманої інформації та/або документів, або відшкодувати
                збитки (включаючи судові витрати), завдані PAYRIS в зв'язку з претензіями та
                позовами, підставою пред'явлення яких стало розміщення/відправлення/отримання
                інформації та/або документів Партнера.
              </li>
              <li>
                Послуги надаються за принципом "як є" і "за наявності". PAYRIS відмовляється від
                усіх заяв і гарантій, прямих, непрямих або передбачених застосовним законодавством,
                явно не визначених умовами цієї Оферти або Договору, зокрема від непрямих гарантій
                задовільної якості, придатності для конкретної мети та непорушення прав
                інтелектуальної власності. Крім того, PAYRIS не робить заяв і не надає гарантій
                надійності, своєчасності, якості, придатності чи доступності послуг або будь-яких
                послуг чи товарів, які замовляються через використання послуг, або того, що послуги
                надаватимуться безперервно чи безвідмовно. PAYRIS не гарантує якості, придатності,
                безпеки або можливостей сторонніх постачальників. тільки Ви несете відповідальність
                за всі ризики, пов’язані з використанням Партнером послуг і замовлених через них
                послуг або товарів, у максимальному обсязі, передбаченому чинним законодавством.
              </li>
              <li>
                PAYRIS не несе відповідальності за будь-які помилки, упущення, збої, дефекти і
                затримки в обробці або передачі даних, неправомірному доступу до Платформи, в тому
                числі до Облікового запису Партнера, що спричинило обмеження доступу до Платформи
                для Користувачів та/або Партнерів.
              </li>
              <li>
                Платформа знаходиться в стадії розробки і може зазнати суттєвих змін. Будь-які
                сподівання щодо форми та функціональності Платформи, які має Партнер, можуть не бути
                задоволеними з низки причин, включаючи зміну планів розробки, реалізації або
                функціонування Платформи.
              </li>
              <li>
                Існують ризики, пов'язані з використанням Платформи, включаючи, але не обмежуючись
                збоєм обладнання, програмного забезпечення та підключень до Інтернету. PAYRIS не
                несе відповідальності за належну та/або повну передачу інформації, що міститься в
                будь-якому електронному повідомленні або самому електронному повідомленні, а також
                за будь-які збої, спотворення або затримки в його доставці або отриманні, незважаючи
                на те, чим це викликано.
              </li>
              <li>
                При порушенні Партнером умов цього Договору та/або норм чинного законодавства
                України, PAYRIS має право обмежити доступ Партнера до Платформи на тимчасовий
                період, а в разі грубого та/або неодноразового порушення умов цього Договору,
                відмовити в доступі до Платформи на постійній основі.
              </li>
              <li>
                PAYRIS не несе відповідальність перед Партнером за будь-які прямі та/або
                опосередковані збитки, включаючи упущену вигоду або втрачені дані, шкоду честі
                та/або діловій репутації, понесені у зв’язку з використанням останнім Платформи
                та/або Послуг, в тому числі у випадку неможливості використання Платформи та/або
                Послуг.
              </li>
              <li>
                У разі пред’явлення третіми особами, в тому числі Користувачами, претензій до
                PAYRIS, які стосуються якості, строків або умов постачання товарів або надання
                послуг Партнерами, що пропонуються останніми Користувачам за допомогою технічних
                можливостей Платформи, Партнер зобов’язаний своїми силами і за свій рахунок
                врегулювати зазначені претензії з третіми особами та захистити PAYRIS від можливих
                збитків та спорів, або виступити на стороні PAYRIS в таких спорах. Партнер
                беззастережно погоджується компенсувати будь-які збитки, в тому числі судові
                витрати, завдані PAYRIS у зв’язку з претензіями і позовами, що пов’язані з
                розміщенням інформації про товари або послуги Партнера на Платформі.
              </li>
              <li>
                PAYRIS не контролює якість та строки продажу товарів або надання послуг Партнера,
                які розміщуються на Платформі. Партнер повністю бере відповідальність за свої товари
                або послуги, що пропонуються Користувачам на Платформі і продаж таких товарів або
                надання таких послуг Партнером жодним чином не пов’язано з діяльністю PAYRIS.
              </li>
            </ol>
          </li>
          <li>
            Порядок вирішення спорів
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Всі спори та розбіжності, пов'язані з цією Офертою або Договором, Сторони вирішують
                шляхом переговорів.
              </li>
              <li>
                Всі питання, що стосуються дійсності, дотримання та тлумачення цього Договору,
                регулюються, тлумачаться та застосовуються відповідно до законодавства України.
                Будь-які спори, суперечки або претензії, що виникають у зв'язку з цією угодою або
                порушенням угоди, Сторони мають спочатку погодити добросовісно шляхом проведення
                переговорів протягом тридцяти (30) днів після письмового повідомлення про спір або
                претензії від відповідної Сторони.
              </li>
              <li>
                Якщо переговори не вирішують суперечки, спору або претензії для розумного
                задоволення вимог всіх Сторін протягом такого періоду, то Сторони беззастережно і
                безумовно подають відповідний позов до компетентного суду відповідно до
                законодавства України.
              </li>
            </ol>
          </li>
          <li>
            Форс – мажор
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                Сторони звільняються від відповідальності за часткове, повне невиконання або
                неналежне виконання зобов'язань за цією Офертою або Договором у разі, якщо
                невиконання або неналежне виконання зобов'язань є наслідком дії непереборної сили,
                тобто надзвичайних і невідворотних обставин, які Сторони не могли ні передбачити, ні
                запобігти їх настанню (обставини форс-мажору), а саме: пожежі, повені, землетруси,
                страйки, масові безпорядки, заколоти, війни, або дії органів державної влади та інші
                обставини, що роблять неможливим виконання Сторонами взятих на себе зобов’язань, і
                якщо такі обставини безпосередньо вплинули на виконання Сторонами взятих на себе
                зобов'язань за Договором.
              </li>
              <li>
                У разі настання обставин форс-мажору під час дії Договору, виконання зобов'язань за
                Договором відкладається на термін дії обставин форс-мажору.
              </li>
              <li>
                Сторона, що не може виконати зобов'язання за Договором, повинна негайно, але не
                пізніше ніж через 3 (три) календарні дні після настання обставин форс-мажору,
                письмово повідомити про це іншу Сторону. Те ж саме стосується моменту закінчення дії
                обставини форс-мажору. Несвоєчасне повідомлення про настання обставини форс-мажору
                позбавляє відповідну Сторону права посилатися надалі на зазначені вище обставини як
                на підставу для звільнення від відповідальності за невиконання або неналежне
                виконання, цілком або частково, взятих на себе зобов'язань за Договором. Настання
                обставин форс-мажору, повинно бути підтверджено довідкою відповідного компетентного
                органу.
              </li>
            </ol>
          </li>
          <li>
            Прикінцеві положення
            <ol style={{ fontWeight: 'normal' }}>
              <li>
                PAYRIS може на власний розсуд передавати будь-які свої права та/або делегувати свої
                обов'язки будь-якій третій особі в будь-який час.
              </li>
              <li>
                Будь-яке повідомлення про виконання та/або порушення цього Договору має
                здійснюватися тільки через електронну пошту Партнера та через Обліковий запис
                Партнера. Офіційним електронним засобом зв'язку вважається відповідна електронна
                пошта Партнера. PAYRIS може передати Партнеру будь-яке повідомлення за цим Договором
                шляхом: (i) направлення повідомлення в Обліковий запис Партнера на Інтернет-сайті;
                або (ii) надсилання електронного листа на адресу електронної пошти Партнера.
                Повідомлення, які PAYRIS надає шляхом розміщення в Обліковому записі Партнера будуть
                вважатися чинними з моменту розміщення повідомлення в такому кабінеті, а
                повідомлення, які PAYRIS надсилає по електронній пошті, будуть вважатися чинними,
                коли таке повідомлення буде надіслано. Партнер несе відповідальність за збереження
                адреси електронної пошти та вважатиметься таким, що отримав будь-яке електронне
                повідомлення, коли PAYRIS надішле таке електронне повідомлення, незалежно від того,
                чи отримав його Партнер та чи читає електронну пошту.
              </li>
              <li>
                PAYRIS залишає за собою право вносити зміни в Оферту та/або відкликати цю Оферту в
                будь-який момент на свій розсуд.
              </li>
              <li>
                Цей Договір набуває чинності з моменту її публікації на Інтернет-сайті -
                https://payris.ua/та є обов'язковим до виконання Партнерами після відповідної онлайн
                реєстрації, оформлення Заявки Партнера та створення Облікового запису Партнера.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};
