import {
  SET_CAMPAIGNS_ACTIVE_DATA,
  SET_CAMPAIGNS_FINISHED_DATA,
  UPDATE_CAMPAIGN_ACTIVE_ROW,
  DELETE_CAMPAIGN_ACTIVE_ROW,
  DELETE_CAMPAIGN_FINISHED_ROW,
  UPDATE_CAMPAIGN_CREATION,
  SET_INITIAL_STATE,
  ADD_NEW_TAGS_TO_CAMPAIGN,
  CREATE_NEW_CAMPAIGN,
  ADD_NEW_TAGS_TO_CAMPAIGN_GEO,
  ADD_TOTAL_BALANCE,
  ADD_NEW_KEYWORDS_TO_CAMPAIGN,
  ADD_PRODUCTS_TO_CAMPAIGN,
  ADD_SELECTED_PRODUCT_TEMP
} from "../actions/types";
import getUniqueArray from '../../utils/getUniqeArray';

const initialState = {
  rowsActive: [],
  rowsFinished: [],
  campaignCreation: {
    creation: false,
    editing: false,
    status: 1,
    name: "",
    start_date: "",
    end_date: "",
    geo_tag: [],
    tag: [],
    keywords: [],
    tags: null,
    products: []
  },
  selectedProductsTemp: [],
  totalBalance: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CAMPAIGNS_ACTIVE_DATA: {
      const newState = action.payload.reduce((acc, el) => {
        const total = el.total_cost ? el.total_cost : 0;
        acc.push({ ...el, total_cost: total });
        return acc;
      }, []);
      return {
        ...state,
        rowsActive: newState
      };
    }
    case SET_CAMPAIGNS_FINISHED_DATA: {
      const newState = action.payload.reduce((acc, el) => {
        const total = el.total_cost ? el.total_cost : 0;
        acc.push({ ...el, total_cost: total });
        return acc;
      }, []);
      return {
        ...state,
        rowsFinished: newState
      };
    }
    case UPDATE_CAMPAIGN_ACTIVE_ROW: {
      const newState = [...state.rowsActive];
      const { id } = action.payload;
      const index = newState.findIndex(el => el.id === id);
      newState[index] = { ...action.payload };
      return {
        ...state,
        rowsActive: [...newState]
      };
    }
    case CREATE_NEW_CAMPAIGN: {
      const newState = [...state.rowsActive];
      newState.push(action.payload);
      return {
        ...state,
        rowsActive: newState
      };
    }
    case DELETE_CAMPAIGN_ACTIVE_ROW: {
      const newState = state.rowsActive.reduce((acc, item) => {
        if (item.id !== action.payload) {
          acc.push(item);
        }
        return acc;
      }, []);
      return {
        ...state,
        rowsActive: [...newState]
      };
    }

    case DELETE_CAMPAIGN_FINISHED_ROW: {
      const newState = state.rowsFinished.reduce((acc, item) => {
        if (item.id !== action.payload) {
          acc.push(item);
        }
        return acc;
      }, []);
      return {
        ...state,
        rowsFinished: [...newState]
      };
    }

    case UPDATE_CAMPAIGN_CREATION: {
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          ...action.payload
        }
      };
    }

    case ADD_NEW_TAGS_TO_CAMPAIGN: {
      const newTags = []
      action.payload.forEach((tag) => {
        if (state.campaignCreation.tag.findIndex(t => t.id === tag.id) < 0) {
          newTags.push(tag)
        }
      })
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          tag: [...state.campaignCreation.tag, ...newTags]
        }
      };
    }

    case ADD_NEW_KEYWORDS_TO_CAMPAIGN: {
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          keywords: getUniqueArray([...state.campaignCreation.keywords, ...action.payload], 'id')
        }
      }
    }

    case ADD_PRODUCTS_TO_CAMPAIGN: {
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          products: action.payload
        }
      }
    }

    case ADD_SELECTED_PRODUCT_TEMP: {
      return {
        ...state,
        selectedProductsTemp: action.payload
      }
    }

    case ADD_NEW_TAGS_TO_CAMPAIGN_GEO: {
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          geo_tag: [...state.campaignCreation.geo_tag, ...action.payload]
        }
      };
    }

    case SET_INITIAL_STATE: {
      return {
        ...state,
        campaignCreation: initialState.campaignCreation
      };
    }

    case ADD_TOTAL_BALANCE: {
      return {
        ...state,
        totalBalance: action.payload
      };
    }
    default:
      return state;
  }
}
