const main = {
  LoginTitle: "Login to Payris",
  LoginEmail: "Email",
  LoginPassword: "Password"
}
const buttons = {
  LoginButton: "Login"
}
const errors = {
  LoginEmailRequired: 'Email is required',
  LoginPasswordRequired: 'Password is required',
  LoginInvalidAddress: 'Invalid email address',
  LoginEmailOrPasswordIncorrect: 'Your email or password are incorrect'
}

const Login = {
  ...main,
  ...buttons,
  ...errors
};
export default Login;
