const styles = (theme) => ({
  main: {
    height: '100%',
    width: 'auto',
    display: 'block',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      minWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 40,
    position: 'relative',
    paddingTop: 72
  },

  header: {
    width: '100%',
    position: 'fixed',
    top: 0,
    background: '#fafafa',
    zIndex: 100,
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.05), 0 4px 6px -4px rgb(0 0 0 / 0.05)'
  },
  headerWrapper: {
    width: '100%',
    maxWidth: 720,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    height: 64,
    margin: 'auto'
  },
  headerLogo: {
    width: '100%',
    maxWidth: 120
  },
  formContainer: {
    width: '100%',
    maxWidth: 720,
    paddingLeft: 24,
    paddingRight: 24
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 3
  },
  error: {
    width: '100%',
    height: 24,
    paddingLeft: 13,
    color: '#de2323',
    fontSize: 14,
    fontFamily: 'Roboto'
  },
  title: {
    fontFamily: 'Helvetica',
    fontSize: 32,
    lineHeight: 'normal',
    letterSpacing: -0.8,
    color: 'black',
    marginBottom: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    width: '100%',
    maxWidth: 720,
    paddingLeft: 24,
    paddingRight: 24
  },
  subTitle: {
    fontFamily: 'Helvetica',
    color: 'black',
    width: '100%',
    maxWidth: 720,
    marginBottom: 0,
    marginTop: theme.spacing.unit * 3,
    paddingLeft: 24,
    paddingRight: 24
  },
  logo: {
    marginBottom: theme.spacing.unit * 5
  },
  input: {
    marginBottom: theme.spacing.unit * 2,
    height: 44,
    borderRadius: 24,
    '& fieldset': {
      borderRadius: 24
    }
  },
  buttonRoot: {
    height: 44,
    width: '100%',
    borderRadius: 24,
    fontSize: 17,
    marginTop: 32,
    '& span': { color: '#ffffff' }
  },
  formControlLabelRoot: {
    marginLeft: -12
  },
  formControlLabelLabel: {
    color: 'black',
    fontFamily: 'Helvetica'
  },
  step: {
    display: 'flex',
    width: '100%',
    maxWidth: 720,
    marginBottom: 24,
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      flexDirection: 'row'
    }
  },
  stepTitle: {
    fontFamily: 'Helvetica',
    color: 'black',
    width: '100%',
    maxWidth: 720,
    marginBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: '60%'
    }
  },
  stepImgWrapper: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: '40%',
      display: 'block'
    }
  },
  stepImg: {
    width: '80%',
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: '100%'
    }
  }
});

export default styles;
