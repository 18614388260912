const styles = theme => ({
    saveBtn: {
        width: 104,
        height: 36,
        boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
        color: "#ffffff",
        fontSize: 14,
        marginLeft: 24,
        backgroundColor: "#0028c2",
        "& path:nth-child(1)": {
          fill: "#fff",
        },
        '& svg':{
          marginRight: 8,
          marginTop: 3
        }
      },
      cancelBtn: {
        width: 104,
        borderRadius: 24,
        height: 36,
        boxShadow: "0 8px 12px -8px rgba(0, 127, 255, 0.24)",
        color: "#0028c2",
        border: "1px solid #0028c2",
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
        "& path": {
          fill: "#0028c2"
        },
        '& svg':{
          marginRight: 8,
          marginBottom: '1.5px'
        }
      },
      actionWrap:{
          margin: '8px 20px'
      }
});

export default styles;