const main = {
  RequestDeleteAccountTitle: 'Or request for account deletion',
  RequestDeleteAccountEmail: 'Email',
  RequestDeleteAccountPhone: 'Phone'
};

const buttons = {
  RequestDeleteAccountSubmitButton: 'Submit'
};

const texts = {
  RequestDeleteAccountCheckbox: 'I agree to the terms and conditions'
};

const errors = {
  RequestDeleteAccountEmailRequired: 'Email is required',
  RequestDeleteAccountPhoneRequired: 'Phone is required',
  RequestDeleteAccountUserWithPhoneEmailDoesNotExist: 'User with phone/email does not exist'
};

const RequestDeleteAccount = {
  ...main,
  ...buttons,
  ...texts,
  ...errors
};

export default RequestDeleteAccount;
