import {
  SET_SHIPMENTLIST_DATA,
  ACTIVE_SEARCH_SHIPMENT,
  ACTIVE_SORT_SHIPMENT,
  DELETE_SHIPMENT,
  CREATE_SHIPMENT
} from "./types";
import store from '../store';
import { appLoading, stopAppLoading, openPopupSystem } from "./appActions";
import apiService from "../../api/apiService";
import { I18n } from 'react-redux-i18n';
import { getLeadsTableData } from "./leadsListActions";

export const saveShipmentTableData = (
  type,
  arr,
  next,
  prev,
  count,
  actionData
) => {
  return {
    type,
    payload: {
      arr,
      next,
      prev,
      count,
      actionData,
    },
  };
};

export const deleteShipmentRow = id => ({
  type: DELETE_SHIPMENT,
  payload: id
});

export const updateCreateShipmentNumber = (id, shipmentNumber) => ({
  type: CREATE_SHIPMENT,
  payload: { rows: store.getState().leadsList.rows, id, ukrposhta_tracking_id: shipmentNumber }
});


export const getShipmentListTableData = (loadMoreData) => (dispatch) => {
  dispatch(appLoading());
  let getShipmentList;

  if (loadMoreData) {
    getShipmentList = apiService.getShipmentListData(loadMoreData);
  } else {
    getShipmentList = apiService.getShipmentListData();
  }

  getShipmentList
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);

      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveShipmentTableData(
          SET_SHIPMENTLIST_DATA,
          tableDataNormolize,
          nextPage,
          prevPage,
          count
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const searchShipmentList = (data, loadMoreData) => (dispatch) => {
  dispatch(appLoading());

  let getSearchShipmentList;

  if (loadMoreData) {
    getSearchShipmentList = apiService.searchShipmentList(data, loadMoreData);
  } else {
    getSearchShipmentList = apiService.searchShipmentList(data);
  }

  getSearchShipmentList
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveShipmentTableData(
          ACTIVE_SEARCH_SHIPMENT,
          tableDataNormolize,
          nextPage,
          prevPage,
          count
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
    });
};

export const sortColumnShipment = (data, loadMoreData) => (dispatch) => {
  dispatch(appLoading());

  let getSortColumnShipment;

  if (loadMoreData) {
    getSortColumnShipment = apiService.sortColumnShipmentList(data, loadMoreData);
  } else {
    getSortColumnShipment = apiService.sortColumnShipmentList(data);
  }

  getSortColumnShipment
    .then((res) => {
      const tableDataNormolize = res.data.results.reduce((acc, item, index) => {
        acc.push({ ...item, index });
        return acc;
      }, []);
      const nextPage = res.data.next;
      const prevPage = res.data.previous;
      const count = res.data.count;
      dispatch(
        saveShipmentTableData(
          ACTIVE_SORT_SHIPMENT,
          tableDataNormolize,
          nextPage,
          prevPage,
          count,
          data
        )
      );
    })
    .then(() => dispatch(stopAppLoading()))
    .catch((err) => {
      console.log(err);
      dispatch(stopAppLoading());
    });
};

export const deleteShipment = (payload) => dispatch => {
  dispatch(appLoading());
  apiService
    .deleteShipment(payload.id)
    .then((res) => {
      dispatch(deleteShipmentRow(payload))
    })
    .then(() => dispatch(getShipmentListTableData()))
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading())
    });
};

export const createShipment = (id, value) => async (dispatch) => {
  const actionMessage = {
    createFailed: I18n.t('ShipmentList.ShipmentListCreateFailed'),
  }
  dispatch(appLoading());
  await apiService
    .createShipment(id, value)
    .then((res) => {
      dispatch(updateCreateShipmentNumber(id, res.data.ukrposhta_tracking_id))
    })
    .then(() => {
      dispatch(stopAppLoading())
    })
    .then(() => {
      dispatch(getLeadsTableData())
    })
    .catch(err => {
      console.log(err);
      dispatch(stopAppLoading())
      if (err && err.response && err.response.data && err.response.data.code) {
        dispatch(openPopupSystem({
          message: err.response.data.message,
          status: "fail",
          detail: err.response.data.error_detail
        }));
      } else {
        dispatch(openPopupSystem({
          message: `${actionMessage.createFailed}`,
          status: "fail",
          detail: err.response.data.error_detail
        }));
      }
    });
};
