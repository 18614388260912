import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 18
  }
});

function SortIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        fill="#9fb0cb"
        fillRule="evenodd"
        d="M9.429 2.402l2.492 4.153a.5.5 0 0 1-.43.758H6.509a.5.5 0 0 1-.429-.758l2.492-4.153a.5.5 0 0 1 .858 0zm-2.92 8.286h4.983a.5.5 0 0 1 .429.757l-2.492 4.153a.5.5 0 0 1-.858 0L6.08 11.445a.5.5 0 0 1 .43-.758z"
      />
    </SvgIcon>
  );
}
export default withStyles(styles)(SortIcon);
