import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 16
  }
});

function ArrowUpIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path fill="#9fb0cb" fillRule="nonzero" d="M7.5 9.5v-7a.5.5 0 0 1 1 0v7h1.617a.5.5 0 0 1 .429.757l-2.117 3.528a.5.5 0 0 1-.858 0l-2.117-3.528a.5.5 0 0 1 .43-.757H7.5z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(ArrowUpIcon);
