import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
  }
});

function ProfileActiveIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#00a0c9"/>
        <path fill="#FFF" fillRule="nonzero" d="M20 23.4c-3.441 0-6.552 2.362-7.375 5.2h14.767c-.826-2.835-3.948-5.2-7.392-5.2zm8.032 6.6H12.005c-.96 0-.96-.663-.906-.993C11.694 25.385 15.444 22 20 22c4.562 0 8.33 3.394 8.92 7.02.019.117.019.98-.888.98zM20 18.6a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2zm0 1.4a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(ProfileActiveIcon);
