import { Translate } from "react-redux-i18n";
import React from 'react';


const data = [
    {
      width: 9,
      flexGrow: 1.0,
      dataKey: 'index',
    },
    {
      width: 166,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableDateTime" />,
      dataKey: 'created_at',
    },
    {
      width: 120,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableCustomerName" />,
      dataKey: 'customer_name',
    },
    {
      width: 200,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableEmail" />,
      dataKey: 'customer_email',
    },
    {
      width: 120,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTablePhone" />,
      dataKey: 'customer_phone',
    },
    {
      width: 120,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableCity" />,
      dataKey: 'customer_city',
    },
    {
      width: 120,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableCampaignName" />,
      dataKey: 'campaign_name',
    },
    {
      width: 40,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableTags" />,
      dataKey: 'tags_count',
      numeric: true,
    },
    {
      width: 30,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableLeadCaught" />,
      dataKey: 'suppliers_caught_count',
      numeric: true,
    },
    {
      width: 180,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableLeadStatus" />,
      dataKey: 'lead_status',
    },
    {
      width: 158,
      flexGrow: 1.0,
      label: <Translate value="LeadsList.LeadsListTableConfirmation" />,
      dataKey: 'is_confirmed',
      button: 'button'
    },
  ]

export default data;