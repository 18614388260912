const main = {
  CampaignsCreationTitle: "Campaigns Creation",
  CampaignsCreationEditingTitle: "Campaigns Editing",
  CampaignsCreationWhatTags: "What Tags",
  CampaignsCreationWhichTags: "Which Tags",
  CampaignsCreationAddress: "Address",
  CampaignsCreationTime: "Time",
  CampaignsCreationTimeStartDate: "Start Date",
  CampaignsCreationTimeEndDate: "End Date",
  CampaignsCreationTimeDelivery: "Delivery time",
  CampaignsCreationPrice: "Price",
  CampaignsCreationPriceFrom: "From",
  CampaignsCreationPriceTo: "To",
  CampaignsMultipleKeywordAddNew: "Add new keywords",
  CampaignsMultipleKeywordAddAndEnter: "Add your keywords here and press Enter",
  CampaignsCampaign: "Campaign",
  CampaignsTypeAddress: "Type Address",
  CampaignsPriceItemUSD: 'USD',
  CampaignsCreationTotalPointTags: 'Total',
  CampaignsCreationDescriptionTotalPointTags: 'We will add a fee for qualified lead and use of the Payris platform to the amount of selected tags',
  CampaignsCreationInfoCostPlaceholder: 'This field will become active after selecting tags',
  CampaignsCreationInfoBonusPlaceholder: 'This field will become active after selecting tags',
  CampaignsCreationWorldwide: 'Worldwide'
}
const info = {
  CampaignsCreationInfoTitle: "General info",
  CampaignsCreationInfoName: "Campaign name",
  CampaignsCreationInfoStartDate: "Campaign Start date",
  CampaignsCreationInfoEndDate: "Campaign End Date",
  CampaignsCreationInfoCost: "Cost, ",
  CampaignsCreationInfoBonus: "Cost of the bonus for Client, ",
  CampaignsCreationInfoRadius: "Radius",
}
const modal = {
  CampaignsCreationWhatTagsModalTitle: "Add «What» Tags",
  CampaignsCreationWhatTagsNoOptions: "No options",
  CampaignsCreationWhichTagsModalTitle: "Add «Which» Tags",
  CampaignsCreationGeoTagsModalTitle: "Address",
  CampaignsCreationGeoTagsModalSelectAddress: "Address",
  CampaignsCreationGeoTagsModalSelectLongitude: "Longitude",
  CampaignsCreationGeoTagsModalSelectLatitude: "Latitude",
  CampaignsCreationGeoTagsModalSelectDistrict: "District",
  CampaignsCreationGeoTagsModalSelectOther: "Other",
  CampaignsCreationWhatTagsModalSelectlevelOne: "1st level",
  CampaignsCreationWhatTagsModalSelectlevelTwo: "2st level",
  CampaignsCreationWhatTagsModalSelectlevelThree: "3st level",
  CampaignsCreationWhatTagsModalSelectlevelFour: "4st level",
  CampaignsCreationWhatTagsModalCountry: "Country"
}

const button = {
  CampaignsCreationButtonComplete: "Complete Creation",
  CampaignsCreationButtonCompleteEdit: "Complete Edit",
  CampaignsCreationButtonClearAll: "Clear All",
  CampaignsCreationButtonAdd: "Add",
  CampaignsCreationButtonSave: "Save",
  CampaignsCreationButtonCancel: "Cancel",
}

const error = {
  CampaignsCreationInfoCostError: 'The price for lead can not be less than calculated amount',
  CampaignsCreationInfoBonusError: 'The number of bonus points is calculated less the commission.',
  CampaignsCreationBalanceError: 'Supplier balance not enough.',
  CampaignsCreationEndDateError: 'End date is not valid',
  CampaignsCreationStartDateRequired: 'Start date is required',
  CampaignsCreationEndDateRequired: 'End date is required',
  CampaignsCreationNameRequired: 'Name is required',
  CampaignsCreationCostRequired: 'Const per lead is required'
}

const CampaignsCreation = {
  ...main,
  ...info,
  ...modal,
  ...button,
  ...error
}

export default CampaignsCreation;