import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    fontSize: 40,
    circle: {},
    '&:hover circle':{
      fill:"#F3F5F8"
    }
  },
});

function HomeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
          <circle cx="20" cy="20" r="20" fill="#FFF"/>
          <path transform="translate(8,8)" fill="#9FB0CB" d="M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z"/>
    </SvgIcon>
  );
}
export default withStyles(styles)(HomeIcon);
