import React from 'react';
import styles from './styles';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ClassNames } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { closePopupSystem } from "../../redux/actions/appActions"
import { I18n } from 'react-redux-i18n';

const PopupSystem = ({ classes }) => {
  const dispatch = useDispatch()

  const { message, status, detail } = useSelector((state) => state.app.popupSystemState);

  const [isOpen, setIsOpen] = React.useState(false);

  const [title, setTitle] = React.useState();

  const [showDetail, setShowDetail] = React.useState(false);

  const onClose = () => {
    dispatch(closePopupSystem())
    setIsOpen(false)
  }

  const handleToggleDetail = () => {
    setShowDetail(!showDetail)
  }

  React.useEffect(() => {
    if (message) {
      setIsOpen(true);
    }
  }, [message]);

  React.useEffect(() => {
    if (status) {
      setTitle(status === 'success' ? I18n.t('App.AppPopupTitleSuccess') : I18n.t('App.AppPopupTitleFail'));
    }
  }, [message]);

  const isSuccess = status === 'success';

  return (
    <Dialog classes={{ paper: classes.popupPaper }} onClose={onClose} open={isOpen}>
      <div className={classes.popupTitle}>
        {isSuccess ? (
          <CheckCircleIcon fontSize='large' />
        ) : (
          <CancelIcon className={classes.iconFail} fontSize='large' />
        )}
        <ClassNames>
          {({ cx }) => (
            <Typography
              variant='h5'
              className={cx({
                [classes.title]: true,
                [classes.titleFail]: !isSuccess
              })}
            >
              {title}
            </Typography>
          )}
        </ClassNames>
      </div>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {detail !== undefined && detail !== null && (
          <>
            <Typography
              style={{ cursor: "pointer", margin: "5px 0" }}
              onClick={handleToggleDetail}
              className={classes.buttonFail}
            >
              {showDetail ? "Hide details" : "Show details"}
            </Typography>
            {showDetail && <DialogContentText>{detail}</DialogContentText>}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <ClassNames>
          {({ cx }) => (
            <Button
              onClick={onClose}
              className={cx({
                [classes.button]: true,
                [classes.buttonFail]: !isSuccess
              })}
            >
              OK
            </Button>
          )}
        </ClassNames>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PopupSystem);
