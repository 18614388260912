export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

export const SET_CONFIG = "SET_CONFIG";

export const GET_IP_GEO = "GET_IP_GEO";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

export const SET_USER_AUTH = "SET_USER_AUTH";

//profile
export const SET_PROFILE_INFO = "SET_PROFILE_INFO";

// leadsList
export const SET_LEADSLIST_DATA = "SET_LEADSLIST_DATA";
export const UPDATE_LEAD_ROW = "UPDATE_LEAD_ROW";
export const UPDATE_LEADS_ROWS_CONFIRM_ALL = "UPDATE_LEADS_ROWS_CONFIRM_ALL";
export const UPDATE_LEAD_ROW_READ = "UPDATE_LEAD_ROW_READ";
export const UPDATE_LEAD_ROW_FEEDBACK_READ = "UPDATE_LEAD_ROW_FEEDBACK_READ";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE"
export const ACTIVE_SEARCH_LEAD = "ACTIVE_SEARCH_LEAD";
export const ACTIVE_SORT_LEAD = "ACTIVE_SORT_LEAD";

//campaigns
export const SET_CAMPAIGNS_ACTIVE_DATA = "SET_CAMPAIGNS_ACTIVE_DATA";
export const SET_CAMPAIGNS_FINISHED_DATA = "SET_CAMPAIGNS_FINISHED_DATA";
export const UPDATE_CAMPAIGN_ACTIVE_ROW = "UPDATE_CAMPAIGN_ACTIVE_ROW";
export const DELETE_CAMPAIGN_ACTIVE_ROW = "DELETE_CAMPAIGN_ACTIVE_ROW";
export const DELETE_CAMPAIGN_FINISHED_ROW = "DELETE_CAMPAIGN_FINISHED_ROW";
export const CREATE_NEW_CAMPAIGN = "CREATE_NEW_CAMPAIGN";

// campaign creation
export const UPDATE_CAMPAIGN_CREATION = "UPDATE_CAMPAIGN_CREATION";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

// campaign creation tags
export const SET_SELECTED_GEO_OTHERS = "SET_SELECTED_GEO_OTHERS";
export const SET_GEO_TAGS = "SET_GEO_TAGS";
export const SET_SELECTED_GEO = "SET_SELECTED_GEO";
export const SET_ADDED_TAGS = "SET_ADDED_TAGS";
export const UPDATE_ADDED_TAGS = "UPDATE_ADDED_TAGS";
export const SET_ADDED_TAGS_GEO = "SET_ADDED_TAGS_GEO";
export const SET_WHAT_TAGS = "SET_WHAT_TAGS";
export const SET_SELECTED_WHAT = "SET_SELECTED_WHAT";
export const SET_WHICH_TAGS = "SET_WHICH_TAGS";
export const SET_SELECTED_WHICH = "SET_SELECTED_WHICH";
export const REMOVE_SELECTED_WHICH = "REMOVE_SELECTED_WHICH";
export const SET_ADDED_TAGS_WHICH = "SET_ADDED_TAGS_WHICH";
export const CLEAR_TAGS_STATE = "CLEAR_TAGS_STATE";
export const ADD_NEW_TAGS_TO_CAMPAIGN = "ADD_NEW_TAGS_TO_CAMPAIGN";
export const ADD_NEW_TAGS_TO_CAMPAIGN_GEO = "ADD_NEW_TAGS_TO_CAMPAIGN_GEO";
export const ADD_NEW_KEYWORDS_TO_CAMPAIGN = "ADD_NEW_KEYWORDS_TO_CAMPAIGN";
export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_SELECTED_KEYWORDS = "SET_SELECTED_KEYWORDS";
export const SET_ADDED_KEYWORDS = "SET_ADDED_KEYWORDS";
export const ADD_PRODUCTS_TO_CAMPAIGN = "ADD_PRODUCTS_TO_CAMPAIGN";
export const ADD_SELECTED_PRODUCT_TEMP = "ADD_SELECTED_PRODUCT_TEMP";

export const ADD_TOTAL_BALANCE = "ADD_TOTAL_BALANCE";

// product management
export const GET_PRODUCT_LIST_FAILED = "GET_PRODUCT_LIST_FAILED";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";

export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT_LIST_LOCAL = "UPDATE_PRODUCT_LIST_LOCAL";

// category management
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_FAILED = "GET_PRODUCT_CATEGORY_FAILED";

export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED";

export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED";

export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";

export const UPDATE_CATEGORY_LIST_LOCAL = "UPDATE_CATEGORY_LIST_LOCAL";
export const SET_CURRENT_CATEGORY_PAGE = "SET_CURRENT_CATEGORY_PAGE";

// toast system
export const OPEN_TOAST_SYSTEM = "OPEN_TOAST_SYSTEM";
export const CLOSE_TOAST_SYSTEM = "CLOSE_TOAST_SYSTEM";

// popup system
export const OPEN_POPUP_SYSTEM = "OPEN_POPUP_SYSTEM";
export const CLOSE_POPUP_SYSTEM = "CLOSE_POPUP_SYSTEM";

// address
export const GET_LAT_LONG_REQEST = "GET_LAT_LONG_REQEST";
export const GET_LAT_LONG_SUCCESS = "GET_LAT_LONG_SUCCESS";
export const GET_lAT_LONG_FAIL = "GET_LAT_LONG_SUCCESS";
export const MOVE_LAT_LONG = "MOVE_LAT_LONG";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const SEARCH_ADDRESS_REQUEST = "SEARCH_ADDRESS_REQUEST";
export const SEARCH_ADDRESS_SUCCESS = "SELECT_ADDRESS_SUCCESS";
export const SEARCH_ADDRESS_FAILED = "SELECT_ADDRESS_FAILED";
export const GET_PLACES_ID_ADDRESS = "GET_PLACES_ID_ADDRESS";
export const DELETE_PLACE_DEFAULT = "DELETE_PLACE_DEFAULT";
export const DELETE_PLACEID_CREATION = "DELETE_PLACEID_CREATION";

// shipment
export const SET_SHIPMENTLIST_DATA = "SET_SHIPMENTLIST_DATA";
export const ACTIVE_SEARCH_SHIPMENT = "ACTIVE_SEARCH_SHIPMENT";
export const ACTIVE_SORT_SHIPMENT = "ACTIVE_SORT_SHIPMENT";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const CREATE_SHIPMENT = "CREATE_SHIPMENT";
// delivery
export const SET_DELIVERY_INFO = "SET_DELIVERY_INFO";
export const SET_BRANCH_DELIVERY_INFO = "SET_BRANCH_DELIVERY_INFO";
