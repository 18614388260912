import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Getlatlng,
  SearchAddress,
  getPlacesIdAddress,
  MoveMaker,
  DelectePlaceIdDefault,
  DelectePlaceIdCreation
} from '../../../../redux/actions/latlngAdress';
import SelectInput from '../../../../components/SelectInput';
import { getUKTextMile } from '../../../../helpers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, Circle } from 'react-google-maps';
import _ from 'lodash';
import './modal.css';
import apiService from '../../../../api/apiService';

const MapWithAMarker = React.memo(
  withScriptjs(
    withGoogleMap((props) => {
      const dispatch = useDispatch();
      const { placeId, campaignName, kmMileRatio, isWorldWide } = props;
      const metters =
        campaignName.geo_unit === 'KM'
          ? campaignName.radius * kmMileRatio * 1609
          : campaignName.radius * 1609;
      const { moveMakerAddress } = useSelector((state) => state.GetlatLngReducer);
      const makerPosition = {
        lat:
          placeId !== null
            ? moveMakerAddress !== null
              ? parseFloat(moveMakerAddress.lat)
              : parseFloat(placeId.lat)
            : moveMakerAddress !== null
            ? parseFloat(moveMakerAddress.lat)
            : 50.4476917,
        lng:
          placeId !== null
            ? moveMakerAddress !== null
              ? parseFloat(moveMakerAddress.lng)
              : parseFloat(placeId.lng)
            : moveMakerAddress !== null
            ? parseFloat(moveMakerAddress.lng)
            : 30.5220573
      };
      const options = {
        fillColor: '#007fff',
        strokeColor: '#007fff',
        strokeOpacity: 0.8,
        strokeWeight: 0.1,
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 2
      };

      const OnmakerDragEnd = (value) => {
        const newLat = value.latLng.lat();
        const newLng = value.latLng.lng();
        dispatch(MoveMaker({ lat: newLat, lng: newLng }));
        apiService.getAddressGoogleMapByLatLong({ lat: newLat, long: newLng }).then((response) => {
          const address = response.data.formatted_address;
          dispatch(MoveMaker({ lat: newLat, lng: newLng, address }));
        });
        dispatch(DelectePlaceIdDefault(null));
      };

      return (
        <GoogleMap
          defaultZoom={8}
          defaultCenter={{ lat: 50.4476917, lng: 30.5220573 }}
          center={makerPosition || { lat: 50.4476917, lng: 30.5220573 }}
        >
          <Marker
            draggable={true}
            onDragEnd={OnmakerDragEnd}
            position={makerPosition || { lat: 50.4476917, lng: 30.5220573 }}
          />
          <Circle
            options={options}
            radius={!isWorldWide ? metters || 0 : 0}
            center={makerPosition}
            defaultCenter={makerPosition}
          />
        </GoogleMap>
      );
    })
  )
);
const ModalCreateCampaignAddress = ({
  classes,
  open,
  handleClose,
  btnSave,
  campaignName,
  kmMileRatio,
  campaign,
  geoUnit,
  profile,
  locale,
  handleChangeRadiusUnit,
  handleChangeInput,
  isWorldWide
}) => {
  const dispatch = useDispatch();
  const debounceTime = useRef(null);
  const [searchTxt, setSearchTxt] = useState('');
  const [isChange, setIsChange] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [chooseAddress, setChooseAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    long: 0,
    address: ''
  });
  const [radiusOptions, setRadiusOptions] = useState([]);
  const handleOnchangeSearch = (e) => {
    dispatch(MoveMaker(null));
    dispatch(DelectePlaceIdDefault(null));
    const value = e.target.value;
    if (debounceTime.current) {
      clearTimeout(debounceTime.current);
    }
    debounceTime.current = setTimeout(() => {
      setIsChange(!isChange);
    }, 500);
    setSearchTxt(value);
    setIsSelect(false);
  };
  useEffect(() => {
    if (campaignName.editing) {
      return dispatch(
        DelectePlaceIdDefault({
          lat: campaignName.lat,
          lng: campaignName.long,
          address: campaignName.address
        })
      );
    }
    dispatch(DelectePlaceIdCreation(null));
  }, []);
  const handleSelectAddress = (place_id, address) => {
    setIsChange(!isChange);
    setSearchTxt(address);
    setChooseAddress(address);
    dispatch(getPlacesIdAddress(place_id));
    setIsSelect(true);
    dispatch(MoveMaker(null));
    setCoordinates({
      lat: place_id.lat,
      long: place_id.lng,
      address: address
    });
  };

  useEffect(() => {
    if (!_.isEmpty(geoUnit)) {
      let rOptions = [];
      _.forEach(geoUnit, (value) => {
        rOptions.push({
          key: value[0],
          display: getUKTextMile(value[1], locale)
        });
      });
      setRadiusOptions(rOptions);
    }
  }, [geoUnit]);

  useEffect(() => {
    if (radiusOptions && radiusOptions.length) {
      let radiusOptionsTemp = [...radiusOptions];
      radiusOptionsTemp = radiusOptionsTemp.map((ro) => {
        return {
          ...ro,
          display: getUKTextMile(ro.display, locale)
        };
      });
      setRadiusOptions(radiusOptionsTemp);
    }
  }, [locale]);

  useEffect(() => {
    if (isSelect) {
      return dispatch(SearchAddress(''));
    }
    if (campaignName.creation) {
      dispatch(SearchAddress(searchTxt));
      return dispatch(Getlatlng(null));
    }
    dispatch(SearchAddress(searchTxt));
  }, [isChange]);
  const { moveMakerAddress } = useSelector((state) => state.GetlatLngReducer);
  const { searchAddress } = useSelector((state) => state.GetlatLngReducer);
  const { placeId } = useSelector((state) => state.GetlatLngReducer);
  const { deletePlaceID } = useSelector((state) => state.GetlatLngReducer);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Translate value='CampaignsCreation.CampaignsCreationGeoTagsModalTitle' />
        </DialogTitle>
        <DialogContent>
          <div
            css={css`
              .input-geo {
                min-width: 240px;
              }
            `}
          >
            {/* <input type="text" onChange={handleOnchangeSearch} /> */}
            <Grid className='grib__custom'>
              <span
                style={{
                  lineHeight: '45px',
                  fontSize: '16px',
                  marginRight: '5px',
                  width: '70px',
                  color: '#9fb0cb',
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  display: 'inline-block'
                }}
              >
                {I18n.t('CampaignsCreation.CampaignsCreationGeoTagsModalSelectAddress')}
              </span>
              <TextField
                variant='outlined'
                onChange={handleOnchangeSearch}
                placeholder={I18n.t('Campaigns.CampaignsTypeAddress')}
                css={css`
                  width: 896px;
                  height: 10px !important;
                  margin-top: 3px;
                  margin-left: 10px;
                `}
                value={
                  searchTxt
                    ? moveMakerAddress !== null
                      ? moveMakerAddress.address
                      : searchTxt
                    : moveMakerAddress
                    ? moveMakerAddress.address
                    : deletePlaceID === null
                    ? searchTxt
                    : deletePlaceID.address
                }
              />
              <div
                style={{
                  position: 'absolute',
                  top: '25px',
                  left: '73px',
                  zIndex: 99,
                  width: '896px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                <div className='selectAddress'>
                  {searchAddress.length ? (
                    <ul>
                      {searchAddress.map((address, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            handleSelectAddress(address.place_id, address.description);
                          }}
                        >
                          <PinDropIcon style={{ color: '#eee', fontSize: 20 }} />{' '}
                          {address.description}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ display: 'none' }}></div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid className='grib__custom__latlng'>
              <div>
                <span
                  style={{
                    lineHeight: '45px',
                    fontSize: '15px',
                    marginRight: '5px',
                    width: '70px',
                    color: '#9fb0cb',
                    fontWeight: 500,
                    fontFamily: 'Roboto',
                    display: 'inline-block'
                  }}
                >
                  {I18n.t('CampaignsCreation.CampaignsCreationGeoTagsModalSelectLongitude')}
                </span>
                <TextField
                  style={{
                    width: '252px',
                    height: '20px',
                    marginLeft: '10px',
                    zIndex: 10
                  }}
                  variant='outlined'
                  value={
                    deletePlaceID === null
                      ? placeId !== null
                        ? moveMakerAddress !== null
                          ? moveMakerAddress.lng
                          : placeId.lng
                        : moveMakerAddress !== null
                        ? moveMakerAddress.lng
                        : 0
                      : deletePlaceID.lng
                  }
                />
              </div>
              <div style={{ marginLeft: '20px' }}>
                <span
                  style={{
                    lineHeight: '45px',
                    fontSize: '15px',
                    marginRight: '5px',
                    width: '70px',
                    color: '#9fb0cb',
                    fontWeight: 500,
                    fontFamily: 'Roboto',
                    display: 'inline-block'
                  }}
                >
                  {I18n.t('CampaignsCreation.CampaignsCreationGeoTagsModalSelectLatitude')}
                </span>
                <TextField
                  css={css`
                    width: 252px;
                    z-index: 10;
                  `}
                  variant='outlined'
                  value={
                    deletePlaceID === null
                      ? placeId !== null
                        ? moveMakerAddress !== null
                          ? moveMakerAddress.lat
                          : placeId.lat
                        : moveMakerAddress !== null
                        ? moveMakerAddress.lat
                        : 0
                      : deletePlaceID.lat
                  }
                />
              </div>
              <div
                style={{ marginLeft: '20px' }}
                css={css`
                  .select-input-wrapper > div:nth-child(2) {
                    display: flex;
                    width: 80px;
                  }
                  .select-input-wrapper fieldset + div {
                    min-width: 80px;
                    height: 52px;
                  }
                  .select-input-wrapper .select-input > div:nth-child(2) + div {
                    position: absolute;
                    width: 100%;
                    z-index: 10;
                  }
                  .select-input-wrapper fieldset + div > div:nth-child(2) > div {
                    padding: 8px 3px;
                  }
                `}
              >
                <span
                  style={{
                    lineHeight: '45px',
                    fontSize: '15px',
                    marginRight: '5px',
                    width: '70px',
                    color: '#9fb0cb',
                    fontWeight: 500,
                    fontFamily: 'Roboto',
                    display: 'inline-block'
                  }}
                >
                  {I18n.t('CampaignsCreation.CampaignsCreationInfoRadius')}
                </span>
                <div
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  className='select-input-wrapper'
                >
                  <TextField
                    css={css`
                      width: 112px;
                      z-index: 10;
                    `}
                    variant='outlined'
                    defaultValue={campaign.radius || 0}
                    onChange={(e) => handleChangeInput(e, e.target.value, 0, 'radius')}
                    disabled={isWorldWide}
                  />
                  {!_.isEmpty(radiusOptions) && (
                    <SelectInput
                      className='select-input'
                      isNormalSelection={true}
                      selectedValue={
                        campaign.geo_unit
                          ? {
                              key: campaign.geo_unit,
                              display: getUKTextMile(
                                _.filter(radiusOptions, (x) => x.key === campaign.geo_unit)[0]
                                  .display,
                                locale
                              )
                            }
                          : profile && profile.supplier && profile.supplier.country === 'UKR'
                          ? { key: 'KM', display: 'KM' }
                          : { key: 'MILE', display: getUKTextMile('MILE', locale) }
                      }
                      options={radiusOptions}
                      onChangeSelect={handleChangeRadiusUnit}
                      disabled={isWorldWide}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </div>
          <Grid style={{ marginTop: '40px' }}>
            <MapWithAMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              coordinates={coordinates}
              placeId={placeId}
              campaignName={campaignName}
              kmMileRatio={kmMileRatio}
              isWorldWide={isWorldWide}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // dispatch(Getlatlng(null));
              handleClose(false);
            }}
            variant='outlined'
            style={{
              color: '#0028c2',
              border: '1px solid #0028c2',
              boxShadow: '0 8px 12px -8px rgba(0, 127, 255, 0.24)',
              borderRadius: '24px',
              padding: '8px 18px'
            }}
          >
            <CloseIcon style={{ fontSize: '14px', marginRight: '5px' }} />{' '}
            {I18n.t('CampaignsCreation.CampaignsCreationButtonCancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                Getlatlng({
                  lat:
                    placeId !== null
                      ? moveMakerAddress !== null
                        ? moveMakerAddress.lat
                        : placeId.lat
                      : moveMakerAddress !== null
                      ? moveMakerAddress.lat
                      : 0,
                  long:
                    placeId !== null
                      ? moveMakerAddress !== null
                        ? moveMakerAddress.lng
                        : placeId.lng
                      : moveMakerAddress !== null
                      ? moveMakerAddress.lng
                      : 0,
                  address: chooseAddress
                    ? moveMakerAddress !== null
                      ? moveMakerAddress.address
                      : chooseAddress
                    : moveMakerAddress !== null
                    ? moveMakerAddress.address
                    : chooseAddress
                })
              );
              handleClose(false);
            }}
            autoFocus
            style={{
              color: '#fff',
              border: '1px solid #0028c2',
              boxShadow: '0 8px 12px -8px rgba(0, 127, 255, 0.24)',
              borderRadius: '24px',
              padding: '8px 22px',
              backgroundColor: '#0028c2',
              marginRight: '20px'
            }}
          >
            <CheckIcon style={{ fontSize: '14px', marginRight: '5px' }} />{' '}
            {I18n.t('CampaignsCreation.CampaignsCreationButtonSave')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.i18n.locale
});

export default connect(mapStateToProps)(ModalCreateCampaignAddress);
